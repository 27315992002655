import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getExclusiveEndDateWithTz } from '../../util/dates';
import config from '../../config';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      // console.log('SearchSuccess', payload.data)
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const startDate = hasDateValues ? dateValues[0] : null;
    const endDate = hasDateValues ? dateValues[1] : null;

    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};

    // Find configs for 'dates-length' filter
    // (type: BookingDateRangeLengthFilter)
    const filterConfigs = config.custom.filters;
    const idOfBookingDateRangeLengthFilter = 'dates-length';
    const dateLengthFilterConfig = filterConfigs.find(
      f => f.id === idOfBookingDateRangeLengthFilter
    );
    // Extract time zone
    const timeZone = dateLengthFilterConfig.config.searchTimeZone;

    const now = Date.now()
    const now90 = now + 89 * 24 * 3600 * 1000
    const nowDate = new Date(now)
    const now90Date = new Date(now90)
    const [y, m, d] = [nowDate.getFullYear(), nowDate.getMonth() + 1, nowDate.getDate()]
    const [y90, m90, d90] = [now90Date.getFullYear(), now90Date.getMonth() + 1, now90Date.getDate()]
    const pad = s => ~~s < 10 ? '0' + s : s
    const nowStr = `${y}-${pad(m)}-${pad(d)}`
    const now90Str = `${y90}-${pad(m90)}-${pad(d90)}`

    return hasDateValues
      ? {
          start: formatDateStringToTz(startDate, timeZone),
          end: getExclusiveEndDateWithTz(endDate, timeZone),

          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
          availability: 'time-partial',

          ...minDurationMaybe,
        }
      : {
          start: formatDateStringToTz(nowStr, timeZone),
          end: getExclusiveEndDateWithTz(now90Str, timeZone),
          availability: 'time-partial',
          minDuration: 5
        }
  }

  const { perPage, price, dates, minDuration, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const availabilityMaybe = availabilityParams(dates, minDuration);

  const params = {
    ...rest,
    ...priceMaybe,
    ...availabilityMaybe,
    per_page: perPage,
    pub_isVerified: true,
  };

  const filterFutureListings = (response) => {
    const {data} = response 
    const {meta, data:list} = data
    
    const filteredListings = list
    // .filter(listing => {
    //   const {events = [], isGroupSession} = listing.attributes.publicData || {}
    //   const now = new Date() // 🔴 🟠 🟡 🟢 
    //   const dates = events.filter(date => new Date(date) >= now)
    //   // console.log(`!groupSession: ${isGroupSession ? '🔴' : '🟢'} hasDates: ${!!dates.length ?  '🟢' : '🔴'}`)
    //   return !isGroupSession || !!dates.length
    // })
    // console.log('😀 DID search listings!', {params, list, filteredListings})
    
    const totalItems = filteredListings.length || 0
    const totalPages =  Math.ceil(totalItems / meta.perPage)
    const newMeta = { ...meta, totalItems, totalPages}

    return {...response, data: {...data, data: filteredListings, meta: newMeta}}
  }

  return sdk.listings
    .query(params)
    .then(filterFutureListings)
    .then(response => {
      /* eslint-disable no-restricted-syntax */
      /* debug log for search queries:
      console.group(`got search results ---------- `, {sdk, params, response})
      console.log(`params[fields.listing]:`, params['fields.listing'])
      console.log(`params[fields.user]:`, params['fields.user'])
      console.log(`params.pub_serviceType:`, params.pub_serviceType)
      const out = []
      const data = response?.data?.data || []
      for (const item of data) {
        const {publicData: {isGroupSession, sessionType, serviceType = '?'}, title} = item?.attributes || {}
        //console.log(`response.data.data[0]`, {title, isGroupSession, sessionType, serviceType})
        out.push({title, isGroupSession, sessionType, serviceType})
      }
      console.table(out)
      console.groupEnd()
      // in case the filtering here is bad, check this:
      // https://github.com/dangerfarms/lemongrass/blob/master/create-search-indexes.sh
      */
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
    pub_isVerified: true,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
