/* eslint-disable no-debugger, no-unused-vars, camelcase,  spaced-comment, no-multi-spaces, 
   no-trailing-spaces, object-curly-spacing, valid-typeof, comma-spacing, no-void, quotes,
   indent, block-spacing, guard-for-in, import/extensions, no-unused-expressions, no-param-reassign,
   prefer-template, array-callback-return, no-restricted-syntax, no-use-before-define, no-multi-assign */

//: inspect.js v1.0 @ 2017.09.08.
//: moisture.js @ 2018.04.16.
//: fumd @ 2018.09.22. v1.1
//: esm @ 2019.11.30.

import {Ø, getRnd, isArr, Debug, DateHumanizer} from '../stdlib/corelib-esm.js'

//8#e63 Moisture is the essence of wetness. Merman!

const {wassert, weject} = Debug
const {dateToHumanDateTimeMs} = DateHumanizer
const {round} = Math

let logInspect = true
let logErrors = true
const MAX_OBJ_LENGTH = 110
const MAX_STRING_LENGTH = 120
const kore = []
const out = []
const outStyles = []
const reservedObjs = []
const reservedObjNames = []

const dumpLastObject = logfun => (logfun || console.log)(out.join('\n'), ...outStyles)

const getLastObjectDump = _ => ({out, outStyles})

const resetVariables = _ => { 
  kore.length = 0
  out.length = 0 
  outStyles.length = 0
  reservedObjs.length = 0
  reservedObjNames.length = 0
}

const addReservedObject = (obj, name) => {
  name === 'Object' && (name = __getObjectName(obj)) 
  reservedObjs.push(obj)
  reservedObjNames.push({obj, name})
}

const objectReserved = o => reservedObjs.includes(o) 
  ? {is: true, name: (reservedObjNames.find(e => e.obj === o) || {}).name} : {is: false}

const __getObjectName = obj => {
  const prot = obj // obj.__proto__ // eslint-disable-line no-proto
  if (prot && prot.constructor) {
    return prot.constructor.name
  } else {
    return 'Object'
  }
}

const __getOwnPropertyExceptionWhites = objName => {
  typeof objName === 'object' && (objName = __getObjectName(objName)) // polymorphism!
  if (objName && objName !== 'Object') { // object or Object?
    const propertyExceptionList = [
      {obj: 'HTMLDocument', all: true, white: [], black: []}
    ]
    const prEx = propertyExceptionList.find(elem => elem.obj === objName) || {}
    const whiteAll = !!prEx.all
    const whiteList = prEx.white || []
    return {whiteAll, whiteList}
  }
  return {whiteAll: false, whiteList: []}
}
    
const getCollapseLock = (parentPath, parentProp, parentObj, property) => {
  // window.chrome.runtime, runtime, Object, app
  const dontExpand = [
  //  {parObj: '*', prop: 'blobStore'},
    {parObj: 'Window', prop: ['history', 'applicationCache']},
    {parObj: 'Window', prop: ['*Exports', '*Globals']},
    {parObj: '*', parPathEnd: 'chrome.webstore', prop: ['ErrorCode', 'InstallStage']},
    {parObj: '*', parPathEnd: 'chrome.app', prop: '*'},
    {parObj: '*', parPathEnd: 'chrome.runtime', prop: '*'}
  ]
  const __matchMaskArr = (candidate, mask) => {
    const __matchMask = (candidate, mask) => {
      if (mask === '*' || candidate === mask) {
        return true
      }
      const arr = mask.split('*')
      if (arr.length === 2) {
        if (arr[0] && candidate.substr(0, arr[0].length) !== arr[0]) {
          //console.log('eleje nem stimmel', candidate, mask)
          return false
        }
        if (arr[1] && candidate.slice(-arr[1].length) !== arr[1]) {
          //console.log('vege nem stimmel', candidate, mask)
          return false
        }
        return true
      }
    }
    
    if (typeof mask === 'object') { // array
      for (const msk of mask) {
        if (__matchMask(candidate, msk)) {
          return true
        }
      }
    } else if (typeof mask === 'string') {
      return __matchMask(candidate, mask)
    }
    return false
  }

  for (const de of dontExpand) {
    if ((!de.parObj || de.parObj === '*' || de.parObj === parentObj) && 
      (!de.parPathEnd || de.parPathEnd === '*' || parentPath.indexOf(de.parPathEnd) > -1) &&
      (!de.prop || de.prop === '*' || __matchMaskArr(property, de.prop))) {
      // this is a match!
      //logInspect && console.log(`###MATCH: parentPath="${parentPath}" parentProp="${parentProp}" parentObj="${parentObj}" prop="${property}"`, {de})
      return true  
    }
  }
  return false
}

/*const removeFromArrayByValue = (arr, val) => {
  for (let i = 0; i < arr.length; i++) { 
    if (arr[i] === val) {
      arr.splice(i, 1)
      return
    }
  }
}*/

JSON.stringifyOnce = (obj, replacer) => {
  const printedObjects = []
  const printedObjectKeys = []

  function printOnceReplacer (key, value) {
    if (printedObjects.length > 20000) {  // browsers will not print more than 20K,
      return '<<object too long>>'        // algorithm will not be fast anyway then
    }
    let printedObjIndex = false
    printedObjects.forEach((obj, index) => {
      if (obj === value) {
        printedObjIndex = index
      }
    })
    if (key === '') {                     // root element
      printedObjects.push(obj)
      printedObjectKeys.push('root')
      return value
    } else if (printedObjIndex + '' !== 'false' && typeof value === 'object') {
      if (printedObjectKeys[printedObjIndex] === 'root') {
        return '<<pointer to root>>'
      } else {
        return '<<see ' + ((!!value && !!value.constructor) ? value.constructor.name.toLowerCase() : typeof value) + ' with key ' + printedObjectKeys[printedObjIndex] + '>>'
      }
    } else {
      const qualifiedKey = key || '(empty key)'
      printedObjects.push(value)
      printedObjectKeys.push(qualifiedKey)
      return replacer ? replacer(key, value) : value
    }
  }
  return JSON.stringify(obj, printOnceReplacer)
}

let sessionRandId = 0

const sessionShuffle = () => { sessionRandId = getRnd(1000, 9999) }
  
  //)OLD old OLD old OLD
const __inspectObjectLevel = (srcObj, path, param, depthRemained = 1, indent = 0) => {
  //console.group(`path=${path}, param=${param}, depth=${depthRemained}, indent=${indent}`, {srcObj})
  const deleteNulls = !param.includeNulls     // default: delete nulls (if not given)
  const deleteZeros = deleteNulls && !param.includeZeros // default: del zero numbers
  const reduceTypes = !param.includeAllTypes       // default: reduce to normal types
  const hideVirtuals = !param.verbose
  const hideReserveds = param.hideReservedDepth >= depthRemained
  const log2con = !!param.log

  // 5#bd4                           setting up obj naming                          
  const thisProp = path.split('.').slice(-1)[0]
  const thisObjName = __getObjectName(srcObj)

  // 5#bd4                         setting up own filtering                        
  const filterOwn = !param.notOnlyOwn            // default: filterOwn (if not given)
  const {whiteAll, whiteList} = __getOwnPropertyExceptionWhites(thisObjName)
  const effectiveFilterOwn = filterOwn && !whiteAll
  
  // 5#bd4                           setting up key stores                          
  const srcOwnKeys = Object.keys(srcObj)  
  const srcAllKeys = Object.allKeys(srcObj)
  const srcActKeys = effectiveFilterOwn ? srcOwnKeys : srcAllKeys//)OLD old OLD old OLD
  
  // 5#bd4                         copy and aux arrays, id                        
  const dstObj = {}  
  const privateKeysArray = [ // dstObj only!
    'createdOnWhiteList', 'lostOnCopy', 'lostOnHide', 'lostOnOwn', 
    'lostOnType', 'lostOnNull', 'objRandId', 'sessionRandId'
  ]    
  srcActKeys.map(key => { dstObj[key] = srcObj[key] }) // shallow copy, chg only this lvl
  
  // 5#bd4                       dst keys and key properties                      
  const dstImmutableKeys = Object.keys(dstObj) // own and enumerable props only, STATIC
  const dstActKeys = dstImmutableKeys                  // actual state of keys, DYNAMIC
  const dstKeyProps = {}                    // 'key properties' (deleted, created, etc)
  
  const __setDstKeyProperty = (key, prop, val) => {
    dstKeyProps[key] = dstKeyProps[key] || {}
    dstKeyProps[key][prop] = val  
  }
  const __getDstKeyProperty = (key, prop) => dstKeyProps[key] ? dstKeyProps[key][prop] : undefined
  const __AddDstKey = (key, storage) => {
    dstObj[key] = srcObj[key]
    dstActKeys.push(key)
    __setDstKeyProperty(key, 'added', true)//)OLD old OLD old OLD
    storage && storage.push(key)
  }
  const __deleteDstKey = (key, storage) => {
    delete dstObj[key]
    dstActKeys.removeFirstByValue(key)
    __setDstKeyProperty(key, 'deleted', true)
    storage && storage.push(key)
  }
  const __dumpDstKeyProperties = () => {
    console.table(dstKeyProps)
    dstKeyProps.loopKeys(key => {
      const keyRecord = dstKeyProps[key]
      keyRecord.loopKeys(property => out.push(` ${property}: ${keyRecord[property]}`))
      console.log(`${key}: {${out.join(', ').substr(0, 60)}}`)
    })
  }
  dstActKeys.map(key => __setDstKeyProperty(key, 'oncopy', true))
  dstActKeys.map(key => __setDstKeyProperty(key, 'typeof', typeof srcObj[key]))

  // 0#bd4                  arrays for collecting manipulated keys                 
  //const createdOnCopy = []
  const createdOnWhiteList = []//)OLD old OLD old OLD
  const lostOnCopy = []
  const lostOnHide = []
  const lostOnOwn = []
  const lostOnType = []
  const lostOnNull = []

  // 0#fb0                adding whitelisted not included keys                       
  if (effectiveFilterOwn) { // filterezunk ES nincs white all exception
    srcAllKeys.map(property => {    
      if (!dstActKeys.includes(property) && whiteList.includes(property)) { 
        // src-ben OWN v PARENT, itt nincs, DE a whitelistben van
        __AddDstKey(property, createdOnWhiteList)
      }
    })
  }
  // 0#fb0                disable properties hidden by input param                  
  if (param.hideArr.length) {
    dstActKeys.slice().map(property => {    
      if (param.hideArr.includes(property)) {
        param.hideHidden 
          ? __deleteDstKey(property, lostOnHide)
          : dstObj[property] = 'HIDDEN'
      }
    })
  }
  // 0#fb0                        reduce the types not needed                        
  if (!param.includeAllTypes) {
    dstActKeys.slice().map(property => {    
      const tof = typeof dstObj[property]
      if (tof === 'function' || tof === 'null' || tof === 'undefined') {//)OLD old OLD old OLD
        __deleteDstKey(property, lostOnType)
      }
    })
  }
  // 0#fb0                             reduce null values                            
  if (deleteNulls) {
    dstActKeys.slice().map(property => {    
      try {
        const val = dstObj[property] // srcObj value nem lenne jobb??
        const tof = typeof val
        if (val === null || val === undefined ||
          (tof === 'number' && val === 0 && deleteZeros) ||
          (tof === 'string' && val === '') ||
          (tof === 'object' && !Object.getPropertyCnt(val, filterOwn))) { // elvileg a szandekra jo ez
          __deleteDstKey(property, lostOnNull)
        }
      } catch (err) {
        console.log(err, property, {dstObj})
        debugger
      }
    })
  }
  if (!hideVirtuals) {
    createdOnWhiteList.length && (dstObj.createdOnWhiteList = createdOnWhiteList)
    lostOnCopy.length && (dstObj.lostOnCopy = lostOnCopy)
    lostOnHide.length && (dstObj.lostOnHide = lostOnHide)
    lostOnOwn.length && (dstObj.lostOnOwn = lostOnOwn)
    lostOnType.length && (dstObj.lostOnType = lostOnType)
    lostOnNull.length && (dstObj.lostOnNull = lostOnNull)//)OLD old OLD old OLD
    dstObj.objRandId = getRnd(100000, 999999)
    dstObj.sessionRandId = sessionRandId
  }     
  //log2con && console.log(`******** path: {${path}} thisProp: {${thisProp}} thisObjName: {${thisObjName}}`)
  
  //__dumpDstKeyProperties() // TEMPORARY
  
  const realDstKeys = Object.keys(dstObj) // uj kulcsok, mert a hozzaadasokat nem kovettuk
  
  //             iterate through remaining properties and print them              0#fb0
  realDstKeys.map(property => {  
    const realValue = dstObj[property]
    const own = Object.isOwnProperty(srcObj, property) // dst-ben minden own, ezert src!
    
    const tof = typeof realValue
    const tob = tof === 'object'  //: tob === object OR array
    const propObjName = tob ? __getObjectName(realValue) : ''
    const tofStr = (tob && propObjName !== 'Object') ? propObjName + ' object' : tof
    const toa = tob && isArr(realValue) // typeof realValue.splice === 'function'     
    const propCnt = tob ? Object.getPropertyCnt(realValue, filterOwn) : 0
    const objArrLen = tob ? propCnt : toa ? realValue.length : 0
    const nonEmptyObj = propCnt
    const isHidden = param.hideArr.includes(property)      
    const realDepthRemained = depthRemained + (param.elevateDepthArr.includes(property) ? 1 : 0)

    let mutableValue = realValue
    // no more dstObj / srcObj references!!
    // function call from here to the rest:
    const realProp = {//)OLD old OLD old OLD
      key: property,
      realValue,
      mutableValue, //ezt bent kell eloallitani
      origTypeOf: tof,
      mutatedTypeOf: tofStr,
      isTypeObject: tob, // possibly an array
      isTypeArray: toa,  // also an object
      
      valueObjName: propObjName, // nem objektnel ez nem ertelmes, igaz?
      nonEmptyObj,
      Object: {
        is: true, // kulonben nincs az egesz alobjektum
        isArray: toa,
        nonEmpty: nonEmptyObj
      }
    }
    // INTERRUPTED
    if (param.breakArr.includes(property)) {
      debugger 
    }
    const virtual = privateKeysArray.includes(property)//:our internal service keys
    // objrandid sessionid miert van megi benne???????????
    const propPath = path + '.' + property
  
    if (tof === 'string') {
      const nsIndex = realValue.search(/\S/)
      const pre = nsIndex > 4 ? 'S(' + nsIndex + ')+ ' : ''
      mutableValue = realValue.substring(nsIndex).substr(0, MAX_STRING_LENGTH)
      mutableValue = pre + `"${mutableValue}"`
      mutableValue.length === MAX_STRING_LENGTH && (mutableValue += '...')
    } else if (tof === 'number') {
      if (realValue > 1500E9 && realValue < 1600E9) {
        const humanDate  = dateToHumanDateTimeMs(realValue)
        mutableValue = humanDate + ' 🕓'/* + realValue*/ //:  Äkta människor:
      } else if (param.fix3 && !Number.isInteger(realValue)) {
        mutableValue = mutableValue.toFixed(3) //round(realValue * 1000) / 1000
      }
    } else if (tof === 'symbol') {
      mutableValue = String(mutableValue)//)OLD old OLD old OLD
    }
    let pre = ' = '
    let bLost = false
    const {is: isReserved, name: resName} = (tob && !hideReserveds)
      ? objectReserved(realValue) : {is: false}
    const lockCollapsed = getCollapseLock(path, thisProp, thisObjName, property)
    const arrTooLong = toa && objArrLen > param.maxArrLen
    const objTooBig = tob && propCnt > param.maxObjLen
    arrTooLong && (mutableValue = `Array too long! (${objArrLen} items.)`)
    objTooBig && (mutableValue = `Object has too many properties! (${propCnt} items.)`)
    isReserved && (mutableValue = `Reference ⭯ ${resName || '?'}`)
    
    const theEnd =    //: We won't expand more levels if any of these conditions are true:
      virtual ||        //: We generated this property (like 'reason').
      isReserved ||     //: Circular reference, we already had this object.
      !realDepthRemained || //: We reached the depth required.
      lockCollapsed ||  //: It's in the predefined exception list.
      !nonEmptyObj ||   //: It's not an object or it's an empty object
      objTooBig ||      //: It's an object and it has too many properties.
      arrTooLong ||     //: It's an array and it has too many items.
      isHidden //:ezt utolag raktam bele mert szerintem hianyzik
    const skipPreview = 
      objTooBig ||
      arrTooLong ||
      virtual ||
      isHidden          //: not needed as it makes an unexpandable string of it
    let isJson = false
    
    if (tob) { // if object, mutableValue can be transformed
      if (!isReserved) {
        addReservedObject(realValue, propPath)//)OLD old OLD old OLD
        
        if (theEnd && !skipPreview) { // no expanding from here, preview will be displayed
          let json = 'ERROR'
          //logInspect && console.log('Will try JSON.stringify now with:', {realValue})
          try {
            json = JSON.stringify(realValue)
          } catch (e) {
            logErrors && console.warn('JSON.stringify failed -> fallback.', {e}, {realValue})
            try {
              json = JSON.stringifyOnce(realValue, indent)
            } catch (e) {
              logErrors && console.warn('JSON stringifyOnce also failed, dummy string will be used.', {e}, path, property)
              json = `Cannot stringify ${property} of ${path}`
            }
          }
          // arrays:
          if (toa) { // array
            json = json.split('"').join('').split(',').join(', ')
          } else { // object
            //const pairs = json.split('","')
            json = json.split('":').join(': ').split(',"').join(', ').split('{"').join('{')
          }
          // general
          if (json === '{}' && nonEmptyObj) {
            const _keys = Object[filterOwn ? 'keys' : 'allKeys'](realValue).join(', ')
            if (_keys.length) {
              json = `{Lost: ${_keys}}`
              bLost = true
            }
          }
          mutableValue = json.substring(0, MAX_OBJ_LENGTH)
          const _cutEnd = mutableValue.length === MAX_OBJ_LENGTH ? '"...' : ''
          mutableValue += _cutEnd
          isJson = true
          toa && (mutableValue = '(' + objArrLen + ') ' + mutableValue)
        } else {
          mutableValue = __getOwnPropertyExceptionWhites(realValue).whiteAll ? 'VIP w/ all props' : ''
          pre = ': '
        }
      }
    }//)OLD old OLD old OLD
    const onWhite = {
      str: '#000000',     //4c black
      nostr: '#444444A0', //4c gray OPA
      virt: '#0000CC',    //4c blue
      own: '#000000',     //4c black
      norm: '#bbbb66',    //4c yellbrn
      
      res: '#ffffe080',   //4bg pale yell OPA
      json: '#f0f8ff80',  //4bg pale blue OPA
      nojson: '#ffe8e880',//4bg pink OPA
      hid: '#ffcc3380',   //4bg orange OPA
      
      lost: '#AA666660',  //4c dark red OPA
      nolost: '#AA6666',  //4c dark red          
      nobstr: '#EE00EE',  //4c magenta
      nobnostr: '#CC0000' //4c deep red
    }
    const onBlack = {
      str: '#ffffff',     //6c black -> white
      nostr: '#ffffffd0', //6c gray -> light gray OPA
      virt: '#cceeff',    //6c blue -> light blue
      own: '#ffffff',     //6c black -> white
      norm: '#eeee99',    //6c yellbrn -> yellow
      
      res: '#ffffe0C0',   //6bg pale yell OPA
      json: '#f0f8ffC0',  //6bg pale blue OPA
      nojson: '#ffe8e8C0',//6bg pink OPA
      hid: '#ffcc33C0',   //6bg orange OPA
      
      lost: '#ffdddd60',  //6c dark red -> orange pink OPA
      nolost: '#AA6666',  //6c dark red -> orange pink
      nobstr: '#e4d3e4',  //6c magenta -> pink
      nobnostr: '#ffcccc' //6c deep red -> dirty pink
    }
    const col = param.dark ? onBlack : onWhite //)OLD old OLD old OLD
    typeof realValue === 'string' && (mutableValue = mutableValue.split('\n').join('⮨'))
    const inputStyle = (param.baseStyle || '') + ';'
    const fontStyle = param.minFontSize ? `font-size:${param.minFontSize + realDepthRemained}px;` : ';'
    const commonStyle = inputStyle + fontStyle
    const typeStyle = commonStyle + 'font-weight:300;color:' + (tof !== tofStr ? col.str : col.nostr)
    const _normW = tob ? '700' : '400'
    const _normC = virtual ? col.virt : (own ? col.own : col.norm)
    const _normBg = 'none'
    const normStyle = commonStyle + `font-style:italic;font-weight:${_normW};color:${_normC};` // background-color:${_normBg}`
    const _bg = tob ? (isReserved ? col.res : (isJson ? col.json : col.nojson)) : (isHidden ? col.hid : 'none')
    const _fg = tob ? (bLost ? col.lost : col.nolost) : (tof === 'string' ? col.nobstr : col.nobnostr)
    const valStyle = commonStyle + `color:${_fg};background:${_bg};`
    const indentPart = '.    '.repeat(indent)
    const typePart = '%c (' + (toa ? 'array' : tofStr) + ')'
    const varPart = '' + property
    const valPart = pre + '%c' + mutableValue
    log2con && console.log('%c' + indentPart + varPart + valPart + typePart, normStyle, valStyle, typeStyle, virtual ? {arr: dstObj[property]} : '')
    out.push('%c' + indentPart + varPart + valPart + typePart) // ' '.repeat(indentPart.length)
    outStyles.push(normStyle, valStyle, typeStyle)

    if (!theEnd) {
      __inspectObjectLevel(dstObj[property], propPath, param, realDepthRemained - 1, indent + 1)
    }
  })//)OLD old OLD old OLD
  //console.groupEnd()
}

const __inspectObject = (srcObj, path, param, depthRemained = 1, indent = 0) => {
  //console.group(`path=${path}, param=${param}, depth=${depthRemained}, indent=${indent}`, {srcObj})
  const deleteNulls = !param.includeNulls     // default: delete nulls (if not given)
  //+ne legyen default  a deletezero
  const deleteZeros = deleteNulls && !param.includeZeros // default: del zero numbers
  const reduceTypes = !param.includeAllTypes       // default: reduce to normal types
                                                //:no function / null / undef types
  const hideVirtuals = !param.verbose
  const hideReserveds = param.hideReservedDepth >= depthRemained
  const log2con = !!param.log

  //4#bd4                           setting up obj naming                          
  const thisProp = path.split('.').slice(-1)[0]
  const thisObjName = __getObjectName(srcObj)

  //4#bd4                         setting up own filtering                        
  const filterOwn = !param.notOnlyOwn            // default: filterOwn (if not given)
  const {whiteAll, whiteList} = __getOwnPropertyExceptionWhites(thisObjName)
  const effectiveFilterOwn = filterOwn && !whiteAll
  
  //4#bd4                           setting up key stores                          
  const srcOwnKeys = Object.keys(srcObj)  
  const srcAllKeys = Object.allKeys(srcObj)
  const srcActKeys = effectiveFilterOwn ? srcOwnKeys : srcAllKeys
  
  //4#bd4                         copy and aux arrays, id                        
  const dstObj = {}  
  const privateKeysArray = [ // dstObj only!
    'createdOnWhiteList', 'lostOnCopy', 'lostOnHide', 'lostOnOwn', 
    'lostOnType', 'lostOnNull', 'objRandId', 'sessionRandId'
  ]    
  srcActKeys.map(key => { dstObj[key] = srcObj[key] }) // shallow copy, chg only this lvl
  
  //4#bd4                       dst keys and key properties                      
  const dstImmutableKeys = Object.keys(dstObj) // own and enumerable props only, STATIC
  const dstActKeys = dstImmutableKeys                  // actual state of keys, DYNAMIC
  const dstKeyProps = {}                    // 'key properties' (deleted, created, etc)
  
  const __setDstKeyProperty = (key, prop, val) => {
    dstKeyProps[key] = dstKeyProps[key] || {}
    dstKeyProps[key][prop] = val  
  }
  const __getDstKeyProperty = (key, prop) => dstKeyProps[key] ? dstKeyProps[key][prop] : undefined
  const __AddDstKey = (key, storage) => {
    dstObj[key] = srcObj[key]
    dstActKeys.push(key)
    __setDstKeyProperty(key, 'added', true)
    storage && storage.push(key)
  }
  const __deleteDstKey = (key, storage) => {
    delete dstObj[key]
    dstActKeys.removeFirstByValue(key)
    __setDstKeyProperty(key, 'deleted', true)
    storage && storage.push(key)
  }
  const __dumpDstKeyProperties = () => {
    console.table(dstKeyProps)
    dstKeyProps.loopKeys(key => {
      const keyRecord = dstKeyProps[key]
      keyRecord.loopKeys(property => out.push(` ${property}: ${keyRecord[property]}`))
      console.log(`${key}: {${out.join(', ').substr(0, 60)}}`)
    })
  }
  dstActKeys.map(key => __setDstKeyProperty(key, 'oncopy', true))
  dstActKeys.map(key => __setDstKeyProperty(key, 'typeof', typeof srcObj[key]))

  //5#bd4                  arrays for collecting manipulated keys                 
  //const createdOnCopy = []
  const createdOnWhiteList = []
  const lostOnCopy = []
  const lostOnHide = []
  const lostOnOwn = []
  const lostOnType = []
  const lostOnNull = []

  //5#fb0                adding whitelisted not included keys
  if (effectiveFilterOwn) { // filterezunk ES nincs white all exception
    srcAllKeys.map(property => {    
      if (!dstActKeys.includes(property) && whiteList.includes(property)) { 
        // src-ben OWN v PARENT, itt nincs, DE a whitelistben van
        __AddDstKey(property, createdOnWhiteList)
      }
    })
  }
  if (param.hideArr.length) {//5#fb0                disable properties hidden by input param
    dstActKeys.slice().map(property => {    
      if (param.hideArr.includes(property)) {
        param.hideHidden 
          ? __deleteDstKey(property, lostOnHide)
          : dstObj[property] = 'HIDDEN'
      }
    })
  }
  if (!param.includeAllTypes) {//5#fb0                        reduce the types not needed
    dstActKeys.slice().map(property => {    
      const tof = typeof dstObj[property]
      if (tof === 'function' || tof === 'null' || tof === 'undefined') {
        __deleteDstKey(property, lostOnType)
      }
    })
  }
  if (deleteNulls) {//5#fb0                             reduce null values
    dstActKeys.slice().map(property => {    
      try {
        const val = dstObj[property] // srcObj value nem lenne jobb??
        const tof = typeof val
        if (val === null || val === undefined ||
          (tof === 'number' && val === 0 && deleteZeros) ||
          (tof === 'string' && val === '') ||
          (tof === 'object' && !Object.getPropertyCnt(val, filterOwn))) { // elvileg a szandekra jo ez
          __deleteDstKey(property, lostOnNull)
        }
      } catch (err) {
        console.log(err, property, {dstObj})
        debugger
      }
    })
  }
  if (!hideVirtuals) {
    createdOnWhiteList.length && (dstObj.createdOnWhiteList = createdOnWhiteList)
    lostOnCopy.length && (dstObj.lostOnCopy = lostOnCopy)
    lostOnHide.length && (dstObj.lostOnHide = lostOnHide)
    lostOnOwn.length && (dstObj.lostOnOwn = lostOnOwn)
    lostOnType.length && (dstObj.lostOnType = lostOnType)
    lostOnNull.length && (dstObj.lostOnNull = lostOnNull)
    dstObj.objRandId = getRnd(100000, 999999)
    dstObj.sessionRandId = sessionRandId
  }     
  //log2con && console.log(`******** path: {${path}} thisProp: {${thisProp}} thisObjName: {${thisObjName}}`)
  
  //__dumpDstKeyProperties() // TEMPORARY
  
  const generateRefProp = (property, dstObj, srcObj) => {
    const isOwnProp = Object.isOwnProperty(srcObj, property) // dst-ben minden own, ezert src!
    const realValue = dstObj[property]
    const tof = typeof realValue
    const isObject = tof === 'object'  //: isObject === object OR array
    const propObjName = isObject ? __getObjectName(realValue) : ''
    const isArray = isObject && isArr(realValue)
    const objPropCnt = isObject ? Object.getPropertyCnt(realValue, filterOwn) : 0
    const arrLen = isArray ? realValue.length : 0
    const nonEmptyObj = !!objPropCnt
    const typeofStr = isArray 
      ? 'array' 
      : (isObject && propObjName !== 'Object')
        ? propObjName + ' object'
        : tof
    
    return {
      property,
      key: property,
      isOwnProp,
      isPropertyVirtual: privateKeysArray.includes(property),
      realValue,
      tof,
      origTypeOf: tof,
      typeofStr,
      mutatedTypeOf: typeofStr,
      isObject, // possibly an array
      isArray,  // also an object
      propObjName,
      valueObjName: propObjName, // nem objektnel ez nem ertelmes, igaz?
      objPropCnt,
      arrLen,        
      nonEmptyObj,
      isHidden: param.hideArr.includes(property),
      realDepthRemained: depthRemained + (param.elevateDepthArr.includes(property) ? 1 : 0)
    }
  }
  
  const generateMutableValueByType = refProp => {
    const {tof, realValue} = refProp
    let mutableValue = realValue
    
    if (tof === 'string') {
      const nsIndex = realValue.search(/\S/)
      const pre = nsIndex > 4 ? 'S(' + nsIndex + ')+ ' : ''
      mutableValue = realValue.substring(nsIndex).substr(0, MAX_STRING_LENGTH)
      mutableValue = pre + `"${mutableValue}"`
      mutableValue.length === MAX_STRING_LENGTH && (mutableValue += '...')
      mutableValue = mutableValue.split('\n').join('⮨')
    } else if (tof === 'number') {
      if (!param.dontConvertTime && realValue > 1500E9 && realValue < 1600E9) {
        const humanDate  = dateToHumanDateTimeMs(realValue)
        mutableValue = humanDate + ' 🕓'/* + realValue*/ //:  Äkta människor:
      } else if (param.fix3 && !Number.isInteger(realValue)) {
        mutableValue = mutableValue.toFixed(3) //round(realValue * 1000) / 1000
      }
    } else if (tof === 'symbol') {
      mutableValue = String(mutableValue)
    } else if (tof === 'object') {//8#5af Special object type previews by object type
      if (typeof realValue.jquery === 'string') {//5#5af jQuery
        mutableValue = `jQuery: ${realValue.length} items`
        refProp.isStringified = true
        refProp.deadEnd = true
      }
    }
    refProp.mutableValue = mutableValue
  }

  const checkMutableValueLimits = refProp => {
    const {isObject, isArray, isReserved, resName, arrLen, objPropCnt, realValue} = refProp
    
    const arrTooLong = refProp.arrTooLong = isArray && arrLen > param.maxArrLen
    const objTooBig = refProp.objTooBig = isObject && !isArray && objPropCnt > param.maxObjLen
    
    arrTooLong && (refProp.mutableValue = `Array too long! (${arrLen} items.)`)
    objTooBig && (refProp.mutableValue = `Object has too many properties! (${objPropCnt} items.)`)
    isReserved && (refProp.mutableValue = `Reference ⭯ ${resName || '?'}`)
    
    const {isPropertyVirtual, isHidden, realDepthRemained, lockCollapsed, nonEmptyObj} = refProp
    const {deadEnd} = refProp

    refProp.theEnd = //: We won't expand more levels if any of these conditions are true:
      deadEnd ||
      isPropertyVirtual ||  //:We generated this property (like 'reason').
      isReserved ||        //: Circular reference, we already had this object.
      !realDepthRemained ||//: We reached the depth required.
      lockCollapsed ||  //: It's in the predefined exception list.
      !nonEmptyObj ||   //: It's not an object or it's empty object (only nonempty objs expand)
      objTooBig ||      //: It's an object and it has too many properties.
      arrTooLong ||     //: It's an array and it has too many items.
      isHidden           //:ezt utolag raktam bele mert szerintem hianyzik...
      
    refProp.skipPreview = 
      //objTooBig ||
      //arrTooLong ||
      isPropertyVirtual ||
      isHidden          //: not needed as it makes an unexpandable string of it
  }
  
  const stringifyFast = (object, limit = 160) => {
    const ret = []
    let isErr = false
    let len = 0
    for (const key in object) {
      const prop = object[key]
      const tof = typeof prop
      if (tof === 'number') {
        const propLimited = param.fix3 && !Number.isInteger(prop) ? prop.toFixed(3) : prop
        const str = `${key}: ${propLimited}`
        len += str.length
        ret.push(str)
      } else if (tof === 'string') {
        const str = `${key}: ${prop.substr(0, 20)}`
        len += str.length
        ret.push(str)
      } else if (tof === 'object') {
        try {
          if (isArr(prop)) {
            const str = `${key}: [${prop.length}]`
            len += str.length
            ret.push(str)
          } else {
            const str = `${key}: {${prop === null ? 'null' : prop.getPropertyCnt()}}`
            len += str.length
            ret.push(str)
          }
        } catch (err) {
          //console.warn(err.message, {err})
          isErr = true
          const str = `Error for key${key}: ${err.message}`
          len += str.length
          ret.push(str)
        }
      } else if (tof === 'function') {
        const str = `${key}: f()`
        len += str.length
        ret.push(str)
      } /*else if (['null', 'undefined'].includes(tof)) {
        console.warn(`stringifyFast trouble:`, {tof, key, object, prop})
      }*/
      if (len > limit) {
        break
      }
    }
    return [isErr, ret.join(', ')]
  }
  
  const generateJsonEndPointFromObj = refProp => {//5#cdeStringifying objects & arrays
    const {property, nonEmptyObj, realValue, isArray} = refProp
    const [errInJson, json] = stringifyFast(realValue)
    const bLost = false
    /*
    let json = 'ERROR'
    
    try { json = JSON.stringify(realValue) } catch (e) {
      logErrors && console.warn('JSON.stringify failed -> fallback.', {e}, {realValue})
      try { json = JSON.stringifyOnce(realValue, indent) } catch (e) {
        logErrors && console.warn('JSON stringifyOnce also failed, dummy string will be used.', {e}, path, property)
        json = `Cannot stringify ${property} of ${path}`
      }
    }
    
    json = isArray
      ? json.split('"').join('').split(',').join(', ')
      : json.split('":').join(': ').split(',"').join(', ').split('{"').join('{')

    if (json === '{}' && nonEmptyObj) {
      const _keys = Object[filterOwn ? 'keys' : 'allKeys'](realValue).join(', ')
      if (_keys.length) {
        json = `{Lost: ${_keys}}`
        bLost = true
      }
    }*/
    refProp.mutableValue = json.substring(0, MAX_OBJ_LENGTH)
    const _cutEnd = refProp.mutableValue.length === MAX_OBJ_LENGTH ? '"...' : ''
    refProp.mutableValue += _cutEnd
    refProp.isJson = true
    refProp.isError = errInJson
    refProp.json = json
    refProp.bLost = bLost
    isArray && (refProp.mutableValue = '(' + refProp.arrLen + ') ' + refProp.mutableValue)
  }

  const realDstKeys = Object.keys(dstObj) // uj kulcsok, mert a hozzaadasokat nem kovettuk
  
  //realDstKeys.map(property => {  //8#fa0 iterate through remaining properties
  for (const property of realDstKeys) {
    const refProp = generateRefProp(property, dstObj, srcObj)
    if (isArr(srcObj) && parseInt(property) >= param.maxArrIndex) {
      break //+ return ha .map
    }
    generateMutableValueByType(refProp)
    
    const propPath = path + '.' + property
    
    weject(param.breakArr.includes(property))
    
    const {isObject, realValue} = refProp
    const {is, name} = refProp.isObject && !hideReserveds ? objectReserved(realValue) : {}
    refProp.lockCollapsed = getCollapseLock(path, thisProp, thisObjName, property)
    refProp.isReserved = is || false
    refProp.resName = name
          
    checkMutableValueLimits(refProp)
      
    const {isReserved, isArray} = refProp
    
    if (isObject) { //4If object, mutableValue can be generated from jsoning
      if (!isReserved) {//4If reserved, theEnd already was set.
        addReservedObject(realValue, propPath)
        if (refProp.theEnd && !refProp.skipPreview) {
          refProp.isStringified || generateJsonEndPointFromObj(refProp)//:json preview
        } else {
          refProp.mutableValue = __getOwnPropertyExceptionWhites(realValue).whiteAll ? 'VIP w/ all props' : ''
        }
      }
    }
    
    const {isOwnProp = '', bLost = '', tof} = refProp
    
    const root = {
      cclass: [
      'kore ',
        refProp.isError ? 'err' : '',
        refProp.isObject ? 'obj ' : '',
        refProp.isArray ? 'arr ' : '',
        refProp.isReserved ? 'res ' : '',
        refProp.isJson ? 'json ' : '',
        refProp.isHidden ? 'hid ' : '',
        refProp.isPropertyVirtual ? 'virt ' : ''
      ].join(''),
      attr: {
        fontsize: refProp.realDepthRemained,
        own: isOwnProp,
        bLost,
        indent,
        tof
      },
      propPart: {
        class: 'prop',
        text: property + ':'
      },
      valPart: {
        class: 'val',
        text: refProp.mutableValue + ''
      },
      typePart: {
        class: 'type',
        text: '(' + refProp.typeofStr + ')'
      },
      whatever: refProp.isPropertyVirtual ? {arr: dstObj[property]} : ''
    }
    
    kore.push(root)

    if (!refProp.theEnd) {
      __inspectObject(dstObj[property], propPath, param, refProp.realDepthRemained - 1, indent + 1)
    }
  }
  //console.groupEnd()
}

const inspectObject = (srcObj, param = {}, depthRemained = 3, indent = 0) => {
  resetVariables()
  sessionShuffle()
  
  console.assert(typeof param.hide === Ø || typeof param.hide === 'string', 'Bad hide type!') 
  console.assert(typeof param.elevateDepth === Ø || typeof param.elevateDepth === 'string', 'Bad elevateDepth type!')     
  
  const convert2Array = p => param[p] ? param[p].split(', ').join(',').split(',') : [] 
  
  if (param.useData) {
    param.silent = true
  }    
  if (!srcObj) {
    return param.silent ? {kore: [], out: [], outStyles: []} : 'null'
  }
  logInspect = !param.nodbg
  logErrors = !param.noerr
  
  param.maxArrLen = param.maxArrLen || 100
  param.maxArrIndex = param.maxArrIndex || 50
  param.maxObjLen = param.maxObjLen || 100
  param.hideArr = convert2Array('hide')
  param.elevateDepthArr = convert2Array('elevateDepth')
  param.breakArr = convert2Array('break')    
  param.name = param.name || __getObjectName(srcObj)
  param.name === 'Object' && (param.name = 'ROOT')
  addReservedObject(srcObj, param.name)
  
  const logPrePost = !param.silent && !param.core
  logPrePost && param.collapsed && console.groupCollapsed(param.collapsed)
  
  logPrePost && console.log(`--Object start: {%c${param.name}%c}--${param.log ? 'Log mode' : ''}---`, 'font-weight: 900', 'font-weight: normal')
  
  // eslint-disable-next-line no-unused-expressions
  param.name !== 'ROOT' && (out.push(`Object: %c${param.name}`) + outStyles.push('font-weight: 900'))
  try {
    param.useData
      ? __inspectObject(srcObj, param.name, param, depthRemained, indent)
      : __inspectObjectLevel(srcObj, param.name, param, depthRemained, indent)
  } catch (err) {
    console.warn(`error in __inspectObject`, err)
    debugger
  }    
  !param.silent && !param.log && dumpLastObject()
    
  logPrePost && console.log(`--Object end-- line=${out.length} reserved[${reservedObjs.length}]=[${{arr: reservedObjNames.map(_ => _.name).join(', ')}}]`)
  
  logPrePost && param.collapsed && console.groupEnd()
  return param.useData ? kore : param.silent ? getLastObjectDump() : ''
}

const param = {
  useData: false,
//7----these params are only for use in NO useData mode---------------------------
  silent: false,         //2no console output (only store everything in 'out')
  dark: false,           //3dark mode colors (no useData only)
  core: false,           //5no prologue/epilogue lines (if not silent)
  baseStyle: '',         //6starting style (no useData)
//2----these are the COMMON params------------------------------------------------
  name: '',              //5name of the object (for display)
  fix3: false,           //3only 3 digits after . (floats)
  maxArrLen: 100,        //3LockCollapse if bigger!
  maxArrIndex: 50,       //3Show only index < ArrMaxIndex elems of an array
  maxObjLen: 100,        //3LockCollapse if bigger! (prop number in object)
  hide: [],              //2list of (hidden) properties not to expand
  hideHidden: false,     //3don't display these hidden props at all
  elevateDepth: [],      //4depthRemained won't be decreased for these props
  hideReservedDepth: 0,  //7hide recursives on levels deeper than this
  dontConvertTime: false,//7don't humanize date/time
  includeZeros: false,   //6can be true, it's ok (log 0-s)
  includeNulls: false,   //7can be true, but not really (log undefs)
  includeAllTypes: false,//8not really can be true, never ever (log functions)
  notOnlyOwn: false,     //8show prototype props
//9----DEBUG and LOG stuff--------------------------------------------------------
  nodbg: false,          //9if true: logInspect -> false (should be default off)
  noerr: false,          //9if true: logErrors -> false
  break: [],             //9break on these props
  log: false,            //9mi a fasz ez? ->log2con
  verbose: false         //9also log lost properties (????)
}

console.ins = inspectObject
console.last = dumpLastObject

export {
  inspectObject,
  dumpLastObject,
  getLastObjectDump
}

const {globalThis} = typeof window === Ø ? global : window

globalThis.inspectObject = inspectObject
globalThis.dumpLastObject = dumpLastObject
globalThis.getLastObjectDump = getLastObjectDump
