import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes

export const LineItemDirectPrice = props => {
  const { totalOverride = 0, isProvider, intl } = props;
  console.log('LineItemDirectPrice', {isProvider, totalOverride})
  if (isProvider || !totalOverride) {
    return null
  }
  const totalLabel = <FormattedMessage id='BookingBreakdown.total' />
  const formattedTotalPrice = formatMoney(intl, totalOverride)
  //console.log({formattedTotalPrice, totalPrice})
  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  )
}

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl } = props;
  console.log('lineitemunitprice', {isProvider, ...transaction})

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider
    ? <FormattedMessage id={providerTotalMessageId} />
    : <FormattedMessage id='BookingBreakdown.total' />

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  console.log({formattedTotalPrice, totalPrice})

  return (
    <>
      {/*<hr className={css.totalDivider} />*/}
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
