/* eslint-disable react/jsx-sort-props, no-return-assign */

import React, {useEffect, useState} from "react";
import {bool, func} from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import {injectIntl, intlShape} from "../../util/reactIntl";
import {PRIVATE_SESSION, propTypes} from "../../util/types";
import {denormalisedResponseEntities, ensureCurrentUser} from "../../util/data";
import {fetchCurrentUser} from "../../ducks/user.duck";
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar, NamedRedirect,
  Page,
  UserNav,
} from "../../components";
import {TopbarContainer} from "..";
import {isScrollingDisabled} from "../../ducks/UI.duck";
import {isCustomer, isPractitioner, isProfileEditingEnabled} from "../../util/user";
import css from "./GuideAvailabilityPage.module.css";
import EditListingAvailabilityPanel
  from "../../components/EditListingAvailabilityPanel/EditListingAvailabilityPanel";
import {getCurrentPractitionerMasterListing} from "../../models/listingAdapter";
import {onCombo, tabLog} from "../../models/misc";
import {
  requestAddAvailabilityException,
  requestDeleteAvailabilityException,
  requestFetchAvailabilityExceptions,
  requestUpdateListing,
  updateListing
} from "../EditListingPage/EditListingPage.duck";
import {AVAILABILITY} from "../../components/EditListingWizard/EditListingWizardTab";
import {listAvailabilityExceptionsForListing} from "../../util/listings";
import {loadData} from "./GuideAvailabilityPage.duck";

export const GuideAvailabilityPageComponent = props => {
  const {
    saveGuideAvailabilityInProgress,
    currentUser,
    currentUserListing,
    GuideAvailabilityChanged,
    onChange,
    scrollingDisabled,
    onSubmitGuideAvailability,
    profileEditingDisabled,
    onUpdateListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    intl,
  } = props;

  const [listing, setListing] = useState(null);
  const [availabilityExceptions, setAvailabilityExceptions] = useState([]);
  const [count, setCount] = useState(0)
  const forceRerender = () => {
    console.log('GuideAvailability chgd, will redraw in 100ms')
    setTimeout(() => setCount(count + 1), 100)
  }

  //console.log(`📅GuideAvailabilityPageComponent`, {listing, ...props})
  tabLog({ml: 40, bg: '#aae', c: '#006'}, `\n%c📅⚜️GuideAvailabilityPage`, {listing, ...props})

  useEffect(() => {
    let mounted = true;
    console.log(`📅🔴guide.useEffect START, will read master listing now`)
    getCurrentPractitionerMasterListing()
      .then(({listing, availabilityExceptions}) => {
        if (mounted && listing) {
          const {availabilityPlan = {}} = listing.attributes || {}
          console.log(`📅🟢guide.useEffect got master listing, will set state`, {listing})
          console.log(`📅🟢guide.useEffect availabilityPlan`, availabilityPlan)
          console.log(`📅🟢guide.useEffect availabilityExceptions`, availabilityExceptions)
          setListing(listing)
          setAvailabilityExceptions(availabilityExceptions)
        }
      })
    return () => mounted = false;
  }, [count])

  const user = ensureCurrentUser(currentUser);
  const title = intl.formatMessage({id: "GuideAvailabilityPage.title"});

  // TODO: This is a workaround to hide the default NamedRedirect from /account
  // TODO: as customers shouldn't see this page.
  if (!isPractitioner(user)) {
    return <NamedRedirect name="ContactDetailsPage"/>;
  }

  return (
    <Page scrollingDisabled={scrollingDisabled} title={title}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="GuideAvailabilityPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          {isCustomer(currentUser) ? null : (
            <UserNav listing={currentUserListing} selectedPageName="GuideAvailabilityPage"/>
          )}
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="GuideAvailabilityPage"
                                             currentUser={currentUser}/>
        <LayoutWrapperMain>
          <div className={css.content}>
            {
              listing &&
              <EditListingAvailabilityPanel
                // availabilityExceptions={props.availabilityExceptions}
                availabilityExceptions={availabilityExceptions}
                durationBased={false}
                disabled={false}
                isGroupSession={false}
                listing={listing}
                listingTitleOverride={currentUser.attributes.profile.displayName}
                sessionType={PRIVATE_SESSION}
                onChange={() => console.log('on change called')}
                panelUpdated={false}
                updateInProgress={false}
                fetchExceptionsInProgress={false}
                // onManageDisableScrolling={() => console.log('on manage disable scrolling')}
                onManageDisableScrolling={() => {}}
                onAddAvailabilityException={onCombo(onAddAvailabilityException, forceRerender)}
                onDeleteAvailabilityException={onCombo(onDeleteAvailabilityException, forceRerender)}
                ready={false}
                // disabled=fetchInProgress,
                errors={{}}
                onSubmit={values => {
                  //console.log(`guide onSubmit called`, values.availabilityPlan.entries[0])
                  const ret = onUpdateListing({
                    ...values,
                    id: listing.id
                  });
                  ret.then(forceRerender)
                  return ret
                }}
                submitButtonText="Update Availability"
              />
            }
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

GuideAvailabilityPageComponent.defaultProps = {
  currentUser: null,
};

GuideAvailabilityPageComponent.propTypes = {
  saveGuideAvailabilityInProgress: bool, // .isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  GuideAvailabilityChanged: bool, // .isRequired,
  onChange: func, // .isRequired,
  scrollingDisabled: bool.isRequired,
  profileEditingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {currentUser } = state.user;
  const { availabilityExceptions } = state.EditListingPage;
  return {
    availabilityExceptions,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    profileEditingDisabled: !isProfileEditingEnabled(currentUser),
  };
};

const mapDispatchToProps = dispatch => ({
  // onChange: () => dispatch(saveGuideAvailabilityClear()),
  onUpdateListing: (values) => dispatch(requestUpdateListing(AVAILABILITY, values)),
  // {
  //   console.log(`guide onUpdateListing called`, values?.availabilityPlan?.entries?.[0])
  //   const reqUL = requestUpdateListing(AVAILABILITY, values)
  //   console.log(`guide onUpdateListing.requestUpdateListing returned, will dispatch`, typeof reqUL)
  //   return dispatch(reqUL)
  // },
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
  onDeleteAvailabilityException: params => dispatch(requestDeleteAvailabilityException(params)),
});

const GuideAvailabilityPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(GuideAvailabilityPageComponent);

GuideAvailabilityPage.loadData = loadData;

export default GuideAvailabilityPage;
