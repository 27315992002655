/* eslint-disable multiline-ternary, quotes, comma-dangle, jsx-quotes, semi, prefer-template,
   no-param-reassign, prefer-template, react/button-has-type, jsx-a11y/click-events-have-key-events,
   jsx-a11y/no-static-element-interactions, react/prop-types
*/

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import css from './ListingPage.module.css';

// const getCertificateInfo = (certificateOptions, key) => certificateOptions.find(c => c.key === key);

const SectionHeading = props => {
  const {
    richTitle,
    // listingCertificate,
    // certificateOptions,
    showContactUser,
    onContactUser,
    children
  } = props;

  // const certificate = getCertificateInfo(certificateOptions, listingCertificate);
  // const showCertificate = certificate && !certificate.hideFromListingInfo;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <InlineTextButton onClick={onContactUser} rootClassName={css.contactLink}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  );
};

export default SectionHeading;
