import React from 'react'
import {string} from 'prop-types'
import classNames from 'classnames'
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl'
import {Logo, NamedLink,} from '..'
import css from './Footer.module.css'
import {SocialLink} from '../SectionSocial/SectionSocial'
import {Link} from "react-router-dom";

const renderSocialMediaLinks = intl => [
  <SocialLink
    key='clubhouse'
    icon='clubhouse'
    link='https://www.clubhouse.com/join/shift-your-padadigm/LfgQSNVp'
    message='LandingPageSectionSocial.clubhouse'
    size='small'
  />,
  <SocialLink
    key='insta'
    icon='instagram'
    link='https://www.instagram.com/lemongrass.life'
    message='LandingPageSectionSocial.instagram'
    size='small'
  />,
  <SocialLink
    key='medium'
    icon='medium'
    link='https://link.medium.com/wzgWqT9TAjb'
    message='LandingPageSectionSocial.medium'
    size='small'
  />,
]

const Footer = props => {
  const {rootClassName, className, intl} = props
  const socialMediaLinks = renderSocialMediaLinks(intl)
  const classes = classNames(rootClassName || css.root, className)

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink className={css.logoLink} name="LandingPage">
                <span className={css.logo}>
                  <Logo format="desktop"/>
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription"/>
                </p>
                <p className={css.organizationCopyright}>
                  <a className={css.copyrightLink} href="http://sundayventures.co.uk/" rel="noreferrer" target="_blank">
                    <FormattedMessage id="Footer.copyright"/>
                  </a>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="AboutPage" to={{hash: '#join-us'}}>
                    <FormattedMessage id="Footer.toNewListingPage"/>
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="AboutPage">
                    <FormattedMessage id="Footer.toAboutPage"/>
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="FAQPage">
                    <FormattedMessage id="Footer.toFAQPage"/>
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="CorporateProgramPage">
                    Corporate Program
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="AboutPage" to={{hash: '#join-us'}}>
                    <FormattedMessage id="Footer.toContactPage"/>
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}/>
            <div className={css.searchesExtra}/>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <br/>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink className={css.legalLink} name="TermsOfServicePage">
                      <FormattedMessage id="Footer.termsOfUse"/>
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.legalLink} name="PrivacyPolicyPage">
                      <FormattedMessage id="Footer.privacyPolicy"/>
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <a className={css.organizationCopyrightMobile} href="http://sundayventures.co.uk/" rel="noreferrer" target="_blank">
              <FormattedMessage id="Footer.copyright"/>
            </a>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink className={css.privacy} name="PrivacyPolicyPage">
                <FormattedMessage id="Footer.privacy"/>
              </NamedLink>
              <NamedLink className={css.terms} name="TermsOfServicePage">
                <FormattedMessage id="Footer.terms"/>
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
