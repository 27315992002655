/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, comma-dangle, quotes, no-return-assign, import/no-unresolved,
   react/jsx-curly-newline, no-unused-expressions
*/
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InlineTextButton, NamedLink } from '..'
import { FormattedMessage } from '../../util/reactIntl'
import { MessageFormatterForPrefix } from '../../util/formattingHelpers'
import css from './TabNavHorizontal.module.css'
import cssLeft from './LeftPublicNav.module.css'
import cssRight from './RightPrivateNav.module.css'
import {GROUP_SESSION, PRIVATE_SESSION} from '../../util/types'

export const LIGHT_SKIN = 'light'
export const DARK_SKIN = 'dark'

const { bool, func, node, object, string } = PropTypes

const Tab = props => {
  const { className, disabled, text, selected, onClick, linkProps, isDark } = props
  const darkSkinClasses = isDark
    ? classNames(css.tabContentDarkSkin, {
        [css.selectedTabContentDarkSkin]: selected,
        [css.disabledDarkSkin]: disabled,
      })
    : null

  const linkClasses = classNames(
    css.tabContent,
    {
      [css.selectedTabContent]: selected,
      [css.disabled]: disabled,
    },
    darkSkinClasses
  )

  const buttonClasses = classNames(
    css.tabContent,
    css.button,
    {
      [css.selectedTabContent]: selected,
      [css.disabled]: disabled,
    },
    darkSkinClasses
  )

  const isButton = !!onClick

  return (
    <div className={className}>
      {isButton
        ? <InlineTextButton onClick={onClick} rootClassName={buttonClasses}>{text}</InlineTextButton>
        : <NamedLink className={linkClasses} {...linkProps}>{text}</NamedLink>}
    </div>
  )
}

Tab.defaultProps = { className: null, disabled: false, selected: false }

Tab.propTypes = {
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  onClick: func,
  linkProps: object,
  isDark: bool.isRequired,
}

const hLog = false

const LeftRightNavBar = props => {
  const { className, rootClassName, css, tabs, skin } = props
  const tabRootClassName = css.tab
  const isDark = skin === DARK_SKIN
  const classes = classNames(rootClassName || css.root, { [css.darkSkin]: isDark }, className)
  const tabClasses = tabRootClassName || css.tab
  return (
    <nav className={classes}>
      {hLog && console.log(`TabNavHorizontal`, tabs, {classes, tabClasses, className, rootClassName})}
      {hLog && console.table(tabs.map(({text: {props: {id}}, linkProps: {name, to}, disabled, selected, ...rest}) =>
        ({id, name, to: to?.search ? `Search=${to.search}` : JSON.stringify(to), ...rest})))}
      {tabs.map((tab, index) => {
        const key = typeof tab.text === 'string' ? tab.text : index
        return <Tab key={key} className={tabClasses} {...tab} isDark={isDark} />
      })}
    </nav>
  )
}

const LeftPublicNav = props => {
  const {className, rootClassName} = props
  const classes = classNames(rootClassName || cssLeft.nav, className)

  const LandingPageNavMessage = MessageFormatterForPrefix('LandingPageNav')

  const tabs = [{
    text: LandingPageNavMessage('practitionersLink'),
    selected: false,
    disabled: false,
    linkProps: {
      name: 'ProfileListPage'
    }
  }, {
    text: LandingPageNavMessage('wokeshopsLink'),
    selected: false,
    disabled: false,
    linkProps: {
      name: 'SearchPage',
      to: {
        search: 'pub_serviceType=wokeshop',
      }
    }
  }, {
    text: LandingPageNavMessage('experiencesLink'),
    selected: false,
    disabled: false,
    linkProps: {
      name: 'SearchPage',
      to: {
        search: 'pub_serviceType=experience',
      }
    }
  }, {
    text: LandingPageNavMessage('oneToOnesLink'),
    selected: false,
    disabled: false,
    linkProps: {
      name: 'SearchPage',
      to: {
        search: 'pub_serviceType=oneToOne'
      }
    }
  }]

  hLog && console.log(`SecondaryNav`, tabs)

  return (
    <LeftRightNavBar className={classes} css={cssLeft} skin='light' tabs={tabs} />
  )
}

const RightPrivateNav = props => {
  const { className, rootClassName, selectedPageName, sessionType } = props
  void selectedPageName

  const classes = classNames(rootClassName || cssRight.root, className)

  const tabs = [{
    text: (
      <FormattedMessage
        id='UserNav.manageServiceLink'
        values={{sessionType: (<FormattedMessage id={GROUP_SESSION} />)}}
      />),
    selected: sessionType === GROUP_SESSION,
    disabled: false,
    linkProps: {
      name: 'ManageListingsPage',
      params: {
        service: GROUP_SESSION,
      }
    },
  },
  {
    text: (
      <FormattedMessage
        id='UserNav.manageServiceLink'
        values={{sessionType: (<FormattedMessage id={PRIVATE_SESSION} />)}}
      />),
    selected: sessionType === PRIVATE_SESSION,
    disabled: false,
    linkProps: {
      name: 'ManageListingsPage',
      params: {
        service: PRIVATE_SESSION,
      }
    },
  }]

  hLog && console.log(`UserNav`, tabs)

  return (
    <LeftRightNavBar className={classes} css={cssRight} skin='light' tabs={tabs} />
  )
}

export const HorMenuNav = props => (
  <>
    <LeftPublicNav {...props} />
    <RightPrivateNav {...props} />
  </>
)

// HorMenuNav.defaultProps = {
//   className: null,
//   rootClassName: null,
//   tabRootClassName: null,
//   tabClassName: null,
//   skin: LIGHT_SKIN,
// }

// HorMenuNav.propTypes = {
//   className: string,
//   rootClassName: string,
//   tabRootClassName: string,
//   tabs: arrayOf(
//     shape({
//       text: node.isRequired,
//       disabled: bool,
//       selected: bool,
//       linkProps: object.isRequired,
//     })
//   ).isRequired,
//   skin: oneOf([LIGHT_SKIN, DARK_SKIN]),
// }
