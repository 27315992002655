import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from '../../util/reactIntl';
import Gate from "../Gate/Gate"
import css from './SectionConsulation.module.css';
import charts from './images/services/charts.jpg';
import family from './images/services/family.jpg';
import healing from './images/services/healing.jpg';
import readings from './images/services/readings.jpg';
import sos from './images/services/sos.jpg';
import wellbeing from './images/services/wellbeing.jpg';

const SectionConsultation = props => {
  const {rootClassName, className} = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="LandingPageSectionConsultation.title"/>
      </div>
      <div className={css.subtitle}>
        <FormattedMessage id="LandingPageSectionConsultation.subtitle"/>
      </div>
      <div className={css.services}>
        <Gate hasShadow={true} imageUrl={readings}>
          <h1><a href="/s?pub_topics=has_any%3AReadings">Readings</a></h1>
        </Gate>
        <Gate hasShadow={true} imageUrl={charts}>
          <h1><a href="/s?pub_topics=has_any%3APersonal%20Charts">Charts</a></h1>
        </Gate>
        <Gate hasShadow={true} imageUrl={healing}>
          <h1><a href="/s?pub_topics=has_any%3AHealing">Healing</a></h1>
        </Gate>
        <Gate hasShadow={true} imageUrl={wellbeing}>
          <h1><a href="/s?pub_topics=has_any%3AWellbeing">Wellbeing</a></h1>
        </Gate>
        <Gate hasShadow={true} imageUrl={family}>
          <h1><a href="/s?pub_topics=has_any%3AFamily">Family</a></h1>
        </Gate>
        <Gate hasShadow={true} imageUrl={sos}>
          <h1><a href="/s?pub_topics=has_any%3ASOS">SOS</a></h1>
        </Gate>
      </div>
    </div>
  );
};

SectionConsultation.defaultProps = {rootClassName: null, className: null};

const {string} = PropTypes;

SectionConsultation.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionConsultation;
