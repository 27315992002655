/* eslint-disable no-void, spaced-comment, no-restricted-syntax, prefer-template,  no-debugger,
   object-curly-spacing, quotes */

import React from "react"
import { FormattedMessage } from "react-intl"
import css from "./ProfileSpecialitiesSection.module.css"
import {ListItem} from "../ListItem/ListItem"

export default function ProfileSpecialitiesSection ({ currentUser, intl }) {
  if (!currentUser) {
    return null
  }
  // console.log(currentUser)

  const {profile = {}} = currentUser?.attributes || {}
  const {publicData: {displayName, specialty = []} = {}} = profile
  const rootClass = specialty.length ? css.root : `${css.root} ${css.empty}`

  return (
    <div className={rootClass}>
      {specialty && (
        <div className={css.practitionerSpecialitiesContainer}>
          <div className={css.practitionerSpecialitiesLabel}>
            <FormattedMessage
              id='ProfilePage.practitionerSpecialities'
              values={{ practitionerFirstName: displayName }}
            />
          </div>
          <ul className={css.specialities}>
            {specialty?.map(value => (
              <ListItem key={value} message={intl.formatMessage({id: `Services.${value}`})} value={value} />
            ))}
          </ul>
        </div>)}
    </div>
  )
}
