/* eslint-disable multiline-ternary, quotes, comma-dangle, jsx-quotes, prefer-template,
   no-param-reassign, prefer-template, react/button-has-type, jsx-a11y/click-events-have-key-events,
   jsx-a11y/no-static-element-interactions, react/prop-types, no-return-assign
*/

import React, {useState} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {withRouter} from 'react-router-dom'
import unionWith from 'lodash/unionWith'
import classNames from 'classnames'
import {injectIntl, intlShape} from '../../util/reactIntl'
import {useEffectProm, useEffectExt} from '../../util/customHooks'
import config from '../../config'
import {parse, stringify} from '../../util/urlHelpers'
import {getListingsById} from '../../ducks/marketplaceData.duck'
import {isScrollingDisabled, manageDisableScrolling} from '../../ducks/UI.duck'
import {Page} from '../../components'
import {TopbarContainer} from '..'
import {listPractitioners} from '../../util/api'
import {featFlags} from '../../red/featureFlags'
import {searchListings, searchMapListings, setActiveListing} from './SearchPage.duck'
import {
  createSearchResultSchema,
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
} from './SearchPage.helpers'
import MainPanel from './MainPanel'
import css from './SearchPage.module.css'

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 96 // 24
const MODAL_BREAKPOINT = 768 // Search is in modal on mobile layout

const isWindowDefined = typeof window !== 'undefined'
const nop = () => {}
const gotoProfile = id => window.location.href = `/u/${id}`

const GuideInset = ({filteredPractitioners}) => filteredPractitioners.length
  ? <div className={css.guideResultLinks}>
      Guides:
      {filteredPractitioners.map(({id, displayName}) => 
        <div key={id} onClick={() => gotoProfile(id)}>{displayName || '??'}</div>)}
    </div>
  : null  

const SearchPageComponent = props => {
  const {
    intl,
    listings,
    filterConfig,
    sortConfig,
    history,
    location,
    onManageDisableScrolling,
    pagination: paginationInput,
    scrollingDisabled,
    searchInProgress,
    searchListingsError,
    searchParams,
    onActivateListing,
  } = props
  // console.log({props})

  const [isMobileModalOpen, setMobileModalOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [practitioners, setPractitioners] = useState([])

  // useEffectProm(listPractitioners(), users => setPractitioners(users) || console.log('🔴Fetched:', {users}), [])
  useEffectExt(isMounted => listPractitioners().then(users => isMounted() && setPractitioners(users)), [])

  const filteredPractitioners = searchString && featFlags.isDev
    ? practitioners
        .filter(pract => pract.displayName.toLowerCase().includes(searchString))
        .slice(0, 9)
    : []

  // Invoked if a modal is opened/closed from a child comp, e.g. if a filter modal is opened in mobile view
  const onOpenMobileModal = () => setMobileModalOpen(true)
  const onCloseMobileModal = () => setMobileModalOpen(true)

  const pagination = paginationInput || {
    perPage: RESULT_PAGE_SIZE,
  //   totalItems: 100,
  //   totalPages: 10,
  }
  const {page, ...searchInURL} = parse(location.search)
  pagination.page = page || 1
  // console.log({pagination})

  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig)

  // Page transition might initially use values from previous search
  const urlQueryString = stringify(urlQueryParams)
  const paramsQueryString = stringify(
    pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
  )
  const searchParamsAreInSync = urlQueryString === paramsQueryString

  const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig)

  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT

  const {address, bounds, origin} = searchInURL || {}
  const {title, description, schema} = createSearchResultSchema(listings, address, intl)

  // Set topbar class based on if a modal is open in a child component
  const topbarClasses = isMobileModalOpen ? classNames(css.topbarBehindModal, css.topbar) : css.topbar

  const onChange = searchStr => setSearchString(searchStr.toLowerCase())

  // N.B. openMobileMap button is sticky.
  // For some reason, stickyness doesn't work on Safari, if the element is <button>
  // console.log({listings})
  return (
    <Page
      description={description}
      schema={schema}
      scrollingDisabled={scrollingDisabled}
      title={title}
    >
      <TopbarContainer
        className={'_SP-TopBarContainer ' + topbarClasses}
        currentPage='SearchPage'
        currentSearchParams={urlQueryParams}
        onChange={onChange}
      />
      <div className={'_SP-TopBarContainerDiv ' + css.container}>
        <MainPanel
          guideResultLinks={<GuideInset {...{filteredPractitioners}} />}
          history={history}
          listings={listings}
          onActivateListing={onActivateListing}
          onCloseModal={onCloseMobileModal}
          onManageDisableScrolling={onManageDisableScrolling}
          onMapIconClick={nop}
          onOpenModal={onOpenMobileModal}
          pagination={pagination}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          searchParamsAreInSync={searchParamsAreInSync}
          searchParamsForPagination={parse(location.search)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          urlQueryParams={validQueryParams}
        />
        {/*<ModalInMobile*/}
        {/*  className={css.mapPanel}*/}
        {/*  id='SearchPage.map'*/}
        {/*  isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}*/}
        {/*  onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}*/}
        {/*  onManageDisableScrolling={onManageDisableScrolling}*/}
        {/*  showAsModalMaxWidth={MODAL_BREAKPOINT}*/}
        {/*>*/}
        {/*  <div className={css.mapWrapper}>*/}
        {/*    {shouldShowSearchMap ? (*/}
        {/*      <SearchMap*/}
        {/*        activeListingId={activeListingId}*/}
        {/*        bounds={bounds}*/}
        {/*        center={origin}*/}
        {/*        isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}*/}
        {/*        listings={mapListings || []}*/}
        {/*        location={location}*/}
        {/*        messages={intl.messages}*/}
        {/*        onCloseAsModal={() => {*/}
        {/*          onManageDisableScrolling('SearchPage.map', false)*/}
        {/*        }}*/}
        {/*        onMapMoveEnd={this.onMapMoveEnd}*/}
        {/*        reusableContainerClassName={css.map}*/}
        {/*      />*/}
        {/*    ) : null}*/}
        {/*  </div>*/}
        {/*</ModalInMobile>*/}
      </div>
    </Page>
  )
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
}

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage
  // console.log('mapStateToProps', state.SearchPage)
  const pageListings = getListingsById(state, currentPageResultIds)
  const mapListings = getListingsById(
    state, unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid))

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  }
}

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
})

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent)

SearchPage.loadData = (params, search) => {
  // console.log('SearchPage.loadData', {params, search})
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  })
  const {page = 1, address, origin, ...rest} = queryParams
  const originMaybe = config.sortSearchByDistance && origin ? {origin} : {}
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData',],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.scaled-small', 'variants.scaled-medium', 'variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  })
}

export default SearchPage
