/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { extractUser } from '../../models/userAdapter'
import css from './ProfileAboutSection.module.css'
import icon from './images/icon.svg';

export default function ProfileAboutSection ({ profileUser, intl }) {
  if (!profileUser) {
    return null
  }
  const {publicData, profile} = extractUser(profileUser)

  return (
    <div className={css.root}>
      <div className={css.practitionerAboutContainer}>
        <div className={css.practitionerLanguagesLabel}>
          <FormattedMessage
            id='ProfilePage.practitionerAbout'
            values={{ practitionerFirstName: publicData.displayName }}
          />
        </div>
        <div className={css.bio}>{profile.bio}</div>
        {/* <div className={css.bio}>{publicData.experience}</div> */}
      </div>
      <div className={css.practitionerAboutImgContainer}>
        <div className={`${css.gateCommon} ${css.gateTop}`} />
        <div className={`${css.gateCommon} ${css.gateBottom}`} />
        <div className={css.gateMiddle} />
      </div>
    </div>
  )
}
