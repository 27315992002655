import React from "react";
import css from "./ListItem.module.css";


export const ListItem = props => {
  const { value, message } = props;
  return (
    <li key={value} className={css.item}>
      {message}
    </li>
  );
}
