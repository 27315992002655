/* eslint-disable object-curly-spacing, no-void, comma-dangle, spaced-comment, quotes, multiline-ternary,
   no-return-assign, import/no-unresolved
*/
import React, {Component} from 'react'
import {bool, string} from 'prop-types'
import {compose} from 'redux'
import {Field, Form as FinalForm} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import isEqual from 'lodash/isEqual'
import classNames from 'classnames'
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl'
import {ensureCurrentUser} from '../../util/data'
import languages from '../../util/languages.json'
import {propTypes} from '../../util/types'
import * as validators from '../../util/validators'
import {isUploadImageOverLimitError} from '../../util/errors'
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from "../../util/validators";
import {
  Avatar,
  Button,
  FieldBirthdayInput,
  FieldCheckboxGroup,
  FieldSelect,
  FieldTextInput,
  Form,
  IconSpinner,
  ImageFromFile,
  LocationAutocompleteInputField,
  ProfileEditingDisabledMessage,
} from '../../components'
import {services} from '../../constants'
import css from './ProfileSettingsForm.module.css'
import {isCustomer, isPractitioner} from '../../util/user'
import FieldCheckbox from "../../components/FieldCheckbox/FieldCheckbox";
import { featFlags } from '../../red/featureFlags'

let Red = featFlags.isSaveDebug && import('../../red/red').then(({Red: _}) => (Red = _).init())

const ACCEPT_IMAGES = `image/*`
const UPLOAD_CHANGE_DELAY = 2000 // Show spinner so that browser has time to load img srcset

const generateTranslations = intl => field => {
  const label = intl.formatMessage({id: `ProfileSettingsForm.${field}Label`})
  const placeholder = intl.formatMessage({id: `ProfileSettingsForm.${field}Placeholder`})
  const requiredMessage = intl.formatMessage({id: `ProfileSettingsForm.${field}Required`})
  //console.log(`generateTranslations`, {label, placeholder, requiredMessage})
  const validator = validators.required(requiredMessage)

  return {label, placeholder, requiredMessage, validator}
}

const getLanguagesOptions = ({withNone = false} = {}) => languages
  .slice(withNone ? 0 : 1)
  .map(({display, value}) => (
    <option key={display} value={value}>
      {display}
    </option>
  ))

const signLanguageOptions = [
  {display: 'both', value: 'both'},
  {display: 'primary', value: 'primary'},
  {display: 'secondary', value: 'secondary'},
  {display: 'neither', value: 'neither'},
]

const ProfileTextField = ({
                            field,
                            translationsFor,
                            type = 'text',
                            required = true,
                            validate = () => {
                            },
                            ...rest
                          }) => {
  const translations = translationsFor(field)
  const validator = required
    ? validators.composeValidators(translations.validator, validate)
    : validate

  return (
    <FieldTextInput
      id={field}
      label={translations.label}
      name={field}
      placeholder={translations.placeholder}
      type={type}
      validate={validator}
      {...rest}
    />
  )
}

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props)

    this.uploadDelayTimeoutId = null
    this.state = {uploadDelay: false}
    this.submittedValues = {}
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({uploadDelay: true})
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({uploadDelay: false})
      }, UPLOAD_CHANGE_DELAY)
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId)
  }

  render() {
    const debug = (state, fieldStates) => {
      for (const field in fieldStates) { // eslint-disable-line
        const {active, modified, touched, valid, visited} = fieldStates[field]
        console.log(`dbg`, field, {active, modified, touched, valid, visited})
      }
    }

    void debug;

    /**
     * Return validators if user is a practitioner, or null if customer, as all customer fields are
     * optional.
     **/
    const getValidatorsForUserType = (user, validators) =>
      isPractitioner(user) ? validators : null;

    return (
      <FinalForm
        {...this.props}
        {...{/* debug */}}
        mutators={{...arrayMutators}}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            profileEditingDisabled,
          } = fieldRenderProps

          //console.warn(`PSForm`, {fieldRenderProps, validators})

          const user = ensureCurrentUser(currentUser)
          const translationsFor = generateTranslations(intl)

          const addressRequiredMessage = intl.formatMessage({
            id: "ProfileSettingsForm.addressRequired",
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: "ProfileSettingsForm.addressNotRecognized",
          });

          const dateOfBirthTranslations = translationsFor('dateOfBirth')

          const required = validators.required(dateOfBirthTranslations.requiredMessage)
          const minAge = 18
          const minAgeRequired = validators.ageAtLeast(
            intl.formatMessage({id: 'ProfileSettingsForm.minimumAgeRequiredText'}, {minAge}),
            minAge,
          )

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner/>
              </div>
            ) : null

          const hasUploadError = !!uploadImageError && !uploadInProgress
          const errorClasses = classNames({[css.avatarUploadError]: hasUploadError})
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage
          const transientUser = {...user, profileImage: transientUserProfileImage}

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file
          const fileUploadInProgress = uploadInProgress && fileExists
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                aspectRatioClassName={css.squareAspectRatio}
                className={errorClasses}
                file={profileImage.file}
                id={profileImage.id}
                rootClassName={css.uploadingImage}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          })
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                disableProfileLink
                renderSizes='(max-width: 767px) 96px, 240px'
                user={transientUser}
              />
            ) : null

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id='ProfileSettingsForm.changeAvatar'/>
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id='ProfileSettingsForm.addYourProfilePicture'/>
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id='ProfileSettingsForm.addYourProfilePictureMobile'/>
                </div>
              </div>
            )

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id='ProfileSettingsForm.updateProfileFailed'/>
            </div>
          ) : null

          const classes = classNames(rootClassName || css.root, className)
          const submitInProgress = updateInProgress
          const submittedOnce = Object.keys(this.submittedValues).length > 0
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues)
          const submitDisabled =
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress ||
            profileEditingDisabled

          const debugProps = {
            submitDisabled,
            invalid,
            pristine,
            pristineSinceLastSubmit,
            profileEditingDisabled
          }

          const {year, month, day} = values.dateOfBirth || {}
          const dateOfBirth = values.dateOfBirth ? new Date(year, month - 1, day) : null
          const isPract = isPractitioner(user)


          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values
                handleSubmit(e)
              }}
            >

              <ProfileEditingDisabledMessage profileEditingDisabled={profileEditingDisabled}/>

              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id='ProfileSettingsForm.yourProfilePicture'/>
                </h3>
                <p>
                  <FormattedMessage id='ProfileSettingsForm.yourProfilePicture.description'/>
                </p>
                <Field
                  accept={ACCEPT_IMAGES}
                  disabled={uploadInProgress}
                  form={null}
                  id='profileImage'
                  label={chooseAvatarLabel}
                  name='profileImage'
                  type='file'
                  uploadImageError={uploadImageError}
                >
                  {fieldProps => {
                    const {accept, id, input, label, disabled, uploadImageError} = fieldProps
                    const {name, type} = input
                    const onChange = e => {
                      const file = e.target.files[0]
                      form.change(`profileImage`, file)
                      form.blur(`profileImage`)
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`
                        onImageUpload({id: tempId, file})
                      }
                    }

                    let error = null

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id='ProfileSettingsForm.imageUploadFailedFileTooLarge'/>
                        </div>
                      )
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id='ProfileSettingsForm.imageUploadFailed'/>
                        </div>
                      )
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          {...{accept, disabled, id, name, onChange, type}}
                          className={css.uploadAvatarInput}
                        />
                        {error}
                      </div>
                    )
                  }}
                </Field>
                <div className={css.tip}>
                  <FormattedMessage id='ProfileSettingsForm.tip'/>
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id='ProfileSettingsForm.fileInfo'/>
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id='ProfileSettingsForm.displayNameHeading'/>
                </h3>
                <div className={css.displayName}>
                  <ProfileTextField
                    field='displayName'
                    translationsFor={translationsFor}
                    required={isPract ? true : false}
                  />
                </div>
              </div>
              <div className={classNames(css.sectionContainer)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id='ProfileSettingsForm.bioHeading'/>
                </h3>
                <LocationAutocompleteInputField
                  className={css.city}
                  field='city'
                  name='city'
                  required={false}
                  type='text'
                  {...translationsFor('city')}
                />
                {isPract && (
                  <>
                    <ProfileTextField
                      className={css.bio}
                      field='bio'
                      name='bio'
                      translationsFor={translationsFor}
                      type='textarea'
                    />
                    <ProfileTextField
                      className={css.descriptionWord}
                      field='descriptionWord'
                      required={false}
                      translationsFor={translationsFor}
                      type='text'
                    />
                    <ProfileTextField
                      className={css.color}
                      field='color'
                      required={false}
                      translationsFor={translationsFor}
                      type='text'
                    />
                  </>)}
              </div>
              {isPract && (
                <div className={classNames(css.sectionContainer)}>
                  <h3 className={css.sectionTitle}>
                    <FormattedMessage id='ProfileSettingsForm.experienceHeading'/>
                  </h3>
                  <ProfileTextField
                    className={css.experience}
                    field='experience'
                    required={false}
                    translationsFor={translationsFor}
                    type='textarea'
                  />
                  <ProfileTextField
                    className={css.qualifications}
                    field='qualifications'
                    required={false}
                    translationsFor={translationsFor}
                    type='textarea'
                  />
                  {/* console.log(`FCBoxG`, {services, intl}) */}
                  <FieldCheckboxGroup
                    id='specialty'
                    label='Specialty'
                    name='specialty'
                    options={services.map(serviceId => ({
                      key: serviceId,
                      label: intl.formatMessage({id: `Services.${serviceId}`}),
                    }))}
                    twoColumns
                  />
                </div>
              )}
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id='ProfileSettingsForm.languagesHeading'/>
                </h3>
                <FieldSelect
                  className={css.textInputField}
                  id='mainLanguage'
                  label={intl.formatMessage({id: 'ProfileSettingsForm.mainLanguageLabel'})}
                  name='mainLanguage'
                  validate={
                    getValidatorsForUserType(
                      currentUser,
                      validators.required(intl.formatMessage({id: 'ProfileSettingsForm.genericRequiredFieldText'}))
                    )
                  }
                >
                  <option disabled value=''>
                    {intl.formatMessage({id: 'ProfileSettingsForm.mainLanguagePlaceholder'})}
                  </option>
                  {getLanguagesOptions()}
                </FieldSelect>

                <FieldSelect
                  className={css.textInputField}
                  id='secondaryLanguage'
                  label={intl.formatMessage({id: 'ProfileSettingsForm.secondaryLanguageLabel'})}
                  name='secondaryLanguage'
                >
                  <option disabled value=''>
                    {intl.formatMessage({id: 'ProfileSettingsForm.secondaryLanguagePlaceholder'})}
                  </option>
                  {getLanguagesOptions({withNone: true})}
                </FieldSelect>
                {isPract && (
                  <FieldCheckboxGroup
                    id='signLanguage'
                    label='Other languages'
                    name='signLanguage'
                    options={[{
                      key: "true",
                      label: intl.formatMessage({id: 'ProfileSettingsForm.signLanguageLabel'}),
                    }]}
                  />
                )}
              </div>
              <div className={css.sectionContainer}>
                {isPract && <h2 className={css.privateSectionTitle}>Personal information</h2>}
                {isPract && (
                  <h3 className={css.tip}>
                    This information will never be shared with users. We use it to verify your
                    account
                    and calculate other fields, such as your star sign.
                  </h3>)}

                <div className={classNames(css.sectionContainer)}>
                  <h3 className={css.sectionTitle}>Birth information</h3>
                  <>
                    <h3 className={css.tip}>
                      We use this to calculate your star sign, moon sign, and element sign.
                    </h3>
                  </>
                  <FieldBirthdayInput
                    className={css.dateOfBirth}
                    format={v => v}
                    id='dateOfBirth'
                    label={dateOfBirthTranslations.label}
                    name='dateOfBirth'
                    placeholder={dateOfBirthTranslations.placeholder}
                    validate={
                      getValidatorsForUserType(
                        currentUser,
                        validators.composeValidators(required, minAgeRequired),
                      )
                    }
                    valueFromForm={dateOfBirth}
                  />
                  <ProfileTextField
                    className={css.timeOfBirth}
                    field='timeOfBirth'
                    placeholder='hh:mm'
                    required={false}
                    translationsFor={translationsFor}
                    type='text'
                    validate={
                      getValidatorsForUserType(
                        currentUser,
                        v => v?.match(/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/)
                          ? undefined
                          : intl.formatMessage({id: 'ProfileSettingsForm.timeFormatErrorText'})
                      )
                    }
                  />
                  <LocationAutocompleteInputField
                    className={css.placeOfBirth}
                    field='placeOfBirth'
                    name='placeOfBirth'
                    required
                    type='text'
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognizedMessage),
                    )}
                    {...translationsFor('placeOfBirth')}
                  />
                </div>
              </div>
              {isCustomer(currentUser) && (
                <div>
                  <h4>{intl.formatMessage({id: 'IdentityForm.acceptMarketingCommunicationSectionTitle'})}</h4>
                  <FieldCheckbox
                    id='acceptsMarketingCommunication'
                    label="I agree to receiving marketing communications"
                    name='acceptsMarketingCommunication'
                  />
                </div>
              )}
              <ProfileEditingDisabledMessage profileEditingDisabled={profileEditingDisabled}/>
              {submitError}
              <Button
                className={css.submitButton}
                disabled={submitDisabled}
                inProgress={submitInProgress}
                ready={pristineSinceLastSubmit}
                type='submit'
              >
                <FormattedMessage id='ProfileSettingsForm.saveChanges' />
              </Button>
              {/*console.log(`Red.FormSaveButton will be rendered`, Red)*/}
              {Red.on ? <Red.FormSaveButton {...{debugProps}} /> : ''}
            </Form>
          )
        }}
      />
    )
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
}

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  profileEditingDisabled: bool.isRequired,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
}

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent)

ProfileSettingsForm.displayName = 'ProfileSettingsForm'

export default ProfileSettingsForm
