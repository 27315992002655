import React from "react";
import { compose } from "redux";
import * as PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";

export function ProfileEditingDisabledMessageComponent({ profileEditingDisabled, intl }) {
  if (!profileEditingDisabled) {
    return null;
  }
  const emailAddress = intl.formatMessage({
    id: "ProfileEditingDisabled.helpEmailAddress",
  });
  const helpEmail = <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;

  return (
    <div>
      <FormattedMessage id="ProfileEditingDisabled.profileEditingDisabled" values={{ helpEmail }} />
    </div>
  );
}

ProfileEditingDisabledMessageComponent.propTypes = {
  profileEditingDisabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(ProfileEditingDisabledMessageComponent);
