import React from 'react';
import { object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from "..";
import css from './ServicesLink.module.css';

const ServicesLink = props => {
  const { className, children } = props;

  return (
    <NamedLink
      className={className || css.yourListingsLink}
      name="ManageListingsPage"
      params={{}}
    >
      <span className={css.menuItemBorder} />
      {children || <FormattedMessage id="ServicesLink.editYourListingLink" />}
    </NamedLink>
  );
};

ServicesLink.defaultProps = {
  className: null,
  listing: null,
  listingFetched: false,
  children: null,
};

ServicesLink.propTypes = {
  className: string,
  children: object,
};

export default ServicesLink;
