/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, comma-dangle, quotes, no-return-assign, import/no-unresolved
*/
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Form as FinalForm } from 'react-final-form'
import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl'
import * as validators from '../../util/validators'
import { ensureCurrentUser } from '../../util/data'
import { extractUser } from '../../models/userAdapter'
import { FormContext } from '../../util/userForm'
import {
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  ProfileEditingDisabledMessage,
} from '../../components'
import config from '../../config'
import currencies from '../../currencies'
import css from './PayoutAndInsuranceForm.module.css'
import { featFlags } from '../../red/featureFlags'

let Red = featFlags.isSaveDebug && import('../../red/red').then(({Red: _}) => (Red = _).init())

const supportedCountries = config.stripe.supportedCountries.map(c => c.code)

const PayoutAndInsuranceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        currentUser,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        pristine,
        profileEditingDisabled,
        values,
      } = fieldRenderProps
      const user = ensureCurrentUser(currentUser)

      if (!user.id) {
        return null
      }

      const submitDisabled = invalid || profileEditingDisabled || pristine

      const debugProps = {submitDisabled, invalid, profileEditingDisabled, pristine}

      const {privateData} = extractUser(user)
      const {
        getTextComponent, getCheckComponent, getSelectComponent, getSelectOptions,
        SectionTitle
      } = FormContext({css, validators, intl, formId, langPrefix: 'PayoutAndInsuranceForm.paymentDetails'})

      // Payment country
      const paymentCurrencyLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.paymentDetails.currencyLabel',
      })
      const paymentCurrencyPlaceholder = privateData?.paymentCurrency || ''
      const paymentCurrencyRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.paymentDetails.currencyRequiredText',
        }),
      )
      const paymentCurrencyComponent = (
        <div className={css.fieldContainer}>
          <FieldSelect
            autoComplete='paymentCurrency'
            className={css.selectCurrency}
            id='paymentCurrency'
            label={paymentCurrencyLabel}
            name='paymentCurrency'
            validate={paymentCurrencyRequired}
          >
            <option disabled value=''>
              {paymentCurrencyPlaceholder}
            </option>
            {currencies.map(({ currencyCode }) => (
              <option key={currencyCode} value={currencyCode}>
                {currencyCode} - {intl.formatMessage({ id: `CurrencyNames.${currencyCode}` })}
              </option>
            ))}
          </FieldSelect>
        </div>
      )

      // Payment country
      const paymentCountryLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.paymentDetails.countryLabel',
      })
      const paymentCountryPlaceholder = privateData?.paymentCountry || ''
      const paymentCountryRequired = validators.required(
        intl.formatMessage({ id: 'PayoutAndInsuranceForm.paymentDetails.countryRequiredText' }),
      )
      const paymentCountryComponent = (
        <div className={css.fieldContainer}>
          <FieldSelect
            autoComplete='paymentCountry'
            className={css.selectCountry}
            id='paymentCountry'
            label={paymentCountryLabel}
            name='paymentCountry'
            validate={paymentCountryRequired}
          >
            <option disabled value=''>
              {paymentCountryPlaceholder}
            </option>
            {supportedCountries.map(c => (
              <option key={c} value={c}>
                {intl.formatMessage({ id: `StripeConnectAccountForm.countryNames.${c}` })}
              </option>
            ))}
          </FieldSelect>
        </div>
      )

      // Payment details
      const paymentSortCode = getTextComponent(privateData, 'paymentSortCode', {idname: 'sortCode'})
      // gb
      // const paymentSortCodeLabel = intl.formatMessage({
      //   id: 'PayoutAndInsuranceForm.paymentDetails.sortCodeLabel',
      // })
      // const paymentSortCodePlaceholder = privateData?.paymentSortCode || ''
      // const paymentSortCodeRequired = validators.required(
      //   intl.formatMessage({
      //     id: 'PayoutAndInsuranceForm.paymentDetails.sortCodeRequiredText',
      //   }),
      // )
      // const paymentSortCodeComponent = (
      //   <div className={css.fieldContainer}>
      //     <FieldTextInput
      //       id={formId ? `${formId}.paymentSortCode` : 'paymentSortCode'}
      //       label={paymentSortCodeLabel}
      //       name='paymentSortCode'
      //       placeholder={paymentSortCodePlaceholder}
      //       type='text'
      //       validate={paymentSortCodeRequired}
      //     />
      //   </div>
      // )

      const paymentAccountNumberLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.paymentDetails.accountNumberLabel',
      })
      const paymentAccountNumberPlaceholder = privateData?.paymentAccountNumber || ''
      const paymentAccountNumberRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.paymentDetails.accountNumberRequiredText',
        }),
      )
      const paymentAccountNumberComponent = (
        <div className={css.fieldContainer}>
          <FieldTextInput
            id={formId ? `${formId}.paymentAccountNumber` : 'paymentAccountNumber'}
            label={paymentAccountNumberLabel}
            name='paymentAccountNumber'
            placeholder={paymentAccountNumberPlaceholder}
            type='text'
            validate={paymentAccountNumberRequired}
          />
        </div>
      )

      const paymentIbanLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.paymentDetails.ibanLabel',
      })
      const paymentIbanPlaceholder = privateData?.paymentIban || ''
      const paymentIbanRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.paymentDetails.ibanRequiredText',
        }),
      )
      const paymentOtherDetailsComponent = (
        <div className={css.fieldContainer}>
          <FieldTextInput
            id={formId ? `${formId}.paymentIban` : 'paymentIban'}
            label={paymentIbanLabel}
            name='paymentIban'
            placeholder={paymentIbanPlaceholder}
            type='text'
            validate={paymentIbanRequired}
          />
        </div>
      )

      const paymentGBDetailsComponent = (
        <>
          {paymentSortCode.textComponent}
          {paymentAccountNumberComponent}
        </>
      )

      // Billing address - city
      const billingAddressCityLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.billingAddress.cityLabel',
      })
      const billingAddressCityPlaceholder = privateData?.billingAddressCity || ''
      const billingAddressCityRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.billingAddress.cityRequiredText',
        }),
      )
      const billingAddressCityComponent = (
        <div className={css.fieldContainer}>
          <FieldTextInput
            id={formId ? `${formId}.billingAddressCity` : 'billingAddressCity'}
            label={billingAddressCityLabel}
            name='billingAddressCity'
            placeholder={billingAddressCityPlaceholder}
            type='text'
            validate={billingAddressCityRequired}
          />
        </div>
      )

      // Billing address - postcode
      const billingAddressPostCodeLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.billingAddress.postCodeLabel',
      })
      const billingAddressPostCodePlaceholder = privateData?.billingAddressPostCode || ''
      const billingAddressPostCodeRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.billingAddress.postCodeRequiredText',
        }),
      )
      const billingAddressPostCodeComponent = (
        <div className={css.fieldContainer}>
          <FieldTextInput
            id={formId ? `${formId}.billingAddressPostCode` : 'billingAddressPostCode'}
            label={billingAddressPostCodeLabel}
            name='billingAddressPostCode'
            placeholder={billingAddressPostCodePlaceholder}
            type='text'
            validate={billingAddressPostCodeRequired}
          />
        </div>
      )

      // Billing address - rest of address
      const billingAddressRestOfAddressLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.billingAddress.restOfAddressLabel',
      })
      const billingAddressRestOfAddressPlaceholder =
        privateData?.billingAddressRestOfAddress || ''
      const billingAddressRestOfAddressRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.billingAddress.restOfAddressRequiredText',
        }),
      )
      const billingAddressRestOfAddressComponent = (
        <div className={css.fieldContainer}>
          <FieldTextInput
            id={
              formId ? `${formId}.billingAddressRestOfAddress` : 'billingAddressRestOfAddress'
            }
            label={billingAddressRestOfAddressLabel}
            name='billingAddressRestOfAddress'
            placeholder={billingAddressRestOfAddressPlaceholder}
            type='text'
            validate={billingAddressRestOfAddressRequired}
          />
        </div>
      )

      // Insurance
      //const hasInsuranceOptions = ['yes', 'noWant', 'noInProcess', 'noPending', 'noDontWant']
      const hasInsuranceOptions = ['yes', 'noWant', 'noInProcess', 'noPending']
      const insuranceHasInsuranceLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.insurance.hasInsuranceLabel',
      })
      const insuranceHasInsurancePlaceholder = privateData?.hasInsurance || ''
      const insuranceHasInsuranceRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutAndInsuranceForm.insurance.hasInsuranceRequiredText',
        }),
      )
      const insuranceHasInsuranceComponent = (
        <div className={css.fieldContainer}>
          <FieldSelect
            autoComplete='insuranceHasInsurance'
            className={css.selectCountry}
            id='insuranceHasInsurance'
            label={insuranceHasInsuranceLabel}
            name='insuranceHasInsurance'
            validate={insuranceHasInsuranceRequired}
          >
            <option disabled value=''>
              {insuranceHasInsurancePlaceholder}
            </option>
            {hasInsuranceOptions.map(option => (
              <option key={option} value={option}>
                {intl.formatMessage({ id: `PayoutAndInsuranceForm.insurance.${option}Label` })}
              </option>
            ))}
          </FieldSelect>
        </div>
      )

      // Billing address - postcode
      const insuranceDetailsLabel = intl.formatMessage({
        id: 'PayoutAndInsuranceForm.insurance.detailsLabel',
      })
      const insuranceDetailsPlaceholder = privateData?.insuranceDetails || ''

      const insuranceDetailsComponent = (
        <div className={css.fieldContainer}>
          <FieldTextInput
            id={formId ? `${formId}.insuranceDetails` : 'insuranceDetails'}
            label={insuranceDetailsLabel}
            name='insuranceDetails'
            placeholder={insuranceDetailsPlaceholder}
            type='textarea'
          />
        </div>
      )

      const classes = classNames(rootClassName || css.root, className)

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ProfileEditingDisabledMessage profileEditingDisabled={profileEditingDisabled} />
          <div className={css.sectionContainer}>
            <h3 className={css.subTitle}>
              <FormattedMessage id='PayoutAndInsuranceForm.paymentDetailsSectionTitle' />
            </h3>
            {paymentCurrencyComponent}
            {paymentCountryComponent}

            {values.paymentCountry === 'GB'
              ? paymentGBDetailsComponent
              : paymentOtherDetailsComponent}

            <h3 className={css.subTitle}>
              <FormattedMessage id='PayoutAndInsuranceForm.billingAddressSectionTitle' />
            </h3>

            {billingAddressCityComponent}
            {billingAddressPostCodeComponent}
            {billingAddressRestOfAddressComponent}

            <h3 className={css.subTitle}>
              <FormattedMessage id='PayoutAndInsuranceForm.insuranceSectionTitle' />
            </h3>

            {insuranceHasInsuranceComponent}
            {insuranceDetailsComponent}
          </div>

          <ProfileEditingDisabledMessage profileEditingDisabled={profileEditingDisabled} />
          <PrimaryButton disabled={submitDisabled} inProgress={inProgress} type='submit'>
            <FormattedMessage id='PayoutAndInsuranceForm.saveChanges' />
          </PrimaryButton>
          {Red.on ? <Red.FormSaveButton {...{debugProps}} /> : ''}
        </Form>
      )
    }}
  />
)

PayoutAndInsuranceFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
}

const { bool, string } = PropTypes

PayoutAndInsuranceFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  profileEditingDisabled: bool.isRequired,
}

const PayoutAndInsuranceForm = compose(injectIntl)(PayoutAndInsuranceFormComponent)

PayoutAndInsuranceForm.displayName = 'PayoutAndInsuranceForm'

export default PayoutAndInsuranceForm
