/* eslint-disable no-debugger, quotes, object-curly-spacing, comma-dangle, spaced-comment */

import { expandLangArr } from "./api";
/**
 * Return a list of human-readable languages for a User, filtering any selections of 'None'.
 * @param user
 * @returns {undefined|*}
 */
export const getUserLanguages = user => {
  let languages;
  if (user.hasOwnProperty('attributes')) {
    const data = user.attributes.profile.publicData
    languages = expandLangArr([data?.mainLanguage, data?.secondaryLanguage]);
  } else {
    languages = user.languages;
  }

  return languages?.filter(language => language.trim() !== "None");
}

export const displayUserLanguages = user => {
  return getUserLanguages(user)?.join(", ");
}
