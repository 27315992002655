/* eslint-disable object-curly-spacing, no-void, comma-dangle, spaced-comment, quotes, multiline-ternary */

import React from 'react'
import {bool, func, object, shape, string} from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl'
import {propTypes} from '../../util/types'
import {ensureCurrentUser} from '../../util/data'
import {isScrollingDisabled} from '../../ducks/UI.duck'
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from '../../components'
import {ProfileSettingsForm} from '../../forms'
import {TopbarContainer} from '..'
import {isCustomer, isPractitioner, isProfileEditingEnabled} from '../../util/user'
import {updateProfile, uploadImage} from './ProfileSettingsPage.duck'
import css from './ProfileSettingsPage.module.css'
import UserNav from "../../components/UserNav/UserNav";

const onImageUploadHandler = (values, fn) => {
  const {id, imageId, file} = values
  if (file) {
    fn({id, imageId, file})
  }
}
//export class ProfileSettingsPageComponent extends Component {
//  render() {

/* eslint-disable multiline-ternary */

const log = console.log

export const ProfileSettingsPageComponent = props => {
  const {
    currentUser,
    //currentUserListing,
    image,
    onImageUpload,
    onUpdateProfile,
    scrollingDisabled,
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    profileEditingDisabled,
    intl,
  } = props

  log(`ProfSetPageComp.start`, {props})

  const handleSubmit = values => {
    const {bio: rawBio, dateOfBirth, ...extraData} = values
    const {profileImage, timeOfBirth, placeOfBirth, acceptsMarketingCommunication, ...publicData} = extraData

    // Ensure that the optional bio is a string
    const bio = rawBio || ''

    const profile = {
      bio,
      privateData: {
        dateOfBirth,
        timeOfBirth,
        placeOfBirth,
        acceptsMarketingCommunication,
      },
    }
    const uploadedImage = props.image

    // Update profileImage only if file system has been accessed
    const updatedValues =
      uploadedImage && uploadedImage.imageId && uploadedImage.file
        ? {...profile, profileImageId: uploadedImage.imageId}
        : profile

    log(`ProfSetPageComp.onHandleSummit->onUpdateProfile`, {...updatedValues, publicData})
    onUpdateProfile({...updatedValues, publicData})
  }

  const user = ensureCurrentUser(currentUser)
  const {firstName, lastName, bio, privateData, publicData} = user.attributes.profile
  const profileImageId = user.profileImage ? user.profileImage.id : null
  const profileImage = image || {imageId: profileImageId}
  const {isCustomerOnly} = privateData || {}

  const profileSettingsForm = user.id ? (
    <ProfileSettingsForm
      className={css.form}
      currentUser={currentUser}
      initialValues={{
        bio,
        dateOfBirth: privateData?.dateOfBirth,
        timeOfBirth: privateData?.timeOfBirth,
        placeOfBirth: privateData?.placeOfBirth,
        profileImage: user.profileImage,
        acceptsMarketingCommunication: privateData?.acceptsMarketingCommunication,
        ...publicData,
      }}
      onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
      onSubmit={handleSubmit}
      profileEditingDisabled={profileEditingDisabled}
      profileImage={profileImage}
      updateInProgress={updateInProgress}
      updateProfileError={updateProfileError}
      uploadImageError={uploadImageError}
      uploadInProgress={uploadInProgress}
    />
  ) : null

  const title = intl.formatMessage({id: 'ProfileSettingsPage.title'})

  return (
    <Page className={css.root} scrollingDisabled={scrollingDisabled} title={title}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage='ProfileSettingsPage' />
          { isPractitioner(user) && <UserNav selectedPageName='ProfileSettingsPage' /> }
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.content}>
            <div className={css.headingContainer}>
              <h1 className={css.heading}>
                <FormattedMessage id='ProfileSettingsPage.heading' />
              </h1>
              {user.id && isPractitioner(user) ? (
                <NamedLink
                  className={css.profileLink}
                  name='ProfilePage'
                  params={{id: user.id.uuid}}
                >
                  <FormattedMessage id='ProfileSettingsPage.viewProfileLink' />
                </NamedLink>
              ) : null}
            </div>
            {profileSettingsForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
}

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
}

const mapStateToProps = state => {
  const {currentUser, currentUserListing} = state.user
  const {image, uploadImageError, uploadInProgress, updateInProgress, updateProfileError} =
    state.ProfileSettingsPage
  return {
    currentUser,
    currentUserListing,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    profileEditingDisabled: !isProfileEditingEnabled(currentUser),
  }
}

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
})

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ProfileSettingsPageComponent)

export default ProfileSettingsPage
