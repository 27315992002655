/* eslint-disable no-debugger, quotes, object-curly-spacing, comma-dangle, spaced-comment, no-return-assign,
   no-restricted-syntax
*/

import config from '../config'
import {createInstance} from './sdkLoader'
import * as apiUtils from './api'

const statik = {}

const createSdk = () => {
  const baseUrl = config.sdk.baseUrl ? { baseUrl: config.sdk.baseUrl } : {}
  return window.sdk = createInstance({
    transitVerbose: config.sdk.transitVerbose,
    clientId: config.sdk.clientId,
    secure: config.usingSSL,
    typeHandlers: apiUtils.typeHandlers,
    ...baseUrl,
  })
}

export const getSdk = () => statik.sdk || (statik.sdk = createSdk())
