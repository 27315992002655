/* eslint-disable object-curly-spacing, quotes, no-void, spaced-comment, no-unused-expressions,
   import/extensions, no-debugger, no-multi-assign, guard-for-in, no-restricted-syntax, prefer-template,
   no-return-assign, no-unused-vars, no-use-before-define, no-param-reassign
*/
import * as Corelib from './esm/stdlib/corelib-esm.js'
import * as DOMplusUltra from './esm/dom/dom-plus-ultra-esm.js'
import React from 'react'
import {featFlags} from './featureFlags.js'
//import { addAvailabilityExceptionRequest } from '../containers/EditListingPage/EditListingPage.duck.js'
import { pad, padMD, padHM, padDHM, getDateDHM, getDateYMDHM } from '../models/dateAdapter'
//import { createGregor } from '../models/calendarAdapter'

const {nop, isFun, isStr, getIncArray, hashOfString, globalThis} = Corelib
const {wassert} = Corelib.Debug
const {createPerfTimer, startEndThrottle} = Corelib.Tardis
const {q$, onDomReady, set$, setClass$, div$} = DOMplusUltra

export const Red = {
  on: false
}

Red.init = async () => {
  await onDomReady()
  wassert(globalThis.window)
  if (!Red.on && typeof window !== 'undefined' && featFlags.isDev) {
    console.log(`Red.init`, {featFlags})
    Red.on = true
    checkDebugCSS()
    console.warn(`%cRed is ON`, 'color:red;font-weight:700')
  }
  // else {
  //   console.log(`Red is off.`)
  //   console.log(featFlags)
  // }
  return Red
}

// CHK bad:
Red.createLazyLog = delay => Red.on ? startEndThrottle(console.log, delay) : () => {}

Red.createXru = topic => (fun, ...args) => (...funargs) => {
  console.log('Calling now:', topic, ...args, ...funargs)
  return fun(...funargs)
}

const dumpObjComponentArr = (obj, {cc = ''} = {}) => {
  const className = 'debug-logline ' + cc
  const ret = []
  for (const key in obj) {
    const value = obj[key]
    ret.push(<div key={key} {...{className}}>{key}: <em>{value + ''}</em></div>)
  }
  return ret
}
const dumpArrComponentArr = (arr, {cc = ''} = {}) => {
  const className = 'debug-logline ' + cc // eslint-disable-next-line react/jsx-key
  return arr.map((value, key) => <div {...{className, key}}>{key}: <em>{value + ''}</em></div>)
}

const DebugPanel = ({title, cc, children}) => (
  <div className={'debug-panel ' + cc}>
    <div className='dbg-panel-header'>{title}</div>
    <div className='dbg-panel-body'>
      {children}
    </div>
  </div>
)

const DebugFrame = ({cc, children}) => (
  <div className={'debug-frame ' + cc}>
    {children}
  </div>
)

Red.FormSaveButton = ({debugProps}) => {
  if (!Red.on) {
    return null
  }

  const {submitDisabled, invalid, pristine, pristineSinceLastSubmit, ...rest} = debugProps

  console.log({debugProps})

  return (
    <DebugFrame cc='formsave-debugframe'>
      <DebugPanel cc='formsave-debug' title='FormSaveButton'>
        {dumpObjComponentArr({submitDisabled, invalid, pristine, pristineSinceLastSubmit}, {cc: 'main'})}
        {dumpObjComponentArr(rest, {cc: 'rest'})}
      </DebugPanel>
    </DebugFrame>
  )
}

Red.ExceptionForm = ({debugException}) => {
  if (!Red.on) {
    return null
  }
  //const {submitInProgress, isGroupSession, availability, seats, exceptionStartDate, exceptionStartTime, exceptionEndDate, exceptionEndTime, hasData, submitDisabled} = debugException // eslint-disable-line
  wassert(debugException)
  const {isGroupSession, availability, seats, hasData, submitDisabled, ...rest} = debugException
  const {exceptionStartDate, exceptionStartTime} = debugException
  //const frodo = exceptionStartTime.toGMTString().split(':00 GMT')[0] + ' 🕖 ' + end.toGMTString().split(':00 GMT')[0]

  const startTime = new Date(exceptionStartTime).toGMTString()
  const startDate = exceptionStartDate?.date?.toGMTString()

  console.log('%cRed (Exceptions):', 'color:red', {debugException})

  return (
    <DebugFrame cc='exceptionform-debugframe'>
      <DebugPanel cc='exceptionform-debug' title='ExceptionForm'>
        {dumpObjComponentArr({submitDisabled, hasData, availability, seats, isGroupSession}, {cc: 'main'})}
        {dumpObjComponentArr({startDate, startTime}, {cc: 'comp'})}
        {dumpObjComponentArr(rest, {cc: 'rest'})}
      </DebugPanel>
    </DebugFrame>
  )
}

Red.logCalendar = (msg, monthlyTimeSlots) => {
  //console.log(msg, {monthlyTimeSlots})
  for (const monthKey in monthlyTimeSlots) {
    const {timeSlots = []} = monthlyTimeSlots[monthKey]
    for (const {attributes: {start, end, seats} = {}} of timeSlots) {
      wassert(start && end)
      const [startD, startH, startM] = getDateDHM(start)
      const [, endH, endM] = getDateDHM(end)
      //console.log(`${monthKey}-${startD} ${padHM(startH, startM)}-${padHM(endH, endM)}`)
    }
  }
}

const compStateHash = {}

Red.diffComponentState = (key, props, state) => {
  console.group(`🌈Red.diffComponentState(${key})`)
  compStateHash[key] || (compStateHash[key] = {props: {}, state: {}})
  const {props: pHash, state: sHash} = compStateHash[key]
  const propKeys = props.propertiesToArr()
  const stateKeys = state.propertiesToArr()

  for (const propKey of propKeys) {
    if (!['getListing', 'getOwnListing'].includes(propKey) && pHash[propKey] !== props[propKey]) {
      console.log(`💥Prop [${propKey}] changed`, {old: pHash[propKey], nu: props[propKey]})
      pHash[propKey] = props[propKey]
    }
  }
  for (const stateKey of stateKeys) {
    if (sHash[stateKey] !== state[stateKey]) {
      console.log(`⚡️State [${stateKey}] changed`, {old: sHash[stateKey], nu: state[stateKey]})
      sHash[stateKey] = state[stateKey]
    }
  }
  console.groupEnd()
}

// HEAD Calendar debug panel start

const clockEmoji = `🕒`

const CalendarHead = () => (
  <div className='dateline datehead'>
    <div className='datedate'>{clockEmoji}</div>
    <div className='dateruler' />
    <div className='datedata'>
      {[0, 6, 12, 18, 24].map((hours, key) => {
        const style = {
          left: (hours / 28) * 100 + '%'
        }
        return <div key={key} className='timehead' style={style}>{hours}</div>
      })}
    </div>
  </div>
)
const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

const CalendarLine = ({year, month, day, wday, dataArr, hi}) => {
  const divAA = []
  const weekDay = weekDays[wday]
  dataArr.map((gregor, ix) => {
    if (gregor) {
      const {name: title = '??', dgrid} = gregor
      const dayIndex = `${year}-${pad(month)}-${pad(day)}`// gregor.getDayIndex()
      const todayArr = dgrid[dayIndex] || []
      for (const todayItem of todayArr) {
        const {startH, startM, startHM, endH, endM, endHM, title = '', duration, isGroupSession} = todayItem
        const startPt = (startH * 60 + startM) / (28 * 60)
        const endPt = ((endH || 24) * 60 + endM) / (28 * 60)
        const durEndPt = (startH * 60 + startM + duration) / (28 * 60)
        //const durEndPt = (startH * 60 + startM + 60 * Math.ceil(duration / 60)) / (28 * 60)
        divAA.push({startPt, endPt, startHM, endHM, ix: ix === 3 && isGroupSession ? 30 : ix, title, durEndPt, duration})
      }
    }
    return null
  })

  const datelineExtraClass = `${hi ? 'hi ' : ''}${(parseInt(month) % 2) ? '' : 'even '}${weekDay}`

  return (
    <div className={`dateline ${datelineExtraClass}`}>
      <div className='datedate'>{`${pad(month)}-${pad(day)}`}</div>
      <div className='dateruler' />
      <div className='datedata'>
        {divAA.map(({startPt, endPt, durEndPt, startHM, endHM, ix, title, duration}, key) => {
          const realEndPt = duration ? durEndPt : endPt
          const style = {
            left: startPt * 100 + '%',
            width: (realEndPt - startPt) * 100 + '%'
          }
          title && (style['--ghue'] = 40 + hashOfString(title + 'xdy') % 180)
          const ixTitle = [
            'Current listing default availability',
            'Master Calendar',
            ' availability',
            ' booked',
            'Final availability'
          ]
          const fullTitle = `${startHM}-${endHM} (${duration}mins) ` + title + ixTitle[ix]
          return <div key={key} className='timebar' style={style} title={fullTitle} type={ix} />
        })}
      </div>
    </div>
  )
}

Red.calendarHash = ''

Red.CalendarForm = ({
  selectedM,
  selectedD,
  gregors
}) => {
  if (!Red.on || !gregors.getPropertyCnt() || !gregors.gregMaster) {
    return <></>
  }
  //const timer = createPerfTimer()
  const {gregAggrPriv, gregAggrGroup, gregMaster, gregListing, gregFinal} = gregors
  const dataArr = [gregListing, gregMaster, gregAggrGroup, gregAggrPriv, gregFinal]
  console.log('bef')
  const hash = JSON.stringify(gregors)
  if (Red.calendarHash !== hash) {
    Red.calendarHash = hash
    console.group(`🏮Red.CalendarForm`, gregors)
    for (const gregor of dataArr) {
      gregor?.slotCnt && gregor.dump('🏮')
    }
    //console.log(`CalForm (gr=${timer.sum().dur.sum}ms)`)
    console.groupEnd()
  }
  console.log('aft')

  const dayTicks = 24 * 60 * 60 * 1000
  const todayTime = new Date().getTime()
  const yesterdayTime = todayTime - 1 * dayTicks
  const daysToShow = []
  const hiDate = padMD(selectedM, selectedD)

  for (let dateTime = yesterdayTime; dateTime < yesterdayTime + 90 * dayTicks; dateTime += dayTicks) {
    const actDate = new Date(dateTime)
    const [year, month, day] = getDateYMDHM(actDate)
    daysToShow.push({year, month, day, wday: actDate.getDay(), hi: hiDate === padMD(month, day)})
  }
  const title = `Practitioner's calendar`

  return (
    <DebugFrame cc='calendar-debugframe'>
      <DebugPanel cc='calendar-debug' title={title}>
        <CalendarHead />
        {daysToShow.map((dayData, key) => // eslint-disable-next-line react/jsx-key
          <CalendarLine {...{...dayData, dataArr, key}} />)}
      </DebugPanel>
    </DebugFrame>
  )
}

const humanizeSched = availabilities => availabilities.map(({attributes: {start, end, seats}}) => {
  const startTime = new Date(start).toGMTString().split(':00 GMT')[0]
  const endTime = new Date(end).toGMTString().split(':00 GMT')[0]
  return startTime + ' 🕖 ' + endTime + ` 🪑 ${seats}`
})

Red.AvailabilityPanel = ({debugAvailability}) => {
  if (!Red.on) {
    return null
  }
  const {isGroupSession, currentListing, availabilityPlan, availabilityExceptions, entries, ...rest} = debugAvailability

  const availEx = humanizeSched(availabilityExceptions)

  const plan = availabilityPlan.entries?.map(({dayOfWeek, seats, startTime, endTime}) =>
    `${dayOfWeek}: ${startTime}-${endTime} 🪑 ${seats}`) || []

  console.log('%cRed (Availability):', 'color:red', {debugAvailability, availabilityExceptions, availEx, plan})

  const {
    price: {amount: price100},
    publicData: {duration, topics: topicsArr = [], specialty: specialtyArr = []},
    title,
    state
  } = currentListing.attributes
  const specialty = specialtyArr.join(', ')
  const topics = topicsArr.join(', ')

  //availEx?.length && console.log('availEx', {availEx}, dumpArrComponentArr(availEx, {cc: 'comp'}))

  return (
    <DebugFrame cc='availpanel-debugframe'>
      <DebugPanel cc='listing-debug' title='Listing data'>
        {dumpObjComponentArr({isGroupSession}, {cc: 'main'})}
        {dumpObjComponentArr({title, state, price100, duration, specialty, topics}, {cc: 'comp max240'})}
      </DebugPanel>
      <DebugPanel cc='availavail-debug' title={isGroupSession ? 'Availabilities' : 'AvailExceptions'}>
        {dumpObjComponentArr({isGroupSession}, {cc: 'main'})}
        {dumpArrComponentArr(availEx, {cc: 'comp'})}
      </DebugPanel>
      {isGroupSession ||
        <DebugPanel cc='availplan-debug' title='Availability plan'>
          {dumpArrComponentArr(plan, {cc: 'main fix'})}
        </DebugPanel>}
      <DebugPanel cc='availexception-debug' title='Exceptions'>
        {dumpObjComponentArr({isGroupSession}, {cc: 'main'})}
        {dumpObjComponentArr({isGroupSession}, {cc: 'comp'})}
      </DebugPanel>
    </DebugFrame>
  )
}

//  // DEBUG start
//     //if (isGroupSession)
//     console.log(`FieldDateAndTimeInput`, {isGroupSession, duration, props: this.props})

//     for (const key in monthlyTimeSlots) {
//       if (key === '2021-07') {
//         const {timeSlots = []} = monthlyTimeSlots[key]
//         console.group(key)
//         for (const {attributes, type: mtype} of timeSlots) {
//           const {start, end, type, seats, ...rest} = attributes
//           const frodo = start.toGMTString().split(':00 GMT')[0] + ' 🕖 ' + end.toGMTString().split(':00 GMT')[0]
//           console.log({frodo, seats, mtype, type, rest})
//         }
//         console.groupEnd()
//       } else {
//         console.log(`Month skipped:`, key)
//       }
//     }
//     // debug end

const root = {
  flags: {}
}
const ui = {
  toggleCmds: {}
}
root.setFlag = (name, on) => {
  wassert(isStr(name))
  root.flags[name] = on
  return on
}

const createToggleCmd = (baseClass, isToggle = true) => (name, text, pars = {}) => {
  const togg = ui.toggleCmds[name] = {name}

  const toggle = (on = !togg.on) => {
    if (on !== togg.on) {
      root.setFlag(name, togg.on = on)
      setClass$(togg.node$, on, 'act')
      for (const linked of togg.linkeds) {
        setClass$(ui[linked], !on, 'off')
      }
      on && togg.focus && ui[togg.focus + '$'].focus()
      void togg.onChg?.(on)
      //dbgDumpFlags('toggle ' + name)
    }
  }
  const defOnClick = isToggle ? toggle : nop

  const {cc = '', click = defOnClick, onChg = nop, link = '', focus} = pars
  const {on = root.flags[name] ?? false} = pars
  const ccExtra = on ? 'act' : ''
  const cclass = [baseClass, cc, ccExtra].join(' ')
  const nodeKey = name + 'Cmd$'
  const node$ = div$({cclass, text, click: _ => click()})
  ui[nodeKey] = node$ //+ temporary for debug
  const linkeds = link.split(',').filter(a => a).map(link => link + '$')
  togg.capture({node$, nodeKey, on, click, toggle, linkeds, focus, onChg})
  wassert(isFun(root.setFlag))
  isToggle && toggle(root.setFlag(name, on))
  return ui[nodeKey] = node$
}

const setFlag = (name, on) => ui.toggleCmds[name]
  ? ui.toggleCmds[name].toggle(on)
  : root.setFlag(name, on) //: kind of fallback for flags without toggleCmd (or @load before ui)

const getFlag = name => root.flags[name] ?? console.warn('unknown flag', name)

const createBar = (name, node$, pars, items = []) => {
  ui.bars[name] = {node$}
  set$(node$, {}, items)
}

const createInlineCSS = (text, cclass = '', first = false) => {
  const style$ = document.createElement('style')
  style$.className = cclass
  style$.textContent = text
  first
    ? document.head.insertBefore(style$, document.head.children[0])
    : document.head.appendChild(style$)
}

const createExternalCSS = (link, id) => {
  const style = document.createElement('link')
  style.setAttribute('rel', 'stylesheet')
  style.setAttribute('href', link)
  style.setAttribute('id', id)
  document.getElementsByTagName('head')[0].appendChild(style)
}

const checkDebugCSS = () => {
  console.log(`PAGE START`)
  if (q$('head .red-debug-css')) {
    console.warn('red-debug-css already inserted.')
  } else {
    console.warn('red-debug-css will be inserted now.')

    //window.fetch('https://srv.tork.work/lemongrass/css/redebug.css')
    window.fetch('/static/redebug.css')
      .then(resp => resp.text())
      .then(resp => createInlineCSS(resp, 'red-debug-css'))
      .catch(console.warn)

    createExternalCSS('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap', 'sup-roboto')
    createExternalCSS('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap', 'sup-robotocondensed')
    createExternalCSS('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap', 'sup-robotomono')

    const {body} = document
    const togg = createToggleCmd('mitem', true)
    const cmd = createToggleCmd('mitem', false)
    //const inp = createInput('minput')
    const label = (name, text) => ui[name + '$'] = div$({class: 'mlabel', text})
    set$(body, {}, [
      togg('hover', 'Hoverize', {cc: 'hoverizer-toggle', onChg: on => setClass$(body, on, 'hoverizer')})
    ])
  }
}
