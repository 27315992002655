/**
 * Slice a local number that is in the form
 * of 555 01234567
 */
// const sliceLocal = numbers => {
//   if (numbers.length <= 3) {
//     return numbers;
//   }
//   return `${numbers.slice(0, 3)} ${numbers.slice(3, 10)}`;
// };

/**
 * Slice an international phone number i.e.
 * the part that is followed after a '+' or '00'.
 */
// const sliceInternational = numbers => {
//   if (numbers.length <= 3) {
//     return numbers;
//   }
//   if (numbers.length <= 5) {
//     return `${numbers.slice(0, 3)} ${numbers.slice(3)}`;
//   }
//   return `${numbers.slice(0, 3)} ${numbers.slice(3, 5)} ${numbers.slice(5, 12)}`;
// };

/**
 * Format a phone number in a UK
 * mobile phone number format.
 *
 * Uses one of the following formats:
 *
 * +44 7445 123467
 * 00123 55 1234567
 * 555 1234567
 */
export const format = value => {
  if (!value) {
    return ''
  }
  const leadingPlus = value[0] === '+'
  const lead = leadingPlus ? '+' : ''
  const leadingPlus44 = value.match(/^\+44/g)
  const numsps = value.replace(/[^\d\s]/g, '')
  const nums = value.replace(/[^\d]/g, '')
  const numLen = nums.length
  const ret = lead + (leadingPlus44 && numLen > 2
    ? `${nums.slice(0, 2)} ${nums.slice(2, 6)} ${nums.slice(6, 15) || ''}`
    : numsps)

  // console.log('FIFO', {leadingPlus, leadingZeros, numbers, sl: sliceLocal(numbers)})

  return ret
}

/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
export const parse = value => value // no need (value ? value.replace(/\s/g, '') : '');
