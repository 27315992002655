/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, guard-for-in, prefer-template,
   object-property-newline, no-param-reassign
*/
/* eslint-disable no-unused-vars */

import * as Corelib from '../red/esm/stdlib/corelib-esm'
import { secureDate, pad, padHM, padDHM, padYMD, getDateDHM, getDateYMDHM, shDate } from './dateAdapter'

const {isFun, isArr, isStr, isObj, hashOfString} = Corelib
const {wassert} = Corelib.Debug
const {createPerfTimer} = Corelib.Tardis

const log = (...args) => console.log(`📅` + args.shift(), ...args)
const ilog = (...args) => console.log(`📆` + args.shift(), ...args)

const getHourIndex = (y, m, d, h) => `${y}${pad(m)}${pad(d)}${pad(h)}`
const getDayIndex = (y, m, d) => `${y}${pad(m)}${pad(d)}}`

const konfig = {
  collapseSlotDisplay: true,
  midnightCheatOn: false,
  lastDayOnEarth: getDayIndex(2525, 12, 25) // reduce visibility window for easier debug logging
}
const hourTicks = 60 * 60 * 1000

export const createGregor = ({origin, name, params}) => {
  const gregor = {
    origin,
    name,
    hue: hashOfString(name) % 360,
    color: `color: hsl(${hashOfString(name + 'g') % 360}, 90%, 90%);`,
    bg: `background: hsl(${hashOfString(name + 'g') % 360}, 75%, 25%);`,
    params,
    state: '',
    slots: [],
    grogs: [],
    mgrid: [],
    dgrid: [],
    slotCnt: 0
  }

  gregor.reset = () => {
    gregor.slots = []
    gregor.grogs = []
    gregor.mgrid = []
    gregor.dgrid = []
    gregor.state = ''
    gregor.slotCnt = 0
  }

  const dumpSlot = ({startYMD, endYMD, startHM, endHM, isGroupSession, sessionType, serviceType, duration, title}) => [
    startYMD,
    ' ',
    startHM,
    konfig.collapseSlotDisplay && startYMD === endYMD ? '-' : ` - ${endYMD} `,
    endHM,
    ` ~${duration}m`,
    isGroupSession ? ' Grp ' : ' Priv ',
    sessionType?.substr(0, 5) || '?',
    ` (${serviceType || ''}) `,
    title
  ].filter(Boolean).join('')

  const dumpSlotArray = (arr, x = wassert(isArr(arr))) => arr.map(dumpSlot).slice(0, 90).join('\n')

  const dumpSlotArrayEnds = (arr, limit = 3) => {
    const dumpArr = arr.map(dumpSlot)
    return [...dumpArr.slice(0, limit), '...', ...dumpArr.slice(-limit)].join('\n')
  }

  const stbg = bg => `color: ${gregor.color}; background: ${bg}`
  const uni = 'font: 300 13px roboto condensed;'
  const dumpMsg = (msg, arr, bg = gregor.bg) => console.log(`%c${msg}\n${dumpSlotArray(arr)}`, uni + bg)

  const dumpAll = () => {
    const st = `font-weight: 400;font-family: roboto condensed;`
    ilog(`%c${name} slots:\n${dumpSlotArrayEnds(gregor.slots)}`, st + 'color:#8f8')
    for (const month in gregor.mgrid) {
      //ilog(`%c${name} mgrid[${month}]:\n${dumpSlotArray(gregor.mgrid[month])}`, st + 'color:#fc8')
    }
    for (const day in gregor.dgrid) {
      //ilog(`%c${name} dgrid[${day}]:\n${dumpSlotArray(gregor.dgrid[day])}`, st + 'color:#aaf')
    }
  }
  const zz = slot => `${slot.startYMD} ${slot.startHM} -> ${slot.endYMD} ${slot.endHM}`

  gregor.dump = msg => {
    console.groupCollapsed(`🌝 ${msg} ${gregor.name}`)
    dumpMsg(`🌝🕛 ${gregor.name} slots: (${gregor.slots.length})`, gregor.slots)
    dumpMsg(`🌝 ${gregor.name} grogs: (${gregor.grogs.length})`, gregor.grogs)
    for (const day in gregor.dgrid) {
      wassert(isArr(gregor.dgrid[day]))
      ilog(`%c${name} dgrid[${day}]:\n${dumpSlotArray(gregor.dgrid[day])}`, gregor.bg)
    }
    console.groupEnd()
  }
  // CORE Operations // REM Helpers

  const populateSlot = (start, end) => {
    const [startY, startMo, startD, startH, startM] = getDateYMDHM(start)
    let [endY, endMo, endD, endH, endM] = getDateYMDHM(end) // eslint-disable-line prefer-const

    if (konfig.midnightCheatOn) {
      if ((startD + 1 === endD || (startD > 28 && endD === 1)) && endH === 0) {
        //ilog(`🔺timeSlotHarvester: midnight cheat!`)
        endD = startD
        endH = 24
      } else if (startD !== endD) {
        ilog(`⛔️timeSlotHarvester: day mismatch`, padDHM(startD, startH, startM), padDHM(endD, endH, endM))
      }
    }
    // day overlapping time slots hould be handled in the future
    const startHM = padHM(startH, startM)
    const startYMD = padYMD(startY, startMo, startD)
    const endHM = padHM(endH, endM)
    const endYMD = padYMD(endY, endMo, endD)
    const dateData = {
      start, end,
      startY, startMo, startD, startH, startM, startHM, startYMD,
      endY, endMo, endD, endH, endM, endHM, endYMD
    }
    return dateData
  }

  // CORE Operations // REM Harvesting

  const harvestSlot = item => {
    wassert(isObj(item))

    const {start, end, seats, isGroupSession, serviceType, sessionType, title, duration = 0} = item
    wassert(start && end)
    const startReal = secureDate(start)
    const endReal = secureDate(end)
    const {startY, startMo, startD, ...restDateData} = populateSlot(startReal, endReal)
    if (getDayIndex(startY, startMo, startD) < konfig.lastDayOnEarth) {
      const slot = {
        startY, startMo, startD, ...restDateData,
        seats, title, isGroupSession, sessionType, serviceType, duration: parseInt(duration)
      }
      gregor.slots.push(slot)
    }
  }

  const splitSlotIntoDays = slot => {
    gregor.debugSlots && console.group('nslot passed as input START', zz(slot))
    const {start, end, startY, startMo, startD, startM, endMo, endD, endH} = slot
    if (typeof start.getTime !== 'function') {
      console.warn(`slot.start.getTime not a fun`, {slot})
    } else {
      wassert(isFun(start.getTime))
      const diffms = Math.abs(start.getTime() - end.getTime())
      wassert(!diffms || diffms > 6E4)
      if (!diffms) {
        gregor.debugSlots && console.warn(`slot is too short, will be skipped`, zz(slot), {diffms, slot})
        gregor.zeroSlotFlag = true
        gregor.debugSlots && console.groupEnd()
        return
      }
    }
    if (startD !== endD) {
      const trimTodayEnd = new Date(startY, startMo - 1, startD, 24, startM)
      const splitTime = trimTodayEnd.getTime()
      const partOneSlot = {
        ...slot,
        ...populateSlot(start, trimTodayEnd)
      }
      gregor.nslots.push(partOneSlot)
      gregor.debugSlots && console.log('nslot added FIRST of bigger BLOCK', zz(partOneSlot))

      const partTwoSlot = {
        ...slot,
        ...populateSlot(trimTodayEnd, end)
      }
      gregor.debugSlots && console.log('nslot passes REMAINING of bigger BLOCK', zz(partTwoSlot))
      splitSlotIntoDays(partTwoSlot) // CHK for 0 length slots!
    } else {
      gregor.nslots.push(slot)
      gregor.debugSlots && console.log('nslot added LAST or ONLY of BLOCK', zz(slot))
    }
    gregor.debugSlots && dumpMsg('nslots', gregor.nslots)
    gregor.debugSlots && console.groupEnd()
  }

  const splitSlotsIntoDays = () => {
    gregor.debugSlots = false
    gregor.zeroSlotFlag = false

    // if (name.includes('aster') && gregor.slots.length) {
    //   console.log('mastercal breakpoint')
    //   gregor.debugSlots = true
    // }
    //const timer = createPerfTimer()
    gregor.nslots = []

    for (const slot of gregor.slots) {
      const slotsArr = splitSlotIntoDays(slot)
      //nslots.push(...slotsArr)
    }
    if (gregor.zeroSlotFlag || gregor.nslots.length !== gregor.slots.length) {
      console.groupCollapsed(`dailyslots EXPAND`, {gregor})
      dumpMsg('slots', gregor.slots)
      dumpMsg('nslots', gregor.nslots)
      console.groupEnd()
      gregor.slots = gregor.nslots
    }
    //console.log(`gridder in ${timer.summary()}ms`)
  }

  gregor.harvestArray = arr => {
    for (const item of arr) {
      harvestSlot(item)
    }
  }

  gregor.harvestObject = obj => {
    for (const month in obj) {
      // console.log(obj[month])
      const {timeSlots = []} = obj[month]
      for (const {attributes} of timeSlots) {
        harvestSlot(attributes)
      }
    }
  }

  // CORE Operations // INF (Subtract)

  gregor.subtract = subGregor => {
    console.groupCollapsed(`🌝🌚🌞🕛 Subtract! ${gregor.name} - ${subGregor.name}`)
    dumpMsg(`🌝🕛 before sub (${gregor.grogs.length})`, gregor.grogs)
    dumpMsg(`🌚🕛 subtrahend`, subGregor.grogs, subGregor.bg)

    const newGrogs = [] // REM first generate an exception hash from subGregor
    const ex = {}

    for (const {startY, startMo, startD, startH, duration: dur} of subGregor.grogs) {
      let duration = parseInt(dur) || 1
      // FIX this works only for same day slots - midnight-crossing subtrahends won't work!
      wassert(duration > 0)
      for (let h = startH; duration > 0; h++, duration -= 60) {
        const index = getHourIndex(startY, startMo, startD, h)
        ex[index] = true
      }
    }
    console.log({ex})
    for (const grog of gregor.grogs) {
      const {startY, startMo, startD, startH} = grog
      const index = getHourIndex(startY, startMo, startD, startH)
      ex[index] || newGrogs.push(grog)
    }
    gregor.prevGrogs = [...gregor.grogs]
    gregor.grogs = newGrogs
    gregor.sortAndIndex()
    dumpMsg(`🌞🕛 after sub (${gregor.grogs.length})`, gregor.grogs)
    console.groupEnd()
  }

  // CORE Operations // INF (Convert to shareTribe)

  gregor.convertToMonthlyTimeSlots = () => {
    const monthlyTimeSlots = {}

    for (const {start, end, startY, startMo} of gregor.slots) {
      const monthKey = `${startY}-${pad(startMo)}`
      monthlyTimeSlots[monthKey] || (monthlyTimeSlots[monthKey] = {timeSlots: []})
      monthlyTimeSlots[monthKey].timeSlots.push({
        attributes: {
          start,
          end,
          seats: 1,
          type: 'time-slot/time'
        },
        type: 'timeSlot'
      })
    }
    return monthlyTimeSlots
  }

  // CORE POST processing // HEAD (Gridding (private))

  const midnightCheat = ({startD, endD, endH, ...rest}) => {
    if (startD + 1 === endD && endH === 0) {
      endD--
      endH = 24
    }
    return {startD, endD, endH, ...rest}
  }

  const gridder = () => {
    //const timer = createPerfTimer()
    gregor.grogs = []
    for (const slot of gregor.slots) {
      const {startY, startMo, startD, startH, startM, endH, ...rest} = slot // FIX: works only for same day slots

      //const startTime = start.getTime()
      //const endTime = end.getTime()
      //for (let dateTime = startTime; dateTime < endTime; dateTime += hourTicks) {
      //  const [year, month, day] = getDateYMDHM(new Date(dateTime))
      //  daysToShow.push({year, month, day})
      //}
      //startTime + h * hourTicks
      //const yesterdayTime = todayTime - 1 * dayTicks
      const maxH = endH || 24 // startD === endD

      // calc hours
      for (let h = startH; h < maxH; h++) {
        const start = new Date(startY, startMo - 1, startD, h, startM)
        const end = new Date(startY, startMo - 1, startD, h + 1, startM)
        const dateData = populateSlot(start, end)
        const grog = {...rest, ...dateData}
        const modGrog = midnightCheat(grog)
        gregor.grogs.push(modGrog)
      }
    }
    //console.log(`gridded`, gregor)
    //console.log(`gridder in ${timer.summary()}ms`)
  }

  const ungridder = () => {
    //const timer = createPerfTimer()
    gregor.slots = []
    for (const grog of gregor.grogs) {
      const {start, startD, startH, endH, duration: dur, ...rest} = grog // FIX: works only for same day slots
      const duration = parseInt(dur)
      const grogStart = new Date(start)
      const grogEnd = new Date(start)

      for (let h = startH; h < endH; h++) {
        grogStart.setHours(h)
        grogEnd.setHours(h + 1)
        const dateData = populateSlot(grogStart, grogEnd)
        const grog = {
          ...rest,
          duration,
          ...dateData
        }
        gregor.grogs.push(grog)
      }
    }
    //console.log(`gridder in ${timer.summary()}ms`)
  }

  // CORE POST processing // HEAD (Sorting & indexing)

  const securePush = (arr, ix, rec) => (arr[ix] || (arr[ix] = [])).push(rec)

  const addToDayIndex = slot => {
    const {startY, startMo, startD} = slot
    const dGridIndex = `${startY}-${pad(startMo)}-${pad(startD)}`
    securePush(gregor.dgrid, dGridIndex, slot)
  }
  const addToMonthIndex = slot => {
    const {startY, startMo} = slot
    const dGridIndex = `${startY}-${pad(startMo)}`
    securePush(gregor.mgrid, dGridIndex, slot)
  }

  gregor.sortAndIndex = () => {
    if (gregor.slots.length) {
      //debugger
      splitSlotsIntoDays()
    }
    gregor.slots.sort((a, b) => a.start > b.start ? 1 : -1)
    gridder()
    gregor.grogCnt = gregor.grogs.length

    gregor.mgrid = {}
    gregor.dgrid = {}
    for (const slot of gregor.slots) {
      addToDayIndex({...slot})
      addToMonthIndex({...slot})
    }
    gregor.slotCnt = gregor.slots.length
    // This is ~hgrid.
  }

  // HEAD Init

  void (() => {
    if (isStr(origin)) {
      gregor.originType = 'string'
      gregor.originLen = 0

      // copy harvest
    } else if (Array.isArray(origin)) {
      gregor.originType = 'array'
      gregor.originLen = origin.length
    } else if (isObj(origin)) {
      gregor.originType = 'object'
      gregor.originLen = Object.keys(origin).length
    }
    const timer = createPerfTimer()
    gregor.reset()
    timer.mark('reset')
    if (gregor.originLen) {
      gregor.originType === 'array' && gregor.harvestArray(origin)
      gregor.originType === 'object' && gregor.harvestObject(origin)
      timer.mark('harvest')
      gregor.sortAndIndex()
      timer.mark('index')

      if (gregor.slots.length) {
        const dur = `in ${timer.sum().dur.sum}ms`
        ilog([`%cgregor[${name}] created from ${gregor.originType}`,
          `(${gregor.originLen} items) ${gregor.slotCnt} slots ${gregor.grogs.length} grogs`,
          `${dur}`
        ].join(' '), gregor.bg + 'font: 400 14px roboto condensed', {gregor})
        dumpSlotArray(gregor.slots) // dumpAll()
      }
    }
  })()

  return gregor
}
