import React from 'react';
import {string} from 'prop-types';
import classNames from 'classnames';
import {Carousel} from 'react-responsive-carousel';
import {FormattedMessage} from '../../util/reactIntl';
import {Gate, NamedLink} from "..";
import {ReactComponent as Arrow} from "../Arrow/Arrow.svg";
import css from './SectionCarousel.module.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";

const SectionCarousel = props => {
  const {rootClassName, className} = props;
  const [selected, setSelected] = React.useState(0);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="LandingPageSectionCarousel.title"/>
        </h1>
        <h2 className={css.heroSubTitle}>
          <span>At Lemongrass you can discover a global community of Paradigm Shifters: inspiring thinkers, masters, teachers, spiritual guides, mediums, and healers. Our collective mission is to <b>Light the Spark, Illuminate the World.</b></span>
        </h2>
        <div className={css.buttonRow}>
          <NamedLink
            className={css.heroButton}
            name="SearchPage"
            to={{
              search: '',
            }}
          >
            <FormattedMessage id="LandingPageSectionCarousel.browseButton"/>
          </NamedLink>
          <NamedLink className={css.joinButton} name="PractitionerListPage" to={{}}>
            <FormattedMessage id="LandingPageSectionCarousel.joinButton"/>
          </NamedLink>
        </div>
      </div>
      <div className={css.services}>
        <Gate className={css.gate}>
          <div className={css.gateContent}>
            <div className={css.gateControls}>
              <span onClick={() => setSelected(selected - 1)}>
                <Arrow className={css.leftArrow}/>
              </span>
              <div className={css.controlLine}/>
              <span onClick={() => setSelected(selected + 1)}>
                <Arrow className={css.rightArrow}/>
              </span>
            </div>
            <Carousel
              autoPlay
              emulateTouch
              infiniteLoop
              interval={5000}
              onChange={(index) => setSelected(index)}
              selectedItem={selected}
              showArrows={false}
              showIndicators
              showStatus={false}
              showThumbs={false}
              swipeable
            >
              <div className={css.serviceTitle}>
                <FormattedMessage id="LandingPageSectionCarousel.slide1"/>
              </div>
              <div className={css.serviceTitle}>
                <FormattedMessage id="LandingPageSectionCarousel.slide2"/>
              </div>
              <div className={css.serviceTitle}>
                <FormattedMessage id="LandingPageSectionCarousel.slide3"/>
              </div>
            </Carousel>
          </div>
        </Gate>
      </div>
    </div>
  );
};

SectionCarousel.defaultProps = {rootClassName: null, className: null};

SectionCarousel.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCarousel;
