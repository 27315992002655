/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, comma-dangle, quotes, no-return-assign, import/no-unresolved
*/
import React from 'react'
import {bool, object} from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import unionWith from 'lodash/unionWith'
import classnames from 'classnames'
import {withRouter} from 'react-router-dom'
import {injectIntl, intlShape} from '../../util/reactIntl'
import {getListingsById} from '../../ducks/marketplaceData.duck'
import {isScrollingDisabled} from '../../ducks/UI.duck'
import {propTypes} from '../../util/types'
import config from '../../config'

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar, LinkTabNavHorizontal,
  //HorMenuNav, - not used yet, maybe in the future
  Page,
  SectionCarousel,
  SectionPeople,
  SectionWorkshops,
} from '../../components'
import {siftListings} from '../../models/listingAdapter'
import {TopbarContainer} from '..'
//import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg'
//import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg'
import facebookImage from '../../assets/lemonGrassFacebook-639x418.jpg';
import twitterImage from '../../assets/lemonGrassTwitter-639x418.jpg';
import {searchListings} from '../SearchPage/SearchPage.duck'
import css from './LandingPage.module.css'
import {FormattedMessage} from "react-intl";

// INF SecondaryNav not used atm, so this part can be deleted
// INF (in HorMenuNav.js there is a copy of this fn called LeftPubLicNav)
// INF (If we want to mod this in the future, HorMenuNav is the only place to do that.)

const SecondaryNav = props => {
  const {className, rootClassName} = props
  const classes = classnames(rootClassName || css.nav, className)

  const tabs = [
    {
      text:
        <FormattedMessage
          id="LandingPageNav.practitionersLink"
          values={{}}
        />,
      selected: false,
      disabled: false,
      linkProps: {
        name: 'PractitionerListPage',
      },
    },
    {
      text:
        <FormattedMessage
          id="LandingPageNav.wokeshopsLink"
          values={{}}
        />,
      selected: false,
      disabled: false,
      linkProps: {
        name: 'SearchPage',
        to: {
          search: 'pub_serviceType=wokeshop',
        }
      },
    },
    {
      text:
        <FormattedMessage
          id="LandingPageNav.experiencesLink"
          values={{}}
        />,
      selected: false,
      disabled: false,
      linkProps: {
        name: 'SearchPage',
        to: {
          search: 'pub_serviceType=experience',
        }
      },
    },
    {
      text:
        <FormattedMessage
          id="LandingPageNav.oneToOnesLink"
          values={{}}
        />,
      selected: false,
      disabled: false,
      linkProps: {
        name: 'SearchPage',
        to: {
          search: 'pub_serviceType=oneToOne',
        }
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} skin="light" tabRootClassName={css.tab} tabs={tabs}/>
  );
};


export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    listings: rawListings,
  } = props;

  const listings = siftListings(rawListings)
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({id: 'LandingPage.schemaTitle'}, {siteTitle});
  const schemaDescription = intl.formatMessage({id: 'LandingPage.schemaDescription'});
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      contentType='website'
      description={schemaDescription}
      facebookImages={[{url: facebookImage, width: 1200, height: 630}]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      twitterImages={[
        {url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314},
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer/>
          {/* <HorMenuNav/> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            {/*<SectionHero/>*/}
            <SectionCarousel className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            {/*<li className={classnames(css.section, css.aboutSection)}>*/}
            {/*  <div className={css.sectionContent}>*/}
            {/*    <SectionAboutUs/>*/}
            {/*  </div>*/}
            {/*</li>*/}
            {/* <li className={classnames(css.section, css.consultationSection)}>
              <div className={css.sectionContent}>
                <SectionConsultation />
              </div>
            </li> */}
            <li className={classnames(css.section, css.workshopSection)}>
              <div className={css.sectionContent}>
                <SectionWorkshops listings={listings} />
              </div>
            </li>
            {/*<li className={classnames(css.section, css.videosSection)}>*/}
            {/*  <div className={css.sectionContent}>*/}
            {/*    <SectionVideos/>*/}
            {/*  </div>*/}
            {/*</li>*/}
            <li className={classnames(css.section, css.peopleSection)}>
              <div>
                <SectionPeople />
              </div>
            </li>
            {/*<li className={classnames(css.section, css.infoSection)}>*/}
            {/*  <div className={css.sectionContent}>*/}
            {/*    <SectionInfo/>*/}
            {/*  </div>*/}
            {/*</li>*/}
            {/*<li className={classnames(css.section, css.socialSection)}>*/}
            {/*  <div className={css.sectionContent}>*/}
            {/*    <SectionSocial/>*/}
            {/*  </div>*/}
            {/*</li>*/}
            {/*<li className={classnames(css.section, css.joinSection)}>*/}
            {/*  <div className={css.sectionContent}>*/}
            {/*    <SectionJoin/>*/}
            {/*  </div>*/}
            {/*</li>*/}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
}

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
}

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => searchListings({
  page: 1,
  perPage: 10,
  include: ['author', 'images'],
  'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
  'fields.image': ['variants.scaled-small', 'variants.scaled-medium', 'variants.landscape-crop', 'variants.landscape-crop2x'],
  'limit.images': 1,
})

export default LandingPage;
