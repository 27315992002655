import React from "react";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import { PrimaryButton } from "..";
import IconCheckmark from "../IconCheckmark/IconCheckmark";
import css from "./ModalMissingInformation.module.css";

const TermsReminder = props => {
  const {
    className,
    user,
    termsUrl,
    acceptTermsInProgress,
    acceptTermsError,
    onAcceptTerms,
    intl,
  } = props;

  const acceptTermsButton = (
    <PrimaryButton onClick={onAcceptTerms}>
      <FormattedMessage id="ModalMissingInformation.acceptPractitionerTermsButtonText" />
    </PrimaryButton>
  );

  const resendErrorMessage = acceptTermsError ? (
    <p className={css.error}>
      <FormattedMessage id="ModalMissingInformation.acceptTermsError" />
    </p>
  ) : null;

  const termsLink = (
    <a href={termsUrl} rel="noreferrer" target="_blank">
      <FormattedMessage id="ModalMissingInformation.acceptPractitionerTermsLinkText" />
    </a>
  );

  const emailAddress = intl.formatMessage({
    id: "ModalMissingInformation.acceptPractitionerTermsEmailAddress",
  });
  const helpEmail = <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
  return (
    <div className={className}>
      <IconCheckmark className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.acceptPractitionerTermsTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id="ModalMissingInformation.acceptPractitionerTermsText"
          values={{ termsLink, helpEmail }}
        />
      </p>
      {resendErrorMessage}
      <div className={css.bottomWrapper}>
        <p className={css.helperText}>
          {acceptTermsInProgress ? (
            <FormattedMessage id="ModalMissingInformation.sendingEmail" />
          ) : (
            acceptTermsButton
          )}
        </p>
      </div>
    </div>
  );
};

export default injectIntl(TermsReminder);
