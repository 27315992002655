/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes
*/
import React from 'react'
import { FieldSelect, FieldTextInput, FieldCheckbox } from '../components'
import { FormattedMessage } from './reactIntl'

//import { wassert } from '../red/esm/stdlib/debug-esm'
//import { isObj, isStr, nop } from '../red/esm/stdlib/corelib-esm'
const nop = _ => _

const log = nop // console.log

export const FormContext = ({css, validators, intl, formId, langPrefix}) => {
  log(`FormContext constructed`, {css, validators, intl, formId, langPrefix})

  const getFieldData = (data, name, {idname = name, fmtExtra, noreq}) => { // firstName
    //wassert(isObj(data) && isStr(name))
    const idPrefix = `${langPrefix}.${idname}`
    const label = intl.formatMessage({id: `${idPrefix}Label`}, fmtExtra)
    const current = data?.[name]
    const placeholder = current || ''
    const required = noreq ? undefined : validators.required(intl.formatMessage({id: `${idPrefix}RequiredText`}))
    log(`getFieldData`, {data, name, idname, idPrefix, required})
    return [label, placeholder, required]
  }

  const getTextComponent = (data, name, pars = {}, props = {}) => { // firstName
    const [label, placeholder, required] = getFieldData(data, name, pars)
    const id = formId ? `${formId}.${name}` : name
    const type = 'text'
    const component = <FieldTextInput {...{id, label, name, placeholder, type, validate: required, ...props}} />
    return {label, placeholder, textComponent: component}
  }

  const getCheckComponent = (data, name, pars = {}, props = {}) => { // firstName
    const [label, placeholder, required] = getFieldData(data, name, pars)
    const id = formId ? `${formId}.${name}` : name
    log(`getCheckComponent`, {data, name, pars, props})
    const component = <FieldCheckbox {...{id, key: name, label, name, ...props}} />
    return {label, placeholder, required, checkComponent: component}
  }

  const getSelectComponent = (data, name, pars = {}, props = {}) => { // firstName
    const [label, placeholder, required] = getFieldData(data, name, pars)
    const {className = css[name], children, ...rest} = props // class id label name val
    const component = (
      <FieldSelect {...{className, id: name, label, name, validate: required, ...rest}}>
        <option disabled value=''>{placeholder}</option>
        {children}
      </FieldSelect>
    )
    log(`getSelectComponent`, {children})
    return {label, placeholder, selectComponent: component}
  }

  const getSelectOptions = (arr, name, pkey, pvalue) => arr.map(({[pkey]: key, [pvalue]: value}) => (
    <option key={key} {...{value}}>{intl.formatMessage({id: `${name}.${key}`})}</option>
  ))

  const SectionTitle = ({id}) => <h3 className={css.sectionTitle}><FormattedMessage id={id} /></h3>

  return {getTextComponent, getCheckComponent, getSelectComponent, getSelectOptions, SectionTitle}
}
