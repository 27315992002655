/* eslint-disable no-underscore-dangle */

import React from 'react';
import { bool, shape } from 'prop-types';
import { AvatarLarge, AvatarMedium } from '../../components';
import css from './ListingPage.module.css';

const SectionAvatar = props => {
  const { user } = props;
  return (
    <div className={css.sectionAvatar}>
      <AvatarLarge
        className={css.avatarDesktop}
        disableProfileLink={
          user.attributes.profile.publicData === undefined ||
          user.attributes.profile.publicData.__practitioner_profileHidden === undefined ||
          user.attributes.profile.publicData.__practitioner_profileHidden
        }
        initialsClassName={css.initialsDesktop}
        user={user}
      />

      <AvatarMedium className={css.avatarMobile} disableProfileLink user={user} />
    </div>
  );
};

SectionAvatar.defaultProps = {
  user: null,
}

SectionAvatar.propTypes = {
  user: shape({
    attributes: shape({
      profile: shape({
        publicData: shape({
          __practitioner_profileHidden: bool,
        })
      }),
    })
  }),
}

export default SectionAvatar;
