/* eslint-disable prefer-template, react/button-has-type, jsx-a11y/click-events-have-key-events,
   jsx-a11y/no-static-element-interactions, react/prop-types */

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';
import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const images = listing.images && listing.images.length > 1 ?
    [listing.images[1], listing.images[0], ...listing.images.slice(2)] :
    listing.images;

  const hasImages = images && images.length > 0;
  const firstImage = hasImages ? images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe editParams={editParams} isOwnListing={isOwnListing} listing={listing} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages 
    ? <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
        <FormattedMessage
          id="ListingPage.viewImagesButton"
          values={{ count: images.length }}
        />
      </button>
    : null;

  return (
    <div className={'_SectionImages ' + css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          <ResponsiveImage
            alt={title}
            image={firstImage}
            rootClassName={css.rootForImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
            ]}
          />
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        containerClassName={css.carouselModalContainer}
        id="ListingPage.imageCarousel"
        isOpen={imageCarouselOpen}
        lightCloseButton
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
        scrollLayerClassName={css.carouselModalScrollLayer}
        usePortal
      >
        <ImageCarousel images={images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
