// ================ Action types ================ //

import { denormalisedResponseEntities } from "../../util/data";
import { currentUserShowSuccess } from "../../ducks/user.duck";

export const SAVE_IDENTITY_REQUEST = "app/IdentityPage/SAVE_IDENTITY_REQUEST";
export const SAVE_IDENTITY_SUCCESS = "app/IdentityPage/SAVE_IDENTITY_SUCCESS";
export const SAVE_IDENTITY_ERROR = "app/IdentityPage/SAVE_IDENTITY_ERROR";

export const SAVE_IDENTITY_CLEAR = "app/IdentityPage/SAVE_IDENTITY_CLEAR";

// ================ Reducer ================ //

const initialState = {
  saveIdentityInProgress: false,
  identityChanged: false,
  saveIdentityError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_IDENTITY_REQUEST:
      return {
        ...state,
        saveIdentityInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        identityChanged: false,
      };
    case SAVE_IDENTITY_SUCCESS:
      return { ...state, saveIdentityInProgress: false, identityChanged: true };
    case SAVE_IDENTITY_ERROR:
      return { ...state, saveIdentityInProgress: false, saveIdentityError: payload };
    case SAVE_IDENTITY_CLEAR:
      return {
        ...state,
        saveIdentityInProgress: false,
        identityChanged: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveIdentityRequest = () => ({ type: SAVE_IDENTITY_REQUEST });
export const saveIdentitySuccess = () => ({ type: SAVE_IDENTITY_SUCCESS });
export const saveIdentityClear = () => ({ type: SAVE_IDENTITY_CLEAR });

export const saveIdentityError = e => ({
  type: SAVE_IDENTITY_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Update identity, actions depend on which data has changed
 */
export const saveIdentity = params => (dispatch, getState, sdk) => {
  dispatch(saveIdentityRequest());
  const {
    accountType,
    firstName,
    lastName,
    businessName,
    companyNumber,
    companyCountry,
    vatNumber,
    idType,
    idIssuingCountry,
    idNumber,
    plannedsessionTypes,
    acceptMarketingCommunication,
    acceptTerms,
  } = params;
  const privateSessions = plannedsessionTypes.includes("private");
  const ticketedEvents = plannedsessionTypes.includes("ticketed");
  console.log(`saveIdentity`, {params, privateSessions, ticketedEvents})
  return sdk.currentUser
    .updateProfile(
      {
        firstName,
        lastName,
        protectedData: {
          accountType,
          businessName,
        },
        privateData: {
          companyNumber,
          companyCountry,
          vatNumber,
          idType,
          idIssuingCountry,
          idNumber,
          ticketedEvents,
          acceptMarketingCommunication,
          acceptTerms,
          privateSessions,
        },
      },
      {
        expand: true,
        include: ["profileImage"],
      },
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error("Expected a resource in the sdk.currentUser.updateProfile response");
      }
      const currentUser = entities[0];
      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));

      dispatch(saveIdentitySuccess());
    })
    .catch(e => dispatch(saveIdentityError(e)));
};
