/* eslint-disable multiline-ternary, spaced-comment, object-curly-spacing, comma-dangle, no-unneeded-ternary,
   guard-for-in, no-restricted-syntax, prefer-template, quotes, no-return-assign,
   import/no-unresolved, import/extensions, prefer-template, react/prop-types */

import React, {useState} from 'react'
import classNames from 'classnames'
import omit from 'lodash/omit'
import config from '../../config'
import routeConfiguration from '../../routeConfiguration'
import {FormattedMessage} from '../../util/reactIntl'
import {createResourceLocatorString} from '../../util/routes'
import {isAnyFilterActive} from '../../util/search'
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy
} from '../../components'
import FilterComponent from './FilterComponent'
import {validFilterParams} from './SearchPage.helpers'
import css from './SearchPage.module.css'

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active keyword search sorts the listings
  // according to relevance. In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(sortConfig.conflictingFilters, searchParams, filterConfig)
  return sortingFiltersActive ? { ...searchParams, [sortConfig.queryParamName]: null } : searchParams
}

// MainPanel contains search results and filters. There are 3 presentational container-components
// that show filters: SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
// The last 2 are for desktop layout.

const MainPanel = props => {
  const {
    className,
    rootClassName,
    urlQueryParams,
    currentUser,
    guideResultLinks,
    listings,
    history,
    searchInProgress,
    searchListingsError,
    searchParamsAreInSync,
    onActivateListing,
    onManageDisableScrolling,
    onOpenModal,
    onCloseModal,
    onMapIconClick,
    pagination,
    searchParamsForPagination,
    showAsModalMaxWidth,
    filterConfig,
    sortConfig,
  } = props
  const [isSecondaryFiltersOpen, setSecondaryFiltersOpen] = useState(false)
  const [currentQueryParams, setCurrentQueryParams] = useState(urlQueryParams)

  // Apply the filters by redirecting to SearchPage with new filters.
  const applyFilters = () => {
    const searchParams = {...urlQueryParams, ...currentQueryParams}
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig)
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search))
  }

  // Close the filters by clicking cancel, revert to the initial params
  const cancelFilters = () => setCurrentQueryParams({})

  // Reset all filter query parameters
  const resetAll = () => {
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames)
    setCurrentQueryParams({}) // Reset state

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames)
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams))
  }

  const initialValues = queryParamNames => {
    // Query parameters that are visible in the URL
    // Query parameters that are in state (user might have not yet clicked "Apply")

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => currentQueryParams[paramName] ?? urlQueryParams[paramName]

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be { amenities: "has_any:towel,jacuzzi" }
    return Array.isArray(queryParamNames)
      ? queryParamNames.reduce((acc, paramName) => ({...acc, [paramName]: getInitialValue(paramName)}), {})
      : {}
  }

  const getHandleChangedValueFn = useHistoryPush => 
    updatedURLParams => {
      const updater = prevQueryParams => {
        const {address, bounds} = urlQueryParams
        const mergedQueryParams = {...urlQueryParams, ...prevQueryParams}

        // Address and bounds are handled outside of MainPanel. I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {...mergedQueryParams, ...updatedURLParams, address, bounds}
      }

      const callback = queryParams => {
        if (useHistoryPush) {
          const searchParams = queryParams
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig)
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search))
        }
      }
      const nextQueryParams = updater(currentQueryParams)
      setCurrentQueryParams(nextQueryParams)
      callback(nextQueryParams)
    }
  // useeEffect(() => {}, [currentQueryParams])

  const handleSortBy = (urlParam, values) => {
    const queryParams = values ? { ...urlQueryParams, [urlParam]: values } : omit(urlQueryParams, urlParam)
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams))
  }

  const primaryFilters = filterConfig.filter(f => f.group === 'primary')
  const secondaryFilters = filterConfig.filter(f => f.group !== 'primary')
  const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0)

  // Selected aka active filters
  const selectedFilters = validFilterParams(urlQueryParams, filterConfig)
  const selectedFiltersCount = Object.keys(selectedFilters).length

  // Selected aka active secondary filters
  const selectedSecondaryFilters = hasSecondaryFilters
    ? validFilterParams(urlQueryParams, secondaryFilters)
    : {}
  const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length
  const isSecondaryFiltersOpenAct = !!hasSecondaryFilters && isSecondaryFiltersOpen
  const propsForSecondaryFiltersToggle = hasSecondaryFilters
    ? {
        isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => setSecondaryFiltersOpen(isOpen),
        selectedSecondaryFiltersCount,
      }
    : {}

  // With time-based availability filtering, pagination is NOT supported. In these cases we get the pagination
  // support info in the response meta object, and we can use the count of listings as the result count.
  // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
  const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported
  const listingsLength = listings ? listings.length : 0
  const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength

  const listingsAreLoaded = !searchInProgress && searchParamsAreInSync

  const sortBy = mode => {
    const conflictingFilterActive = isAnyFilterActive(sortConfig.conflictingFilters, urlQueryParams, filterConfig)

    const mobileClassesMaybe = mode === 'mobile'
      ? {
          rootClassName: css.sortBy,
          menuLabelRootClassName: css.sortByMenuLabel,
        }
      : {}
      
    return sortConfig.active
      ? <SortBy
          {...mobileClassesMaybe}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={handleSortBy}
          showAsPopup
          sort={urlQueryParams[sortConfig.queryParamName]}
        />
      : null
  }

  const classes = classNames(rootClassName || css.searchResultContainer, className)

  return (
    <div className={`_MainPanel ` + classes}>
      <SearchFiltersPrimary
        className={css.searchFiltersPrimary}
        resultsCount={totalItems}
        sortByComponent={sortBy('desktop')}
        {...{guideResultLinks, listingsAreLoaded}}
        {...{searchInProgress, searchListingsError, propsForSecondaryFiltersToggle}}
      >
        {primaryFilters.map(conf => (
            <FilterComponent
              key={`SearchFiltersPrimary.${conf.id}`}
              contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
              filterConfig={conf}
              idPrefix='SearchFiltersPrimary'
              showAsPopup
              {...{initialValues, urlQueryParams, getHandleChangedValueFn}}
            />
          ))}
      </SearchFiltersPrimary>
      <SearchFiltersMobile
        className={css.searchFiltersMobile}
        resultsCount={totalItems}
        sortByComponent={sortBy('mobile')}
        {...{listingsAreLoaded, selectedFiltersCount, showAsModalMaxWidth}}
        {...{onCloseModal, onManageDisableScrolling, onMapIconClick, onOpenModal, resetAll}}
        {...{searchInProgress, searchListingsError, urlQueryParams}}
      >
        {filterConfig.map(conf => (
            <FilterComponent
              key={`SearchFiltersMobile.${conf.id}`}
              filterConfig={conf}
              idPrefix='SearchFiltersMobile'
              liveEdit
              showAsPopup={false}
              {...{initialValues, urlQueryParams, getHandleChangedValueFn}}
            />
          ))}
      </SearchFiltersMobile>
      {isSecondaryFiltersOpenAct
        ? <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersSecondary
              onClosePanel={() => setSecondaryFiltersOpen(false)}
              {...{applyFilters, cancelFilters, listingsAreLoaded, resetAll, urlQueryParams}}
            >
              {secondaryFilters.map(conf => (
                  <FilterComponent
                    key={`SearchFiltersSecondary.${conf.id}`}
                    filterConfig={conf}
                    idPrefix='SearchFiltersSecondary'
                    showAsPopup={false}
                    {...{initialValues, urlQueryParams, getHandleChangedValueFn}}
                  />
                ))}
            </SearchFiltersSecondary>
          </div>
        : <div className={classNames(css.listings, {[css.newSearchInProgress]: !listingsAreLoaded})}>
            {searchListingsError
              ? <h2 className={css.error}>
                  <FormattedMessage id='SearchPage.searchError' />
                </h2>
              : null}
            <SearchResultsPanel
              className={css.searchListingsPanel}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              {...{currentUser, listings}}
            />
          </div>}
    </div>
  )
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
}

export default MainPanel
