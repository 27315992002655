/* eslint-disable multiline-ternary, quotes, comma-dangle, object-curly-spacing, spaced-comment */

import React from 'react'
import {bool, func, object, string} from 'prop-types'
import classNames from 'classnames'
import {FormattedMessage} from '../../util/reactIntl'
import {ensureOwnListing} from '../../util/data'
import {findOptionsForSelectFilter} from '../../util/search'
import {LISTING_STATE_DRAFT, WOKESHOP} from '../../util/types'
import {ListingLink} from '..'
import {EditListingDescriptionForm} from '../../forms'
import config from '../../config'
import css from './EditListingDescriptionPanel.module.css'
import {getTopicsForSpecialties} from "../../util/listings";
import {Listing} from "../../models/listings";

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    isGroupSession,
    errors,
  } = props

  const classes = classNames(rootClassName || css.root, className)
  const currentListing = ensureOwnListing(listing)
  const flatListing = Listing(listing)
  const { description, title, publicData, privateData = {}} = currentListing.attributes
  const { certificate, specialty = [], keywords = ''} = publicData
  const { recordingLinkUrl:currentLinkUrl = '' } = privateData

  // The service type currently will always be oneToOne for private sessions.
  const serviceType = isGroupSession ?
    publicData.serviceType || WOKESHOP :
    "oneToOne";

  const isVerified = currentListing.isVerified || false;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT
  const panelTitle = isPublished ? (
    <FormattedMessage
      id={`EditListingDescriptionPanel.title${isGroupSession ? '.groupSession' : ''}`}
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage
              id='EditListingDescriptionPanel.listingTitle'
              values={{listingType: isGroupSession ? 'Group Session' : 'Private Session'}}
            />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id={`EditListingDescriptionPanel.createListingTitle${isGroupSession ? '.groupSession' : ''}`} />
  )

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters)
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        certificateOptions={certificateOptions}
        className={css.form}
        disabled={disabled}
        fetchErrors={errors}
        initialValues={{ title, description, specialty, certificate, serviceType, keywords, isVerified }}
        isGroupSession={isGroupSession}
        onChange={onChange}
        onSubmit={values => {
          const { title, description, certificate, specialty, serviceType } = values

          const languages = flatListing?.author?.languages;

          const topics = getTopicsForSpecialties(specialty);
          const recordingLinkUrl = currentLinkUrl || ""
          const updateValues = {
            title: title.trim(),
            description,
            publicData: { certificate, specialty, serviceType, topics, keywords, isVerified, languages},
            privateData: { recordingLinkUrl },
          };
          onSubmit(updateValues)
        }}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
    </div>
  )
}

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
}

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  isGroupSession: bool.isRequired,
}

export default EditListingDescriptionPanel
