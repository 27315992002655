import React from 'react';
import {bool, string} from 'prop-types';
import classNames from 'classnames';
import {injectIntl, intlShape} from '../../util/reactIntl';
import css from './Gate.module.css';

export const GateComponent = props => {
  const {className, imageUrl, hasShadow} = props;

  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.gateInner} style={
        {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          boxShadow: hasShadow ? "inset 0 0 15em 0em #333" : null,
        }
      }>
        {props.children}
      </div>
    </div>
  )
};

GateComponent.defaultProps = {
  className: null,
  imageUrl: null,
  hasShadow: false,
  rootClassName: null,
  initialsClassName: null,
  user: null,
  disableProfileLink: false,
};

GateComponent.propTypes = {
  className: string,
  imageUrl: string,
  hasShadow: bool,

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Gate = injectIntl(GateComponent);

export default Gate;
