/* eslint-disable no-unused-vars, react/prefer-stateless-function, semi, quotes, jsx-quotes,
   comma-dangle, no-unused-expressions, object-curly-spacing, spaced-comment
*/

import React, {Component} from "react";
import {array, func, string, object} from "prop-types";
import classNames from "classnames";
import {injectIntl, intlShape} from "../../util/reactIntl";
import {lazyLoadWithDimensions} from "../../util/contextHelpers";
import {EXPERIENCE, ONE_TO_ONE, PRIVATE_SESSION, propTypes, WOKESHOP} from "../../util/types";
import {formatMoney} from "../../util/currency";
import {ensureListing} from "../../util/data";
import {richText} from "../../util/richText";
import {createSlug, LISTING_PAGE_PARAM_TYPE_EDIT} from "../../util/urlHelpers";
import config from "../../config";
import {NamedLink, ResponsiveImage, AvatarMedium, AvatarLarge, Avatar} from "..";
import css from "./ListingCard.module.css";
import {displayDate, isGroupSession} from "../../util/listings";
import {AiOutlineWifi, FaMapMarkerAlt} from "react-icons/all";
import {displayUserLanguages} from "../../util/practitioners";
import {Listing} from "../../models/listings";
import { featFlags } from '../../red/featureFlags'

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return {formattedPrice, priceTitle: formattedPrice};
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        {id: "ListingCard.unsupportedPrice"},
        {currency: price.currency},
      ),
      priceTitle: intl.formatMessage(
        {id: "ListingCard.unsupportedPriceTitle"},
        {currency: price.currency},
      ),
    };
  }
  return {};
};

// class ListingImage extends Component {
//   render () {
//     return <ResponsiveImage {...this.props} />;
//   }
// }

//const ListingImage = props => <ResponsiveImage {...props} />

const ListingImage = ResponsiveImage

const LazyImage = lazyLoadWithDimensions(ListingImage, {loadAfterInitialRendering: 3000});

export const ListingCardComponent = props => {
  const {className, rootClassName, intl, currentUser, listing, renderSizes, minify, filtersConfig, setActiveListing} = props
  const classes = classNames(rootClassName || css.root, className);

  const currentListing = Listing(ensureListing(listing));
  const {id, title, price, serviceType, sessionType, locationType} = currentListing;
  const {publicData} = listing.attributes;

  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const listingOwnerName = currentListing.author.displayName;
  const listingOwnerCity = currentListing.author.city;
  const locationString = currentListing.isOnline
    ? "Online"
    : currentListing.city;

  const {formattedPrice, priceTitle} = priceData(price, intl);
  const unitTranslationKey = isGroupSession(currentListing) ? "ListingCard.perTicket" : "ListingCard.perSession";

  const getColorForServiceType = serviceType => {
    const serviceColorMap = {
      [WOKESHOP]: "#00a699",
      [EXPERIENCE]: "#2a3d4b",
      [ONE_TO_ONE]: "#568efc",
    }
    return serviceColorMap[serviceType];
  }
  const serviceColor = getColorForServiceType(serviceType)

  const {author} = currentListing
  typeof author?.id === 'string' && (author.id = {uuid: author.id})
  const editIcon = '✏️'
  const editParams = {
    id,
    slug,
    type: LISTING_PAGE_PARAM_TYPE_EDIT,
    tab: 'description',
    listingCategory: isGroupSession(currentListing) ? 'groupSession' : 'privateSession'
  }
  const isOwnListing = author?.id?.uuid && author?.id?.uuid === currentUser?.id.uuid
  //console.log(`LICA:`, {editParams, isOwnListing, currentUser})

  return (
    //<NamedLink className={classes} name="ListingPage" params={{id, slug}}>
    <div className={classes} component='ListingCard'>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper} style={{position: 'relative'}}>
          <NamedLink name="ListingPage" params={{id, slug}}>
            <LazyImage
              alt={title}
              image={firstImage}
              rootClassName={css.rootForImage}
              sizes={renderSizes}
              variants={["scaled-small", "scaled-medium"]}
            />
            <span className={css.pill} style={{backgroundColor: serviceColor}}>
              {intl.formatMessage({id: `ServiceType.${serviceType}`})}
            </span>
            {/* <span className={css.edit}>{editIcon}</span> */}
          </NamedLink>
          {isOwnListing &&
            <NamedLink className={css.edit} name="EditListingPage" params={editParams}>{editIcon}</NamedLink>}
          {author && featFlags.isDev && (
            <div style={{'--sCol': serviceColor}}>
              <AvatarLarge className={css.avatar} debug='card' user={author} />
            </div>)}
        </div>
      </div>
      <NamedLink name="ListingPage" params={{id, slug}}>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.host}>
              {
                publicData.location.address === ""
                  ? <AiOutlineWifi />
                  : <FaMapMarkerAlt />
              }
              {"  "}
              {locationString}
            </div>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              <span>
                <b>{formattedPrice}</b>
                {
                  currentListing.sessionType === PRIVATE_SESSION
                    ? <span> • {currentListing.duration} mins</span>
                    : currentListing.earliestBookableDate &&
                      <span> • {displayDate(currentListing.earliestBookableDate)}</span>
                }
              </span>
            </div>
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  currentUser: object,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
