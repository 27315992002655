import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const {rootClassName, className} = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: August 20, 2021</p>
      <p>About this policy.</p>
      <p> Lemongrass Life Ltd is committed to protecting and respecting your privacy. This policy
        sets out who we are and how and why we collect, store, use and share your personal
        information. It also explains your rights in relation to your personal information, so
        please read it carefully. This policy applies to your use of
        https://lemongrass-staging.herokuapp.com (the “Website”) or any services offered through or
        associated with the Website (the “Services”).</p>
      <p> The Website is not intended for children and we do not knowingly collect data relating to
        children.</p>
      <p> What is “personal data”?</p>
      <p> “Personal data” means any information that identifies an individual person. It does not
        include data about a company or anonymous data (i.e. data where the personal identity has
        been removed.)</p>
      <p> About us.</p>
      <p> The Website is owned and operated by Lemongrass Life Ltd, a limited company registered in
        England and Wales under company number 13265882 with registered address at 20-22 Wenlock
        Road, London, England, N1 7GU. When we process personal data, we are responsible as a
        “controller” of that personal data for the purposes of the General Data Protection
        Regulation and the Data Protection Act 2018 (the “data protection laws”). In this policy,
        “we”, “us” or “our” refers to Lemongrass Life Ltd.</p>
      <p> Third-party links.</p>
      <p> The Website may include links to third-party websites, plug-ins and applications. Clicking
        on those links or enabling those connections may allow third parties to collect or share
        data about you. We do not control these third-party websites and are not responsible for
        their privacy statements. When you leave our Website, we encourage you to read the privacy
        policy of every website you visit.</p>
      <p> How we collect data.</p>
      <p> Most of the data we collect is collected directly from you—you give us personal data when
        you visit our Website or when you communicate with us by phone, post, email or otherwise.
        This includes data you provide when you fill out forms on our Website or our other platforms
        (like registering to use the Website, subscribing to our mailing list, placing an order or
        using other interactive features). We may also receive information from third parties, like
        analytics providers, payment service providers, third-party accounts you use to log in to
        our Website (like Google or Facebook) and other third parties we use to help us deliver our
        Services. For more information on how these companies handle your personal data, please
        refer to their privacy policies.</p>
      <p> Data we collect about you.</p>
      <p> The personal data we collect about you includes your name and contact information (like
        email address, phone number, mailing address), identity verification information (like date
        of birth, place of birth, personal identification numbers), billing information and payment
        card information, business information (like company name, job title), CV information (like
        education information, past employment information), profile picture (if you choose to set
        one), location data, behavioural data (like information about past orders and how you use
        our Website, including your IP address), demographic information (like race, gender, sexual
        orientation, religion, political affiliation) and details about other social networks linked
        accounts. We also collect information about your communication with us and your
        communication with other users of our Website (such as the Sellers).</p>
      <p> How and why we use your data.</p>
      <p> Under data protection laws, we can only use your personal information if we have a proper
        reason for doing so, such as complying with our legal and regulatory obligations, fulfilling
        contractual obligations to you (or taking any steps at your request before entering into a
        contract), where you have given consent or for our (or a third party’s) legitimate interest.
        (A “legitimate interest” is when we have a business or commercial reason to use your
        information, which is a proper reason for using your personal data as long as it’s not
        overridden by your own rights and interests.) This section explains what we use your
        personal information for and our reasons for doing so.</p>
      <p> Providing our Website and other online platforms to you. We use your personal data to
        administer the Website, to ensure content from the Website is presented in the most
        effective manner for you and for your device, as part of our efforts to keep the Website
        safe and secure and to allow you to participate in interactive features of our Website (when
        you choose to do so). We use your personal data this way for our legitimate interests in
        maintaining our Website.</p>
      <p> Providing Services to you. We use your personal data for internal operations, to
        administer and manage your account, respond to communications from you and provide you with
        information you request from us, notify you about changes to the Services and carry out any
        other obligations arising from any contracts entered into between you and us. We use your
        personal data this way to fulfil contractual obligations to you (or take steps at your
        request before entering into a contract).</p>
      <p> Improving our Website and Services. We use your personal data to make our Website and
        Services better, including troubleshooting, data analysis, testing, research, statistical
        and survey purposes.</p>
      <p> Improving our marketing. We use your personal data to measure and understand the
        effectiveness of our marketing efforts, deliver relevant marketing to you and make relevant
        suggestions and recommendations to you. We use your personal data this way for our
        legitimate interests in promoting our business and Services.</p>
      <p> Complying with legal obligations. We also use your personal data to comply with legal
        obligations like mandatory reporting and record retention, ensuring confidentiality of
        commercially sensitive information and responding to any requests from regulatory or legal
        authorities.</p>
      <p> Handling sensitive data. Certain personal data we collect is extremely sensitive, so it is
        considered a “special category” of personal data under data protection laws. We will not
        collect your biometric data, medical history, race, gender, sexual orientation, religion,
        political affiliation without your explicit consent. Furthermore, our Sellers recognise that
        you may disclose sensitive personal information to them for the purpose of delivering the
        service. Sellers have a contract with us in place, under which they are bound to treat such
        information as strictly confidential. Sellers explicitly agree not to disclose this
        sensitive information to any third parties, not to use this information for any purpose
        other than the delivery of the purchased service and not to copy or reproduce this
        information without your written permission.</p>
      <p> We may process your personal data for more than one lawful ground depending on the
        specific purpose for which we are using your data. Please contact us if you need details
        about the specific legal ground we are relying on to process your personal data where more
        than one ground applies.</p>
      <p> Marketing and promotional communications.</p>
      <p> We have a legitimate interest in processing your personal information for promotional
        purposes, which means we don’t usually need your consent to send you these types of
        communications. However, we’ll only send you updates about Services you have used or that we
        think you might be interested in, including exclusive offers, promotions or new Services. We
        won’t subscribe you to our mailing list without your explicit consent. You can ask us to
        stop sending you marketing messages at any time by the following opt-out links on such
        marketing messages or by contacting us at namaste@lemongrass.life.</p>
      <p> We will get your express opt-in consent before we share your personal data with any third
        party for marketing purposes. You can ask the third parties to stop sending you marketing
        messages at any time by following their opt-out procedures.</p>
      <p> Who we share your data with</p>
      <p> We may share personal information with third parties we use to help run our business and
        deliver our Services (like payment service provider to facilitate payments, delivery
        provider to facilitate deliveries, Analytics Provider to help us understand how users
        interact with our Website, marketing service provider to market our business, mailinglist
        provider to deliver marketing messages, website host to host our website, security provider
        to help make our Services secure, cloud service provider for cloud storage services,
        customer relationship management provider for account management and other service provider
        for other services). We only allow these service providers to handle your personal data if
        we are satisfied they take appropriate measures to protect your personal data. We do not
        allow our third-party service providers to use your personal data for their own purposes and
        only permit them to process your personal data for specified purposes and in accordance with
        our instructions.</p>
      <p> We may disclose and exchange information with law enforcement agencies and regulatory
        bodies to comply with our legal and regulatory obligations, enforce any of our rights or
        protect ourselves and others.</p>
      <p> We may also need to share some personal information with other parties, such as potential
        buyers of some or all of our business or during a restructuring. The recipient will only be
        permitted to use the data for the purposes we originally collected it for, and they will be
        bound by confidentiality obligations.</p>
      <p> How long we keep your data.</p>
      <p> We keep your personal data while you have an account with us or we are providing Services
        to you. You can tell us to stop providing Services to you by deleting your account or
        contacting us. After that, we keep your personal information for as long as is necessary to
        respond to any questions, complaints or claims made by you or on your behalf, to show that
        we treated you fairly and to keep records required by law. We won’t retain your personal
        data for longer than necessary for the purposes set out in this policy. However, please note
        that different retention periods apply for different types of personal data.</p>
      <p> In some circumstances we will anonymise your personal data (so that it can no longer be
        associated with you) for research or statistical purposes, in which case we may use this
        information indefinitely without further notice to you.</p>
      <p> How we secure your data.</p>
      <p> We have appropriate security measures to prevent personal information from being
        accidentally lost, or used or accessed unlawfully. We limit access to your personal
        information to those who have a genuine business need to access it. Those processing your
        information will do so only in an authorised manner and are subject to a duty of
        confidentiality.</p>
      <p> We also have procedures in place to deal with any suspected data security breach. We’ll
        notify you and any applicable regulator of a suspected data security breach where we are
        legally required to do so.</p>
      <p> Unfortunately, sending personal data via the internet is not completely secure. Although
        we do our best to protect your personal data, we can’t guarantee the security of data sent
        to our Website and you send personal data to us at your own risk.</p>
      <p> Where your data is stored.</p>
      <p> To deliver our Services to you, it’s sometimes necessary for us to share your personal
        information outside the UK and/or the European Economic Area (EEA), for example with service
        providers located outside the UK/EEA or if you are based outside the UK/EEA. These transfers
        are subject to special rules under data protection laws. Although non-UK/EEA countries don’t
        have the same data protection laws as the UK/EEA, however we will ensure the transfer
        complies with data protection laws and that all personal data will be secure. Where this is
        the case personal data is adequately protected by EU Commission approved standard
        contractual clauses, an appropriate Privacy Shield certification, the Processor Binding
        Corporate Rules, or another adequacy mechanism established under applicable data protection
        law. If you’d like more information about what happens to your personal data when it’s
        transferred outside the UK/EEA, please contact us.</p>
      <p> Your rights</p>
      <p> Under data protection laws you have the right to:</p>
      <p> Be informed: the right to be informed of what personal data we have about you and our
        purpose for processing it.</p>
      <p> Access: the right to be provided with a copy of your personal data.</p>
      <p> Rectification: the right to require us to correct any mistakes in your personal data.</p>
      <p> Be forgotten: the right to require us to delete your personal data—in certain
        situations.</p>
      <p> Restriction: the right to require us to restrict processing of your personal data—in
        certain circumstances.</p>
      <p> Portability: the right to receive the personal data you provided to us in a structured,
        commonly used and machine-readable format and/or transmit that data to a third party—in
        certain situations.</p>
      <p> Object: the right to object to your personal data being processed for direct marketing
        (including profiling) and, in certain circumstances, the right to object to our continued
        processing of your personal data.</p>
      <p> Not to be subject to automated decision-making: the right not to be subject to a decision
        that has legal effects on you or significantly affects you based solely on automated
        processing (including profiling).</p>
      <p> Complain: the right to complain at any time to a supervisory authority if you think any of
        your rights have been infringed by us. In the UK, the regulator for data protection issues
        is the Information Commissioner’s Office (ICO) (www.ico.org.uk). We would, however,
        appreciate the chance to deal with your concerns before you approach the ICO so please
        contact us in the first instance.</p>
      <p> If you would like to exercise any of these rights, please contact us at
        namaste@lemongrass.life.</p>
      <p> Cookies (and other similar technologies)</p>
      <p> What are cookies? A cookie is a small (ordinarily, text) file of letters and numbers that
        we store on your browser or device if you agree. Cookies contain information that is
        transferred to your computer's hard drive (or the hard drive of another relevant device). We
        use cookies to distinguish you from other users on the Website, to tailor your experience to
        your preferences, and to help us improve the Website.</p>
      <p> Cookies we use</p>
      <p> Strictly necessary cookies. These cookies are required to save your session and to carry
        out other activities that are strictly necessary for the operation of the Website. They
        include, by way of general example, cookies that enable you to log into secure areas of the
        Website, use a shopping cart, or make use of e-billing services. These cookies are session
        cookies, which means they’re temporary and will usually expire when you close your
        browser.</p>
      <p> Analytical/performance cookies. These cookies allow us to recognise and count the number
        of visitors and to see how visitors move around the Website when they’re using it. These
        cookies help us improve the way the Website works by, for example, ensuring that users are
        finding what they’re looking for easily.</p>
      <p> Functionality cookies. These cookies are used to recognise you when you return to the
        Website. They enable us to personalise our content for you, greet you by name and remember
        your preferences.</p>
      <p> Targeting cookies. These cookies record your visit to the Website, the pages you visit,
        and the links you follow. We use this information to make the Website and the advertising
        displayed on it more relevant to your interests. We may also share this information with
        third parties for the same purpose. When we use third parties for advertising and targeting
        purposes, we may disclose: Personally identifiable information, such as email address [ADD
        ANY OTHER INFO]. Generic, aggregated or anonymised data relating to your visits and use of
        our Website; or Information in a pseudonymised form such as a browser cookie ID / code or
        cryptographic hash of your email address to help us tailor and display our ads to you on
        other services. This ID or code is matched against your equivalent unique code similarly
        generated by our ad partners to tailor adverts to you.</p>
      <p> Social media cookies. These cookies work together with social media plug-ins. For example,
        when we embed photos, video and other content from social media websites, the embedded pages
        contain cookies from these websites. Similarly, if you choose to share our content on social
        media, a cookie may be set by the service you have chosen to share content through.</p>
      <p> Third party cookies. Please note that some of the above cookies are placed by third
        parties and that the Website does not block third party cookies.</p>
      <p> Consenting to cookies. You will be shown a pop-up message requesting your consent to
        setting non-essential cookies before any are placed on your device. When you give your
        consent, a unique token is generated to show that you have consented, and you won’t receive
        the pop-up message again when you return to our Website.</p>
      <p> Disabling cookies</p>
      <p> By default, most internet browsers accept cookies, but you can choose to enable or disable
        some or all cookies via the settings on your internet browser. Most internet browsers also
        enable you to choose whether you wish to disable all cookies or only third party cookies.
        For further details, please consult the help menu in your internet browser.</p>
      <p> Some of the cookies we use are essential for the Website to operate. If you use your
        browser settings to block essential cookies, you may not be able to access all or parts of
        our Website.</p>
      <p> You have the right to opt out of social media cookies and third-party cookies. To enforce
        this right, please contact us.</p>
      <p> Duration. Except for essential cookies, all cookies will expire after [INSERT AN EXPIRY
        PERIOD].</p>
      <p> Sessions. Sessions are a mechanism that enable a webpage to remember information from a
        previous webpage. By default, a webpage forgets information from a previous webpage—sessions
        are used to help a website function when it needs to remember certain information from one
        webpage to the next (for example, to help you fill out forms or make payments). Sessions
        ordinarily work with cookie files, but they can also work without cookies. Information from
        sessions is deleted when you close your window or tab. We use sessions in addition to
        cookies to help our Website function.</p>
      <p> Local storage. Local storage is a mechanism that enables us to store information locally
        on your device. Like sessions, local storage is used to help a website function when it
        needs to remember certain information, but local storage is used to continue remembering
        information after you close your window or tab. We also use local storage to help our
        Website function.</p>
      <p> Hidden fields. Hidden fields are a mechanism used to store information on a webpage. Users
        can’t typically see hidden fields or submit information through them. We use hidden fields
        to help present our Website in the best way for you and your device (for example, to retain
        information about what language you’re viewing the Website in).</p>
      <p> Changes to this policy</p>
      <p> We keep this privacy policy under review. Any changes we may make to this policy will be
        posted on this page (and, where appropriate, notified to you by email). Please check back
        frequently for updates and changes.</p>
      <p> Keeping information up-to-date</p>
      <p> It is important that the personal data we hold about you is accurate and current. Please
        keep us informed if your personal data changes during your relationship with us.</p>
      <p> Contact us</p>
      <p> Questions, comments and requests regarding this policy are welcomed. You can contact us by
        post at our registered address (above) or by email at namaste@lemongrass.life.</p>


    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const {string} = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
