/* eslint-disable semi, quotes, comma-dangle, object-curly-spacing, spaced-comment
*/
import pick from "lodash/pick";
import config from "../../config";
import { types as sdkTypes } from "../../util/sdkLoader";
import { storableError } from "../../util/errors";
import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { transactionLineItems } from "../../util/api";
import * as log from "../../util/log";
import { denormalisedResponseEntities } from "../../util/data";
import {
  findNextBoundary,
  nextMonthFn,
  monthIdStringInTimeZone,
  resetToStartOfDay
} from "../../util/dates";
import { TRANSITION_ENQUIRE } from "../../util/transaction";
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from "../../util/urlHelpers";
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from "../../ducks/user.duck";
import { util as sdkUtil } from "sharetribe-flex-sdk/src";
import { featFlags } from '../../red/featureFlags'

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = "app/ListingPage/SET_INITIAL_VALUES";

export const SHOW_LISTING_REQUEST = "app/ListingPage/SHOW_LISTING_REQUEST";
export const SHOW_LISTING_ERROR = "app/ListingPage/SHOW_LISTING_ERROR";

export const FETCH_REVIEWS_REQUEST = "app/ListingPage/FETCH_REVIEWS_REQUEST";
export const FETCH_REVIEWS_SUCCESS = "app/ListingPage/FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_ERROR = "app/ListingPage/FETCH_REVIEWS_ERROR";

export const FETCH_TIME_SLOTS_REQUEST = "app/ListingPage/FETCH_TIME_SLOTS_REQUEST";
export const FETCH_TIME_SLOTS_SUCCESS = "app/ListingPage/FETCH_TIME_SLOTS_SUCCESS";
export const FETCH_TIME_SLOTS_ERROR = "app/ListingPage/FETCH_TIME_SLOTS_ERROR";

export const FETCH_LINE_ITEMS_REQUEST = "app/ListingPage/FETCH_LINE_ITEMS_REQUEST";
export const FETCH_LINE_ITEMS_SUCCESS = "app/ListingPage/FETCH_LINE_ITEMS_SUCCESS";
export const FETCH_LINE_ITEMS_ERROR = "app/ListingPage/FETCH_LINE_ITEMS_ERROR";

export const SEND_ENQUIRY_REQUEST = "app/ListingPage/SEND_ENQUIRY_REQUEST";
export const SEND_ENQUIRY_SUCCESS = "app/ListingPage/SEND_ENQUIRY_SUCCESS";
export const SEND_ENQUIRY_ERROR = "app/ListingPage/SEND_ENQUIRY_ERROR";

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) =>
  (dispatch, getState, sdk) => {
    dispatch(showListingRequest(listingId));
    dispatch(fetchCurrentUser());
    const params = {
      id: listingId,
      include: ["author", "author.profileImage", "images"],
      "fields.image": [
        // Listing page
        "variants.landscape-crop",
        "variants.landscape-crop2x",
        "variants.landscape-crop4x",
        "variants.landscape-crop6x",

        // Social media
        "variants.facebook",
        "variants.twitter",

        // Image carousel
        "variants.scaled-small",
        "variants.scaled-medium",
        "variants.scaled-large",
        "variants.scaled-xlarge",

        // Avatars
        "variants.square-small",
        "variants.square-small2x",
      ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

    return show
      .then(data => {
        dispatch(addMarketplaceEntities(data));
        return data;
      })
      .catch(e => {
        dispatch(showListingError(storableError(e)));
      });
  };

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: "public",
      include: ["author", "author.profileImage"],
      "fields.image": [
        "variants.square-small",
        "variants.square-small2x",
        "variants.large-portrait",
      ],
      "imageVariant.large-portrait": sdkUtil.objectQueryString({
        w: 560,
        h: 770,
        fit: "crop",
      }),
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};
const sho = date => date.toString().split(' GMT')[0]
const clog = (...args) => featFlags.isDev && console.log(...args)

const timeSlotsRequest = params => (dispatch, getState, sdk) =>
  clog(`🟡🟡ORI timeSlotRequest %c${sho(params.start)}-${sho(params.end)}`, 'background:#60c', params.listingId?.uuid, {params}) ||
    sdk.timeslots.query(params).then(response => denormalisedResponseEntities(response));

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1
  }

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
      clog(`🟡🟡ListingPage.🦆.fetchTimeSlots.then `, {timeSlots});
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  if (typeof window === 'undefined') {
    return Promise.all([])
  }
  //const hasWindow = typeof window !== "undefined";
  const attributes = listing.attributes;
  clog(`🟡🟩ListingPage.🦆.fetchMonthlyTimeSlots: conditions`, {attributes})
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const {availabilityPlan} = attributes || {}
  const {timezone} = availabilityPlan || {}

  // Fetch time-zones on client side only.
  clog(`🟡🟩ListingPage.🦆.fetchMonthlyTimeSlots: conditions`, {timezone, is: listing.id, availabilityPlan})
  if (listing.id && timezone) {
    clog(`🟡🟩ListingPage.🦆.fetchMonthlyTimeSlots: %c ${attributes?.title}`, 'background: #00f', listing.id?.uuid)

    const oldFetch = true // we use the old, varWidth (60-90days) window here!

    if (oldFetch) {
      const tz = listing.attributes.availabilityPlan.timezone;
      const nextBoundary = findNextBoundary(tz, new Date());
      const month1 = nextMonthFn(nextBoundary, tz);
      const month2 = nextMonthFn(month1, tz);
      const month3 = nextMonthFn(month2, tz);
      // console.log({tz, nextBoundary, month1, month2, month3})
      return Promise.all([
        dispatch(fetchTimeSlots(listing.id, nextBoundary, month1, tz)),
        dispatch(fetchTimeSlots(listing.id, month1, month2, tz)),
        dispatch(fetchTimeSlots(listing.id, month2, month3, tz)),
      ]);
    } else {
      const range = 89;
      const today = new Date();
      const start = resetToStartOfDay(today, timezone, 0);
      const end = resetToStartOfDay(today, timezone, range);

      return Promise.all([dispatch(fetchTimeSlots(listing.id, start, end, timezone))])
    }
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest())
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data
      dispatch(fetchLineItemsSuccess(lineItems))
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)))
      log.error(e, "fetching-line-items-failed", {
        listingId: listingId.uuid,
        bookingData,
      })
    })
}

export const loadData = (params, search) => dispatch => {
  clog(`🟡ListingPage.🦆: loadData params/`, params)
  clog(`🟡ListingPage.🦆: loadData search/`, search)
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]).then(
    responses => {
      clog(`🟡🟨🟧ListingPage.🦆: loadData responded`, { responses })
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const listing = responses[0].data.data;
        clog(`🟡🟨🟩listingPage.🦆.loadData will call fetchMonthlyTimeSlots, listing`, listing)

        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        fetchMonthlyTimeSlots(dispatch, listing)
          .then(response => {
            clog(`🐣🐥🦆DUCK FETCH THEN`, {response})
          })
      }
      return responses;
    },
  );
};
