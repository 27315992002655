import React from "react";
import {bool, func, shape, string} from "prop-types";
import {compose} from "redux";
import {Form as FinalForm} from "react-final-form";
import classNames from "classnames";
import {FormattedMessage, injectIntl, intlShape} from "../../util/reactIntl";
import {propTypes} from "../../util/types";
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from "../../util/validators";
import {
  Button,
  FieldCheckbox,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
} from "../../components";
import css from "./EditListingLocationForm.module.css";

const identity = v => v;

const onOnlineChange = (value, props) => {
  const {form} = props;

  form.batch(() => {
    form.change("address", "online");
    form.change("building", "");
    form.change("online", value);
  });
};

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        isGroupSession,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({id: "EditListingLocationForm.address"});
      const addressPlaceholderMessage = intl.formatMessage({
        id: "EditListingLocationForm.addressPlaceholder",
      });
      const addressRequiredMessage = intl.formatMessage({
        id: "EditListingLocationForm.addressRequired",
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: "EditListingLocationForm.addressNotRecognized",
      });

      const optionalText = intl.formatMessage({
        id: "EditListingLocationForm.optionalText",
      });

      const buildingMessage = intl.formatMessage(
        {id: "EditListingLocationForm.building"},
        {optionalText},
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: "EditListingLocationForm.buildingPlaceholder",
      });

      const {updateListingError, showListingsError} = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed"/>
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed"/>
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const isOnline = !!values.online;

      const sessionLinkLabel = intl.formatMessage({
        id: `EditListingSessionLinkForm.label${isGroupSession ? ".groupSession" : ""}`
      });

      const sessionLinkPasswordLabel = intl.formatMessage({
        id: `EditListingSessionLinkForm.linkPassword`
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <fieldset className={css.onlineField}>
            <legend>
              <FormattedMessage id="EditListingLocationForm.online"/>
            </legend>
            <FieldCheckbox
              className={css.online}
              id="online"
              label={intl.formatMessage({id: "EditListingLocationForm.onlineLabel"})}
              name="online"
              onChange={value => onOnlineChange(value, formRenderProps)}
            />
          </fieldset>

          {!isOnline && (
            <LocationAutocompleteInputField
              autoFocus
              className={css.locationAddress}
              disabled={isOnline}
              format={identity}
              iconClassName={css.locationAutocompleteInputIcon}
              inputClassName={css.locationAutocompleteInput}
              label={titleRequiredMessage}
              name="location"
              placeholder={addressPlaceholderMessage}
              predictionsClassName={css.predictionsRoot}
              useDefaultPredictions={false}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage),
              )}
              validClassName={css.validLocation}
              valueFromForm={values.location}
            />
          )}

          {!isOnline && (
            <FieldTextInput
              className={css.building}
              disabled={isOnline}
              id="building"
              label={buildingMessage}
              name="building"
              placeholder={buildingPlaceholderMessage}
              type="text"
            />
          )}

          {isOnline && (
            <div>
              <FieldTextInput
                className={css.sessionLink}
                id="sessionLink"
                label={sessionLinkLabel}
                name="sessionLink"
                type="text"
              />

              <p className={css.tip}>
                <FormattedMessage id="EditListingSessionLinkForm.linkTooltip"/>
              </p>

              <br/>

              <FieldTextInput
                className={css.sessionLink}
                id="sessionLinkPassword"
                label={sessionLinkPasswordLabel}
                name="sessionLinkPassword"
                type="text"
              />

              <p className={css.tip}>
                <FormattedMessage id="EditListingSessionLinkForm.linkPasswordTooltip"/>
              </p>
            </div>
          )}

          <Button
            className={css.submitButton}
            disabled={submitDisabled}
            inProgress={submitInProgress}
            ready={submitReady}
            type="submit"
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
