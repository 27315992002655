/* eslint-disable multiline-ternary, quotes, comma-dangle, object-curly-spacing */

import React from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {compose} from 'redux'
import {Form as FinalForm} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import classNames from 'classnames'
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl'
import {propTypes} from '../../util/types'
import {composeValidators, maxLength, required} from '../../util/validators'
import {Button, FieldCheckboxGroup, FieldTextInput, Form} from '../../components'
import {services} from '../../constants'
import css from './EditListingDescriptionForm.module.css'
import FieldRadioButton from "../../components/FieldRadioButton/FieldRadioButton";

const TITLE_MAX_LENGTH = 60

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{...arrayMutators}}
    render={formRenderProps => {
      const {
        certificateOptions,
        isGroupSession,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors
      } = formRenderProps

      const msg = id => intl.formatMessage({id: `${id}${isGroupSession ? '.groupSession' : ''}`})

      const titleMessage = msg(`EditListingDescriptionForm.title`)
      const titlePlaceholderMessage = msg(`EditListingDescriptionForm.titlePlaceholder`)
      const titleRequiredMessage = msg(`EditListingDescriptionForm.titleRequired`)
      const maxLengthMessage = intl.formatMessage({
        id: `EditListingDescriptionForm.maxLength`
      }, {
        maxLength: TITLE_MAX_LENGTH,
      })
      const descriptionMessage = msg(`EditListingDescriptionForm.description`)
      const descriptionPlaceholderMessage = msg(`EditListingDescriptionForm.descriptionPlaceholder`)
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH)
      const descriptionRequiredMessage = msg(`EditListingDescriptionForm.descriptionRequired`)

      const {updateListingError, createListingDraftError, showListingsError} = fetchErrors || {}
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingDescriptionForm.updateFailed'/>
        </p>
      ) : null

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingDescriptionForm.createListingDraftError'/>
        </p>
      ) : null

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingDescriptionForm.showListingFailed'/>
        </p>
      ) : null

      const classes = classNames(css.root, className)
      const submitReady = (updated && pristine) || ready
      const submitInProgress = updateInProgress
      const submitDisabled = invalid || disabled || submitInProgress

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            autoFocus
            className={css.title}
            id='title'
            label={titleMessage}
            maxLength={TITLE_MAX_LENGTH}
            name='title'
            placeholder={titlePlaceholderMessage}
            type='text'
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />
          <FieldTextInput
            className={css.description}
            id='description'
            label={descriptionMessage}
            name='description'
            placeholder={descriptionPlaceholderMessage}
            type='textarea'
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          {isGroupSession === true ?
            <>
              <legend>Type</legend>

              <p style={{fontSize: '12px'} }>
                Experiences: Create an adventure, guide a tour or host a retreat.<br />
                Wokeshops: Share knowledge, hold a circle, teach a workshop.
              </p>

              <FieldRadioButton
                id="serviceType-experience"
                label="Experience"
                name="serviceType"
                value="experience"
              />
              <FieldRadioButton
                id="serviceType-wokeshop"
                label="Wokeshop"
                name="serviceType"
                value="wokeshop"
              />
              <br/>
            </>
            :
            <input type="hidden" name="serviceType" value="oneToOne"/>
          }

          <h3>Specialty</h3>
          <FieldCheckboxGroup
            id='specialty'
            label='Choose between 1-5 tags that best describe your listing, this will help us build our algorithm'
            name='specialty'
            options={services.map(specialty => ({
              key: specialty,
              label: intl.formatMessage({id: `Services.${specialty}`}),
            }))}
            twoColumns
          />

          <br/>

          <Button
            className={css.submitButton}
            disabled={submitDisabled}
            inProgress={submitInProgress}
            ready={submitReady}
            type='submit'
          >
            {saveActionMsg}
          </Button>
        </Form>
      )
    }}
  />
)

EditListingDescriptionFormComponent.defaultProps = {className: null, fetchErrors: null}

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  isGroupSession: bool.isRequired
}

export default compose(injectIntl)(EditListingDescriptionFormComponent)
