import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from "..";
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  PrivacyPolicy,
  Footer,
} from '../../components';
import config from '../../config';
import css from './CorporateProgramPage.css';
import classNames from "classnames";

const CorporateProgram = props => {
  const { scrollingDisabled, intl } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.privacyTabTitle' }),
      selected: false,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
    {
      text: "FAQ",
      selected: false,
      linkProps: {
        name: 'FAQPage',
      },
    },
    {
      text: "Corporate Program",
      selected: true,
      linkProps: {
        name: 'CorporateProgramPage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'PrivacyPolicyPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Page schema={schema} scrollingDisabled={scrollingDisabled} title={schemaTitle}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="PrivacyPolicyPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.heading}>
              Corporate Program
            </h1>
            <div className={classes}>
              <p className={css.lastUpdated}>Last updated: August 20, 2021</p>
              <p>As part of our offering we have a corporate proposition in place The Inside Job&trade;.</p>
              <p>The Inside Job&trade; is here to create connections and bonds within corporate teams, to share tools that tackle cognitive bounce and can offer a set of benefits aligned with the new or present company values.</p>
              <p>If you are interested in our corporate program, contact us at <a href="mailto:namaste@lemongrass.life">namaste@lemongrass.life</a>.</p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

CorporateProgram.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
    scrollingDisabled: isScrollingDisabled(state),
  });

const CorporateProgramPage = compose(
  connect(mapStateToProps),
  injectIntl
)(CorporateProgram);

export default CorporateProgramPage;
