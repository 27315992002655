/* eslint-disable spaced-comment, no-void, comma-dangle, object-curly-spacing, multiline-ternary, quotes,
   no-debugger, no-return-assign, no-unused-expressions, no-param-reassign, no-restricted-syntax, guard-for-in
*/
import {topicsSpecialtyMap} from "../constants"
// import {storableError} from "./errors";
// import {
//   fetchAvailabilityExceptionsError,
//   fetchAvailabilityExceptionsSuccess
// } from "../containers/EditListingPage/EditListingPage.duck";
import {getSdk} from "./sdkHelper";
import {denormalisedResponseEntities} from "./data";
import {getDefaultTimeZoneOnBrowser, resetToStartOfDay} from "./dates";
import {EXPERIENCE, GROUP_SESSION, ONE_TO_ONE, PRIVATE_SESSION, WOKESHOP} from "./types"

const specialtyTopicMap = {}

void (() => {
  for (const specialty in topicsSpecialtyMap) {
    const topics = topicsSpecialtyMap[specialty]
    for (const topic of topics) {
      specialtyTopicMap[topic] = [...(specialtyTopicMap[topic] || []), specialty]
    }
  }
})()

export const isGroupSession =
    listing => listing.attributes.publicData?.sessionType === GROUP_SESSION

export const isPrivateSession =
  listing => listing.attributes.publicData?.sessionType === PRIVATE_SESSION

export const isWokeshop =
  listing => listing.attributes.publicData?.serviceType === WOKESHOP

export const isExperience =
  listing => listing.attributes.publicData?.serviceType === EXPERIENCE

export const isOneToOne =
  listing => listing.attributes.publicData?.serviceType === ONE_TO_ONE

export const isMasterListing = listing => listing.attributes.publicData?.flags?.isMaster

export const getTopicsForSpecialties = specialties => {
  const allTopicArr = specialties
    .map(specialty => topicsSpecialtyMap[specialty] || console.warn(`Invalid specialty:`, specialty))
    .filter(Boolean)
    .flat()

  return [...new Set(allTopicArr)] // distinctTopicArr 
}

export const getSpecialtiesForTopics = topics => {
  const allSpecialtyArr = topics
    .map(topic => specialtyTopicMap[topic] || console.warn(`Invalid topic:`, topic))
    .filter(Boolean)
    .flat()

  return [...new Set(allSpecialtyArr)] // distinctSpecialtyArr
}

export const displayDate = date => date.toDateString().split(" ").slice(1,3).reverse().join(" ");

/**
 * Create a master listing to store a users shared calendar.
 */
export const createMasterListing = async () => { // TO DO: ensure multiple master listings cannot be created
  const queryParams = {
    expand: true,
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  }
  const createParams = {
    title: "Master Listing",
    availabilityPlan: {
      type: 'availability-plan/time',
      timezone: 'GMT',
      entries: []
    },
    publicData: {
      flags: {
        isMaster: true,
      }
    }
  }
  const sdk = getSdk()
  const listings = await sdk.ownListings.query({})
  const masterListings = listings.data.data.filter(isMasterListing)

  if (masterListings.length > 0) {
    console.log('Master Listing already exists')
    return null
  }

  const resourcePromise = sdk.ownListings.create(createParams, queryParams)
    .catch(err => {
      console.error('Error creating Master Listing', err, {...err})
      //debugger  
    })
  const resource = await resourcePromise
  console.log('masterListing created', resource)
  return resource  
}

export const listAvailabilityExceptionsForListing = async (listing) => {
  const availabilityPlan = listing.attributes.availabilityPlan;
  const tz = availabilityPlan
    ? listing.attributes.availabilityPlan.timezone
    : typeof window !== 'undefined'
      ? getDefaultTimeZoneOnBrowser()
      : 'Etc/UTC';
  const today = new Date();
  const start = resetToStartOfDay(today, tz, 0);
  // Query range: today + 364 days
  const exceptionRange = 364;
  const end = resetToStartOfDay(today, tz, exceptionRange);
  const params = {
    listingId: listing.id,
    start,
    end,
  };
  const response = await getSdk().availabilityExceptions.query(params, { expand: true });
  return denormalisedResponseEntities(response);
}
