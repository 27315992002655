//import {getUserLanguages} from "../util/practitioners";

export {inspectAsTable} from '../red/esm/stdlib/logre-esm'

export const onCombo = (mainFun, auxFun) => (...args) => {
  const ret = mainFun(...args)
  auxFun()
  return ret
}

export const tabLog = ({bg = '#bbb', ml = 0}, ...args) => {
  const st = [
    `background:${bg}`,
    `color:#000`,
    `font-weight:900`,
    `padding:6px 4px 2px`,
    `border-radius: 12px 12px 0 0`,
    `font:700 14px roboto condensed`,
    `margin-left:${ml}px`
  ].join(';')
  console.log(args.shift(), st, ...args)
}