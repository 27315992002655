import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from '../../util/reactIntl';
import {NamedLink} from "..";
import Gate from "../Gate/Gate"
import css from './SectionJoin.module.css';

const SectionJoin = props => {
  const {rootClassName, className} = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="LandingPageSectionJoin.title"/>
      </div>
      <div className={css.subtitle}>
        <FormattedMessage id="LandingPageSectionJoin.subtitle"/>
      </div>
      <div className={css.verticalLine}/>
      <NamedLink name="SignupPage" to={{}}>
        <FormattedMessage id="LandingPageSectionCarousel.joinButton"/>
      </NamedLink>
      {/*<Gate className={css.gate}/>*/}
    </div>
  );
};

SectionJoin.defaultProps = {rootClassName: null, className: null};

const {string} = PropTypes;

SectionJoin.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionJoin;
