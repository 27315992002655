import React from 'react';
import PropTypes, {object} from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from '../../util/reactIntl';
import {ReactComponent as Arrow} from "../Arrow/Arrow.svg";
import ListingCard from '../ListingCard/ListingCard';
import css from './SectionWorkshops.module.css';

const SectionWorkshops = props => {
  const {rootClassName, className, listings} = props;

  const classes = classNames(rootClassName || css.root, className);
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [`200px`,].join(', ');

  return (
    <div className={classes}>
      <div className={css.header}>
        <div>
          <div className={css.title}>
            <FormattedMessage id="LandingPageSectionWorkshops.title"/>
          </div>
          <div className={css.subtitle}>
            <FormattedMessage id="LandingPageSectionWorkshops.subtitle"/>
          </div>
        </div>
      </div>
      <div className={css.gateControls}>
        <Arrow className={css.leftArrow}/>
        <Arrow className={css.rightArrow}/>
      </div>
      <div className={css.listingCardsWrapper}>
        <div className={css.listingCards}>

          {listings.slice(0, 5).map(l => (
            <ListingCard
              key={l.id.uuid}
              className={css.listingCard}
              listing={l}
              renderSizes={cardRenderSizes}
            />))}
        </div>
      </div>
    </div>
  );
};

SectionWorkshops.defaultProps = {rootClassName: null, className: null};

const { string, array } = PropTypes

SectionWorkshops.propTypes = {
  rootClassName: string,
  className: string,
  listings: array // object, // CHK why not an array? object generates errors
}

export default SectionWorkshops
