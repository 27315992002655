import React from "react";
import { bool, func, shape, string } from "prop-types";
import { compose } from "redux";
import { Form as FinalForm } from "react-final-form";
import classNames from "classnames";
import { intlShape, injectIntl, FormattedMessage } from "../../util/reactIntl";
import config from "../../config";
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from "../../util/types";
import * as validators from "../../util/validators";
import { formatMoney } from "../../util/currency";
import { types as sdkTypes } from "../../util/sdkLoader";
import { Button, Form, FieldCurrencyInput, FieldTextInput } from "../../components";
import css from "./EditListingPricingForm.module.css";

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        isGroupSession,
      } = formRenderProps;

      const unitType = config.bookingUnitType;

      const translationKey = `EditListingPricingForm.pricePerUnit${isGroupSession ? ".groupSession" : ""}`;

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: "EditListingPricingForm.priceInputPlaceholder",
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: "EditListingPricingForm.priceRequired",
        }),
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: "EditListingPricingForm.priceTooLow",
          },
          {
            minPrice: formatMoney(intl, minPrice),
          },
        ),
        config.listingMinimumPriceSubUnits,
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            autoFocus
            className={css.priceInput}
            currencyConfig={config.currencyConfig}
            id="price"
            label={pricePerUnitMessage}
            name="price"
            placeholder={pricePlaceholderMessage}
            validate={priceValidators}
          />

          <FieldTextInput
            className={css.durationInput}
            id="duration"
            label={intl.formatMessage({ id: "EditListingPricingForm.durationLabel" })}
            name="duration"
            placeholder={intl.formatMessage({ id: "EditListingPricingForm.durationPlaceholder" })}
          />

          <Button
            className={css.submitButton}
            disabled={submitDisabled}
            inProgress={submitInProgress}
            ready={submitReady}
            type="submit"
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  isGroupSession: bool.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
