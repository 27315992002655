/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes
*/

//import { getSdk } from '../util/sdkHelper'
//import { denormalisedResponseEntities } from '../util/data'
import languages from '../util/languages.json'

const languageHash = (() => {
  const hash = Object.create(null)
  for (const {display, value} of languages) {
    hash[value] = {display, value}
  }
  delete hash.none
  return hash
})()

//console.log({languageHash})

export const extractUser = user => {
  const {profile = {}} = user?.attributes || {}
  const {publicData = {}, protectedData = {}, privateData = {}} = profile

  return {publicData, protectedData, privateData, profile}
}

export const extractUserLang = user => {
  const {publicData = {}} = user?.attributes?.profile || {}
  const practitionerLanguages = [publicData.mainLanguage, publicData.secondaryLanguage]
    .map(l => languageHash[l]) // this must be the first as 'none' gives back undefined
    .filter(Boolean)

  return {practitionerLanguages}
}
