/* eslint-disable multiline-ternary, spaced-comment, no-void, object-curly-spacing, quotes,
   no-return-assign, comma-dangle, react/prop-types */

import React, {useEffect, useState} from 'react'
import {StaticPage, TopbarContainer} from '../../containers'
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components'

import css from './ProfileListPage.module.css'
import classNames from 'classnames'
import {listPractitioners} from '../../util/api'
import {FormattedMessage} from 'react-intl'
import {getUserLanguages} from "../../util/practitioners";
import {compose} from "redux";
import {injectIntl} from "../../util/reactIntl";
import {ListItem} from "../../components/ListItem/ListItem";

// import {lazyLoadWithDimensions} from '../../util/contextHelpers'

// const ListingImage = props => <ResponsiveImage {...props} />
// const LazyImage = lazyLoadWithDimensions(ListingImage, {loadAfterInitialRendering: 3000})

const PractitionerList = (props) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    let mounted = true
    listPractitioners().then(users => mounted && setUsers(users))
    return () => mounted = false
  }, [])

  const {className, rootClassName, intl} = props

  const classes = classNames(rootClassName || css.root, className)

  const userList = users.map(user => (
    <li key={user.id} className={css.userItem}>
      <div>
        {/* {console.log({user})} */}
        {/*<LazyImage*/}
        {/*  alt={user.name}*/}
        {/*  image={user.imageUrl}*/}
        {/*  rootClassName={css.rootForImage}*/}
        {/*  variants={['variants.landscape-crop']}*/}
        {/*/>*/}
        <NamedLink className={classes} name='ProfilePage' params={{id: user.id}}>
          <img alt='' className={css.userItemImg} src={user.imageUrl}/>
        </NamedLink>
      </div>
      <div className={css.info}>
        <NamedLink className={classes} name='ProfilePage' params={{id: user.id}}>
          <h3 className={css.priceValue}>
            {user.displayName}
          </h3>
        </NamedLink>
        <div className={css.mainInfo}>
          <div className={css.title}>
            Speaks {getUserLanguages(user).join(', ')}
          </div>
        </div>
        {user.city && <div>Location • <span>{user.city}</span></div>}
        {user.specialties &&
        <div>
          <ul className={css.hostname}>
            {
              user.specialties.slice(0, 7)
                .map(specialty => intl.formatMessage({id: `Services.${specialty}`})).join(", ")
                // TODO: different way to render below
                // .map(specialty =>
                //   <ListItem key={specialty}
                //             message={intl.formatMessage({id: `Services.${specialty}`})}
                //             value={specialty}/>
                // )
            }
          </ul>
        </div>}
      </div>
    </li>)
  )

  return (
    <StaticPage
      className={css.root}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'PractitionerListPage',
        description: 'Practitioner',
        name: 'Our Guides',
      }}
      title='Our Guides'
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <h1><FormattedMessage id='ProfileListPage.title'/></h1>
          <p className={css.summary}><FormattedMessage id='ProfileListPage.summary'/></p>
          <ul className={css.userCards}>
            {userList}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  )
}

const PractitionerListPage = compose(
  injectIntl
)(PractitionerList);

export default PractitionerListPage
