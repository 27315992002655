/* eslint-disable spaced-comment, no-console, quotes, object-curly-spacing , no-void, no-return-assign,
   no-restricted-syntax, comma-dangle
*/
import React, {Component} from "react"
import PropTypes from "prop-types"
import {compose} from "redux"
import {connect} from "react-redux"
import {FormattedMessage, injectIntl, intlShape} from "../../util/reactIntl"
import {types as sdkTypes} from "../../util/sdkLoader"
import {propTypes, REVIEW_TYPE_OF_CUSTOMER, REVIEW_TYPE_OF_PROVIDER} from "../../util/types"
import {ensureCurrentUser, ensureUser} from "../../util/data"
import {withViewport} from "../../util/contextHelpers"
import {isScrollingDisabled} from "../../ducks/UI.duck"
import {getMarketplaceEntities} from "../../ducks/marketplaceData.duck"
import {NotFoundPage, TopbarContainer} from ".."
import config from "../../config"
import {
  listPractitionerListings,
  siftListings
} from "../../models/listingAdapter"
import {
  ButtonTabNavHorizontal,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  ProfileAboutSection,
  ProfileHeaderSection,
  ProfileSpecialitiesSection,
  Reviews,
  SearchResultsPanel,
} from "../../components"
import {loadData} from "./ProfilePage.duck"
import css from "./ProfilePage.module.css"

const {UUID} = sdkTypes
const MAX_MOBILE_SCREEN_WIDTH = 768

const ProfileListingsSections = ({profileUser, intl}) => {
  const [listings, setListings] = React.useState([])

  React.useEffect(() => {
    let mounted = true
    listPractitionerListings(profileUser).then(listings => {
      if (mounted) {
        setListings(siftListings(listings));
      }
    })
    return () => mounted = false
  }, [profileUser.id])

  return (
    <div className={css.listings}>
      <div className={css.dataLabel}>
        <h2><FormattedMessage id='ProfilePage.sessionsTitle' /></h2>
      </div>
      {listings?.length ?
        <SearchResultsPanel
          className={css.searchListingsPanel}
          listings={listings}
          minify
          pagination={{
            totalItems: 10,
            totalPages: 1,
            page: 1,
            perPage: 10
          }}
          setActiveListing={() => {
          }}
        /> :
        <div><FormattedMessage id='ProfilePage.noListings' /></div>
        // <div>Hey, thanks for checking in. I’m currently working on my listings. Please check back soon!</div>
      }
    </div>
  )
}

/* eslint-disable */
export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    }

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this)
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this)
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    })
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    })
  }
  
  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      reviews,
      queryReviewsError,
      viewport,
      intl,
    } = this.props

    const ensuredCurrentUser = ensureCurrentUser(currentUser)
    const profileUser = ensureUser(user)
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid

    const displayName = profileUser.attributes.profile.displayName
    const bio = profileUser.attributes.profile.bio
    const hasBio = !!bio
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH

    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile"/>
      </NamedLink>
    ) : null
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop"/>
      </NamedLink>
    ) : null
    const asideContent = (
      <div className={css.asideContent}>
        <h1 className={css.mobileHeading}>
          {displayName ? (
            <FormattedMessage id="ProfilePage.mobileHeading" values={{name: displayName}}/>
          ) : null}
        </h1>
        {editLinkMobile}
        {editLinkDesktop}
      </div>
    )

    const hasReviewsOn = true // hide them for now

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed"/>
      </p>
    )

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER)

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER)

    const mobileReviews = (
      <div className={css.mobileReviews}>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{count: reviewsOfProvider.length}}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfProvider}/>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{count: reviewsOfCustomer.length}}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfCustomer}/>
      </div>
    )

    const desktopReviewTabs = [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{count: reviewsOfProvider.length}}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{count: reviewsOfCustomer.length}}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ]

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs}/>

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <Reviews reviews={reviewsOfProvider}/>
        ) : (
          <Reviews reviews={reviewsOfCustomer}/>
        )}
      </div>
    )

    const mainContent = (
      <div>
        <h1 className={css.desktopHeading}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{name: displayName}}/>
        </h1>
        <h2>About</h2>
        {hasBio ? <p className={css.bio}>{bio}</p> : null}
        {hasReviewsOn && (isMobileLayout ? mobileReviews : desktopReviews)}
      </div>
    )

    let content

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage/>;
    } else if (userShowError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed"/>
        </p>
      )
    } else {
      content = mainContent
    }

    const schemaTitle = intl.formatMessage(
      {
        id: "ProfilePage.schemaTitle",
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    )

    if (profileUser.id && !profileUser.id) {
      console.groupCollapsed('TRIAGE', {user, currentUser, profileUser})

      const dispname = user => user?.attributes?.profile?.displayName || '-'

      console.log('ProfPageComp render1', [
        `currentUser:[${dispname(currentUser)}]`,
        `profileUser:[${dispname(profileUser)}]`,
        `user:[${dispname(user)}]`,
        `ensuredCurrentUser:[${dispname(ensuredCurrentUser)}]`
      ].join(' '))

      console.log('ProfPageComp render2', {asideContent, currentUser, profileUser})
      console.groupEnd()
    }

    return (
      <Page
        schema={{
          "@context": "http://schema.org",
          "@type": "ProfilePage",
          name: schemaTitle,
        }}
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {asideContent}
            <ProfileHeaderSection {...{profileUser, currentUser}}/>
            {<ProfileSpecialitiesSection currentUser={profileUser} intl={intl}/> }
            {<ProfileAboutSection {...{profileUser, intl}} />}
            {profileUser.id && <ProfileListingsSections {...{profileUser, intl}} />}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>

        {/* <LayoutSideNavigation> */}
        {/*  <LayoutWrapperTopbar> */}
        {/*    <TopbarContainer currentPage="ProfilePage" /> */}
        {/*  </LayoutWrapperTopbar> */}
        {/*  <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav> */}
        {/*  <LayoutWrapperMain>{content}</LayoutWrapperMain> */}
        {/*  <LayoutWrapperFooter> */}
        {/*    <Footer /> */}
        {/*  </LayoutWrapperFooter> */}
        {/* </LayoutSideNavigation> */}
      </Page>
    )
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  reviews: [],
  queryReviewsError: null,
}

const {bool, arrayOf, number, shape} = PropTypes

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
}

const mapStateToProps = state => {
  const {currentUser} = state.user
  const {userId, userShowError, reviews, queryReviewsError} = state.ProfilePage
  const userMatches = getMarketplaceEntities(state, [{type: "user", id: userId}])
  const user = userMatches.length === 1 ? userMatches[0] : null
  // console.log(`ProfilePage.mapStateToProps`, {state, reviews, currentUser})

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    reviews,
    queryReviewsError,
  }
}

const ProfilePage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl,
)(ProfilePageComponent)

ProfilePage.loadData = params => {
  const id = new UUID(params.id)
  const loaded = loadData(id)
  // console.log(`Running ProfilePage.loadData`, params, {id, loaded});
  return loaded;
}

export default ProfilePage
