/* eslint-disable react/jsx-sort-props, no-unused-expressions, quotes, object-curly-spacing, semi,
   comma-dangle, spaced-comment, jsx-quotes, multiline-ternary, no-restricted-syntax, guard-for-in
*/
import React, { Component } from "react";
import { array, bool, func, object, string, oneOfType, instanceOf } from "prop-types";
import { compose } from "redux";
import { Form as FinalForm, FormSpy } from "react-final-form";
import classNames from "classnames";
import { FormattedMessage, intlShape, injectIntl } from "../../util/reactIntl";
import { timestampToDate } from "../../util/dates";
import { DATE_TYPE_DATETIME, propTypes } from "../../util/types";
import config from "../../config";
import { IconSpinner, Form, PrimaryButton, TimeRange } from "../../components";
import { selectedDateListener } from '../../models/dateAdapter'
import EstimatedBreakdownMaybe from "./EstimatedBreakdownMaybe";
import FieldDateAndTimeInput from "./FieldDateAndTimeInput";
import css from "./BookingTimeForm.module.css";

export class BookingTimeFormComponent extends Component {
  constructor (props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit (e) {
    this.props.onSubmit(e);
  }

  fetchTransaction (bookingStartTime, bookingEndTime, startDate, endDate ) {
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    startDate && selectedDateListener(startDate)

    if (bookingStartTime && bookingEndTime && !this.props.fetchLineItemsInProgress) {
     this.props.onFetchTransactionLineItems({
          bookingData: { startDate, endDate },
          listingId,
          isOwnListing,
        });
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange (formValues) {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    this.fetchTransaction (bookingStartTime, bookingEndTime, startDate, endDate)
  }

  updateTransaction (fixedBookingTime, fixedBookingEndTime) {
    const startDate = fixedBookingTime || null;
    const endDate = fixedBookingEndTime || null;

    this.fetchTransaction (fixedBookingTime, fixedBookingEndTime, startDate, endDate)
  }

  componentDidMount() {
    const {hasSetDate, fixedBookingTime, fixedBookingEndTime} = this.props
    if(hasSetDate) {
      this.updateTransaction(fixedBookingTime, fixedBookingEndTime)
    }
  }

  render () {
    const { rootClassName, className, price: unitPrice, monthlyTimeSlots, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const verboseLog = false

    if (verboseLog) {
      /* eslint-disable-next-line */
      const o2a = obj => {
        const ret = []
        for (const key in obj) {
          ret.push({key, value: obj[key]})
        }
        return ret
      }
      const {timeZone, timeZone3} = this.props
      const novTimeSlots = monthlyTimeSlots?.['2021-11']?.timeSlots
        ?.map(({attributes: {start, end}}) => ({start, end})) || []

      const {
        monthlyTimeSlots: monTS,
        isGroupSession: isGroup,
        earliestBookableDate: earliest,
        startDatePlaceholder: startD,
        endDatePlaceholder: endD
      } = this.props

      console.group(`⏰ bookingTimeForm`, {timeZone3, timeZone, startD, endD, earliest})
      console.log({isGroup, ...monTS})

      //console.table(o2a(agg))
      console.table(novTimeSlots)

      console.groupEnd()
    }

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const hasFreeMonthlyTimeSlots =
      Object.keys(monthlyTimeSlots).filter(
        month =>
          !monthlyTimeSlots[month].fetchTimeSlotsInProgress &&
          monthlyTimeSlots[month].timeSlots.length > 0,
      ).length > 0;

    return (
      <FinalForm
        {...rest}
        monthlyTimeSlots={monthlyTimeSlots}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            isGroupSession,
            duration,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            timeZone3,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            hasSetDate,
            fixedBookingTime,
            fixedBookingEndTime
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          // console.log({startTime, endTime})

          const bookingStartLabel = intl.formatMessage({
            id: "BookingTimeForm.bookingStartTitle",
          });
          const bookingEndLabel = intl.formatMessage({
            id: "BookingTimeForm.bookingEndTitle",
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const hasFreeTimeSlots = hasSetDate || hasFreeMonthlyTimeSlots

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                  timeZone3
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              {console.log(`%cBookingTimeForm lineItems[0]`, 'background:#08e;', lineItems?.[0]?.lineTotal)}
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                duration={duration}
                isGroupSession={isGroupSession}
                lineItems={lineItems}
                totalOverride={unitPrice} // single point of price hack (comment this out!)
              />
            </div>
          ) : null;

          const seatInfoMaybe = !hasFreeTimeSlots ? (
            <div>No available places for this event</div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper,
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <FormSpy
                onChange={values => {
                  this.handleOnChange(values);
                }}
                subscription={{ values: true }}
              />
              {hasSetDate && (
                <>
                  <FormattedMessage id='CheckoutPage.priceBreakdownTitle'/>
                  {console.log({fixedBookingTime, fixedBookingEndTime, timeZone, timeZone3})}
                  <TimeRange
                      className={css.timeRange}
                      dateType={DATE_TYPE_DATETIME}
                      endDate={fixedBookingEndTime}
                      startDate={fixedBookingTime}
                      timeZone={timeZone}
                      timeZone3={timeZone3}
                    />
                  </>
                )
              }
              
              {!hasSetDate && monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  earliestBookableDate={this.props.earliestBookableDate}
                  bookingStartLabel={bookingStartLabel}
                  className={css.bookingDates}
                  data-mac='mukk1'
                  duration={duration}
                  form={form}
                  intl={intl}
                  isGroupSession={isGroupSession}
                  listingId={listingId}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  pristine={pristine}
                  timeZone={timeZone}
                  timeZone3={timeZone3}
                  values={values}
                />
              ) : null}

              {seatInfoMaybe}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {hasFreeTimeSlots ? (
                <>
                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? "BookingTimeForm.ownListing"
                          : "BookingTimeForm.youWontBeChargedInfo"
                      }
                    />
                  </p>
                  <p className={css.smallPrint}>
                    By booking, you agree to the provider's <a href="/terms-of-service">terms of service</a>.
                  </p>
                  <div className={submitButtonClasses}>
                    <PrimaryButton type="submit">
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton>
                  </div>
                </>
              ) : null}
            </Form>
          );
        }}
        unitPrice={unitPrice}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  fixedBookingTime: null,
  fixedBookingEndTime: null,
  className: null,
  hasSetDate: false,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  fixedBookingTime: oneOfType([instanceOf(Date), string]),
  fixedBookingEndTime: object,
  hasSetDate: bool,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = "BookingTimeForm";

export default BookingTimeForm;
