/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax */

import { CUSTOMER, PRACTITIONER } from '../constants'

// DRY property accessor primitives for protectedData properties

const __isCustomer = protectedData => protectedData?.userType === CUSTOMER
const __isPractitioner = protectedData => protectedData?.userType === PRACTITIONER
const __isPractitionerInitialSetupComplete = protectedData => !!protectedData?.practitioner_initialSetupComplete
const __hasCompletedProfile = protectedData => !!protectedData?.practitioner_profileCompleted
const __hasApprovedProfile = protectedData => !!protectedData?.__practitioner_profileApproved
const __hasEditingAllowedProfile = protectedData => !!protectedData?.__practitioner_profileEditingAllowed

// Combined accessors

const __isPractitionerProfileVerified = prot => __hasCompletedProfile(prot) && __hasApprovedProfile(prot)
const __isPendingPractitionerProfileApproval = prot => __hasCompletedProfile(prot) && !__hasApprovedProfile(prot)
const __isProfileEditingEnabled = prot => __isCustomer(prot) || (!__hasCompletedProfile(prot) || __hasEditingAllowedProfile(prot))

// Helpers

const getProtectedData = user => user?.attributes?.profile?.protectedData
const byProtected = protFn => user => protFn(getProtectedData(user))

// Public user interface

export const isCustomer = byProtected(__isCustomer)
export const isPractitioner = byProtected(__isPractitioner)
export const isPractitionerInitialSetupComplete = byProtected(__isPractitionerInitialSetupComplete)
export const hasCompletedProfile = byProtected(__hasCompletedProfile)
export const hasApprovedProfile = byProtected(__hasApprovedProfile)
export const isPendingPractitionerProfileApproval = byProtected(__isPendingPractitionerProfileApproval)
export const isPractitionerProfileVerified = byProtected(__isPractitionerProfileVerified)
export const isProfileEditingEnabled = byProtected(__isProfileEditingEnabled)

// export function isCustomer(currentUser) {
//   return currentUser?.attributes?.profile?.protectedData?.userType === CUSTOMER;
// }

// export function isPractitioner(currentUser) {
//   return currentUser?.attributes?.profile?.protectedData?.userType === PRACTITIONER;
// }

// export function isPractitionerInitialSetupComplete(currentUser) {
//   return currentUser.attributes.profile?.protectedData?.practitioner_initialSetupComplete === true;
// }

// export function hasCompletedProfile(currentUser) {
//   return currentUser?.attributes?.profile?.protectedData?.practitioner_profileCompleted === true;
// }

// export function isPendingPractitionerProfileApproval(currentUser) {
//   return (
//     hasCompletedProfile(currentUser) &&
//     !currentUser.attributes.profile?.protectedData?.__practitioner_profileApproved
//   );
// }

// export function isPractitionerProfileVerified(currentUser) {
//   return (
//     hasCompletedProfile(currentUser) &&
//     currentUser.attributes.profile?.protectedData?.__practitioner_profileApproved
//   );
// }

// export function isProfileEditingEnabled(currentUser) {
//   return (
//     !hasCompletedProfile(currentUser) ||
//     currentUser?.attributes.profile?.protectedData?.__practitioner_profileEditingAllowed
//   );
// }

export function getMissingPractitionerProfileFields (currentUser) {
  const requiredAttributesFields = ['email']
  const requiredProfileFields = ['firstName', 'lastName', 'bio']
  const requiredPrivateFields = [
    'idType',
    'idIssuingCountry',
    'idNumber',
    'paymentCurrency',
    'paymentCountry',
    'billingAddressCity',
    'billingAddressPostCode',
    'billingAddressRestOfAddress',
    'insuranceHasInsurance',
    'dateOfBirth',
    'timeOfBirth',
    'placeOfBirth'
  ]
  const requiredProtectedFields = ['phoneNumber', 'accountType']
  const requiredPublicFields = ['mainLanguage', 'displayName', 'city']

  const attributes = currentUser?.attributes || {}
  const profile = attributes?.profile || {}
  const protectedData = profile?.protectedData || {}
  const privateData = profile?.privateData || {}
  const publicData = profile?.publicData || {}

  if (protectedData.accountType === 'company') {
    requiredProtectedFields.push('businessName')
    requiredPrivateFields.push('companyNumber')
    requiredPrivateFields.push('companyCountry')
    requiredPrivateFields.push('vatNumber')
  }

  if (privateData.paymentCountry === 'GB') {
    requiredPrivateFields.push('paymentSortCode')
    requiredPrivateFields.push('paymentAccountNumber')
  } else {
    requiredPrivateFields.push('paymentIban')
  }

  const missingAttributesFields = requiredAttributesFields.filter(
    field => !Object.keys(attributes).includes(field)
  )

  const missingProfileFields = requiredProfileFields.filter(
    field => !Object.keys(profile).includes(field)
  )
  const missingPrivateFields = requiredPrivateFields.filter(
    field => !Object.keys(privateData).includes(field)
  )

  const missingProtectedFields = requiredProtectedFields.filter(
    field => !Object.keys(protectedData).includes(field)
  )
  const missingPublicFields = requiredPublicFields.filter(
    field => !Object.keys(publicData).includes(field)
  )

  return missingAttributesFields
    .concat(missingProfileFields)
    .concat(missingPrivateFields)
    .concat(missingProtectedFields)
    .concat(missingPublicFields)
}

export function isPractitionerProfileReadyToSubmitForApproval (currentUser) {
  return getMissingPractitionerProfileFields(currentUser).length === 0
}

export function getRequiredFieldDisplayName (intl, fieldName) {
  return intl.formatMessage({ id: `ProfileRequiredFields.${fieldName}` })
}
