import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {FormattedMessage} from "../../util/reactIntl";
import {LISTING_STATE_DRAFT} from "../../util/types";
import {ensureOwnListing} from "../../util/data";
import {ListingLink} from "..";
import {EditListingLocationForm} from "../../forms";
import css from "./EditListingLocationPanel.module.css";

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const {listing} = this.props;
    const currentListing = ensureOwnListing(listing);
    const {geolocation, publicData} = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const {online, address, building} = location;
    const sessionLink = currentListing.attributes.privateData?.sessionLink;
    const sessionLinkPassword = currentListing.attributes.privateData?.sessionLinkPassword;
    return {
      online,
      building,
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: {address, origin: geolocation},
        }
        : null,
      sessionLink,
      sessionLinkPassword,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      isGroupSession,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage
                id="EditListingLocationPanel.listingTitle"
                values={{listingType: isGroupSession ? "groupSession" : "sessions"}}
              />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id={`EditListingLocationPanel.createListingTitle${isGroupSession ? ".groupSession" : ""}`}
      />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingLocationForm
          className={css.form}
          disabled={disabled}
          fetchErrors={errors}
          initialValues={this.state.initialValues}
          isGroupSession={isGroupSession}
          onChange={onChange}
          onSubmit={values => {
            const {online, building = "", location, sessionLink, sessionLinkPassword } = values;
            const link = online === true ? sessionLink : "";
            const linkPassword = online === true ? sessionLinkPassword : "";
            const address = online === true ? "" : location?.selectedPlace?.address;

            const updateValues = {
              geolocation: location?.selectedPlace?.origin,
              publicData: {
                location: {
                  building,
                  online,
                  address,
                },
                locationType: online === true ? 'online' : 'physical',
              },
              privateData: {sessionLink: link, sessionLinkPassword: linkPassword},
            };

            this.setState({
              initialValues: {
                online,
                building,
                location,
              },
            });
            onSubmit(updateValues);
          }}
          ready={ready}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
      </div>
    );
  }
}

const {func, object, string, bool} = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  isGroupSession: bool.isRequired,
};

export default EditListingLocationPanel;
