import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink } from "..";
import css from './SectionVideos.module.css';
import VideosPlaceholder from "../../assets/videos-placeholder.png";

const SectionVideos = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="LandingPageSectionVideos.title" />
      </div>
      <div className={css.videosPlaceholder}>
        <img className={css.videos} src={VideosPlaceholder} />
      </div>
    </div>
  );
};

SectionVideos.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionVideos.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionVideos;
