/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, no-use-before-define,
   no-multi-assign
*/

// HEAD filter out isDev listings if not isDev

const devModeStyle = `
body.devmode::before {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index:99;
  content: 'devModeOn';
  color: hsla(0, 0%, 100%, .4);
  text-shadow: 4px 4px 8px black;
  font: 700 32px roboto condensed;
  font-style: italic;
}`

const createInlineCSS = (text, cclass = '', first = false) => {
  const style$ = document.createElement('style')
  style$.className = cclass
  style$.textContent = text
  first
    ? document.head.insertBefore(style$, document.head.children[0])
    : document.head.appendChild(style$)
}
const {location, localStorage} = typeof window === 'undefined' ? {} : window

const setDev = flag => {
  localStorage?.setItem('isDevModeOn', flag)
  location && (window.location.href = location)
  return flag
}

const dev = {
  isDev: false,
  isAvailDebug: false,
  isExcFormDebug: false,
  isCalDebug: true,
  isSaveDebug: false,
  get on () { return setDev(dev.isDev = true) },
  get off () { return setDev(dev.isDev = false) }
}
const getDev = () => dev.isDev
void getDev

const onReadyState = (doc = document) => new Promise(resolve => doc.getElementsByTagName('head').length
  ? resolve()
  : doc.addEventListener('readystatechange', _ => {
    if (doc.readyState === 'interactive' || doc.readyState === 'complete') {
      resolve()
    }
  })
)
const onDomReady = async callback => onReadyState().then(callback)

typeof window !== 'undefined' && onDomReady(() => {
  window.dev = dev

  dev.isDev = localStorage.getItem('isDevModeOn') === 'true' // WTF
  document.body.classList.toggle('devmode', dev.isDev)
  createInlineCSS(devModeStyle)
})

export const featFlags = dev
