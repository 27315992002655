// ================ Action types ================ //

import { denormalisedResponseEntities } from "../../util/data";
import { currentUserShowSuccess } from "../../ducks/user.duck";
import React from "react";

export const SAVE_PAYOUT_AND_INSURANCE_REQUEST =
  "app/PayoutAndInsurancePage/SAVE_PAYOUT_AND_INSURANCE_REQUEST";
export const SAVE_PAYOUT_AND_INSURANCE_SUCCESS =
  "app/PayoutAndInsurancePage/SAVE_PAYOUT_AND_INSURANCE_SUCCESS";
export const SAVE_PAYOUT_AND_INSURANCE_ERROR =
  "app/PayoutAndInsurancePage/SAVE_PAYOUT_AND_INSURANCE_ERROR";

export const SAVE_PAYOUT_AND_INSURANCE_CLEAR =
  "app/PayoutAndInsurancePage/SAVE_PAYOUT_AND_INSURANCE_CLEAR";

// ================ Reducer ================ //

const initialState = {
  savePayoutAndInsuranceInProgress: false,
  payoutAndInsuranceChanged: false,
  savePayoutAndInsuranceError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_PAYOUT_AND_INSURANCE_REQUEST:
      return {
        ...state,
        savePayoutAndInsuranceInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        payoutAndInsuranceChanged: false,
      };
    case SAVE_PAYOUT_AND_INSURANCE_SUCCESS:
      return { ...state, savePayoutAndInsuranceInProgress: false, payoutAndInsuranceChanged: true };
    case SAVE_PAYOUT_AND_INSURANCE_ERROR:
      return {
        ...state,
        savePayoutAndInsuranceInProgress: false,
        savePayoutAndInsuranceError: payload,
      };
    case SAVE_PAYOUT_AND_INSURANCE_CLEAR:
      return {
        ...state,
        savePayoutAndInsuranceInProgress: false,
        payoutAndInsuranceChanged: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const savePayoutAndInsuranceRequest = () => ({ type: SAVE_PAYOUT_AND_INSURANCE_REQUEST });
export const savePayoutAndInsuranceSuccess = () => ({ type: SAVE_PAYOUT_AND_INSURANCE_SUCCESS });
export const savePayoutAndInsuranceClear = () => ({ type: SAVE_PAYOUT_AND_INSURANCE_CLEAR });

export const savePayoutAndInsuranceError = e => ({
  type: SAVE_PAYOUT_AND_INSURANCE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Update payoutAndInsurance, actions depend on which data has changed
 */
export const savePayoutAndInsurance = params => (dispatch, getState, sdk) => {
  dispatch(savePayoutAndInsuranceRequest());
  const {
    paymentCurrency,
    paymentCountry,
    paymentSortCode,
    paymentAccountNumber,
    paymentIban,
    billingAddressCity,
    billingAddressPostCode,
    billingAddressRestOfAddress,
    insuranceHasInsurance,
    insuranceDetails,
  } = params;
  return sdk.currentUser
    .updateProfile(
      {
        privateData: {
          paymentCurrency,
          paymentCountry,
          paymentSortCode,
          paymentAccountNumber,
          paymentIban,
          billingAddressCity,
          billingAddressPostCode,
          billingAddressRestOfAddress,
          insuranceHasInsurance,
          insuranceDetails,
        },
      },
      {
        expand: true,
        include: ["profileImage"],
      },
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error("Expected a resource in the sdk.currentUser.updateProfile response");
      }
      const currentUser = entities[0];
      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));

      dispatch(savePayoutAndInsuranceSuccess());
    })
    .catch(e => dispatch(savePayoutAndInsuranceError(e)));
};
