import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LinkTabNavHorizontal } from "..";
import {GROUP_SESSION, PRIVATE_SESSION} from '../../util/types';
import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, selectedPageName, sessionType } = props;
  const classes = classNames(rootClassName || css.root, className);

  /* const tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettingsPage" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.contactDetailsPage" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ]; */

  const tabs = [
    {
      text:
        <FormattedMessage
          id="UserNav.manageServiceLink"
          values={{sessionType: (<FormattedMessage id={GROUP_SESSION} />)}}
        />,
      selected: sessionType === GROUP_SESSION,
      disabled: false,
      linkProps: {
        name: 'ManageListingsPage',
        params: {
          service: GROUP_SESSION,
        }
      },
    },
    {
      text:
        <FormattedMessage
          id="UserNav.manageServiceLink"
          values={{sessionType: (<FormattedMessage id={PRIVATE_SESSION} />)}}
        />,
      selected: sessionType === PRIVATE_SESSION,
      disabled: false,
      linkProps: {
        name: 'ManageListingsPage',
        params: {
          service: PRIVATE_SESSION,
        }
      },
    },
    /* {
      text: <FormattedMessage id="UserNav.manageServiceLink" />,
      selected: sessionType === WORKSHOP,
      disabled: true,
      linkProps: {
        name: 'ManageListingsPage',
        params: {
          service: WORKSHOP,
        }
      },
    }, */
  ];

  return (
    <LinkTabNavHorizontal className={classes} skin="light" tabRootClassName={css.tab} tabs={tabs} />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  sessionType: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  sessionType: string,
};

export default UserNav;
