import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from "..";
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import css from './AboutPage.module.css';
import image from './about-us-1056.jpg';

const AboutPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Lemongrass',
        name: 'About page',
      }}
      title="About Us"
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img alt="My first ice cream." className={css.coverImage} src={image} />

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
            </div>

            <div className={css.contentMain}>
              <h3 style={{fontStyle: "italic"}}>
                “Lemongrass was formed with a vision  to create a global community, a space where you can discover and connect with alternative healers, spiritual teachers and paradigm shifters to do the sacred work that illuminates yourself and the world.”
              </h3>
              <span>Tatiana, CEO + Founder of Lemongrass</span>

              <p></p>
              <br />

              <h3>Things we do</h3>
              <ul className={css.list}>
                <li>One-To-Ones</li>
                <li>Workshops</li>
                <li>Retreats</li>
                <li>Experiences</li>
                <li>Courses and Corporate Events</li>
              </ul>
              <br />

              <h3>Specialties</h3>
              <ul className={css.list}>
                <li>Ancient Knowledge</li>
                <li>Personal Growth</li>
                <li>Spiritual Teachings</li>
                <li>Alchemy</li>
                <li>Healing</li>
                <li>Charts</li>
                <li>Readings</li>
              </ul>
              <br/>

              <h2 id="join-us" className={css.subtitle}>Join Us</h2>

              <p>
                Do you have a special skill, knowledge or experience that is aligned with Lemongrass?
                If you’d like to be part of our community and have an entrepreneurial mindset and are open to embrace our mission please get in touch.
                Email us at <a href="mailto:namaste@lemongrass.life">namaste@lemongrass.life</a>.
              </p>

              {/*<h3 className={css.subtitle} id="contact">*/}
              {/*  Create your own marketplace like Lemongrass*/}
              {/*</h3>*/}
              {/*<p>*/}
              {/*  You can also checkout our{' '}*/}
              {/*  <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}*/}
              {/*  <ExternalLink href={siteTwitterPage}>Twitter</ExternalLink>.*/}
              {/*</p>*/}
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
