import React, {Component} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import Protect from "react-app-protect";
import Cookies from "js-cookie";
import classNames from "classnames";
import {isEmpty} from "lodash";
import routeConfiguration from "../../routeConfiguration";
import {pathByRouteName} from "../../util/routes";
import {apiBaseUrl} from "../../util/api";
import {FormattedMessage, injectIntl, intlShape} from "../../util/reactIntl";
import config from "../../config";
import {propTypes} from "../../util/types";
import {ensureCurrentUser} from "../../util/data";
import {isSignupEmailTakenError, isTooManyEmailVerificationRequestsError,} from "../../util/errors";
import {
  Footer,
  IconClose,
  IconEmailSent,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LinkTabNavHorizontal,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  SocialLoginButton,
  TermsOfService,
} from "../../components";
import {ConfirmSignupForm, LoginForm, SignupForm} from "../../forms";
import {TopbarContainer} from "..";
import {authenticationInProgress, login, signup, signupWithIdp} from "../../ducks/Auth.duck";
import {isScrollingDisabled, manageDisableScrolling} from "../../ducks/UI.duck";
import {sendVerificationEmail} from "../../ducks/user.duck";
import {PRACTITIONER} from "../../constants";
import css from "./PractitionerSignup.module.css";
import {FacebookLogo, GoogleLogo} from "./socialLoginLogos";

export class PractitionerSignupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      authError: Cookies.get("st-autherror")
        ? JSON.parse(Cookies.get("st-autherror").replace("j:", ""))
        : null,
      authInfo: Cookies.get("st-authinfo")
        ? JSON.parse(Cookies.get("st-authinfo").replace("j:", ""))
        : null,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove("st-autherror");
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      isPractitioner,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      confirmError,
      submitSingupWithIdp,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
    } = this.props;

    const isConfirm = tab === "confirm";
    const isLogin = tab === "login";
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom || authinfoFrom || null;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      return <Redirect to={from}/>;
    } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
      return <NamedRedirect name="LandingPage"/>;
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="PractitionerSignup.loginFailed"/>
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="PractitionerSignup.signupFailedEmailAlreadyTaken"/>
        ) : (
          <FormattedMessage id="PractitionerSignup.signupFailed"/>
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="PractitionerSignup.signupFailedEmailAlreadyTaken"/>
        ) : (
          <FormattedMessage id="PractitionerSignup.signupFailed"/>
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const fromState = {state: from ? {from} : null};

    const tabs = [
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="PractitionerSignup.signupAsPractitionerLinkText"/>
          </h1>
        ),
        selected: !isLogin,
        linkProps: {
          name: "SignupPage",
          to: fromState,
        },
      },
    ];

    const handleSubmitSignup = values => {
      const {fname, lname, ...rest} = values;

      const params = {
        firstName: fname.trim(),
        lastName: lname.trim(),
        userType: PRACTITIONER,
        __isPractitioner: true,
        __practitioner_profileApproved: false,
        __practitioner_profileEditingAllowed: false,
        __isCustomer: false,
        practitioner_profileCompleted: false,
        practitioner_initialSetupComplete: false,
        __temporaryPassword: rest.password,
        publicData: {
          signs: {
            moonSign: "",
            starSign: "",
            elementSign: "",
          },
          __practitioner_profileHidden: true,
        },
        ...rest,
      };
      submitSignup(params);
    };

    const handleSubmitConfirm = values => {
      const {idpToken, email, firstName, lastName, idpId} = this.state.authInfo;
      const {email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest} = values;

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && {email: newEmail}),
        ...(newFirstName !== firstName && {firstName: newFirstName}),
        ...(newLastName !== lastName && {lastName: newLastName}),
      };

      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? {...rest} : null;

      submitSingupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...(!!protectedData && {protectedData}),
      });
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : "";

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName("LandingPage", routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : "";

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName("ConfirmPage", routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : "";

      return {baseUrl, fromParam, defaultReturnParam, defaultConfirmParam};
    };
    const authWithFacebook = () => {
      const defaultRoutes = getDefaultRoutes();
      const {baseUrl, fromParam, defaultReturnParam, defaultConfirmParam} = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const authWithGoogle = () => {
      const defaultRoutes = getDefaultRoutes();
      const {baseUrl, fromParam, defaultReturnParam, defaultConfirmParam} = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          <FormattedMessage id="PractitionerSignup.confirmSignupWithIdpTitle" values={{idp}}/>
        </h1>

        <p className={css.confirmInfoText}>
          <FormattedMessage id="PractitionerSignup.confirmSignupInfoText"/>
        </p>
        {confirmErrorMessage}
        <ConfirmSignupForm
          authInfo={this.state.authInfo}
          className={css.form}
          idp={idp}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({tosModalOpen: true})}
          onSubmit={handleSubmitConfirm}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const facebookButtonText = isLogin ? (
      <FormattedMessage id="PractitionerSignup.loginWithFacebook"/>
    ) : (
      <FormattedMessage id="PractitionerSignup.signupWithFacebook"/>
    );

    const googleButtonText = isLogin ? (
      <FormattedMessage id="PractitionerSignup.loginWithGoogle"/>
    ) : (
      <FormattedMessage id="PractitionerSignup.signupWithGoogle"/>
    );
    const socialLoginButtonsMaybe = showSocialLogins ? (
      <div className={css.idpButtons}>
        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="PractitionerSignup.or"/>
          </span>
        </div>

        {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithGoogle()}>
              <span className={css.buttonIcon}>{GoogleLogo}</span>
              {googleButtonText}
            </SocialLoginButton>
          </div>
        ) : null}
      </div>
    ) : null;

    // Tabs for SignupForm and LoginForm
    const authenticationForms = (
      <div className={css.content}>
        <LinkTabNavHorizontal className={css.tabs} tabs={tabs}/>
        {loginOrSignupError}

        {isLogin ? (
          <LoginForm className={css.loginForm} inProgress={authInProgress} onSubmit={submitLogin}/>
        ) : (
          <SignupForm
            className={css.signupForm}
            inProgress={authInProgress}
            onOpenTermsOfService={() => this.setState({tosModalOpen: true})}
            onSubmit={handleSubmitSignup}
          />
        )}

        {socialLoginButtonsMaybe}
      </div>
    );

    const formContent = isConfirm ? confirmForm : authenticationForms;

    const name = user.attributes.profile.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton onClick={onResendVerificationEmail} rootClassName={css.modalHelperLink}>
        <FormattedMessage id="PractitionerSignup.resendEmailLinkText"/>
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
        <FormattedMessage id="PractitionerSignup.fixEmailLinkText"/>
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError,
    )
      ? "PractitionerSignup.resendFailedTooManyRequests"
      : "PractitionerSignup.resendFailed";
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId}/>
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
          <span className={css.closeText}>
            <FormattedMessage id="PractitionerSignup.verifyEmailClose"/>
          </span>
          <IconClose rootClassName={css.closeIcon}/>
        </NamedLink>
        <IconEmailSent className={css.modalIcon}/>
        <h1 className={css.modalTitle}>
          <FormattedMessage id="PractitionerSignup.verifyEmailTitle" values={{name}}/>
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="PractitionerSignup.verifyEmailText" values={{email}}/>
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="PractitionerSignup.sendingEmail"/>
            ) : (
              <FormattedMessage id="PractitionerSignup.resendEmail" values={{resendEmailLink}}/>
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="PractitionerSignup.fixEmail" values={{fixEmailLink}}/>
          </p>
        </div>
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({id: "PractitionerSignup.schemaTitleLogin"}, {siteTitle})
      : intl.formatMessage({id: "PractitionerSignup.schemaTitleSignup"}, {siteTitle});

    const topbarClasses = classNames({
      [css.hideOnMobile]: showEmailVerification,
    });

    const page =
      <Page
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          name: schemaTitle,
        }}
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer className={topbarClasses}/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              {showEmailVerification ? emailVerificationContent : formContent}
            </div>
            <Modal
              id="PractitionerSignup.tos"
              isOpen={this.state.tosModalOpen}
              onClose={() => this.setState({tosModalOpen: false})}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="PractitionerSignup.termsHeading"/>
                </h2>
                <TermsOfService/>
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    ;

    // Required as react-app-protect breaks with server-side rendering.
    // TODO: Look at more robust solution.
    if (typeof window === 'undefined') {
      return page;
    }

    return (
      <Protect
        blur
        boxTitle={intl.formatMessage({id: "PractitionerSignup.passwordProtect.adminOnlyLabel"})}
        buttonLabel={intl.formatMessage({id: "PractitionerSignup.passwordProtect.buttonLabel"})}
        inputPlaceholder={intl.formatMessage({
          id: "PractitionerSignup.passwordProtect.inputPlaceholder",
        })}
        sha512="f8841277bc5456add7f10a8ec4747f7ed3c81368b2fbff7c181bcd93cb67a4ac8d46021af661c5da86096fc805bd208c58d4ec2c0ebf289b649af5f8263a0cb7"
        wrapperClass={css.signupPagePasswordWrapper}
      >
        {page}
      </Protect>
    );
  }
}

PractitionerSignupComponent.defaultProps = {
  currentUser: null,
  isPractitioner: false,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: "signup",
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
};

const {bool, func, object, oneOf, shape} = PropTypes;

PractitionerSignupComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  isPractitioner: bool,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(["login", "signup", "confirm"]),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({state: object}).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {isAuthenticated, loginError, signupError, confirmError} = state.Auth;
  const {currentUser, sendVerificationEmailInProgress, sendVerificationEmailError} = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({email, password}) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PractitionerSignup = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PractitionerSignupComponent);

export default PractitionerSignup;
