/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, no-use-before-define,
   no-multi-assign, guard-for-in, camelcase
*/

import { featFlags } from '../red/featureFlags'
import * as Corelib from '../red/esm/stdlib/corelib-esm'
//import { onDomReady } from '../red/esm/dom/dom-plus-ultra-esm'
import { getSdk } from '../util/sdkHelper'
import { denormalisedResponseEntities } from '../util/data'
import { monthIdStringInTimeZone } from '../util/dates'
import {createMasterListing, isMasterListing} from '../util/listings'
import {getImminentTimeWindow} from './dateAdapter'

const {isStr} = Corelib
const {wassert} = Corelib.Debug

export const listPractitionerListings = async user => { // called from ProfilePage
  if (!user) {
    return []
  }
  const response = await getSdk().listings.query({
    authorId: user.id.uuid,
    pub_isVerified: null,
    perPage: 20,
    page: 1,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.bio', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.scaled-small', 'variants.scaled-medium', 'variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1
  })
  const listings = response.data.data.map((listing, ix) => {
    const images = listing.relationships.images.data.map(image =>
      response.data.included.filter(im => im.id.uuid === image.id.uuid))

    return {
      ...listing,
      images: images[0]
    }
  }).filter(listing => !isMasterListing(listing))

  //console.log(listings)

  return listings
}

// WARN This is a duplicated, redux-decontaminated version of timeslot-fetching from listingPage.duck

const tlog = console.log
const sho = date => date.toString().split(' GMT')[0]

export const fetchTimeSlots = (listingId, start, end, timeZone) => {
  const monthId = monthIdStringInTimeZone(start, timeZone)
  const extraParams = { // The maximum pagination page size for timeSlots is 500
    per_page: 500,
    page: 1
  }
  tlog(`☀️2️⃣🟢🟢NU fetchTimeSlots %c${sho(start)}-${sho(end)}`, 'background:#66b', listingId?.uuid, {monthId})

  return getSdk().timeslots.query({listingId, start, end, ...extraParams})
    .then(response => {
      const responseEntities = denormalisedResponseEntities(response)
      //tlog(`☀️2️⃣🟢🟢NU fetchTimeSlots.then`, {monthId, response, responseEntities})
      return {monthId, responseEntities}
    })
}

const fetchMonthlyAggregatedTimeSlots = listing => {
  const {attributes: {availabilityPlan = {}, title = ''} = {}} = listing || {}
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const tz = availabilityPlan.timezone
  const hasTimeZone = !!tz

  // Fetch time-zones on client side only.
  if (listing.id && hasTimeZone) {
    tlog(`☀️2️⃣🟢NU fetchMonthlyAggregatedTimeSlots called: %c ${title}`, 'background: #06e', listing.id?.uuid)
    const {today: start, futureDay: end} = getImminentTimeWindow()
    const promises = [fetchTimeSlots(listing.id, start, end, availabilityPlan.timezone)]
    return Promise.all(promises)
  } else {
    return []
  }
}

export const getListingTimeSlots = async listing => { // group session only!!!
  tlog('☀️2️⃣getListingTimeSlots called:', listing?.attributes?.title, {listing})
  if (!listing) {
    return {}
  }
  const respArr = await fetchMonthlyAggregatedTimeSlots(listing)
  const ret = {}
  for (const {monthId, responseEntities} of respArr) {
    ret[monthId] = {timeSlots: responseEntities}
  }
  tlog(`☀️2️⃣getListingTimeSlots.then %c ${listing.attributes?.title}`, 'background: #06e', respArr, {listing, ret})
  return ret
}

// INT helpers

const extendListingWithExceptions = async listing => {
  const sdk = getSdk()
  const {today: start, futureDay: end} = getImminentTimeWindow()
  const res = await sdk.availabilityExceptions.query({listingId: listing.id, start, end}, {expand: true})
  return {listing, availabilityExceptions: res.data?.data || []}
}

const readCurrentPractitionerMasterListing = async (strict = false) => { // exists or not
  const sdk = getSdk()
  const response = await sdk.ownListings.query({pub_isVerified: null})
  const listings = response.data.data?.filter(isMasterListing)
  
  if (listings.length > 1) {
    console.error(`readCurrentPractitionerMasterListing: Multiple masters`, {strict}, listings)
    return {}
  }
  if (listings.length === 1) {
    const extendedMasterListing = await extendListingWithExceptions(listings[0])
    console.log(`readCurrentPractitionerMasterListing: ONE MASTER!`, {strict, extendedMasterListing})
    return extendedMasterListing
  }
  console.log(`readCurrentPractitionerMasterListing: No master`, {strict}, response.data.data)    
  return null
}

// INT this is it

export const getCurrentPractitionerMasterListing = async () => 
  await readCurrentPractitionerMasterListing(false) || 
    createMasterListing().then(async freshMasterListingTx => {
      console.log('getCurrentPractitionerMasterListing', {freshMasterListingTx})
      const newExtendedMasterListing = await readCurrentPractitionerMasterListing(true)
      console.log('getCurrentPractitionerMasterListing', {newExtendedMasterListing})
      return newExtendedMasterListing
    })

// export const getCurrentPractitionerMasterListing = async () => {
//   const extendedMasterListing = await readCurrentPractitionerMasterListing(false)
//   if (extendedMasterListing) {
//     return extendedMasterListing  
//   }
//   const freshMasterListingTx = await createMasterListing()
//   console.log('getCurrentPractitionerMasterListing', {freshMasterListingTx})
//   const newExtendedMasterListing = await readCurrentPractitionerMasterListing(true)
//   console.log('getCurrentPractitionerMasterListing', {newExtendedMasterListing})
//   return newExtendedMasterListing
// }

// HEAD get author's MasterListing and MasterCalendar (for ListingPage query!) 

export const getAuthorMasterListing = async authUuid => {
  console.log(`☀️1️⃣getAuthorMasterListing called(${authUuid})`)
  wassert(isStr(authUuid))
  if (!authUuid) {
    return null
  }
  const response = await getSdk().listings.query({authorId: authUuid, pub_isVerified: null})
  const listings = response.data.data?.filter(listing => listing.attributes.publicData?.flags?.isMaster)
  const masterListing = listings[0]

  console.log(`☀️1️⃣getAuthorMasterListing awaited`, {masterListing, listings})

  listings.length > 1 &&
    console.error(`☀️1️⃣getAuthorMasterListing: Multiple master listings`, listings)

  return masterListing
}

export const realGetAuthorMasterCalendar = async authUuid => {
  console.log(`❗️❗️realGetAuthorMasterCalendar`, authUuid)
  wassert(authUuid)
  if (!authUuid) {
    return {}
  }
  const masterListing = await getAuthorMasterListing(authUuid)
  console.log(`🌞getAuthorMasterListingsTimeSlot awaited`, {masterListing})
  const masterCalendar = await getListingTimeSlots(masterListing)
  console.log(`🌞getAuthorMasterListingsTimeSlot awaited`, {masterCalendar})
  return masterCalendar
}

const masterCalendarOf = {}

export const getAuthorMasterCalendar = authUuid => {
  if (masterCalendarOf[authUuid]) {
    console.log(`❓✔️getAuthorMasterCalendar`, authUuid, masterCalendarOf[authUuid], {masterCalendarOf})
    return masterCalendarOf[authUuid]
  } else {
    console.log(`❓❌getAuthorMasterCalendar`, authUuid, masterCalendarOf[authUuid], {masterCalendarOf})
    return masterCalendarOf[authUuid] = realGetAuthorMasterCalendar(authUuid)
  }
  //return masterCalendarOf[authUuid] || (masterCalendarOf[authUuid] = realGetAuthorMasterCalendar(authUuid))
}

// HEAD end of MasterListing/Calendar

export const getPractitionerGroupListingsTimeSlotsArray = async user => { // from BookingAdapter
  if (!user) {
    return []
  }
  const sdk = getSdk()

  const response = await sdk.listings.query({authorId: user.id.uuid, pub_isVerified: null})
  //console.log({response})
  const groupListings = response.data.data?.filter(({attributes}) =>
    attributes?.publicData?.isGroupSession && !attributes.publicData?.flags?.isMaster)

  const allTimeSlotsPromise = Promise.all(groupListings.map(listing => getListingTimeSlots(listing)))

  allTimeSlotsPromise.catch(err => {
    console.warn(`getPractitionerGroupListingsTimeSlots`, err)
    return []
  })

  const arrArr = []
  const respArr = await allTimeSlotsPromise
  const dataArr = respArr.map((data, ix) => ({data, listing: groupListings[ix]}))

  for (const {data, listing} of dataArr) {
    const {title, publicData} = listing.attributes || {}
    const {duration, isGroupSession, serviceType = '', sessionType = ''} = publicData
    for (const key in data) {
      const {timeSlots = []} = data[key] || {}
      for (const {attributes: {start, end, seats}} of timeSlots) {
        const rec = {start, end, seats, title, isGroupSession, serviceType, sessionType, duration}
        arrArr.push(rec)
      }
    }
  }
  const ret = arrArr
  console.log({respArr, ret})
  return ret
}

export const siftListings = rawListings => rawListings
  // .filter(({attributes = {}}) => featFlags.isDev || !attributes?.publicData?.flags?.isDev)
  .filter(({attributes = {}}) => !attributes?.publicData?.flags?.isMaster)
