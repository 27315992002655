/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
/* eslint-disable quotes, semi, comma-dangle, spaced-comment */
import React from "react";
import { oneOf, string } from "prop-types";
import classNames from "classnames";
import { FormattedMessage, intlShape, injectIntl } from "../../util/reactIntl";
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from "../../util/types";
import LineItemBookingPeriod from "./LineItemBookingPeriod";
import LineItemBasePriceMaybe from "./LineItemBasePriceMaybe";
import LineItemUnitPriceMaybe from "./LineItemUnitPriceMaybe";
import LineItemSubTotalMaybe from "./LineItemSubTotalMaybe";
import LineItemCustomerCommissionMaybe from "./LineItemCustomerCommissionMaybe";
import LineItemCustomerCommissionRefundMaybe from "./LineItemCustomerCommissionRefundMaybe";
import LineItemProviderCommissionMaybe from "./LineItemProviderCommissionMaybe";
import LineItemProviderCommissionRefundMaybe from "./LineItemProviderCommissionRefundMaybe";
import LineItemRefundMaybe from "./LineItemRefundMaybe";
import LineItemTotalPrice, {LineItemDirectPrice} from "./LineItemTotalPrice";
import LineItemUnknownItemsMaybe from "./LineItemUnknownItemsMaybe";
import css from "./BookingBreakdown.module.css";

export const BookingBreakdownComponent = props => {
  const {
    isGroupSession,
    duration,
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    totalOverride,
    timeZone,
    timeZone3
  } = props;

  console.log('BookingBreakdown', timeZone, props)

  const isCustomer = userRole === "customer";
  const isProvider = userRole === "provider";

  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const doLog = true

  if (doLog) {
    const {attributes: {payinTotal, payoutTotal}} = transaction
    const {attributes: {start, end}} = booking
    console.log(`🧅🧅`, {unitType, transaction})
    console.log(`🧅🧅🧅 payinTotal`, payinTotal)
    console.log(`🧅🧅🧅 payoutTotal`, payoutTotal)
  }

  //const totalPrice = transaction.payinTotal

  const classes = classNames(rootClassName || css.root, className);

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={booking}
        dateType={dateType}
        duration={duration}
        isGroupSession={isGroupSession}
        timeZone={timeZone}
        timeZone3={timeZone3}
        unitType={unitType}
      />
      {/* The below have been commented out as a more simplistic booking fee structure was requested. */}
      {/*<LineItemUnitPriceMaybe intl={intl} transaction={transaction} unitType={unitType} />*/}

      {/*<LineItemBasePriceMaybe intl={intl} transaction={transaction} unitType={unitType} />*/}
      {/*<LineItemUnknownItemsMaybe intl={intl} isProvider={isProvider} transaction={transaction} />*/}

      {/*<LineItemSubTotalMaybe*/}
      {/*  intl={intl}*/}
      {/*  transaction={transaction}*/}
      {/*  unitType={unitType}*/}
      {/*  userRole={userRole}*/}
      {/*/>*/}
      {/*<LineItemRefundMaybe intl={intl} transaction={transaction} />*/}

      {/*<LineItemCustomerCommissionMaybe*/}
      {/*  intl={intl}*/}
      {/*  isCustomer={isCustomer}*/}
      {/*  transaction={transaction}*/}
      {/*/>*/}
      {/*<LineItemCustomerCommissionRefundMaybe*/}
      {/*  intl={intl}*/}
      {/*  isCustomer={isCustomer}*/}
      {/*  transaction={transaction}*/}
      {/*/>*/}

      {/*<LineItemProviderCommissionMaybe*/}
      {/*  intl={intl}*/}
      {/*  isProvider={isProvider}*/}
      {/*  transaction={transaction}*/}
      {/*/>*/}
      {/*<LineItemProviderCommissionRefundMaybe*/}
      {/*  intl={intl}*/}
      {/*  isProvider={isProvider}*/}
      {/*  transaction={transaction}*/}
      {/*/>*/}
      {totalOverride
        ? <LineItemDirectPrice {...{intl, isProvider, totalOverride}} />
        : <LineItemTotalPrice intl={intl} isProvider={isProvider} transaction={transaction} />
      }

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
  timeZone3: null
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(["customer", "provider"]).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,
  timeZone3: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = "BookingBreakdown";

export default BookingBreakdown;
