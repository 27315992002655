/* eslint-disable multiline-ternary, spaced-comment, object-curly-spacing, comma-dangle, no-unneeded-ternary,
   guard-for-in, no-restricted-syntax, prefer-template, quotes, no-return-assign,
   import/no-unresolved, import/extensions, react/prop-types */

import React, {useEffect, useState} from 'react'
import {bool, func, number, object, string} from 'prop-types'
import classNames from 'classnames'
import {FormattedMessage, intlShape} from '../../util/reactIntl'
import {ACCOUNT_SETTINGS_PAGES} from '../../routeConfiguration'
import {propTypes} from '../../util/types'
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  ServicesLink,
} from '..'
import {TopbarSearchForm} from '../../forms'
import {isPractitioner} from '../../util/user'
import css from './TopbarDesktop.module.css'

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    //currentUserListing, // -> cemetery (one item) WTF is this?
    //currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onChange,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props
  const [mounted, setMounted] = useState(false)

  // console.log({currentUserListing, currentUserListingFetched})

  useEffect(() => {
    setMounted(true)
  }, [])

  const authenticatedOnClientSide = mounted && isAuthenticated
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted

  const classes = classNames(rootClassName || css.root, className)

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      initialValues={initialSearchFormValues}
      onChange={onChange}
      onSubmit={onSearchSubmit}
    />
  )

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot}/> : null

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name='InboxPage'
      params={{tab: currentUserHasListings ? 'sales' : 'orders'}}
    >
      <span className={css.inbox}>
        <b>
        <FormattedMessage id='TopbarDesktop.inbox'/>
        {notificationDot}</b>
      </span>
    </NamedLink>
  ) : null

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage)
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null
  }

  const profileMenuItem = (
    <MenuItem key='ProfileSettingsPage'>
      <NamedLink
        className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
        name='ProfileSettingsPage'
      >
        <span className={css.menuItemBorder}/>
        <FormattedMessage id='TopbarDesktop.profileSettingsLink'/>
      </NamedLink>
    </MenuItem>
  )
  const servicesMenuItem = isPractitioner(currentUser) &&
    <MenuItem key='EditListingPage'>
      <ServicesLink className={css.yourListingsLink}>
        <div>
          <span className={css.menuItemBorder}/>
          <FormattedMessage id='TopbarDesktop.servicesLink'/>
        </div>
      </ServicesLink>
    </MenuItem>

  const servicesItem = null;
  // TODO: Remove if not needed
  // isPractitioner(currentUser) && (
  //   <NamedLink
  //     className={css.inboxLink}
  //     name='ManageListingsPage'
  //   >
  //       <span className={css.inbox}>
  //         My Services
  //       </span>
  //   </NamedLink>
  // );

  const accountSettingsMenuItem = (
    <MenuItem key='AccountSettingsPage'>
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
        name='AccountSettingsPage'
      >
        <span className={css.menuItemBorder}/>
        <FormattedMessage id='TopbarDesktop.accountSettingsLink'/>
      </NamedLink>
    </MenuItem>
  )
  const logoutMenuItem = (
    <MenuItem key='logout'>
      <InlineTextButton onClick={onLogout} rootClassName={css.logoutButton}>
        <span className={css.menuItemBorder}/>
        <FormattedMessage id='TopbarDesktop.logout'/>
      </InlineTextButton>
    </MenuItem>
  )
  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} disableProfileLink user={currentUser}/>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {[profileMenuItem, servicesMenuItem, accountSettingsMenuItem, logoutMenuItem].filter(Boolean)}
      </MenuContent>
    </Menu>
  ) : null

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink className={css.signupLink} name='SignupPage'>
      <span className={css.signup}>
        <FormattedMessage id='TopbarDesktop.signup'/>
      </span>
    </NamedLink>
  )

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink className={css.loginLink} name='LoginPage'>
      <span className={css.login}>
        <FormattedMessage id='TopbarDesktop.login'/>
      </span>
    </NamedLink>
  )

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name='LandingPage'>
        <Logo
          alt={intl.formatMessage({id: 'TopbarDesktop.logo'})}
          className={css.logo}
          format='desktop'
        />
        <span style={{ color: "#666", position: "absolute", fontSize: "10px", fontWeight: "300", marginLeft: "-0", marginTop: "12px" }}>Beta</span>
      </NamedLink>
      {search}
      <NamedLink
        className={css.inboxLink}
        name='PractitionerListPage'
      >
        <span className={css.inbox}>
          <FormattedMessage id='LandingPageNav.practitionersLink'/>
        </span>
      </NamedLink>
      <NamedLink
        className={css.inboxLink}
        name='SearchPage'
        to={{
          search: 'pub_serviceType=experience',
        }}
      >
        <span className={css.inbox}>
          <FormattedMessage id='LandingPageNav.experiencesLink'/>
        </span>
      </NamedLink>
      <NamedLink
        className={css.inboxLink}
        name='SearchPage'
        to={{
          search: 'pub_serviceType=oneToOne',
        }}
      >
      <span className={css.inbox}>
        <FormattedMessage id='LandingPageNav.oneToOnesLink'/>
      </span>
      </NamedLink>
      <NamedLink
        className={css.inboxLink}
        name='SearchPage'
        to={{
          search: 'pub_serviceType=wokeshop',
        }}
      >
      <span className={css.inbox}>
        <FormattedMessage id='LandingPageNav.wokeshopsLink'/>
      </span>
      </NamedLink>
      {inboxLink}
      {servicesItem}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  )
}

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
}

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
}

export default TopbarDesktop
