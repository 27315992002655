/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, guard-for-in, prefer-template,
   no-param-reassign
*/
import * as Corelib from '../red/esm/stdlib/corelib-esm'

const {isFun, nop} = Corelib
const {post} = Corelib.Tardis
const {wassert, brexru} = Corelib.Debug

export const hourTicks = 60 * 60 * 1000
export const dayTicks = 24 * hourTicks
export const weekTicks = 7 * dayTicks

export const secureDate = (date, brexru = nop) => date instanceof Date
  ? date
  : typeof date === 'string' || typeof date === 'number'
    ? new Date(date)
    : console.error('secureDate: invalid date, will return [now]', {date}, brexru()) || new Date()

export const pad = x => ('0' + x).slice(-2)
export const padHM = (h, m) => `${pad(h)}:${pad(m)}`
export const padDHM = (d, h, m) => `${pad(d)}. ${pad(h)}:${pad(m)}`
export const padMD = (m, d) => `${pad(m)}-${pad(d)}`
export const padYMD = (y, m, d) => `${y}-${pad(m)}-${pad(d)}`

export const getDateYMDHM = date => {
  const d = secureDate(date, brexru)
  wassert(isFun(d.getDate))
  return [d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes()]
}

export const getDateHM = date => getDateYMDHM(date).slice(3)
export const getDateDHM = date => getDateYMDHM(date).slice(2)
export const getDateYMD = date => getDateYMDHM(date).slice(0, 3)

export const getStringHM = date => padHM(...getDateHM(date))
export const getStringYMD = date => padYMD(...getDateYMD(date))

export const shDate = date => date.toString().split(' GMT')[0]

export const weekStartDateFor = today => {
  const dayOfWeek = today.getDay() || 7 // 1 mon 7 sun
  const firstDay = new Date(today.getTime() + (1 - dayOfWeek) * dayTicks)
  return firstDay
  // mon 1 mon=today-0
  // tue 2 mon=today-1
  // wed 3 mon=today-2
  // thu 4 mon=today-3
  // fri 5 mon=today-4
  // sat 6 mon=today-5
  // sun 7 mon=today-6
}

const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', '???']
const longWeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', '???']

export const weekdayOf = date => weekDays[wassert(date)?.getDay() % 7]
export const longWeekdayOf = date => longWeekDays[wassert(date)?.getDay() % 7]

// FIX temporary TEMP?????

const datelistener = {}

export const addSelectedDateListener = cb => datelistener.cb = cb

export const selectedDateListener = startDate => {
  post(() => {
    const [, month, day] = getDateYMDHM(startDate)
    console.log('LOLO', month, day)
    void datelistener.cb?.({month, day})
  })
}

const statik = {
  browserTZ: '',
  browserTZ3: ''
}

export const getBrowserTimeZone = () => {
  statik.browserTZ || (statik.browserTZ = globalThis.Intl.DateTimeFormat().resolvedOptions().timeZone)
  statik.browserTZ || console.warn(`Cannot get browser TZ!`)

  if (!statik.browserTZ3) {
    const tz3 = Intl.DateTimeFormat('en', {timeZoneName: 'short'})
      .formatToParts()
      .find(p => p.type === 'timeZoneName').value

    const darr = new Date()
      .toLocaleTimeString(undefined, {timeZoneName: 'short', hour12: false})
      .split(' ')

    statik.browserTZ3 = tz3 || darr[2] || darr[1] 
    //console.warn({darr, tz3, TZ3: statik.browserTZ3})
  }

  return {
    timeZone: statik.browserTZ || 'Europe/London',
    timeZone3: statik.browserTZ3 || '' // GMT
  }
}

const hh = [
  '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM', 
  '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
  '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', 
  '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM', '12:00 AM'
]

const printHourStrings = h => hh[h]
const timeToHour = time => hh.indexOf(time)

export const timeTo12 = time24 => {
  const [hours, mins] = time24.split(':')
  const h = parseInt(hours)
  if (mins !== '00' || h > '24') {
    console.warn(`timeTo12: bad format`, time24)
    return '??'
  }
  return hh[h]
}

export const getImminentTimeWindow = () => {
  const range = 89
  const dayTicks = 24 * 60 * 60 * 1000
  const today = new Date()
  const futureDay = new Date(today.getTime() + range * dayTicks)
  return {today, futureDay}
}
