export default [
  { currencyCode: "EUR", currencyName: "Euro" },
  { currencyCode: "GBP", currencyName: "Pound Sterling" },
  { currencyCode: "USD", currencyName: "United States Dollar" },
  { currencyCode: "AED", currencyName: "Arab Emirates Dirham" },
  { currencyCode: "AFN", currencyName: "Afghanistan Afghani" },
  { currencyCode: "ALL", currencyName: "Albanian Lek" },
  { currencyCode: "AMD", currencyName: "Armenian Dram" },
  { currencyCode: "ANG", currencyName: "Netherlands Antillean Guilder" },
  { currencyCode: "AOA", currencyName: "Angolan Kwanza" },
  { currencyCode: "ARS", currencyName: "Argentine Peso" },
  { currencyCode: "AUD", currencyName: " Australian Dollar" },
  { currencyCode: "AWG", currencyName: "Aruban Guilder" },
  { currencyCode: "AZN", currencyName: "Azerbaijan New Manat" },
  { currencyCode: "BBD", currencyName: "Barbados Dollar" },
  { currencyCode: "BDT", currencyName: "Bangladeshi Taka" },
  { currencyCode: "BGN", currencyName: "Bulgarian Lev" },
  { currencyCode: "BHD", currencyName: "Bahraini Dinar" },
  { currencyCode: "BIF", currencyName: "Burundi Franc" },
  { currencyCode: "BMD", currencyName: "Bermudian Dollar" },
  { currencyCode: "BOB", currencyName: "Boliviano" },
  { currencyCode: "BRL", currencyName: "Brazilian Real" },
  { currencyCode: "BSD", currencyName: "Bahamian Dollar" },
  { currencyCode: "BTN", currencyName: "Bhutan Ngultrum" },
  { currencyCode: "BWP", currencyName: "Botswana Pula" },
  { currencyCode: "BYR", currencyName: "Belarussian Ruble" },
  { currencyCode: "BZD", currencyName: "Belize Dollar" },
  { currencyCode: "CAD", currencyName: "Canadian Dollar" },
  { currencyCode: "CDF", currencyName: "Franc Congolais" },
  { currencyCode: "CFA", currencyName: "CFA Franc" },
  { currencyCode: "CHF", currencyName: "Swiss Franc" },
  { currencyCode: "CLP", currencyName: "Chilean Peso" },
  { currencyCode: "CNY", currencyName: "Yuan Renminbi" },
  { currencyCode: "COP", currencyName: "Colombian Peso" },
  { currencyCode: "CRC", currencyName: "Costa Rican Colon" },
  { currencyCode: "CUP", currencyName: "Cuban Peso" },
  { currencyCode: "CVE", currencyName: "Cape Verde Escudo" },
  { currencyCode: "DJF", currencyName: "Djibouti Franc" },
  { currencyCode: "DKK", currencyName: " Danish Krone" },
  { currencyCode: "DOP", currencyName: "Dominican Peso" },
  { currencyCode: "DZD", currencyName: "Algerian Dinar" },
  { currencyCode: "ECS", currencyName: "Ecuador Sucre" },
  { currencyCode: "EGP", currencyName: "Egyptian Pound" },
  { currencyCode: "ERN", currencyName: "Eritrean Nakfa" },
  { currencyCode: "ETB", currencyName: "Ethiopian Birr" },
  { currencyCode: "FKP", currencyName: "Falkland Islands Pound" },
  { currencyCode: "GEL", currencyName: "Georgian Lari" },
  { currencyCode: "GHS", currencyName: "Ghanaian Cedi" },
  { currencyCode: "GIP", currencyName: "Gibraltar Pound" },
  { currencyCode: "GMD", currencyName: "Gambian Dalasi" },
  { currencyCode: "GNF", currencyName: "Guinea Franc" },
  { currencyCode: "GYD", currencyName: "Guyana Dollar" },
  { currencyCode: "HKD", currencyName: "Hong Kong Dollar" },
  { currencyCode: "HNL", currencyName: "Honduran Lempira" },
  { currencyCode: "HRK", currencyName: "Croatian Kuna" },
  { currencyCode: "HTG", currencyName: "Haitian Gourde" },
  { currencyCode: "HUF", currencyName: "Hungarian Forint" },
  { currencyCode: "IDR", currencyName: "Indonesian Rupiah" },
  { currencyCode: "ILS", currencyName: "Israeli New Shekel" },
  { currencyCode: "INR", currencyName: "Indian Rupee" },
  { currencyCode: "IQD", currencyName: "Iraqi Dinar" },
  { currencyCode: "IRR", currencyName: "Iranian Rial" },
  { currencyCode: "ISK", currencyName: "Iceland Krona" },
  { currencyCode: "JMD", currencyName: "Jamaican Dollar" },
  { currencyCode: "JOD", currencyName: "Jordanian Dinar" },
  { currencyCode: "JPY", currencyName: "Japanese Yen" },
  { currencyCode: "KES", currencyName: "Kenyan Shilling" },
  { currencyCode: "KGS", currencyName: "Som" },
  { currencyCode: "KHR", currencyName: "Kampuchean Riel" },
  { currencyCode: "KMF", currencyName: "Comoros Franc" },
  { currencyCode: "KPW", currencyName: "North Korean Won" },
  { currencyCode: "KRW", currencyName: "Korean Won" },
  { currencyCode: "KWD", currencyName: "Kuwaiti Dinar" },
  { currencyCode: "KYD", currencyName: "Cayman Islands Dollar" },
  { currencyCode: "LAK", currencyName: "Lao Kip" },
  { currencyCode: "LBP", currencyName: "Lebanese Pound" },
  { currencyCode: "LRD", currencyName: "Liberian Dollar" },
  { currencyCode: "LSL", currencyName: "Lesotho Loti" },
  { currencyCode: "LTL", currencyName: "Lithuanian Litas" },
  { currencyCode: "LVL", currencyName: "Latvian Lats" },
  { currencyCode: "MAD", currencyName: "Moroccan Dirham" },
  { currencyCode: "MDL", currencyName: "Moldovan Leu" },
  { currencyCode: "MGF", currencyName: "Malagasy Franc" },
  { currencyCode: "MKD", currencyName: "Denar" },
  { currencyCode: "MMR", currencyName: "Myanmar Kyat" },
  { currencyCode: "MNT", currencyName: "Mongolian Tugrik" },
  { currencyCode: "MRO", currencyName: "Mauritanian Ouguiya" },
  { currencyCode: "MUR", currencyName: "Mauritius Rupee" },
  { currencyCode: "MVR", currencyName: "Maldive Rufiyaa" },
  { currencyCode: "MWK", currencyName: "Malawi Kwacha" },
  { currencyCode: "MXN", currencyName: "Mexican Nuevo Peso" },
  { currencyCode: "MYR", currencyName: "Malaysian Ringgit" },
  { currencyCode: "MZN", currencyName: "Mozambique Metical" },
  { currencyCode: "NAD", currencyName: "Namibian Dollar" },
  { currencyCode: "NGN", currencyName: "Nigerian Naira" },
  { currencyCode: "NIO", currencyName: "Nicaraguan Cordoba Oro" },
  { currencyCode: "NOK", currencyName: "Norwegian Krone" },
  { currencyCode: "NPR", currencyName: "Nepalese Rupee" },
  { currencyCode: "NZD", currencyName: "New Zealand Dollar" },
  { currencyCode: "OMR", currencyName: "Omani Rial" },
  { currencyCode: "PAB", currencyName: "Panamanian Balboa" },
  { currencyCode: "PEN", currencyName: "Peruvian Nuevo Sol" },
  { currencyCode: "PGK", currencyName: "Papua New Guinea Kina" },
  { currencyCode: "PHP", currencyName: "Philippine Peso" },
  { currencyCode: "PKR", currencyName: "Pakistan Rupee" },
  { currencyCode: "PLN", currencyName: "Polish Zloty" },
  { currencyCode: "PYG", currencyName: "Paraguay Guarani" },
  { currencyCode: "QAR", currencyName: "Qatari Rial" },
  { currencyCode: "QTQ", currencyName: "Guatemalan Quetzal" },
  { currencyCode: "RON", currencyName: "Romanian New Leu" },
  { currencyCode: "RWF", currencyName: "Rwanda Franc" },
  { currencyCode: "SAR", currencyName: "Saudi Riyal" },
  { currencyCode: "SBD", currencyName: "Solomon Islands Dollar" },
  { currencyCode: "SCR", currencyName: "Seychelles Rupee" },
  { currencyCode: "SDG", currencyName: "Sudanese Pound" },
  { currencyCode: "SEK", currencyName: "Swedish Krona" },
  { currencyCode: "SGD", currencyName: "Singapore Dollar" },
  { currencyCode: "SHP", currencyName: "St. Helena Pound" },
  { currencyCode: "SLL", currencyName: "Sierra Leone Leone" },
  { currencyCode: "SOS", currencyName: "Somali Shilling" },
  { currencyCode: "SRD", currencyName: "Surinam Dollar" },
  { currencyCode: "SSP", currencyName: "South Sudan Pound" },
  { currencyCode: "STD", currencyName: " Dobra" },
  { currencyCode: "SVC", currencyName: "El Salvador Colon" },
  { currencyCode: "SYP", currencyName: "Syrian Pound" },
  { currencyCode: "SZL", currencyName: "Swaziland Lilangeni" },
  { currencyCode: "THB", currencyName: "Thai Baht" },
  { currencyCode: "TJS", currencyName: "Tajik Somoni" },
  { currencyCode: "TMT", currencyName: "Manat" },
  { currencyCode: "TND", currencyName: "Tunisian Dollar" },
  { currencyCode: "TOP", currencyName: "Tongan Pa'anga" },
  { currencyCode: "TRY", currencyName: "Turkish Lira" },
  { currencyCode: "TTD", currencyName: "Trinidad and Tobago Dollar" },
  { currencyCode: "TZS", currencyName: "Tanzanian Shilling" },
  { currencyCode: "UAH", currencyName: "Ukraine Hryvnia" },
  { currencyCode: "UGX", currencyName: "Uganda Shilling" },
  { currencyCode: "UYU", currencyName: "Uruguayan Peso" },
  { currencyCode: "UZS", currencyName: "Uzbekistan Sum" },
  { currencyCode: "VEF", currencyName: "Venezuelan Bolivar" },
  { currencyCode: "VND", currencyName: "Vietnamese Dong" },
  { currencyCode: "VUV", currencyName: "Vanuatu Vatu" },
  { currencyCode: "WST", currencyName: "Samoan Tala" },
  { currencyCode: "XAF", currencyName: "Central African CFA Franc" },
  { currencyCode: "XCD", currencyName: "East Caribbean Dollar" },
  { currencyCode: "XOF", currencyName: "CFA Franc BCEAO" },
  { currencyCode: "YER", currencyName: "Yemeni Rial" },
  { currencyCode: "ZAR", currencyName: "South African Rand" },
  { currencyCode: "ZMW", currencyName: "Zambian Kwacha" },
  { currencyCode: "ZWD", currencyName: "Zimbabwe Dollar" },
];
