/* eslint-disable multiline-ternary, quotes, comma-dangle, jsx-quotes, semi, prefer-template,
   no-param-reassign, prefer-template, react/button-has-type, jsx-a11y/click-events-have-key-events,
   jsx-a11y/no-static-element-interactions, react/prop-types
*/

import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {array, bool, func, node, object, oneOfType, shape, string, instanceOf} from "prop-types";
import classNames from "classnames";
import omit from "lodash/omit";
import {FormattedMessage, injectIntl, intlShape} from "../../util/reactIntl";
import {GROUP_SESSION, LISTING_STATE_CLOSED, propTypes,} from "../../util/types";
import {addMinutes} from '../../util/dates'
import {formatMoney} from "../../util/currency";
import {parse, stringify} from "../../util/urlHelpers";
import config from "../../config";
import {Button, ModalInMobile} from "..";
import {BookingTimeForm} from "../../forms";
import {getBrowserTimeZone} from '../../models/dateAdapter'
import css from "./BookingPanel.module.css";

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return {formattedPrice, priceTitle: formattedPrice};
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const {pathname, search, state} = location;
    const searchString = `?${stringify({...parse(search), book: true})}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const {pathname, search, state} = location;
  const searchParams = omit(parse(search), "book");
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = {month: "short", day: "numeric", weekday: "short"};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    earliestBookableDate,
    hasSetDate,
  } = props;

  const price = listing.attributes.price;
  // FIX this:
  //const timeZone = listing.attributes.availabilityPlan?.timezone || 'Europe/London';
  const {timeZone, timeZone3} = getBrowserTimeZone()
  //console.log(`Time zone calculated from browser, not from the listing!`, timeZone)

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const {formattedPrice, priceTitle} = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const duration = listing.attributes.publicData.duration;
  
  const fixedBookingTime = listing.attributes?.publicData?.events?.length 
    ? listing.attributes.publicData.events[0] : null;
  const fixedBookingEndTime = fixedBookingTime ? addMinutes(fixedBookingTime, duration) : null;

  const isGroupSession =
    listing.attributes.publicData.isGroupSession ||
    listing.attributes.publicData.sessionType === GROUP_SESSION;

  const subTitleText =
    subTitle ||
    (showClosedListingHelpText
      ? intl.formatMessage({id: "BookingPanel.subTitleClosedListing"})
      : null);

  // const unitTranslationKey = isGroupSession ? "BookingPanel.perTicket" : "BookingPanel.perUnit";
  const unitTranslationKey = isGroupSession ? "BookingPanel.perTicket" : "BookingPanel.perSession";

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const timeSlots = Object.keys({...monthlyTimeSlots}).reduce((p, c) => {
    if (monthlyTimeSlots[c].timeSlots && monthlyTimeSlots[c].timeSlots.length > 0)
      p[c] = monthlyTimeSlots[c];
    return p;
  }, {});

  return (
    <div className={'_BookingPanel ' + classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        onManageDisableScrolling={onManageDisableScrolling}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        <div className={css.bookingHeading}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.desktopPerUnit}>
              <FormattedMessage id={unitTranslationKey}/>
            </div>
            <div className={css.desktopDuration}>
              {`(${duration} mins)`}
            </div>
          </div>
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>

        {/* {console.log({fixedBookingTime, fixedBookingEndTime})} */}
        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            duration={duration}
            earliestBookableDate={earliestBookableDate}
            endDatePlaceholder={intl.formatDate(earliestBookableDate, dateFormattingOptions)}
            fetchLineItemsError={fetchLineItemsError}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fixedBookingEndTime={fixedBookingEndTime}
            fixedBookingTime={fixedBookingTime}
            formId="BookingPanel"
            hasSetDate={hasSetDate}
            isGroupSession={isGroupSession}
            isOwnListing={isOwnListing}
            lineItems={lineItems}
            listingId={listing.id}
            monthlyTimeSlots={timeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onSubmit={onSubmit}
            price={price}
            startDatePlaceholder={intl.formatDate(earliestBookableDate, dateFormattingOptions)}
            submitButtonWrapperClassName={css.submitButtonWrapper}
            timeZone={timeZone}
            timeZone3={timeZone3}
            unitType={unitType}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey}/>
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
            rootClassName={css.bookButton}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage"/>
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText"/>
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  hasSetDate: false,
  fixedBookingTime: null,
  fixedBookingEndTime: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  hasSetDate: bool,
  fixedBookingTime: oneOfType([instanceOf(Date), string]),
  fixedBookingEndTime: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
