import React from "react";
import { bool, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { ensureCurrentUser } from "../../util/data";
import { fetchCurrentUser } from "../../ducks/user.duck";
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from "../../components";
import { PayoutAndInsuranceForm } from "../../forms";
import { TopbarContainer } from "..";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { isProfileEditingEnabled } from "../../util/user";
import { savePayoutAndInsurance, savePayoutAndInsuranceClear } from "./PayoutAndInsurancePage.duck";
import css from "./PayoutAndInsurancePage.module.css";

export const PayoutAndInsurancePageComponent = props => {
  const {
    savePayoutAndInsuranceInProgress,
    currentUser,
    currentUserListing,
    payoutAndInsuranceChanged,
    onChange,
    scrollingDisabled,
    onSubmitPayoutAndInsurance,
    profileEditingDisabled,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const privateData = user.attributes.profile.privateData || {};

  const contactInfoForm = user.id ? (
    <PayoutAndInsuranceForm
      className={css.form}
      currentUser={currentUser}
      initialValues={{
        ...privateData,
      }}
      inProgress={savePayoutAndInsuranceInProgress}
      onChange={onChange}
      onSubmit={onSubmitPayoutAndInsurance}
      profileEditingDisabled={profileEditingDisabled}
      ready={payoutAndInsuranceChanged}
    />
  ) : null;

  const title = intl.formatMessage({ id: "PayoutAndInsurancePage.title" });

  return (
    <Page scrollingDisabled={scrollingDisabled} title={title}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PayoutAndInsurancePage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav listing={currentUserListing} selectedPageName="PayoutAndInsurancePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav
          currentTab="PayoutAndInsurancePage"
          currentUser={currentUser}
        />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PayoutAndInsurancePage.heading" />
            </h1>
            {contactInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PayoutAndInsurancePageComponent.defaultProps = {
  currentUser: null,
};

PayoutAndInsurancePageComponent.propTypes = {
  savePayoutAndInsuranceInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  payoutAndInsuranceChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitPayoutAndInsurance: func.isRequired,
  scrollingDisabled: bool.isRequired,
  profileEditingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, currentUserListing } = state.user;
  const {
    savePayoutAndInsuranceInProgress,
    savePayoutAndInsuranceError,
    payoutAndInsuranceChanged,
  } = state.PayoutAndInsurancePage;
  return {
    savePayoutAndInsuranceError,
    savePayoutAndInsuranceInProgress,
    currentUser,
    currentUserListing,
    payoutAndInsuranceChanged,
    scrollingDisabled: isScrollingDisabled(state),
    profileEditingDisabled: !isProfileEditingEnabled(currentUser),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(savePayoutAndInsuranceClear()),
  onSubmitPayoutAndInsurance: values => dispatch(savePayoutAndInsurance(values)),
});

const PayoutAndInsurancePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PayoutAndInsurancePageComponent);

PayoutAndInsurancePage.loadData = () =>
  // Since verify email happens in separate tab, current user's data might be updated
  fetchCurrentUser();

export default PayoutAndInsurancePage;
