/* eslint-disable spaced-comment, no-void, comma-dangle, object-curly-spacing, multiline-ternary, quotes,
   no-debugger, no-return-assign, no-unused-expressions, no-param-reassign, no-restricted-syntax,
   prefer-template, guard-for-in, react/prop-types
*/
import React from 'react'
// import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl'
import config from '../../config'
import routeConfiguration from '../../routeConfiguration'
import { findOptionsForSelectFilter } from '../../util/search'
import { 
  LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes,
  GROUP_SESSION, WOKESHOP, EXPERIENCE, DATE_TYPE_DATETIME
} from '../../util/types'
import { types as sdkTypes } from '../../util/sdkLoader'
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers'
import { formatMoney } from '../../util/currency'
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes'
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data'
import { timestampToDate, addMinutes } from '../../util/dates'
import { richText } from '../../util/richText'
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck'
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck'
import { initializeCardPaymentData } from '../../ducks/stripe.duck'
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  TimeRange
} from '../../components'
import { EnquiryForm } from '../../forms'
import { TopbarContainer, NotFoundPage } from '..'
import { getAuthorMasterCalendar } from '../../models/listingAdapter'
import { logListingTimeSlots } from '../../models/listingLog'
import { addSelectedDateListener, getBrowserTimeZone } from '../../models/dateAdapter'
import { getPractitionersAggregatedBookings } from '../../models/bookingAdapter'
import { consolidateTimeSlots } from '../../models/timeSlotsAdapter'
import { Listing as flattenListing } from '../../models/listings'
//import SectionFeaturesMaybe from './SectionFeaturesMaybe'
//import SectionReviews from './SectionReviews'
//import SectionMapMaybe from './SectionMapMaybe'
//import {getSpecialtiesForTopics, getTopicsForSpecialties} from '../../util/listings'
import { featFlags } from '../../red/featureFlags'
import { Red } from '../../red/red'
import css from './ListingPage.module.css'
import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck'
import SectionImages from './SectionImages'
import SectionAvatar from './SectionAvatar'
import SectionHeading from './SectionHeading'
import SectionDescriptionMaybe from './SectionDescriptionMaybe'

Red.init()

const lazyLog = Red.createLazyLog(500)

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16

const { UUID } = sdkTypes

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price)
    return { formattedPrice, priceTitle: formattedPrice }
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    }
  }
  return {}
}

// HEAD ListingPage component <<<<<<<<

export const ListingPageComponent = props => {
  const {
    enquiryModalOpenForListingId,
    unitType,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    onFetchTimeSlots,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    //reviews,
    //fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    monthlyTimeSlots: listingTimeSlots,
    filterConfig,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = props

  const listingId = new UUID(rawParams.id)
  // console.log({listingId})

  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT
  const currentListing = isPendingApprovalVariant || isDraftVariant
    ? ensureOwnListing(getOwnListing(listingId))
    : ensureListing(getListing(listingId))

  const flattenedListing = flattenListing(currentListing)
  const earliestBookableDate = flattenedListing?.earliestBookableDate || new Date()

  const {
    description = '',
    //geolocation = null,
    price = null,
    title = '',
    publicData,
    state: listingState,
  } = currentListing.attributes

  const listingSlug = rawParams.slug || createSlug(title)
  const params = { slug: listingSlug, ...rawParams }

  const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT
  const listingTab = isDraftVariant ? 'photos' : 'description'

  const isApproved = currentListing.id && listingState !== LISTING_STATE_PENDING_APPROVAL
  const pendingIsApproved = isPendingApprovalVariant && isApproved

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) && showListingError && showListingError.status === 403
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing

  const isGroupSession = publicData.isGroupSession || publicData.sessionType === GROUP_SESSION
  const hasSetDate = publicData.serviceType === WOKESHOP ||  publicData.serviceType === EXPERIENCE

  const authorAvailable = currentListing?.author
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable)
  const isOwnListing = userAndListingAuthorAvailable && authorAvailable.id.uuid === currentUser.id.uuid
  const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing))
  const currentAuthor = authorAvailable || null
  const ensuredAuthor = ensureUser(currentAuthor)

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '')

  //const [pageClassNames, setPageClassNames] = React.useState([])
  const [imageCarouselOpen, setImageCarouselOpen] = React.useState(false)
  const [enquiryModalOpen, setEnquiryModalOpen] = React.useState(enquiryModalOpenForListingId === rawParams.id)

  // rem extra time display

  const {timeZone, timeZone3} = getBrowserTimeZone()

  const fixedBookingTime = publicData?.events?.[0] || null
  const fixedBookingEndTime = fixedBookingTime ? addMinutes(fixedBookingTime, publicData?.duration) : null

  // console.log({currentListing, flattenedListing, fixedBookingTime, fixedBookingEndTime})

  // HEAD Master Calendar & Aggregated Bookings List!

  const [masterCalendarTimeSlots, setMasterCalendarTimeSlots] = React.useState({})
  const [aggregatedBookingsData, setAggregatedBookingsData] = React.useState({})
  // FIX This has a [] / {} mismatch.
  const {groupReservedArr = [], aggregatedBookingsList = []} = aggregatedBookingsData

  //console.log(`Author: ${currentAuthor?.attributes?.profile?.displayName} Listing: ${currentListing?.attributes?.title}`)
  //Red.diffComponentState('listingPage', props, {masterCalendarTimeSlots, aggregatedBookingsData})

  const validMaster = masterCalendarTimeSlots?.getPropertyCnt()
  const validAggrBookings = aggregatedBookingsList?.length
  if (!validMaster && !validAggrBookings) {
    //console.log(`🥀🥀No Master calendar nor aggregated booking list (yet).`)
  } else {
    const bf = 'font-size: 22px;'
    validMaster
      ? console.log(`%c🌼Master calendar awaited: (attributes/)`, bf, {masterCalendarTimeSlots})
      : console.log(`🥀No Master calendar (yet).`)

    if (validMaster) {
      const tsarr = masterCalendarTimeSlots['2021-11']?.timeSlots?.slice(0, 20).map(a => a.attributes)
      console.table(tsarr)
    }

    validAggrBookings
      ? console.log(`🌻Aggregated booking list for user awaited:`, {aggregatedBookingsList})
      : console.log(`🥀No aggregated booking list (yet).`)
  }
  listingTimeSlots?.getPropertyCnt() &&
    logListingTimeSlots(`ListingPage prop monthlyTimeSlots`, listingTimeSlots)
  const preTimeSlotsData = {listingTimeSlots, masterCalendarTimeSlots, aggregatedBookingsList, groupReservedArr}
  const {finalTimeSlots, ...gregors} = isGroupSession
    ? {finalTimeSlots: listingTimeSlots}
    : consolidateTimeSlots(preTimeSlotsData)
  //const finalTimeSlots = isGroupSession ? listingTimeSlots : consolidateTimeSlots(preTimeSlotsData)

  // HEAD useEffect: Common Availability + Aggregated Booking List

  const [selectedDay, setSelectedDay] = React.useState({})
  addSelectedDateListener(setSelectedDay)
  const {month: selectedM = 0, day: selectedD = 0} = selectedDay || {}

  const redCalendarPars = {selectedM, selectedD, gregors}
  const showRedCalendar = Red.on && featFlags.isCalDebug && !isGroupSession

  React.useEffect(() => {
    let mounted = true
    //console.log(`ListingPage mounted!!`)

    if (!isGroupSession && ensuredAuthor?.id) {
      getAuthorMasterCalendar(ensuredAuthor.id.uuid).then(masterCalendar => {
        mounted && setMasterCalendarTimeSlots(masterCalendar)
      })
      getPractitionersAggregatedBookings(ensuredAuthor).then(aggrBookingData => {
        mounted && setAggregatedBookingsData(aggrBookingData)
      })
    }

    return () => mounted = false
  }, [ensuredAuthor.id, listingTimeSlots]) // maybe [] is enough

  lazyLog('💤ListingPage', {authorDisplayName, ensuredAuthor, listingTimeSlots, props})
  Red.logCalendar('master', masterCalendarTimeSlots)
  Red.logCalendar('listing', listingTimeSlots)


  // HEAD handleSubmit
  const handleSubmit = values => {

    const {history, params, callSetInitialValues, onInitializeCardPaymentData} = props
    const listingId = new UUID(params.id)
    const listing = getListing(listingId)

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values

    let bookingStart
    let bookingEnd
    let duration

    if(hasSetDate) {
      bookingStart = listing.attributes?.publicData?.events?.length ? new Date(listing.attributes.publicData.events[0]) : null;
      // NOTE: bookingEnd is set to 1 hour after bookingStart regardless of the actual length of the session.
      bookingEnd = bookingStart ? new Date(addMinutes(bookingStart, 60)) : null;
    } else {
      bookingStart = timestampToDate(bookingStartTime)
      duration = listing.attributes?.publicData?.duration
      bookingEnd = (bookingStart && !!duration) ? new Date(addMinutes(bookingStart, duration)) : null;
    }

    const bookingData = {
      quantity: 1,
      ...restOfValues,
    }

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    }

    const saveToSessionStorage = !props.currentUser

    const routes = routeConfiguration()
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes)

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage)

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData()

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    )
  }

  // HEAD onContactUser

  const onContactUser = () => {
    const { history, callSetInitialValues, params } = props

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` }

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id })

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state)
    } else {
      setEnquiryModalOpen(true)
    }
  }

  // HEAD onSubmitEnquiry

  const onSubmitEnquiry = values => {
    const { history, params, onSendEnquiry } = props
    const routes = routeConfiguration()
    const listingId = new UUID(params.id)
    const { message } = values

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        setEnquiryModalOpen(false)

        // Redirect to OrderDetailsPage
        history.push(createResourceLocatorString('OrderDetailsPage', routes, {id: txId.uuid}, {}))
      })
      .catch(() => {
        // Ignore, error handling in duck file
      })
  }

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name='ListingPage' params={params} search={location.search} />
  }

  const richTitleText = richText(title, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
    longWordClass: css.longWord,
  })
  const richTitle = <span>{richTitleText}</span>

  const bookingTitle = (
    <FormattedMessage id={`ListingPage.bookingTitle${isGroupSession ? '.groupSession' : ''}`} values={{ title: richTitle }} />
  )

  const topbar = <TopbarContainer />

  if (showListingError && showListingError.status === 404) { // 404 listing not found
    return <NotFoundPage />
  } else if (showListingError) { // Other error in fetching listing
    const errorTitle = intl.formatMessage({id: 'ListingPage.errorLoadingListingTitle'})

    return (
      <Page scrollingDisabled={scrollingDisabled} title={errorTitle}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.errorText}><FormattedMessage id='ListingPage.errorLoadingListingMessage' /></p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    )
  } else if (!currentListing.id) { // Still loading the listing
    const loadingTitle = intl.formatMessage({id: 'ListingPage.loadingListingTitle'})

    return (
      <Page scrollingDisabled={scrollingDisabled} title={loadingTitle}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}><FormattedMessage id='ListingPage.loadingListingMessage' /></p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    )
  }

  const handleViewPhotosClick = e => {
    // Stop event from bubbling up to prevent image click handler
    // trying to open the carousel as well.
    e.stopPropagation()
    setImageCarouselOpen(true)
  }

  const { formattedPrice, priceTitle } = priceData(price, intl)

  const handleBookingSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0)
    } else {
      handleSubmit(values)
    }
  }

  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants
        const variant = variants ? variants[variantName] : null

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes
        const size = sizes ? sizes.find(i => i.name === variantName) : null

        return variant || size
      })
      .filter(variant => variant != null)

  const facebookImages = listingImages(currentListing, 'facebook')
  const twitterImages = listingImages(currentListing, 'twitter')
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url))
  const siteTitle = config.siteTitle
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, siteTitle }
  )

  const hostLink = (
    <NamedLink
      className={css.authorNameLink}
      name='ListingPage'
      params={params}
      to={{ hash: '#host' }}
    >
      {authorDisplayName}
    </NamedLink>
  )

  const certificateOptions = findOptionsForSelectFilter('certificate', filterConfig)

  return (
    <Page
      author={authorDisplayName}
      contentType='website'
      description={description}
      facebookImages={facebookImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description,
        name: schemaTitle,
        image: schemaImages,
      }}
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      twitterImages={twitterImages}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            <SectionImages
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
                listingCategory: isGroupSession ? 'groupSession' : 'privateSession'
              }}
              handleViewPhotosClick={handleViewPhotosClick}
              imageCarouselOpen={imageCarouselOpen}
              isOwnListing={isOwnListing}
              listing={currentListing}
              onImageCarouselClose={() => setImageCarouselOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              title={title}
            />
            <div className={css.contentContainer}>
              <SectionAvatar params={params} user={currentAuthor} />
              <div className={'_ListingPage-mainContent ' + css.mainContent}>
                <SectionHeading
                  certificateOptions={certificateOptions}
                  formattedPrice={formattedPrice}
                  hostLink={hostLink}
                  listingCertificate={publicData ? publicData.certificate : null}
                  onContactUser={onContactUser}
                  priceTitle={priceTitle}
                  richTitle={richTitle}
                  showContactUser={showContactUser}
                >
                  {fixedBookingTime && fixedBookingEndTime && 
                    <TimeRange
                      className={css.timeRange}
                      dateType={DATE_TYPE_DATETIME}
                      endDate={fixedBookingEndTime}
                      startDate={fixedBookingTime}
                      timeZone={timeZone}
                      timeZone3={timeZone3}
                    />}
                </SectionHeading>
                {/* <div className='_Maci'>Maci!!!</div> */}
                <SectionDescriptionMaybe {...{description, isGroupSession}} />
                {/* <SectionMapMaybe
                  geolocation={geolocation}
                  listingId={currentListing.id}
                  publicData={publicData}
                /> */}
                {/*<SectionReviews fetchReviewsError={fetchReviewsError} reviews={reviews} />*/}
              </div>
              <BookingPanel
                {...{authorDisplayName, earliestBookableDate, isOwnListing, lineItems, unitType}}
                {...{fetchLineItemsError, fetchLineItemsInProgress, onManageDisableScrolling}}
                {...{monthlyTimeSlots: finalTimeSlots, onFetchTimeSlots, onFetchTransactionLineItems}}
                className={css.bookingPanel}
                hasSetDate={hasSetDate}
                listing={currentListing}
                onSubmit={handleBookingSubmit}
                title={bookingTitle}
              />
            </div>
          </div>
          <Modal
            contentClassName={css.enquiryModalContent}
            id='ListingPage.enquiry'
            isOpen={isAuthenticated && enquiryModalOpen}
            onClose={() => setEnquiryModalOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              authorDisplayName={authorDisplayName}
              className={css.enquiryForm}
              inProgress={sendEnquiryInProgress}
              listingTitle={title}
              onSubmit={onSubmitEnquiry}
              sendEnquiryError={sendEnquiryError}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
          {showRedCalendar && <Red.CalendarForm {...redCalendarPars} />}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
}

// ListingPageComponent.propTypes = {
//   // from withRouter
//   history: shape({
//     push: func.isRequired,
//   }).isRequired,
//   location: shape({
//     search: string,
//   }).isRequired,

//   unitType: propTypes.bookingUnitType,
//   // from injectIntl
//   intl: intlShape.isRequired,

//   params: shape({
//     id: string.isRequired,
//     slug: string,
//     variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
//   }).isRequired,

//   isAuthenticated: bool.isRequired,
//   currentUser: propTypes.currentUser,
//   getListing: func.isRequired,
//   getOwnListing: func.isRequired,
//   onManageDisableScrolling: func.isRequired,
//   scrollingDisabled: bool.isRequired,
//   enquiryModalOpenForListingId: string,
//   showListingError: propTypes.error,
//   callSetInitialValues: func.isRequired,
//   reviews: arrayOf(propTypes.review),
//   fetchReviewsError: propTypes.error,
//   monthlyTimeSlots: object,
//   // monthlyTimeSlots could be something like:
//   // monthlyTimeSlots: {
//   //   '2019-11': {
//   //     timeSlots: [],
//   //     fetchTimeSlotsInProgress: false,
//   //     fetchTimeSlotsError: null,
//   //   }
//   // }
//   sendEnquiryInProgress: bool.isRequired,
//   sendEnquiryError: propTypes.error,
//   onSendEnquiry: func.isRequired,
//   onInitializeCardPaymentData: func.isRequired,
//   filterConfig: array,
//   onFetchTransactionLineItems: func.isRequired,
//   lineItems: array,
//   fetchLineItemsInProgress: bool.isRequired,
//   fetchLineItemsError: propTypes.error,
// }

const listingCache = {}

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage
  const { currentUser } = state.user

  // INF log

  const {Auth, ListingPage, user} = state
  const AuthJSON = JSON.stringify(Auth)
  const ListingPageJSON = JSON.stringify(ListingPage)
  const userJSON = JSON.stringify(user)
  if (listingCache.Auth !== AuthJSON) {
    listingCache.Auth = AuthJSON
    //console.log('ListingPage mapStateToProps / Auth', Auth)
  }
  if (listingCache.user !== userJSON) {
    listingCache.user = userJSON
    //console.log('ListingPage mapStateToProps / currentUser', user?.currentUser)
  }
  if (listingCache.ListingPage !== ListingPageJSON) {
    listingCache.ListingPage = ListingPageJSON
    //console.log('ListingPage mapStateToProps / ListingPage', {ListingPage})
  }

  const getListing = id => {
    const ref = { id, type: 'listing' }
    const listings = getMarketplaceEntities(state, [ref])
    if (listings.length) {
      //console.log(`ListingPage mapStateToProps getListing`, listings[0], listings)
    }
    return listings.length === 1 ? listings[0] : null
  }

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' }
    const listings = getMarketplaceEntities(state, [ref])
    return listings.length === 1 ? listings[0] : null
  }

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  }
}

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
})

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent)

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues)
ListingPage.loadData = loadData

export default ListingPage
