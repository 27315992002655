/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, guard-for-in, prefer-template
*/
import { shDate } from './dateAdapter'
import { featFlags } from '../red/featureFlags'

export const logListingTimeSlots = (msg, listing, pars = {}) => {
  if (!featFlags.isDev) {
    return
  }
  const {expand = false} = pars
  const glog = expand ? console.group : console.groupCollapsed
  const monthKeys = Object.keys(listing)
  if (monthKeys.length) {
    glog(`💈💈` + msg, monthKeys.join(' / '))
    for (const monthKey of monthKeys) {
      const {timeSlots = []} = listing[monthKey]
      console.group(monthKey, timeSlots)

      for (const {attributes = {}} of timeSlots) {
        const {start, end} = attributes
        console.log(monthKey, shDate(start), shDate(end))
      }
      console.groupEnd()
    }
    console.groupEnd()
  }
}
