/* eslint-disable no-void, spaced-comment, no-restricted-syntax, prefer-template,
   no-debugger, object-curly-spacing */

import React from 'react'
import {FormattedMessage} from 'react-intl'
import {isPractitionerProfileVerified} from '../../util/user'
import {extractUser, extractUserLang} from '../../models/userAdapter'
import css from './ProfileHeaderSection.module.css'

// This is the star/etc signs part, could be split into it's own comp module

const signSvg = {
  moon: {},
  star: {},
  element: {}
}

void (() => {
  const moonSignNames = 'Aquarius,Aries,Cancer,Capricorn,Gemini,Leo,Libra,Pisces,Sagittarius,Scorpio,Taurus,Virgo'.split(',')
  const elementSignNames = 'Fire,Earth,Water,Wind,Air'.split(',')
  const images = require.context('../../assets/signs', true)

  for (const sign of moonSignNames) {
    signSvg.moon[sign] = images(`./moon/${sign}.svg`)
    signSvg.star[sign] = images(`./star/${sign}.svg`)
  }
  for (const sign of elementSignNames) {
    signSvg.element[sign] = images(`./element/${sign}.svg`)
  }
})()

const Sign = ({label: olabel, id, type}) => {
  const label = olabel ? olabel[0].toUpperCase() + olabel.substr(1).toLowerCase() : "" // eslint-disable-line no-param-reassign
  console.log({olabel, label, type});

  const style = {'--svg': `url("${signSvg[type]?.[label]?.default}")`}

  return (
    <>
    {label && type ?
      <div className={css.astrologyInline}>
        <div className={css.astrologyLabel}>
         <FormattedMessage id={id}/>
         <div className={css.astrologySign} style={style}>
            <span type={type}>{label}</span>
         </div>
       </div>
     </div>
    : null
    }
    </>
  )
}

// END of signs.

export default function ProfileHeaderSection({profileUser, currentUser}) {
  if (!profileUser) {
    return null
  }
  const {publicData} = extractUser(profileUser)
  const {variants = {}} = profileUser.profileImage?.attributes || {}
  const profileImageUrl = variants['large-portrait']?.url
  const isVerified = isPractitionerProfileVerified(profileUser)
  const {practitionerLanguages} = extractUserLang(profileUser)
  const {starSign = '', moonSign = '', elementSign = ''} = publicData?.signs || {}

  const showSigns = Object.values({starSign, moonSign, elementSign}).some(value => value);

  return (
    <div className={css.root}>
      <div
        className={css.profilePictureContainer}
        style={{backgroundImage: `url(${profileImageUrl})`}}
      />
      <div className={css.dataContainer}>
        <div className={css.sectionContainer}>
          <h1 className={css.name}>{publicData.displayName}</h1>
           <div className={css.extraInfoContainer}>
            <span className={css.extraInfoLocation}>
              <FormattedMessage id='ProfilePage.practitionerLocationLabel'/>
              <span className={css.extraInfoLocationCity}>
                {' '}
                {publicData?.city?.selectedPlace?.address?.split(',')[0] || ''}
              </span>
            </span>
            {isVerified &&
            <>
              {' • '}
              <span className={css.extraInfoVerified}>
                  <FormattedMessage id='ProfilePage.verified'/>
                </span>
            </>}
          </div>
        </div>
        <div className={css.sectionContainer}>
          <div className={css.practitionerLanguagesLabel}>
            <FormattedMessage id='ProfilePage.practitionerLanguages'/>
          </div>
          <ul className={css.languages}>
            {practitionerLanguages.map(({display, value}) => (
              <li key={value} className={css.languagesLanguage}>
                {display}
              </li>
            ))}
          </ul>
        </div>
        <div className={css.sectionContainer}>
          <div className={css.practitionerQualificationsLabel}>
            <FormattedMessage id='ProfilePage.practitionerQualifications'/>
          </div>
          <div className={css.blob}>{publicData.qualifications}</div>
        </div>
        {showSigns &&
        <div className={css.sectionContainer}>
          <Sign id='ProfilePage.moonSign' label={moonSign} type='moon'/>
          <Sign id='ProfilePage.starSign' label={starSign} type='star'/>
          <Sign id='ProfilePage.elementSign' label={elementSign} type='element'/>
        </div>
        }
      </div>
    </div>
  )
}
