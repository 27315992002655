import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { Button } from "..";
import { ensureCurrentUser } from "../../util/data";
import {
  getRequiredFieldDisplayName,
  hasCompletedProfile,
  isPendingPractitionerProfileApproval,
  isPractitioner,
} from "../../util/user";
import css from "./ProfileStatusBanner.module.css";

// Due to the layout structure, do not render the banner on the following pages
const disabledPages = ["SearchPage"];

const ProfileStatusBanner = props => {
  const {
    currentUser,
    onSubmitProfileForApproval,
    onAcknowledgeProfileApprovalRequestError,
    currentPage,
    isProfileApprovalRequestInProgress,
    profileApprovalRequestError,
    intl,
  } = props;

  //console.log({currentPage})

  const handleSubmit = params => {
    onSubmitProfileForApproval(params);
  };

  const user = ensureCurrentUser(currentUser);

  const showBanner =
    user.id &&
    isPractitioner(currentUser) &&
    (!hasCompletedProfile(currentUser) || isPendingPractitionerProfileApproval(currentUser)) &&
    !disabledPages.includes(currentPage);

  const missingFields = profileApprovalRequestError?.missingFields;

  if (showBanner && missingFields) {
    const missingFieldsText = `\n\n${missingFields
      .map(fieldName => getRequiredFieldDisplayName(intl, fieldName))
      .join("\n")}`;
    alert(intl.formatMessage({ id: "ProfileStatusBanner.missingFields" }, { missingFieldsText }));
    props.onAcknowledgeProfileApprovalRequestError();
    return null;
  }
  const isPending = isPendingPractitionerProfileApproval(currentUser);
  return showBanner ? (
    <div className={css.root}>
      <p className={css.text}>
        {isPending ? (
          <FormattedMessage id="ProfileStatusBanner.messageUnderReview" />
        ) : (
          <FormattedMessage id="ProfileStatusBanner.message" />
        )}
      </p>
      {isPending ? null : (
        <Button
          inProgress={isProfileApprovalRequestInProgress}
          onClick={handleSubmit}
          rootClassName={css.button}
        >
          <FormattedMessage id="ProfileStatusBanner.submit" />
        </Button>
      )}
    </div>
  ) : null;
};

ProfileStatusBanner.defaultProps = {
  currentUser: null,
  profileApprovalRequestError: null,
};

ProfileStatusBanner.propTypes = {
  currentUser: propTypes.currentUser,
  currentPage: PropTypes.string, // was:.isRequired, -> generates an error on every run
  onSubmitProfileForApproval: PropTypes.func.isRequired,
  onAcknowledgeProfileApprovalRequestError: PropTypes.func.isRequired,
  isProfileApprovalRequestInProgress: PropTypes.bool.isRequired,
  profileApprovalRequestError: PropTypes.any,
};

export default compose(injectIntl)(ProfileStatusBanner);
