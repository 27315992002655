import {fetchCurrentUser} from "../../ducks/user.duck";
import {getDefaultTimeZoneOnBrowser, resetToStartOfDay} from "../../util/dates";
import {requestFetchAvailabilityExceptions} from "../EditListingPage/EditListingPage.duck";
import {getCurrentPractitionerMasterListing} from "../../models/listingAdapter";


export const loadData = params => (dispatch, getState, sdk) => {
  return getCurrentPractitionerMasterListing()
    .then(({listing}) => {
      const availabilityPlan = listing.attributes.availabilityPlan;
      const tz = availabilityPlan
        ? listing.attributes.availabilityPlan.timezone
        : typeof window !== 'undefined'
          ? getDefaultTimeZoneOnBrowser()
          : 'Etc/UTC';

      const today = new Date();
      const start = resetToStartOfDay(today, tz, 0);
      const exceptionRange = 364;
      const end = resetToStartOfDay(today, tz, exceptionRange);
      const params = {
        listingId: listing.id,
        start,
        end,
      };
      dispatch(requestFetchAvailabilityExceptions(params));
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      throw e;
    });
};
