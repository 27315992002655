import React from "react";
import moment from "moment";
import { FormattedMessage, FormattedDate } from "../../util/reactIntl";
import { LINE_ITEM_NIGHT, LINE_ITEM_UNITS, DATE_TYPE_DATE, propTypes } from "../../util/types";
import css from "./BookingBreakdown.module.css";

const BookingPeriod = props => {
  const { isGroupSession, duration, startDate, endDate, dateType, timeZone, timeZone3 } = props;
  // CHECK: isGroupSession is never passed here.

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: "long",
        }
      : {
          weekday: "short",
          hour: "numeric",
          minute: "numeric",
        };

  const dateFormatOptions = {
    month: "short",
    day: "numeric",
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const breakdownEndDate = moment(startDate).add(duration, "minutes").toDate();

  const useDuration = true // TODO: This should be a global / config variable.

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
            {timeZone3 && <span className={css.bookingTimeZone}>({timeZone3})</span>}
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>

        {console.log(`BookingPeriod`, {props})}

        {useDuration ? (
          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.dayLabel}>
              <FormattedMessage id="BookingBreakdown.bookingDuration" />
            </div>
            <div className={css.dayInfo}>{`${duration} mins`}</div>
          </div>
        ) : (
          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.dayLabel}>
              {!isGroupSession && <FormattedMessage id="BookingBreakdown.bookingEnd" />}
            </div>
            <div className={css.dayInfo}>
              <FormattedDate value={breakdownEndDate} {...timeFormatOptions} {...timeZoneMaybe} />
            </div>
            <div className={css.itemLabel}>
              {!isGroupSession && (
                <FormattedDate value={breakdownEndDate} {...dateFormatOptions} {...timeZoneMaybe} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, timeZone, timeZone3, duration } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  // const localEndDateRaw = displayEnd || end;
  // TODO: confirm working
  const localEndDateRaw = new Date(localStartDate.getTime() + duration*60000);

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_UNITS;
  const endDay =
    isUnit || isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, "days");

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          duration={duration}
          dateType={dateType}
          endDate={endDay}
          startDate={localStartDate}
          timeZone={timeZone}
          timeZone3={timeZone3}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
