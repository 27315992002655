/* eslint-disable no-return-assign */

import {useEffect} from 'react'

export const useEffectExt = (fun, cond) =>
  useEffect(() => {
    let mounted = true
    fun(() => mounted)
    return () => mounted = false
  }, cond)

export const useEffectProm = (promise, fun, cond) =>
  useEffect(() => {
    let mounted = true
    promise.then(arg => mounted && fun(arg))
    return () => mounted = false
  }, cond)
