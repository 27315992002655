import React from "react";
import { bool, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { ensureCurrentUser } from "../../util/data";
import { fetchCurrentUser } from "../../ducks/user.duck";
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar, NamedRedirect,
  Page,
  UserNav,
} from "../../components";
import { IdentityForm } from "../../forms";
import { TopbarContainer } from "..";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import {isCustomer, isPractitioner, isProfileEditingEnabled} from "../../util/user";
import { saveIdentity, saveIdentityClear } from "./IdentityPage.duck";
import css from "./IdentityPage.module.css";

export const IdentityPageComponent = props => {
  const {
    saveIdentityInProgress,
    currentUser,
    currentUserListing,
    identityChanged,
    onChange,
    scrollingDisabled,
    onSubmitIdentity,
    profileEditingDisabled,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const protectedData = user.attributes.profile.protectedData || {};
  const privateData = user.attributes.profile.privateData || {};
  const plannedsessionTypes = [];

  if (privateData?.privateSessions) {
    plannedsessionTypes.push("private");
  }
  if (privateData?.ticketedEvents) {
    plannedsessionTypes.push("ticketed");
  }

  const contactInfoForm = user.id ? (
    <IdentityForm
      className={css.form}
      currentUser={currentUser}
      initialValues={{
        accountType: protectedData?.accountType,
        businessName: protectedData?.businessName,
        companyNumber: privateData?.companyNumber,
        companyCountry: privateData?.companyCountry,
        vatNumber: privateData?.vatNumber,
        firstName: user.attributes.profile.firstName,
        lastName: user.attributes.profile.lastName,
        idType: privateData?.idType,
        idIssuingCountry: privateData?.idIssuingCountry,
        idNumber: privateData?.idNumber,
        plannedsessionTypes,
        acceptMarketingCommunication: privateData?.acceptMarketingCommunication,
        acceptTerms: privateData?.acceptTerms,
      }}
      inProgress={saveIdentityInProgress}
      onChange={onChange}
      onSubmit={onSubmitIdentity}
      profileEditingDisabled={profileEditingDisabled}
      ready={identityChanged}
    />
  ) : null;

  const title = intl.formatMessage({ id: "IdentityPage.title" });

  // TODO: This is a workaround to hide the default NamedRedirect from /account
  // TODO: as customers shouldn't see this page.
  if (!isPractitioner(currentUser)) {
    return <NamedRedirect name="ContactDetailsPage"/>;
  }

  return (
    <Page scrollingDisabled={scrollingDisabled} title={title}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="IdentityPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          {isCustomer(currentUser) ? null : (
            <UserNav listing={currentUserListing} selectedPageName="IdentityPage" />
          )}
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="IdentityPage" currentUser={currentUser} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="IdentityPage.heading" />
            </h1>
            {contactInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

IdentityPageComponent.defaultProps = {
  currentUser: null,
};

IdentityPageComponent.propTypes = {
  saveIdentityInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  identityChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitIdentity: func.isRequired,
  scrollingDisabled: bool.isRequired,
  profileEditingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, currentUserListing } = state.user;
  const { saveIdentityInProgress, saveIdentityError, identityChanged } = state.IdentityPage;
  return {
    saveIdentityError,
    saveIdentityInProgress,
    currentUser,
    currentUserListing,
    identityChanged,
    scrollingDisabled: isScrollingDisabled(state),
    profileEditingDisabled: !isProfileEditingEnabled(currentUser),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(saveIdentityClear()),
  onSubmitIdentity: values => {
    console.log('onSubmitIdentity', {values})
    dispatch(saveIdentity(values))
  },
});

const IdentityPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(IdentityPageComponent);

IdentityPage.loadData = () =>
  // Since verify email happens in separate tab, current user's data might be updated
  fetchCurrentUser();

export default IdentityPage;
