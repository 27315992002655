/* eslint-disable arrow-body-style */

import React from "react";
import {
  AboutPage,
  AuthenticationPage,
  CheckoutPage,
  ContactDetailsPage,
  EditListingPage,
  EmailVerificationPage,
  InboxPage,
  LandingPage,
  ListingPage,
  ManageListingsPage,
  NotFoundPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PaymentMethodsPage,
  PractitionerSignupPage,
  PrivacyPolicyPage,
  ProfileSettingsPage,
  SearchPage,
  StripePayoutPage,
  StyleguidePage,
  TermsOfServicePage,
  TransactionPage,
} from "./containers";
import {GROUP_SESSION, PRIVATE_SESSION} from "./util/types";
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import {NamedRedirect} from "./components";
import IdentityPage from "./containers/IdentityPage/IdentityPage";
import PractitionerListPage from "./containers/PractitionerListPage/PractitionerListPage";
import PayoutAndInsurancePage from "./containers/PayoutAndInsurancePage/PayoutAndInsurancePage";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import FAQPage from "./containers/FAQPage/FAQPage";
import CorporateProgramPage from "./containers/CorporateProgramPage/CorporateProgramPage";
import GuideAvailabilityPage from "./containers/GuideAvailabilityPage/GuideAvailabilityPage";
import EditListingAvailabilityPanel
  from "./components/EditListingAvailabilityPanel/EditListingAvailabilityPanel";

export const ACCOUNT_SETTINGS_PAGES = [
  "IdentityPage",
  "PayoutAndInsurancePage",
  "ContactDetailsPage",
  "PasswordChangePage",
  "StripePayoutPage",
  "PaymentMethodsPage",
  "GuideAvailabilityPage",
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = "00000000-0000-0000-0000-000000000000";
const draftSlug = "draft";

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage"/>;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => [
  {
    path: "/",
    name: "LandingPage",
    component: props => <LandingPage {...props} />,
    loadData: LandingPage.loadData,
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/s",
    name: "SearchPage",
    component: props => {
      // console.log({props})
      return <SearchPage {...props} />
    },
    loadData: SearchPage.loadData,
  },
  {
    path: "/s/filters",
    name: "SearchFiltersPage",
    component: props => <SearchPage {...props} tab="filters"/>,
    loadData: SearchPage.loadData,
  },
  {
    path: "/s/listings",
    name: "SearchListingsPage",
    component: props => <SearchPage {...props} tab="listings"/>,
    loadData: SearchPage.loadData,
  },
  {
    path: "/s/map",
    name: "SearchMapPage",
    component: props => <SearchPage {...props} tab="map"/>,
    loadData: SearchPage.loadData,
  },
  {
    path: "/l",
    name: "ListingBasePage",
    component: RedirectToLandingPage,
  },
  {
    path: "/l/new/:service?",
    name: "NewListingPage",
    auth: true,
    component: props => (
      <NamedRedirect
        name="EditListingPage"
        params={{
          id: draftId,
          listingCategory: props.params.service || PRIVATE_SESSION,
          slug: draftSlug,
          tab: "description",
          type: "new",
        }}
      />
    ),
  },
  {
    path: "/l/:slug/:id",
    name: "ListingPage",
    component: props => <ListingPage {...props} />,
    loadData: ListingPage.loadData,
  },
  {
    path: "/l/:slug/:id/checkout",
    name: "CheckoutPage",
    auth: true,
    component: props => <CheckoutPage {...props} />,
    setInitialValues: CheckoutPage.setInitialValues,
  },
  {
    path: "/l/:slug/:id/:variant",
    name: "ListingPageVariant",
    auth: true,
    authPage: "LoginPage",
    component: props => <ListingPage {...props} />,
    loadData: ListingPage.loadData,
  },
  {
    path: "/l/:slug/:id/:type/:tab/:listingCategory?",
    name: "EditListingPage",
    auth: true,
    component: props => <EditListingPage {...props} />,
    loadData: EditListingPage.loadData,
  },
  {
    path: "/l/:slug/:id/:type/:tab/:listingCategory/:returnURLType",
    name: "EditListingStripeOnboardingPage",
    auth: true,
    component: props => <EditListingPage {...props} />,
    loadData: EditListingPage.loadData,
  },

  // Canonical path should be after the `/l/new` path since they
  // conflict and `new` is not a valid listing UUID.
  {
    path: "/l/:id",
    name: "ListingPageCanonical",
    component: props => <ListingPage {...props} />,
    loadData: ListingPage.loadData,
  },
  {
    path: "/u",
    name: "ProfileBasePage",
    component: RedirectToLandingPage,
  },
  {
    path: "/u/:id",
    name: "ProfilePage",
    component: props => <ProfilePage {...props} />,
    loadData: ProfilePage.loadData,
  },
  {
    path: "/profile-settings",
    name: "ProfileSettingsPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <ProfileSettingsPage {...props} />,
  },
  {
    path: "/guides",
    name: "PractitionerListPage",
    component: props => <PractitionerListPage {...props} />,
  },

  // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
  // so that in the error case users can be redirected back to the LoginPage
  // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
  {
    path: "/login",
    name: "LoginPage",
    component: props => <AuthenticationPage {...props} tab="login"/>,
  },
  {
    path: "/customer-signup",
    name: "SignupPage",
    component: props => <AuthenticationPage {...props} tab="signup"/>,
  },
  {
    path: "/guide-signup",
    name: "PractitionerSignupPage",
    component: props => <PractitionerSignupPage {...props} tab="signup"/>,
  },
  {
    path: "/confirm",
    name: "ConfirmPage",
    component: props => <AuthenticationPage {...props} tab="confirm"/>,
  },
  {
    path: "/recover-password",
    name: "PasswordRecoveryPage",
    component: props => <PasswordRecoveryPage {...props} />,
  },
  {
    path: "/inbox",
    name: "InboxBasePage",
    auth: true,
    authPage: "LoginPage",
    component: () => <NamedRedirect name="InboxPage" params={{tab: "sales"}}/>,
  },
  {
    path: "/inbox/:tab",
    name: "InboxPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <InboxPage {...props} />,
    loadData: InboxPage.loadData,
  },
  {
    path: "/order/:id",
    name: "OrderPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <NamedRedirect name="OrderDetailsPage" params={{...props.params}}/>,
  },
  {
    path: "/order/:id/details",
    name: "OrderDetailsPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <TransactionPage {...props} transactionRole="customer"/>,
    loadData: params => TransactionPage.loadData({...params, transactionRole: "customer"}),
    setInitialValues: TransactionPage.setInitialValues,
  },
  {
    path: "/sale/:id",
    name: "SalePage",
    auth: true,
    authPage: "LoginPage",
    component: props => <NamedRedirect name="SaleDetailsPage" params={{...props.params}}/>,
  },
  {
    path: "/sale/:id/details",
    name: "SaleDetailsPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <TransactionPage {...props} transactionRole="provider"/>,
    loadData: params => TransactionPage.loadData({...params, transactionRole: "provider"}),
  },
  {
    path: "/listings/",
    name: "ManageListingsDefaultPage",
    auth: true,
    authPage: "LoginPage",
    component: () => <NamedRedirect name="ManageListingsPage" params={{service: GROUP_SESSION}}/>,
  },
  {
    path: "/listings/:service?",
    name: "ManageListingsPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <ManageListingsPage {...props} />,
    loadData: ManageListingsPage.loadData,
  },
  {
    path: "/account",
    name: "AccountSettingsPage",
    auth: true,
    authPage: "LoginPage",
    component: () => <NamedRedirect name="IdentityPage"/>
  },
  {
    path: "/account/availability",
    name: "GuideAvailabilityPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <GuideAvailabilityPage {...props} />,
  },
  {
    path: "/account/contact-details",
    name: "ContactDetailsPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <ContactDetailsPage {...props} />,
    loadData: ContactDetailsPage.loadData,
  },
  {
    path: "/account/identity",
    name: "IdentityPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <IdentityPage {...props} />,
    loadData: IdentityPage.loadData,
  },
  {
    path: "/account/payoutAndInsurance",
    name: "PayoutAndInsurancePage",
    auth: true,
    authPage: "LoginPage",
    component: props => <PayoutAndInsurancePage {...props} />,
    loadData: PayoutAndInsurancePage.loadData,
  },
  {
    path: "/account/change-password",
    name: "PasswordChangePage",
    auth: true,
    authPage: "LoginPage",
    component: props => <PasswordChangePage {...props} />,
  },
  {
    path: "/account/payments",
    name: "StripePayoutPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <StripePayoutPage {...props} />,
    loadData: StripePayoutPage.loadData,
  },
  {
    path: "/account/payments/:returnURLType",
    name: "StripePayoutOnboardingPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <StripePayoutPage {...props} />,
    loadData: StripePayoutPage.loadData,
  },
  {
    path: "/account/payment-methods",
    name: "PaymentMethodsPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <PaymentMethodsPage {...props} />,
    loadData: PaymentMethodsPage.loadData,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfServicePage",
    component: props => <TermsOfServicePage {...props} />,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: props => <PrivacyPolicyPage {...props} />,
  },
  {
    path: "/faq",
    name: "FAQPage",
    component: props => <FAQPage {...props} />,
  },
  {
    path: "/corporate-program",
    name: "CorporateProgramPage",
    component: props => <CorporateProgramPage {...props} />,
  },
  {
    path: "/styleguide",
    name: "Styleguide",
    component: props => <StyleguidePage {...props} />,
  },
  {
    path: "/styleguide/g/:group",
    name: "StyleguideGroup",
    component: props => <StyleguidePage {...props} />,
  },
  {
    path: "/styleguide/c/:component",
    name: "StyleguideComponent",
    component: props => <StyleguidePage {...props} />,
  },
  {
    path: "/styleguide/c/:component/:example",
    name: "StyleguideComponentExample",
    component: props => <StyleguidePage {...props} />,
  },
  {
    path: "/styleguide/c/:component/:example/raw",
    name: "StyleguideComponentExampleRaw",
    component: props => <StyleguidePage raw {...props} />,
  },
  {
    path: "/notfound",
    name: "NotFoundPage",
    component: props => <NotFoundPage {...props} />,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /reset-password endpoint
  {
    path: "/reset-password",
    name: "PasswordResetPage",
    component: props => <PasswordResetPage {...props} />,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /verify-email endpoint
  {
    path: "/verify-email",
    name: "EmailVerificationPage",
    auth: true,
    authPage: "LoginPage",
    component: props => <EmailVerificationPage {...props} />,
    loadData: EmailVerificationPage.loadData,
  },
];

export default routeConfiguration;
