import {getUserLanguages} from "../util/practitioners";

/**
 * Convert a User object from Sharetribe into a more flattened and maintainable structure.
 */
export const User = user => {
  if (!user) {
    return {};
  }
  const { publicData = {} } = user.attributes.profile
  const addressStr = publicData.city?.selectedPlace?.address || ''
  const city = addressStr.split(",")[0]
  return {
    name: user.attributes.profile.displayName,
    displayName: publicData.displayName,
    city,
    language: publicData.mainLanguage,
    languages: getUserLanguages(user),
    specialties: publicData.specialty,
    imageUrl: user.profileImage?.attributes.variants.default?.url || "https://via.placeholder.com/150",
    ...user,
    id: user.id.uuid,
  }
}
