/* eslint-disable multiline-ternary, spaced-comment, object-curly-spacing, comma-dangle, no-unneeded-ternary,
   guard-for-in, no-restricted-syntax, prefer-template, quotes, no-return-assign,
   import/no-unresolved, import/extensions, prefer-template, react/prop-types */

import React from 'react'
import classNames from 'classnames'
import { ListingCard, PaginationLinks } from '..'
import { siftListings } from '../../models/listingAdapter'
import css from './SearchResultsPanel.module.css'

const SearchResultsPanel = props => {
  const { 
    className, rootClassName, currentUser, listings: rawListings, pagination, search, setActiveListing, minify
  } = props
  const listings = siftListings(rawListings)
  // console.log({listings, rawListings})

  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName='SearchPage'
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null

  // Panel width relative to the viewport
  const panelMediumWidth = 50
  const panelLargeWidth = 62.5
  const renderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 3}vw`,
    `${panelLargeWidth / 4}vw`,
  ].join(', ')
  // console.log('SRP', {listings})
  return (
    <div className={`_SearchResultsPanel ` + classes}>
      <div className={`${css.listingCards} ${minify ? css.listingCardsMinified : ''}`}>
        {listings.map(listing => (
          <ListingCard
            key={listing.id.uuid}
            className={css.listingCard}
            {...{minify, listing, currentUser, setActiveListing, renderSizes}}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  )
}

export default SearchResultsPanel
