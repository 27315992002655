/* eslint-disable no-unused-vars, react/prefer-stateless-function, semi, quotes, jsx-quotes,
   no-unused-expressions, object-curly-spacing, spaced-comment, no-restricted-syntax,
   comma-dangle, prefer-template
*/

import React, {Component} from "react";
import {array, func, string, object} from "prop-types";
import classNames from "classnames";
import {injectIntl, intlShape} from "../../util/reactIntl";
import {lazyLoadWithDimensions} from "../../util/contextHelpers";
import {EXPERIENCE, ONE_TO_ONE, PRIVATE_SESSION, propTypes, WOKESHOP} from "../../util/types";
import {formatMoney} from "../../util/currency";
import {ensureListing} from "../../util/data";
import {richText} from "../../util/richText";
import {createSlug, LISTING_PAGE_PARAM_TYPE_EDIT} from "../../util/urlHelpers";
import config from "../../config";
import {NamedLink, ResponsiveImage, AvatarMedium, AvatarLarge, Avatar, SmartAvatar} from "..";
import css from "./PractitionerCalendar.module.css";
import {displayDate, isGroupSession} from "../../util/listings";
import {AiOutlineWifi, FaMapMarkerAlt} from "react-icons/all";
import {displayUserLanguages} from "../../util/practitioners";
import {Listing} from "../../models/listings";
import {
  dayTicks,
  pad,
  padMD,
  padYMD,
  getStringYMD,
  weekdayOf,
  longWeekdayOf,
  weekStartDateFor,
  getDateYMDHM
} from '../../models/dateAdapter'
import { createGregor } from '../../models/calendarAdapter'

import * as Corelib from '../../red/esm/stdlib/corelib-esm'

const {wassert} = Corelib.Debug

const CalendarFrame = ({cc, children}) => <div className={classNames(css.calendarFrame, cc)}>{children}</div>

const CalendarWeek = ({cc, children}) => <div className={classNames(css.calendarWeek, cc)}>{children}</div>

const DayHead = ({actDate}) => (
  <div className={css.dayHead} comp='DayHead'>
    {/*console.log({actDate, getStringYMD: getStringYMD(actDate)})*/}
    <div className={css.headDate}>{getStringYMD(actDate)}</div>
    <div className={css.headWeekDay}>{longWeekdayOf(actDate)}</div>
  </div>
)

const DayBody = ({actDate, style, children}) => (
  <div className={css.dayBody} {...{style}} comp='DayBody'>
    {children}
    {/* <div className='dateruler' /> */}
  </div>
)

const DayRuler = ({hourStart, hourEnd}) => {
  const hours = []
  for (let hour = hourStart; hour <= hourEnd; hour++) {
    hours.push(
      <div key={hour} className={css.hourRuler} style={{'--hour': hour}}>
        <div className={css.hourRulerTime}>{`${hour % 24}:00`}</div>
      </div>)
  }
  return <div className={css.rulerFrame} comp='DayRuler'>{hours}</div>
}

const CalendarDay = ({actDate, year, month, day, wday, dataArr, gregors, hi}) => {
  const {gregMaster, gregAggrGroup, gregAggrPrivBook, gregAggrGroupBook} = gregors
  const dayIndex = padYMD(year, month, day)
  // `${year}-${pad(month)}-${pad(day)}`// gregor.getDayIndex()
  const overHours = 4
  const maxHour = 24 + overHours
  const hourStart = 0 // calc!
  const hourEnd = maxHour
  const hourHeight = hourEnd - hourStart
  const bodyStyle = {'--hiddenHours': -hourStart}

  let key = 0

  const spawnsFromGregor = (gregor, {slotype, defTitle = ''} = {}) => {
    const spawns = []
    const actDateArr = gregor?.dgrid[dayIndex] || []
    for (const grog of actDateArr) {
      const {startH, startM, startHM, endH, endM, endHM, title: otitle = '', duration} = grog
      const startPt = (startH * 60 + startM) / (maxHour * 60)
      const endPt = ((endH || 24) * 60 + endM) / (maxHour * 60)
      const durEndPt = (startH * 60 + startM + duration) / (maxHour * 60)
      // this is for rounded hours:
      //const durEndPt = (startH * 60 + startM + 60 * Math.ceil(duration / 60)) / (maxHour * 60)
      const realEndPt = duration ? durEndPt : endPt // 'cause masterCal doesn't have dur

      const style = {
        top: startPt * 100 + '%',
        height: (realEndPt - startPt) * 100 + '%'
      }
      //otitle && (style['--ghue'] = 40 + 100) //hashOfString(title + 'xdy') % 180)
      const durStr = duration ? `${duration}mins ` : ``
      const title = `${startHM}-${endHM} ${durStr}${otitle} ${defTitle}`
      spawns.push(<div key={key++} className={css.slot} {...{slotype, style, title}}>{otitle}</div>)
    }
    return spawns
  }

  const mcChildren = spawnsFromGregor(gregMaster, {slotype: 'mc', defTitle: 'available in Master Calendar'})
  const grpChildren = spawnsFromGregor(gregAggrGroup, {slotype: 'grp', defTitle: 'available group session'})
  const privBkChildren = spawnsFromGregor(gregAggrPrivBook, {slotype: 'priv', defTitle: 'booked private session'})
  const groupBkChildren = spawnsFromGregor(gregAggrGroupBook, {slotype: 'grpbk', defTitle: 'booked group session'})

  const extraClass = weekdayOf(actDate) // `${hi ? 'hi ' : ''}`//${(parseInt(month) % 2) ? '' : 'even '}${weekDay}`
  const classes = classNames(css.calendarDay, extraClass);

  const now = new Date()
  const [nowH, nowM] = [now.getHours(), now.getMinutes()]
  const currPt = (nowH * 60 + nowM) / (maxHour * 60)
  console.log({nowH, nowM, currPt})
  const timeLine = hi && <div className={css.timeLine} style={{top: currPt * 100 + '%'}} />

  return (
    <div className={classes}>
      <DayHead {...{actDate}} />
      <DayBody {...{actDate, style: bodyStyle}}>
        <DayRuler {...{hourStart, hourEnd}} />
        {mcChildren}
        {timeLine}
        {grpChildren}
        {privBkChildren}
        {groupBkChildren}
      </DayBody>
    </div>
  )
}

export const PractitionerCalendarComponent = ({aggregatedBookings, masterCalendar}) => {
  const {aggregatedBookingsList, groupReservedArr} = aggregatedBookings
  const gregMaster = createGregor({origin: masterCalendar, name: 'PCalMasterCalendar'})
  console.log({aggregatedBookingsList})
  const aggrPrivBookingsList = aggregatedBookingsList?.filter(a => !a.isGroupSession) || []
  const aggrGroupBookingsList = aggregatedBookingsList?.filter(a => a.isGroupSession) || []
  const gregAggrPrivBook = createGregor({origin: aggrPrivBookingsList, name: 'PCalAggrPrivBookings'})
  const gregAggrGroupBook = createGregor({origin: aggrGroupBookingsList, name: 'PCalAggrGroupBookings'})
  const gregAggrGroup = createGregor({origin: groupReservedArr, name: 'PCalAggrGroupResList(arr)'})
  const gregors = {gregMaster, gregAggrGroup, gregAggrPrivBook, gregAggrGroupBook}

  const dataArr = [gregMaster, gregAggrGroup, gregAggrPrivBook, gregAggrGroupBook]
  console.group(`📅PractitionerCalendar`, dataArr)
  //const timer = createPerfTimer()
  for (const gregor of dataArr) {
    gregor?.slotCnt && gregor.dump('📅')
  }
  //console.log(`CalForm (gr=${timer.sum().dur.sum}ms)`)
  console.groupEnd()

  const today = new Date()
  const todayTime = today.getTime()
  const yesterdayTime = todayTime - 1 * dayTicks
  const daysToShow = []
  const [, selectedM, selectedD] = getDateYMDHM(today)
  const firstDay = weekStartDateFor(today)
  const firstDayTime = firstDay.getTime()

  const hiDate = padMD(selectedM, selectedD)

  for (let dateTime = firstDayTime; dateTime < firstDayTime + 14 * dayTicks; dateTime += dayTicks) {
    const actDate = new Date(dateTime)
    const [year, month, day] = getDateYMDHM(actDate)
    daysToShow.push({actDate, year, month, day, wday: actDate.getDay(), hi: hiDate === padMD(month, day)})
  }
  const title = `Practitioner's calendar`

  return (
    <CalendarFrame cc='calendar-frame'>
      <h2>My calendar:</h2>
      {[0, 1, 2].map(offset => (
        <CalendarWeek key={offset} cc='calendar-week-notused' title={title}>
          {console.log({daysToShow, offset})}
          {daysToShow.slice(offset * 7, (offset + 1) * 7).map((dayData, key) =>
            // eslint-disable-next-line react/jsx-key
            <CalendarDay {...{...dayData, dataArr, gregors, key}} />)}
        </CalendarWeek>))}
    </CalendarFrame>
  )
}

const PractitionerCalendar = injectIntl(PractitionerCalendarComponent)

export default PractitionerCalendar
