/* eslint-disable no-restricted-syntax, no-unused-expressions, spaced-comment, quotes,
   object-curly-spacing, comma-dangle
*/
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck'
import { fetchCurrentUser } from '../../ducks/user.duck'
import { denormalisedResponseEntities } from '../../util/data'
import { storableError } from '../../util/errors'
import { util as sdkUtil } from 'sharetribe-flex-sdk/src'

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE'

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST'
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS'
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR'

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST'
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS'
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR'

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST'
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS'
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR'

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
}

export default function profilePageReducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState }
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId }
    case SHOW_USER_SUCCESS:
      return state
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload }

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      }
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs }
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload }
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null }
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload }
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload }

    default:
      return state
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
})

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
})

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
})

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
})

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
})

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
})

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
})

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
})

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
})

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
})

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId))
  return sdk.listings
    .query({
      pub_isVerified: true,
      author_id: userId,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData',],
      'fields.user': ['profile.displayName', 'profile.bio', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': ['variants.scaled-small', 'variants.scaled-medium', 'variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }))
      dispatch(addMarketplaceEntities(response))
      dispatch(queryListingsSuccess(listingRefs))
      return response
    })
    .catch(e => dispatch(queryListingsError(storableError(e))))
}

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.large-portrait',
      ],
      'imageVariant.large-portrait': sdkUtil.objectQueryString({
        w: 560,
        h: 770,
        fit: 'crop',
      }),
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response)
      dispatch(queryReviewsSuccess(reviews))
    })
    .catch(e => dispatch(queryReviewsError(e)))
}

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId))
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.large-portrait',
      ],
      'imageVariant.large-portrait': sdkUtil.objectQueryString({
        w: 560,
        h: 770,
        fit: 'crop',
      }),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response))
      dispatch(showUserSuccess())
      return response
    })
    .catch(e => dispatch(showUserError(storableError(e))))
}

export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState())

  const promises = Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
  ])

  // INF mod start

  const nop = () => {}
  const log = nop // console.log
  const glog = nop // console.groupCollapsed
  const elog = nop // console.groupEnd

  log(`Running ProfilePage.duck loadData`, { promises })

  promises.then(prall => {
    glog(`Running ProfilePage.duck loadData.then`, { prall })
    const prlabel = ['fetchCurrentUser', 'showUser', 'queryUserListings', 'queryUserReviews']
    for (let ix = 0; ix < prlabel.length; ix++) {
      const pr = prall[ix]
      const label = prlabel[ix]
      if (pr) {
        const {data: outerData, ...outerRest} = pr

        if (outerData) {
          const {data, included, meta, ...rest} = outerData
          glog(`then[${label}] data:`, data, outerRest)

          if (Array.isArray(data)) {
            glog(`then.data[${data.length}]:`)
            for (const {type, attributes, ...rest} of data) {
              log('data type/attributes:', type, attributes, rest)
            }
            elog()
          } else {
            log('data not an array:', data)
          }

          if (Array.isArray(included)) {
            glog(`then.included[${included.length}]:`)
            for (const {attributes, ...rest} of included) {
              log('include/attributes:', attributes, rest)
            }
            elog()
          } else {
            log('included not an array:', included)
          }
          log('meta, rest', meta, rest)
          elog()
        } else {
          log(`then[${label}] data: undef`, outerRest)
        }
      } else {
        log(`then[${label}]: undef`)
      }
    }
    elog()
    //window.lastOwnListings = prall[2] // WARN this is a very termporary hack for demoing
  })

  return promises

  // INF mod end
}
