import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink } from "..";
import Gate from "../Gate/Gate"
import {ReactComponent as Arrow} from "../Arrow/Arrow.svg";
import css from './SectionInfo.module.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "./carousel.css";

const GateItem = ({index}) => (
  <div className={css.gateItem}>
    <div className={css.title}>
      <FormattedMessage id={`SectionInfo.title${index}`} />
    </div>
    <div className={css.subtitle}>
      <FormattedMessage id={`SectionInfo.subtitle${index}`} />
    </div>
    <NamedLink className={css.actionButton} name="SignupPage" to={{}} >
      <FormattedMessage id={`SectionInfo.actionButton${index}`} />
    </NamedLink>
    <div className={css.verticalLine} />
  </div>
)

const SectionInfo = props => {
  const { rootClassName, className } = props;
  const [selected, setSelected] = React.useState(0);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <Gate className={css.gate}>
        <div className={css.gateContent}>
          <div className={css.gateControls}>
            <span onClick={() => setSelected(selected-1)}>
              <Arrow className={css.leftArrow}/>
            </span>

            <div className={css.controlLine}/>

            <span onClick={() => setSelected(selected+1)}>
              <Arrow className={css.rightArrow}/>
            </span>
          </div>
          <Carousel
            emulateTouch
            onChange={(index) => setSelected(index)}
            selectedItem={selected}
            showArrows={false}
            showIndicators
            showStatus={false}
            showThumbs={false}
            swipeable
          >
            <GateItem index={1}/>
            <GateItem index={2}/>
            <GateItem index={3}/>
            <GateItem index={4}/>
          </Carousel>
        </div> 
        <div className={css.background} />
      </Gate>
    </div>
  );
};

SectionInfo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionInfo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionInfo;
