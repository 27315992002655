/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, comma-dangle, multiline-ternary
*/
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl'
import {PRIVATE_SESSION, propTypes} from '../../util/types'
import {parse} from '../../util/urlHelpers'
import {isScrollingDisabled} from '../../ducks/UI.duck'
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ManageListingCard,
  NamedLink,
  Page,
  PaginationLinks,
  UserNav,
} from '../../components'
//import { HorMenuNav } from '../../components/TabNavHorizontal/HorMenuNav'
import {TopbarContainer} from '..'
import {
  closeListing,
  getOwnListingsById,
  openListing,
  queryOwnListings,
} from './ManageListingsPage.duck'
import css from './ManageListingsPage.module.css'

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42

export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {listingMenuOpen: null}
    this.onToggleMenu = this.onToggleMenu.bind(this)
  }

  onToggleMenu(listing) {
    console.log(`onToggleMenu`, {listing})
    this.setState({listingMenuOpen: listing})
  }

  render() {
    const {
      closingListing,
      closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      openingListing,
      openingListingError,
      pagination,
      params,
      queryInProgress,
      queryListingsError,
      queryParams,
      scrollingDisabled,
      intl,
    } = this.props

    const sessionType = params.service

    const hasPaginationInfo = !!pagination && pagination.totalItems != null
    const listingsAreLoaded = !queryInProgress && hasPaginationInfo
    const sessionTypeMsg = {sessionType: intl.formatMessage({id: sessionType})}

    const loadingResults = (
      <h2 className={css.title}>
        <FormattedMessage id='ManageListingsPage.loadingOwnListings' values={sessionTypeMsg}/>
      </h2>
    )

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id='ManageListingsPage.queryError' values={sessionTypeMsg}/>
      </h2>
    )

    const noResults =
      listingsAreLoaded && listings.length === 0 && (
        <h1 className={css.title}>
          <FormattedMessage
            id='ManageListingsPage.noResults'
            values={sessionTypeMsg}
          />
          {' '}
          <NamedLink name='NewListingPage' params={{service: sessionType}}>
            <span>
              <FormattedMessage
                id='ManageListingsPage.createNewTitle'
                values={sessionTypeMsg}
              />
            </span>
          </NamedLink>
        </h1>
      )

    const heading =
      listingsAreLoaded && listings.length > 0 ? (
        <div className={css.title}>
          <h1 className={css.headerTitle}>
            <FormattedMessage
              id='ManageListingsPage.youHaveListings'
              values={{count: pagination.totalItems, ...sessionTypeMsg}}
            />
          </h1>
          <h4 className={css.subTitle}>
            <NamedLink name='NewListingPage' params={{service: sessionType}}>
              <span>
                <FormattedMessage
                  id='ManageListingsPage.createNewLink'
                  values={sessionTypeMsg}
                />
              </span>
            </NamedLink>
            {
              sessionType === PRIVATE_SESSION &&
              <NamedLink name='GuideAvailabilityPage' params={{service: sessionType}}>
                <span>
                  <FormattedMessage
                    id='ManageListingsPage.guideAvailabilityLink'
                    values={sessionTypeMsg}
                  />
                </span>
              </NamedLink>
            }
          </h4>
        </div>
      ) : (
        noResults
      )

    const page = queryParams ? queryParams.page : 1
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 && (
        <PaginationLinks
          className={css.pagination}
          pageName='ManageListingsPage'
          pageSearchParams={{page}}
          pagination={pagination}
        />
      )

    const listingMenuOpen = this.state.listingMenuOpen
    const closingErrorListingId = !!closingListingError && closingListingError.listingId
    const openingErrorListingId = !!openingListingError && openingListingError.listingId

    const title = intl.formatMessage({id: 'ManageListingsPage.title'}, sessionTypeMsg)

    const panelWidth = 62.5
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ')

    return (
      <Page scrollingDisabled={scrollingDisabled} title={title}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage='ManageListingsPage'/>
            {/*<HorMenuNav selectedPageName='ManageListingsPage' sessionType={sessionType} />*/}
            <UserNav selectedPageName='ManageListingsPage' sessionType={sessionType}/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {queryInProgress ? loadingResults : null}
            {queryListingsError ? queryError : null}
            <div className={css.listingPanel}>
              {heading}
              <div className={css.listingCards}>
                {listings.map(l => (
                  <ManageListingCard
                    key={l.id.uuid}
                    actionsInProgressListingId={openingListing || closingListing}
                    className={css.listingCard}
                    hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                    hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                    isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                    listing={l}
                    onCloseListing={onCloseListing}
                    onOpenListing={onOpenListing}
                    onToggleMenu={this.onToggleMenu}
                    renderSizes={renderSizes}
                    sessionType={sessionType}
                  />
                ))}
              </div>
              {paginationLinks}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    )
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
}

const {arrayOf, bool, func, object, shape, string} = PropTypes

ManageListingsPageComponent.propTypes = {
  closingListing: shape({uuid: string.isRequired}),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({uuid: string.isRequired}),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  params: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
}

const mapStateToProps = state => {
  const {currentUser, currentUserListing} = state.user
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage
  const listings = getOwnListingsById(state, currentPageResultIds)
  return {
    currentUser,
    currentUserListing,
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  }
}

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
})

const ManageListingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageListingsPageComponent)

ManageListingsPage.loadData = (params, search) => {
  const queryParams = parse(search)
  const pub_sessionType = params.service // eslint-disable-line camelcase
  const page = queryParams.page || 1

  return queryOwnListings({
    ...queryParams,
    ...{pub_sessionType},
    pub_sessionType,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  })
}

export default ManageListingsPage
