import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {injectIntl, intlShape} from '../../util/reactIntl';
import {isScrollingDisabled} from '../../ducks/UI.duck';
import {TopbarContainer} from "..";
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import css from './FAQPage.css';
import classNames from "classnames";

const FAQ = props => {
  const {scrollingDisabled, intl} = props;

  const tabs = [
    {
      text: intl.formatMessage({id: 'PrivacyPolicyPage.privacyTabTitle'}),
      selected: false,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({id: 'PrivacyPolicyPage.tosTabTitle'}),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
    {
      text: "FAQ",
      selected: true,
      linkProps: {
        name: 'FAQPage',
      },
    },
    {
      text: "Corporate Program",
      selected: false,
      linkProps: {
        name: 'CorporateProgramPage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({id: 'PrivacyPolicyPage.schemaTitle'}, {siteTitle});
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  const {rootClassName, className} = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Page schema={schema} scrollingDisabled={scrollingDisabled} title={schemaTitle}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="PrivacyPolicyPage"/>
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs}/>
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.heading}>
              FAQ
            </h1>
            <div className={classes}>
              <p className={css.lastUpdated}>Last updated: August 20, 2021</p>

              <h2>What payment methods do you accept?</h2>
              <p>For now, we accept all international credit and debit cards and are looking to
                extend payment options in the future.</p>

              <h2>What is your cancellation policy?</h2>
              <p>For most of the services our cancellation policy is 48 hours. However, some
                experiences that are tailored for smaller groups will have a different notice
                period. Please read the full description of each listing or contact us with any
                questions at <a href="mailto:namaste@lemongrass.life">namaste@lemongrass.life</a>.
              </p>

              <h2>How do you select guides who are listed on the platform? Do they have
                qualifications?</h2>
              <p>All of our guides have been hand selected by us based on personal experience or a
                recommendation. Some of our faculty have professional accreditation while others
                have not. This information is displayed in each profile, should you require any
                further information reach out to us at <a
                  href="mailto:namaste@lemongrass.life">namaste@lemongrass.life</a> or contact our
                guides directly through the messaging option after making a booking.</p>

              <h2>Can I message a guide before making a booking if I need more info about their
                service?</h2>
              <p>Not at the moment. This option will be available soon, currently you can message
                them directly after making a booking. If you have a specific question that you need
                answered ahead of a book please reach out to us on <a
                  href="mailto:namaste@lemongrass.life">namaste@lemongrass.life</a>.</p>

              <h2>I would like to recommend someone to join the platform as a guide.</h2>
              <p>Great! Please send their details and your recommendation to <a
                href="mailto:namaste@lemongrass.life">namaste@lemongrass.life.</a></p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const {bool} = PropTypes;

FAQ.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
  scrollingDisabled: isScrollingDisabled(state),
});

const FAQPage = compose(
  connect(mapStateToProps),
  injectIntl
)(FAQ);

export default FAQPage;
