/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, guard-for-in, prefer-template
*/
import * as Corelib from '../red/esm/stdlib/corelib-esm'
import { createGregor } from './calendarAdapter'
import { featFlags } from '../red/featureFlags'

//const {isArr} = Corelib
//const {wassert} = Corelib.Debug
const {createPerfTimer} = Corelib.Tardis

export const consolidateTimeSlots = pars => {
  const logOn = true && featFlags.isDev
  const timer = createPerfTimer()
  const {listingTimeSlots, masterCalendarTimeSlots, aggregatedBookingsList, groupReservedArr} = pars
  //wassert(isArr(aggregatedBookingsList)) // FIX XXXXXXXXX  !!!!!
  const masterCalValid = masterCalendarTimeSlots?.getPropertyCnt()
  const listingValid = listingTimeSlots?.getPropertyCnt()
  if (!masterCalValid && !listingValid) {
    logOn && console.log(`🪓consolidateTSs no valid calendar!`)
    return {}
  }
  logOn && console.group(`🪓consolidateTSs valid!`, {masterCalValid, listingValid})

  const gregMaster = createGregor({origin: masterCalendarTimeSlots, name: 'masterCalendar'})
  const gregListing = createGregor({origin: listingTimeSlots, name: 'listingCalendar'})
  const gregAggrPriv = createGregor({origin: aggregatedBookingsList, name: 'aggrPrivBookings'})
  const gregAggrGroup = createGregor({origin: groupReservedArr, name: 'aggrGroupResList(arr)'})

  // INF We could simply duplicate gregMaster/gregListing here (but there is no duplicate function yet).
  // INF So we recreate them as we want to keep gregMaster/gregListing immutable for the form.
  // INF Only gregFinal will be mutable for the subtractions.

  const gregFinal = gregMaster.slotCnt
    ? createGregor({origin: masterCalendarTimeSlots, name: 'finalFromMasterCalendar.mutable'})
    : createGregor({origin: listingTimeSlots, name: 'final.fromListingCalendar.mutable'})

  // gregAggrGroup.slotCnt && gregFinal.subtract(gregAggrGroup)// for #233 "Double Booking"
  gregAggrPriv.slotCnt && gregFinal.subtract(gregAggrPriv)

  const finalTimeSlots = gregFinal.convertToMonthlyTimeSlots()

  if (logOn) {
    const gregCnts = [gregFinal, gregAggrPriv, gregAggrGroup].map(greg => greg.slotCnt)
    const realGregs = gregCnts.filter(Boolean)
    realGregs.length && console.log(`🪓consolidateTSs (gr=${timer.sum().dur.sum}ms)`, gregCnts.join('/'),
      {gregFinal, gregAggrPriv, gregAggrGroup})
    console.groupEnd()
  }
  return {finalTimeSlots, gregAggrPriv, gregAggrGroup, gregMaster, gregListing, gregFinal}
}
