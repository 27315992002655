/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from "react";
import { node, number, object, shape, string } from "prop-types";
import { compose } from "redux";
import { FormattedMessage } from "../../util/reactIntl";
import { withViewport } from "../../util/contextHelpers";
import { LayoutWrapperSideNav } from "..";
import { isCustomer } from "../../util/user";

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = currentTab => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: "end",
      inline: "end",
      behavior: "smooth",
    });
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentTab, viewport, currentUser } = props;

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains("fontsLoaded");

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
    hasScrolledToTab = true;
  }

  let tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.IdentityTabTitle" />,
      selected: currentTab === "IdentityPage",
      id: "IdentityPageTab",
      linkProps: {
        name: "IdentityPage",
      },
    },
    {
      text: (
        <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.PayoutAndInsuranceTabTitle" />
      ),
      selected: currentTab === "PayoutAndInsurancePage",
      id: "PayoutAndInsuranceTab",
      linkProps: {
        name: "PayoutAndInsurancePage",
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
      selected: currentTab === "ContactDetailsPage",
      id: "ContactDetailsPageTab",
      linkProps: {
        name: "ContactDetailsPage",
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentTab === "PasswordChangePage",
      id: "PasswordChangePageTab",
      linkProps: {
        name: "PasswordChangePage",
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.guideAvailabilityTabTitle" />,
      selected: currentTab === "GuideAvailabilityPage",
      id: "GuideAvailabilityTab",
      linkProps: {
        name: "GuideAvailabilityPage",
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
      selected: currentTab === "StripePayoutPage",
      id: "StripePayoutPageTab",
      linkProps: {
        name: "StripePayoutPage",
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentTab === "PaymentMethodsPage",
      id: "PaymentMethodsPageTab",
      linkProps: {
        name: "PaymentMethodsPage",
      },
    },
  ];

  // Remove the payout tab (to hide from practitioners after sign up and customers (always))
  const hidePayout =
    isCustomer(currentUser) ||
    !!currentUser?.attributes.profile.protectedData.practitioner_initialSetupComplete;
  if (hidePayout) {
    tabs = tabs.filter(tab => tab.id !== "StripePayoutPageTab");
  }

  if (isCustomer(currentUser)) {
    tabs = tabs.filter(tab => !["IdentityPageTab", "PayoutAndInsuranceTab", "GuideAvailabilityTab"].includes(tab.id));
  } else {
    // Practitioner branch: uncommenting this we can hide the practitioner's payment panel
    //tabs = tabs.filter(tab => !["PaymentMethodsPageTab"].includes(tab.id))
  }
  // console.log(`acc settings tabs`, {tabs})

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
  currentUser: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  currentUser: object,
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent,
);

export default LayoutWrapperAccountSettingsSideNav;
