import {User} from "./users";

const getAreaFromAddress = (address) => {
  const addressParts = address
    .split(',')
    .map(part => part.trim());
  switch (addressParts.length) {
    case 0:
      return "";
    case 1:
      return addressParts.join(", ");
    case 2:
      return addressParts.slice(0, 1).join(", ")
    case 3:
      return addressParts.slice(1, 3).join(", ")
    default:
      return addressParts.slice(-3, -1).join(", ")
  }
};

/**
 * Backwards compatible flattened Listing object that provides access to common fields.
 *
 * Assumes nested data within the Listing object does not use reserved ShareTribe keywords
 * e.g. attributes, author etc.. otherwise, these values will be overwritten with Sharetribe
 * defaults.
 *
 * Note: this does not contain all available fields from the Listing data, just those that the
 * application expects to display. In the future, it might be worth considering a data layer that
 * provides write access to ShareTribe.
 *
 * @param listing
 * @constructor
 */
export const Listing = listing => {
  const { title, description, price, publicData = {} } = listing.attributes;

  const events = publicData.events || [];
  const today = new Date();
  const dates = events
    .filter(date => new Date(date) >= today)
    .sort((a,b) => new Date(b.date) - new Date(a.date));

  return {
    title,
    description,
    price,
    city: getAreaFromAddress(publicData.location?.address || ""),
    duration: publicData.duration,
    events: dates,
    earliestBookableDate: dates.length > 0 ? new Date(dates[0]) : null,
    isVerified: publicData.isVerified || false,
    location: publicData.location,
    locationType: publicData.locationType || "UNKNOWN",
    serviceType: publicData.serviceType || "UNKNOWN",
    sessionType: publicData.sessionType || "UNKNOWN",
    specialties: publicData.specialty,
    topics: publicData.topics,
    isOnline: publicData.locationType === "online" || !publicData.location?.address,
    ...listing,
    id: listing.id?.uuid,
    author: User(listing.author),
  }
}
