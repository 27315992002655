import React from 'react';
import {bool, func} from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl';
import {propTypes} from '../../util/types';
import {ensureCurrentUser} from '../../util/data';
import {fetchCurrentUser, sendVerificationEmail} from '../../ducks/user.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import {ContactDetailsForm} from '../../forms';
import {TopbarContainer} from "..";
import {isScrollingDisabled} from '../../ducks/UI.duck';
import {
  resetPassword,
  saveContactDetails,
  saveContactDetailsClear,
} from './ContactDetailsPage.duck';
import css from './ContactDetailsPage.module.css';
import {isPractitioner} from "../../util/user";

export const ContactDetailsPageComponent = props => {
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    currentUserListing,
    contactDetailsChanged,
    onChange,
    scrollingDisabled,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSubmitContactDetails,
    onResetPassword,
    resetPasswordInProgress,
    resetPasswordError,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email || '';
  const protectedData = user.attributes.profile.protectedData || {};
  const currentPhoneNumber = protectedData.phoneNumber || '';
  const contactInfoForm = user.id ? (
    <ContactDetailsForm
      className={css.form}
      currentUser={currentUser}
      initialValues={{
        email: currentEmail,
        phoneNumber: currentPhoneNumber,
        acceptMarketingCommunication: user.attributes.privateData?.acceptMarketingCommunication,
      }}
      inProgress={saveContactDetailsInProgress}
      onChange={onChange}
      onResendVerificationEmail={onResendVerificationEmail}
      onResetPassword={onResetPassword}
      onSubmit={values => onSubmitContactDetails({...values, currentEmail, currentPhoneNumber})}
      ready={contactDetailsChanged}
      resetPasswordError={resetPasswordError}
      resetPasswordInProgress={resetPasswordInProgress}
      saveEmailError={saveEmailError}
      savePhoneNumberError={savePhoneNumberError}
      sendVerificationEmailError={sendVerificationEmailError}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
    />
  ) : null;

  const title = intl.formatMessage({id: 'ContactDetailsPage.title'});

  return (
    <Page scrollingDisabled={scrollingDisabled} title={title}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ContactDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          { isPractitioner(user) && <UserNav listing={currentUserListing} selectedPageName="ContactDetailsPage"/>}
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="ContactDetailsPage"
                                             currentUser={currentUser}/>
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="ContactDetailsPage.heading"/>
            </h1>
            {contactInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ContactDetailsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

ContactDetailsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    currentUser,
    currentUserListing,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    resetPasswordInProgress,
    resetPasswordError,
  } = state.ContactDetailsPage;
  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    currentUserListing,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(saveContactDetailsClear()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
  onResetPassword: values => dispatch(resetPassword(values)),
});

const ContactDetailsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ContactDetailsPageComponent);

ContactDetailsPage.loadData = () =>
  // Since verify email happens in separate tab, current user's data might be updated
  fetchCurrentUser()
;

export default ContactDetailsPage;
