/* eslint-env browser, node, worker */
/* eslint-disable no-debugger, spaced-comment, no-multi-spaces, no-unused-vars, no-return-assign,
   valid-typeof, object-curly-spacing, no-trailing-spaces, indent, new-cap, object-property-newline,
   block-spacing, comma-spacing, handle-callback-err,  camelcase, multiline-ternary, quotes, prefer-template */

//: [Reduced]

import { globalThis } from './global'

export const brexru = _ => {
  return _
}

export const wassert = (assertion, fallback) => assertion || brexru(assertion) || fallback

wassert(globalThis)

export const weject = denied => denied && brexru(denied)

export const wejectNaN = num => weject(Number.isNaN(num)) || num

export const sfx = (assertion, ret, ...args) => assertion === 'skip'
  ? sfx(ret, ret, ...args)
  : assertion
    ? ret
    : brexru(console.log('sfx args:', ...args)) || ret

export const loxru = (proc, msg) => console.log((proc(), 'loxru proc called: ' + msg))

Error.stackTraceLimit = Infinity
