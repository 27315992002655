/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import * as analytics from './analytics/analytics';
import config from './config';

/**
 * Create a new store with the given initial state. Adds Redux
 * middleware and enhancers.
 */
export default function configureStore(initialState = {}, sdk = null, analyticsHandlers = []) {
  // eslint-disable-next-line no-unused-expressions
  // TODO handle including drafts
  (function waitForSdk() { /* eslint-disable no-param-reassign */
    if (sdk) {
      sdk._ownListings = { ...sdk.ownListings };
      // ownListings query cannot filter by pub_ parameters, so this patches through
      // the normal listings endpoint
      sdk.ownListings.query = (async function query(q = {}) {
        const currentUser = await this.currentUser.show();
        const userId = currentUser.data.data.id.uuid;

        const ownListings = await this.listings.query({...q, author_id: userId});

        const _ownListings = await this._ownListings.query({});
        const hiddenOwnListings = _ownListings.data && _ownListings.data.data.filter(listing => {
          const listingState = listing.attributes.state;
          const isHidden = listingState === "draft" || listingState === "pendingApproval";

          if (q.pub_sessionType) {
            return isHidden && q.pub_sessionType === listing.attributes.publicData?.sessionType;
          }

          return isHidden;
        });

        ownListings.data.data.forEach(l => {
          l.type = "ownListing";
        });

        ownListings.data.data = [...ownListings.data.data, ...hiddenOwnListings ];

        const result = { ...ownListings };

        return result;
      }).bind(sdk);
    } else {
      setTimeout(waitForSdk, 250);
    }
  })();

  const middlewares = [thunk.withExtraArgument(sdk), analytics.createMiddleware(analyticsHandlers)];

  // Enable Redux Devtools in client side dev mode.
  const composeEnhancers =
    config.dev && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(createReducer(), initialState, enhancer);

  return store;
}
