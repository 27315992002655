/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, comma-dangle, quotes, no-return-assign, import/no-unresolved
*/
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Form as FinalForm } from 'react-final-form'
import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl'
import * as validators from '../../util/validators'
import { ensureCurrentUser } from '../../util/data'
import { extractUser } from '../../models/userAdapter'
import { FormContext } from '../../util/userForm'
import {
  //FieldCheckbox,
  FieldCheckboxGroup,
  FieldRadioButton,
  //FieldSelect,
  //FieldTextInput,
  Form,
  PrimaryButton,
  ProfileEditingDisabledMessage,
} from '../../components'
import allCountries from '../../countries'
import { pathByRouteName } from '../../util/routes'
import routeConfiguration from '../../routeConfiguration'
import css from './IdentityForm.module.css'
import { featFlags } from '../../red/featureFlags'

let Red = featFlags.isSaveDebug && import('../../red/red').then(({Red: _}) => (Red = _).init())

const idTypes = [
  {
    code: 'Passport',
    value: 'Passport',
  },
  {
    code: 'DriversLicense',
    value: `Driver's License`,
  },
  {
    code: 'CountryID',
    value: 'Country ID',
  },
]

const IdentityFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        currentUser,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        pristine,
        values,
        profileEditingDisabled,
      } = fieldRenderProps

      const user = ensureCurrentUser(currentUser)

      if (!user.id) {
        return null
      }

      const {protectedData, privateData, profile} = extractUser(user)
      const {
        getTextComponent, getCheckComponent, getSelectComponent, getSelectOptions,
        SectionTitle
      } = FormContext({css, validators, intl, formId, langPrefix: 'IdentityForm'})

      const individualAccountLabel = intl.formatMessage({id: 'PayoutDetailsForm.individualAccount'})
      const companyAccountLabel = intl.formatMessage({id: 'PayoutDetailsForm.companyAccount'})

      const companyCountry = getSelectComponent(privateData, 'companyCountry', {}, {
        autoComplete: 'companyCountry',
        className: css.selectCountry,
        children: getSelectOptions(allCountries, 'CountryNames', 'code2', 'name')
      })

      const firstName = getTextComponent(profile, 'firstName')
      const lastName = getTextComponent(profile, 'lastName')
      const businessName = getTextComponent(protectedData, 'businessName')
      const companyNumber = getTextComponent(privateData, 'companyNumber')
      const vatNumber = getTextComponent(privateData, 'vatNumber')
      const idNumber = getTextComponent(privateData, 'idNumber')

      const idType = getSelectComponent(privateData, 'idType', {}, {
        className: css.selectCountry,
        children: getSelectOptions(idTypes, 'IdentityForm.idType', 'code', 'value')
      })

      const idIssuingCountry = getSelectComponent(privateData, 'idIssuingCountry', {}, {
        className: css.selectCountry,
        children: getSelectOptions(allCountries, 'CountryNames', 'code2', 'name')
      })

      // Terms and conditions
      // const termsUrl = pathByRouteName('TermsOfServicePage', routeConfiguration())
      // const termsLink = (
      //   <a href={termsUrl} rel='noreferrer' target='_blank'>
      //     <FormattedMessage id='IdentityForm.acceptTermsLinkText' />
      //   </a>
      // )
      // const acceptTerms = getCheckComponent(values, 'acceptTerms', {fmtExtra: {termsLink}})

      const acceptMarketingCommunication = getCheckComponent(values, 'acceptMarketingCommunication', {noreq: true})

      const classes = classNames(rootClassName || css.root, className)
      const submitDisabled = invalid || profileEditingDisabled || pristine

      const debugProps = {submitDisabled, acceptTerms: values.acceptTerms, invalid, profileEditingDisabled, accountType: values.accountType, pristine}

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ProfileEditingDisabledMessage profileEditingDisabled={profileEditingDisabled} />

          <div className={css.sectionContainer}>
            <SectionTitle id='PayoutDetailsForm.accountTypeTitle' />
            <div className={css.radioButtonRow}>
              <FieldRadioButton
                id='individual'
                label={individualAccountLabel}
                name='accountType'
                showAsRequired
                value='individual'
              />
              <FieldRadioButton
                id='company'
                label={companyAccountLabel}
                name='accountType'
                showAsRequired
                value='company'
              />
            </div>
          </div>
          {values.accountType === 'company' && (
            <div className={css.sectionContainer}>
              <SectionTitle id='IdentityForm.businessDetailsTitle' />
              <div className={css.fieldContainer}>{businessName.textComponent}</div>
              <div className={css.fieldContainer}>{companyNumber.textComponent}</div>
              <div className={css.fieldContainer}>{companyCountry.selectComponent}</div>
              <div className={css.fieldContainer}>{vatNumber.textComponent}</div>
            </div>
          )}
          <div className={css.sectionContainer}>
            <SectionTitle id='IdentityForm.legalNameSectionTitle' />
            <div className={css.fieldContainer}>{firstName.textComponent}</div>
            <div className={css.fieldContainer}>{lastName.textComponent}</div>
          </div>
          <div className={css.sectionContainer}>
            <SectionTitle id='IdentityForm.idDetailsSectionTitle' />
            <div className={css.fieldContainer}>{idType.selectComponent}</div>
            <div className={css.fieldContainer}>{idIssuingCountry.selectComponent}</div>
            <div className={css.fieldContainer}>{idNumber.textComponent}</div>
          </div>

          <div className={css.sectionContainer}>
            <SectionTitle id='IdentityForm.sessionTypesSectionTitle' />
            <FieldCheckboxGroup
              id={formId ? `${formId}.plannedsessionTypes` : 'plannedsessionTypes'}
              label=""
              name='plannedsessionTypes'
              options={[{
                key: 'private',
                label: intl.formatMessage({id: 'IdentityForm.plannedsessionTypesPrivateLabel'}),
              }, {
                key: 'ticketed',
                label: intl.formatMessage({id: 'IdentityForm.plannedsessionTypesTicketedLabel'}),
              }]}
            />
          </div>

          <div className={css.sectionContainer}>
            <SectionTitle id='IdentityForm.acceptMarketingCommunicationSectionTitle' />
            {acceptMarketingCommunication.checkComponent}
          </div>

          <div className={css.bottomWrapper}>
            <PrimaryButton disabled={submitDisabled} inProgress={inProgress} type='submit'>
              <FormattedMessage id='IdentityForm.saveChanges' />
            </PrimaryButton>
            {Red.on ? <Red.FormSaveButton {...{debugProps}} /> : ''}
          </div>
        </Form>
      )
    }}
  />
)

IdentityFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
}

const { bool, string } = PropTypes

IdentityFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  profileEditingDisabled: bool.isRequired,
}

const IdentityForm = compose(injectIntl)(IdentityFormComponent)

IdentityForm.displayName = 'IdentityForm'

export default IdentityForm
