import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from '../../util/reactIntl';
import Gate from "../Gate/Gate"
import css from './SectionSocial.module.css';
import {Icon} from '@iconify/react';
import instagramFill from '@iconify/icons-akar-icons/instagram-fill';
import clubhouseIcon from '@iconify-icons/simple-icons/clubhouse';
import mediumM from '@iconify/icons-cib/medium-m';


export const SocialLink = ({message, icon, link, size }) => {

  const iconMap = {
    "instagram": instagramFill,
    "clubhouse": clubhouseIcon,
    "medium": mediumM,
  }

  const sizes = {
    "small": "24px",
    "large": "84px",
  }

  const textSize = {
    "small": "12px",
    "large": "20px",
  }

  return (
    <a href={link} rel="noreferrer" target="_blank">
      <span className={css.socialLink}>
        <Icon icon={iconMap[icon]} style={{color: '#ff6353', fontSize: sizes[size]}}/>
        <span className={css.message} style={{fontSize: textSize[size]}}>
          <FormattedMessage id={message}/>
        </span>
      </span>
    </a>
  )
};

SocialLink.defaultProps = {
  size: "large",
}

const SectionSocial = props => {
  const {rootClassName, className} = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.leftContainer}>
        <div className={css.title}>
          <FormattedMessage id="LandingPageSectionSocial.title"/>
        </div>
        <div className={css.subtitle}>
          <FormattedMessage id="LandingPageSectionSocial.subtitle"/>
        </div>
        <Gate className={css.backgroundGate}/>
      </div>
      <div className={css.links}>
        <SocialLink link="https://www.clubhouse.com/join/shift-your-padadigm/LfgQSNVp" icon="clubhouse" message="LandingPageSectionSocial.clubhouse"/>
        <SocialLink link="https://www.instagram.com/lemongrass.life" icon="instagram" message="LandingPageSectionSocial.instagram"/>
        <SocialLink link="https://link.medium.com/wzgWqT9TAjb" icon="medium" message="LandingPageSectionSocial.medium"/>
      </div>
    </div>
  );
};

SectionSocial.defaultProps = {rootClassName: null, className: null};

const {string} = PropTypes;

SectionSocial.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSocial;
