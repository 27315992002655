export default [
  {
    code2: "AF",
    code3: "AFG",
    name: "Afghanistan",
    currencyName: "Afghanistan Afghani",
    currencyCode: "AFN",
  },
  {
    code2: "AX",
    code3: "ALA",
    name: "Åland Islands",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "AL",
    code3: "ALB",
    name: "Albania",
    currencyName: "Albanian Lek",
    currencyCode: "ALL",
  },
  {
    code2: "DZ",
    code3: "DZA",
    name: "Algeria",
    currencyName: "Algerian Dinar",
    currencyCode: "DZD",
  },
  {
    code2: "AS",
    code3: "ASM",
    name: "American Samoa",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    code2: "AD",
    code3: "AND",
    name: "Andorra",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "AO",
    code3: "AGO",
    name: "Angola",
    currencyName: "Angolan Kwanza",
    currencyCode: "AOA",
  },
  {
    code2: "AI",
    code3: "AIA",
    name: "Anguilla",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "AQ",
    code3: "ATA",
    name: "Antarctica",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "AG",
    code3: "ATG",
    name: "Antigua and Barbuda",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "AR",
    code3: "ARG",
    name: "Argentina",
    currencyName: "Argentine Peso",
    currencyCode: "ARS",
  },
  {
    code2: "AM",
    code3: "ARM",
    name: "Armenia",
    currencyName: "Armenian Dram",
    currencyCode: "AMD",
  },
  {
    code2: "AW",
    code3: "ABW",
    name: "Aruba",
    currencyName: "Aruban Guilder",
    currencyCode: "AWG",
  },
  {
    code2: "AU",
    code3: "AUS",
    name: "Australia",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "AT",
    code3: "AUT",
    name: "Austria",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "AZ",
    code3: "AZE",
    name: "Azerbaijan",
    currencyName: "Azerbaijan New Manat",
    currencyCode: "AZN",
  },
  {
    code2: "BS",
    code3: "BHS",
    name: "Bahamas",
    currencyName: "Bahamian Dollar",
    currencyCode: "BSD",
  },
  {
    code2: "BH",
    code3: "BHR",
    name: "Bahrain",
    currencyName: "Bahraini Dinar",
    currencyCode: "BHD",
  },
  {
    code2: "BD",
    code3: "BGD",
    name: "Bangladesh",
    currencyName: "Bangladeshi Taka",
    currencyCode: "BDT",
  },
  {
    code2: "BB",
    code3: "BRB",
    name: "Barbados",
    currencyName: "Barbados Dollar",
    currencyCode: "BBD",
  },
  {
    code2: "BY",
    code3: "BLR",
    name: "Belarus",
    currencyName: "Belarussian Ruble",
    currencyCode: "BYR",
  },
  {
    code2: "BE",
    code3: "BEL",
    name: "Belgium",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "BZ",
    code3: "BLZ",
    name: "Belize",
    currencyName: "Belize Dollar",
    currencyCode: "BZD",
  },
  {
    code2: "BJ",
    code3: "BEN",
    name: "Benin",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "BM",
    code3: "BMU",
    name: "Bermuda",
    currencyName: "Bermudian Dollar",
    currencyCode: "BMD",
  },
  {
    code2: "BT",
    code3: "BTN",
    name: "Bhutan",
    currencyName: "Bhutan Ngultrum",
    currencyCode: "BTN",
  },
  {
    code2: "BO",
    code3: "BOL",
    name: "Bolivia",
    currencyName: "Boliviano",
    currencyCode: "BOB",
  },
  {
    code2: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    currencyName: "United States Dollar",
    currencyCode: "USD",
  },
  {
    code2: "BA",
    code3: "BIH",
    name: "Bosnia and Herzegovina",
    currencyName: "",
    currencyCode: "BAM",
  },
  {
    code2: "BW",
    code3: "BWA",
    name: "Botswana",
    currencyName: "Botswana Pula",
    currencyCode: "BWP",
  },
  {
    code2: "BV",
    code3: "BVT",
    name: "Bouvet Island",
    currencyName: "Norwegian Krone",
    currencyCode: "NOK",
  },
  {
    code2: "BR",
    code3: "BRA",
    name: "Brazil",
    currencyName: "Brazilian Real",
    currencyCode: "BRL",
  },
  {
    code2: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    code2: "BN",
    code3: "BRN",
    name: "Brunei",
    currencyName: "",
    currencyCode: "BND",
  },
  {
    code2: "BG",
    code3: "BGR",
    name: "Bulgaria",
    currencyName: "Bulgarian Lev",
    currencyCode: "BGN",
  },
  {
    code2: "BF",
    code3: "BFA",
    name: "Burkina Faso",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "BI",
    code3: "BDI",
    name: "Burundi",
    currencyName: "Burundi Franc",
    currencyCode: "BIF",
  },
  {
    code2: "KH",
    code3: "KHM",
    name: "Cambodia",
    currencyName: "Kampuchean Riel",
    currencyCode: "KHR",
  },
  {
    code2: "CM",
    code3: "CMR",
    name: "Cameroon",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    code2: "CA",
    code3: "CAN",
    name: "Canada",
    currencyName: "Canadian Dollar",
    currencyCode: "CAD",
  },
  {
    code2: "CV",
    code3: "CPV",
    name: "Cape Verde",
    currencyName: "Cape Verde Escudo",
    currencyCode: "CVE",
  },
  {
    code2: "KY",
    code3: "CYM",
    name: "Cayman Islands",
    currencyName: "Cayman Islands Dollar",
    currencyCode: "KYD",
  },
  {
    code2: "CF",
    code3: "CAF",
    name: "Central African Republic",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    code2: "TD",
    code3: "TCD",
    name: "Chad",
    currencyName: "Central African CFA Franc",
    currencyCode: "XAF",
  },
  {
    code2: "CL",
    code3: "CHL",
    name: "Chile",
    currencyName: "Chilean Peso",
    currencyCode: "CLP",
  },
  {
    code2: "CN",
    code3: "CHN",
    name: "China",
    currencyName: "Yuan Renminbi",
    currencyCode: "CNY",
  },
  {
    code2: "CX",
    code3: "CXR",
    name: "Christmas Island",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "CC",
    code3: "CCK",
    name: "Cocos (Keeling) Islands",
    currencyName: " Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "CO",
    code3: "COL",
    name: "Colombia",
    currencyName: "Colombian Peso",
    currencyCode: "COP",
  },
  {
    code2: "KM",
    code3: "COM",
    name: "Comoros",
    currencyName: "Comoros Franc",
    currencyCode: "KMF",
  },
  {
    code2: "CG",
    code3: "COG",
    name: "Congo",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    code2: "CK",
    code3: "COK",
    name: "Cook Islands",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    code2: "CR",
    code3: "CRI",
    name: "Costa Rica",
    currencyName: "Costa Rican Colon",
    currencyCode: "CRC",
  },
  {
    code2: "HR",
    code3: "HRV",
    name: "Croatia",
    currencyName: "Croatian Kuna",
    currencyCode: "HRK",
  },
  {
    code2: "CU",
    code3: "CUB",
    name: "Cuba",
    currencyName: "Cuban Peso",
    currencyCode: "CUP",
  },
  {
    code2: "CW",
    code3: "CUW",
    name: "Curaçao",
    currencyName: "Netherlands Antillean guilder",
    currencyCode: "ANG",
  },
  {
    code2: "CY",
    code3: "CYP",
    name: "Cyprus",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "CZ",
    code3: "CZE",
    name: "Czech Republic",
    currencyName: "",
    currencyCode: "CZK",
  },
  {
    code2: "DK",
    code3: "DNK",
    name: "Denmark",
    currencyName: "Danish Krone",
    currencyCode: "DKK",
  },
  {
    code2: "DJ",
    code3: "DJI",
    name: "Djibouti",
    currencyName: "Djibouti Franc",
    currencyCode: "DJF",
  },
  {
    code2: "DM",
    code3: "DMA",
    name: "Dominica",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "DO",
    code3: "DOM",
    name: "Dominican Republic",
    currencyName: "Dominican Peso",
    currencyCode: "DOP",
  },
  {
    code2: "EC",
    code3: "ECU",
    name: "Ecuador",
    currencyName: "Ecuador Sucre",
    currencyCode: "ECS",
  },
  {
    code2: "EG",
    code3: "EGY",
    name: "Egypt",
    currencyName: "Egyptian Pound",
    currencyCode: "EGP",
  },
  {
    code2: "SV",
    code3: "SLV",
    name: "El Salvador",
    currencyName: "El Salvador Colon",
    currencyCode: "SVC",
  },
  {
    code2: "GQ",
    code3: "GNQ",
    name: "Equatorial Guinea",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    code2: "ER",
    code3: "ERI",
    name: "Eritrea",
    currencyName: "Eritrean Nakfa",
    currencyCode: "ERN",
  },
  {
    code2: "EE",
    code3: "EST",
    name: "Estonia",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "ET",
    code3: "ETH",
    name: "Ethiopia",
    currencyName: "Ethiopian Birr",
    currencyCode: "ETB",
  },
  {
    code2: "FK",
    code3: "FLK",
    name: "Falkland Islands",
    currencyName: "Falkland Islands Pound",
    currencyCode: "FKP",
  },
  {
    code2: "FO",
    code3: "FRO",
    name: "Faroe Islands",
    currencyName: " Danish Krone",
    currencyCode: "DKK",
  },
  {
    code2: "FJ",
    code3: "FJI",
    name: "Fiji Islands",
    currencyName: "",
    currencyCode: "FJD",
  },
  {
    code2: "FI",
    code3: "FIN",
    name: "Finland",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "FR",
    code3: "FRA",
    name: "France",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "GF",
    code3: "GUF",
    name: "French Guiana",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "PF",
    code3: "PYF",
    name: "French Polynesia",
    currencyName: "",
    currencyCode: "XPF",
  },
  {
    code2: "TF",
    code3: "ATF",
    name: "French Southern territories",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "GA",
    code3: "GAB",
    name: "Gabon",
    currencyName: "CFA Franc BEAC",
    currencyCode: "XAF",
  },
  {
    code2: "GM",
    code3: "GMB",
    name: "Gambia",
    currencyName: "Gambian Dalasi",
    currencyCode: "GMD",
  },
  {
    code2: "GE",
    code3: "GEO",
    name: "Georgia",
    currencyName: "Georgian Lari",
    currencyCode: "GEL",
  },
  {
    code2: "DE",
    code3: "DEU",
    name: "Germany",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "GH",
    code3: "GHA",
    name: "Ghana",
    currencyName: "Ghanaian Cedi",
    currencyCode: "GHS",
  },
  {
    code2: "GI",
    code3: "GIB",
    name: "Gibraltar",
    currencyName: "Gibraltar Pound",
    currencyCode: "GIP",
  },
  {
    code2: "GR",
    code3: "GRC",
    name: "Greece",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "GL",
    code3: "GRL",
    name: "Greenland",
    currencyName: "Danish Krone",
    currencyCode: "DKK",
  },
  {
    code2: "GD",
    code3: "GRD",
    name: "Grenada",
    currencyName: "East Carribean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "GP",
    code3: "GLP",
    name: "Guadeloupe",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "GU",
    code3: "GUM",
    name: "Guam",
    currencyName: "",
    currencyCode: "USD",
  },
  {
    code2: "GT",
    code3: "GTM",
    name: "Guatemala",
    currencyName: "Guatemalan Quetzal",
    currencyCode: "QTQ",
  },
  {
    code2: "GG",
    code3: "GGY",
    name: "Guernsey",
    currencyName: "Pound sterling",
    currencyCode: "GBP",
  },
  {
    code2: "GN",
    code3: "GIN",
    name: "Guinea",
    currencyName: "Guinea Franc",
    currencyCode: "GNF",
  },
  {
    code2: "GW",
    code3: "GNB",
    name: "Guinea-Bissau",
    currencyName: "CFA Franc",
    currencyCode: "CFA",
  },
  {
    code2: "GY",
    code3: "GUY",
    name: "Guyana",
    currencyName: "Guyana Dollar",
    currencyCode: "GYD",
  },
  {
    code2: "HT",
    code3: "HTI",
    name: "Haiti",
    currencyName: "Haitian Gourde",
    currencyCode: "HTG",
  },
  {
    code2: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "VA",
    code3: "VAT",
    name: "Holy See (Vatican City State)",
    currencyName: "",
    currencyCode: "EUR",
  },
  {
    code2: "HN",
    code3: "HND",
    name: "Honduras",
    currencyName: "Honduran Lempira",
    currencyCode: "HNL",
  },
  {
    code2: "HK",
    code3: "HKG",
    name: "Hong Kong",
    currencyName: "Hong Kong Dollar",
    currencyCode: "HKD",
  },
  {
    code2: "HU",
    code3: "HUN",
    name: "Hungary",
    currencyName: "Hungarian Forint",
    currencyCode: "HUF",
  },
  {
    code2: "IS",
    code3: "ISL",
    name: "Iceland",
    currencyName: "Iceland Krona",
    currencyCode: "ISK",
  },
  {
    code2: "IN",
    code3: "IND",
    name: "India",
    currencyName: "Indian Rupee",
    currencyCode: "INR",
  },
  {
    code2: "ID",
    code3: "IDN",
    name: "Indonesia",
    currencyName: "Indonesian Rupiah",
    currencyCode: "IDR",
  },
  {
    code2: "IR",
    code3: "IRN",
    name: "Iran",
    currencyName: "Iranian Rial",
    currencyCode: "IRR",
  },
  {
    code2: "IQ",
    code3: "IRQ",
    name: "Iraq",
    currencyName: "Iraqi Dinar",
    currencyCode: "IQD",
  },
  {
    code2: "IE",
    code3: "IRL",
    name: "Ireland",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "IM",
    code3: "IMN",
    name: "Isle of Man",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "IL",
    code3: "ISR",
    name: "Israel",
    currencyName: "Israeli New Shekel",
    currencyCode: "ILS",
  },
  {
    code2: "IT",
    code3: "ITA",
    name: "Italy",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "CI",
    code3: "CIV",
    name: "Ivory Coast",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "JM",
    code3: "JAM",
    name: "Jamaica",
    currencyName: "Jamaican Dollar",
    currencyCode: "JMD",
  },
  {
    code2: "JP",
    code3: "JPN",
    name: "Japan",
    currencyName: "Japanese Yen",
    currencyCode: "JPY",
  },
  {
    code2: "JE",
    code3: "JEY",
    name: "Jersey",
    currencyName: "Pound sterling",
    currencyCode: "GBP",
  },
  {
    code2: "JO",
    code3: "JOR",
    name: "Jordan",
    currencyName: "Jordanian Dinar",
    currencyCode: "JOD",
  },
  {
    code2: "KZ",
    code3: "KAZ",
    name: "Kazakhstan",
    currencyName: "",
    currencyCode: "KZT",
  },
  {
    code2: "KE",
    code3: "KEN",
    name: "Kenya",
    currencyName: "Kenyan Shilling",
    currencyCode: "KES",
  },
  {
    code2: "KI",
    code3: "KIR",
    name: "Kiribati",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "KW",
    code3: "KWT",
    name: "Kuwait",
    currencyName: "Kuwaiti Dinar",
    currencyCode: "KWD",
  },
  {
    code2: "KG",
    code3: "KGZ",
    name: "Kyrgyzstan",
    currencyName: "Som",
    currencyCode: "KGS",
  },
  {
    code2: "LA",
    code3: "LAO",
    name: "Laos",
    currencyName: "Lao Kip",
    currencyCode: "LAK",
  },
  {
    code2: "LV",
    code3: "LVA",
    name: "Latvia",
    currencyName: "Latvian Lats",
    currencyCode: "LVL",
  },
  {
    code2: "LB",
    code3: "LBN",
    name: "Lebanon",
    currencyName: "Lebanese Pound",
    currencyCode: "LBP",
  },
  {
    code2: "LS",
    code3: "LSO",
    name: "Lesotho",
    currencyName: "Lesotho Loti",
    currencyCode: "LSL",
  },
  {
    code2: "LR",
    code3: "LBR",
    name: "Liberia",
    currencyName: "Liberian Dollar",
    currencyCode: "LRD",
  },
  {
    code2: "LY",
    code3: "LBY",
    name: "Libyan Arab Jamahiriya",
    currencyName: "",
    currencyCode: "LYD",
  },
  {
    code2: "LI",
    code3: "LIE",
    name: "Liechtenstein",
    currencyName: "Swiss Franc",
    currencyCode: "CHF",
  },
  {
    code2: "LT",
    code3: "LTU",
    name: "Lithuania",
    currencyName: "Lithuanian Litas",
    currencyCode: "LTL",
  },
  {
    code2: "LU",
    code3: "LUX",
    name: "Luxembourg",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "MO",
    code3: "MAC",
    name: "Macao",
    currencyName: "",
    currencyCode: "MOP",
  },
  {
    code2: "MG",
    code3: "MDG",
    name: "Madagascar",
    currencyName: "Malagasy Franc",
    currencyCode: "MGF",
  },
  {
    code2: "MW",
    code3: "MWI",
    name: "Malawi",
    currencyName: "Malawi Kwacha",
    currencyCode: "MWK",
  },
  {
    code2: "MY",
    code3: "MYS",
    name: "Malaysia",
    currencyName: "Malaysian Ringgit",
    currencyCode: "MYR",
  },
  {
    code2: "MV",
    code3: "MDV",
    name: "Maldives",
    currencyName: "Maldive Rufiyaa",
    currencyCode: "MVR",
  },
  {
    code2: "ML",
    code3: "MLI",
    name: "Mali",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "MT",
    code3: "MLT",
    name: "Malta",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "MH",
    code3: "MHL",
    name: "Marshall Islands",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    code2: "MQ",
    code3: "MTQ",
    name: "Martinique",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "MR",
    code3: "MRT",
    name: "Mauritania",
    currencyName: "Mauritanian Ouguiya",
    currencyCode: "MRO",
  },
  {
    code2: "MU",
    code3: "MUS",
    name: "Mauritius",
    currencyName: "Mauritius Rupee",
    currencyCode: "MUR",
  },
  {
    code2: "YT",
    code3: "MYT",
    name: "Mayotte",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "MX",
    code3: "MEX",
    name: "Mexico",
    currencyName: "Mexican Nuevo Peso",
    currencyCode: "MXN",
  },
  {
    code2: "FM",
    code3: "FSM",
    name: "Micronesia, Federated States of",
    currencyName: "",
    currencyCode: "USD",
  },
  {
    code2: "MD",
    code3: "MDA",
    name: "Moldova",
    currencyName: "Moldovan Leu",
    currencyCode: "MDL",
  },
  {
    code2: "MC",
    code3: "MCO",
    name: "Monaco",
    currencyName: "",
    currencyCode: "EUR",
  },
  {
    code2: "MN",
    code3: "MNG",
    name: "Mongolia",
    currencyName: "Mongolian Tugrik",
    currencyCode: "MNT",
  },
  {
    code2: "ME",
    code3: "MNE",
    name: "Montenegro",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "MS",
    code3: "MSR",
    name: "Montserrat",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "MA",
    code3: "MAR",
    name: "Morocco",
    currencyName: "Moroccan Dirham",
    currencyCode: "MAD",
  },
  {
    code2: "MZ",
    code3: "MOZ",
    name: "Mozambique",
    currencyName: "Mozambique Metical",
    currencyCode: "MZN",
  },
  {
    code2: "MM",
    code3: "MMR",
    name: "Myanmar",
    currencyName: "Myanmar Kyat",
    currencyCode: "MMR",
  },
  {
    code2: "NA",
    code3: "NAM",
    name: "Namibia",
    currencyName: "Namibian Dollar",
    currencyCode: "NAD",
  },
  {
    code2: "NR",
    code3: "NRU",
    name: "Nauru",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "NP",
    code3: "NPL",
    name: "Nepal",
    currencyName: "Nepalese Rupee",
    currencyCode: "NPR",
  },
  {
    code2: "NL",
    code3: "NLD",
    name: "Netherlands",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "NC",
    code3: "NCL",
    name: "New Caledonia",
    currencyName: "",
    currencyCode: "XPF",
  },
  {
    code2: "NZ",
    code3: "NZL",
    name: "New Zealand",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    code2: "NI",
    code3: "NIC",
    name: "Nicaragua",
    currencyName: "Nicaraguan Cordoba Oro",
    currencyCode: "NIO",
  },
  {
    code2: "NE",
    code3: "NER",
    name: "Niger",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "NG",
    code3: "NGA",
    name: "Nigeria",
    currencyName: "Nigerian Naira",
    currencyCode: "NGN",
  },
  {
    code2: "NU",
    code3: "NIU",
    name: "Niue",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    code2: "NF",
    code3: "NFK",
    name: "Norfolk Island",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    code2: "KP",
    code3: "PRK",
    name: "North Korea",
    currencyName: "North Korean Won",
    currencyCode: "KPW",
  },
  {
    code2: "MK",
    code3: "MKD",
    name: "North Macedonia",
    currencyName: "Denar",
    currencyCode: "MKD",
  },
  {
    code2: "NO",
    code3: "NOR",
    name: "Norway",
    currencyName: "Norwegian Krone",
    currencyCode: "NOK",
  },
  {
    code2: "OM",
    code3: "OMN",
    name: "Oman",
    currencyName: "Omani Rial",
    currencyCode: "OMR",
  },
  {
    code2: "PK",
    code3: "PAK",
    name: "Pakistan",
    currencyName: "Pakistan Rupee",
    currencyCode: "PKR",
  },
  {
    code2: "PW",
    code3: "PLW",
    name: "Palau",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    code2: "PS",
    code3: "PSE",
    name: "Palestine",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "PA",
    code3: "PAN",
    name: "Panama",
    currencyName: "Panamanian Balboa",
    currencyCode: "PAB",
  },
  {
    code2: "PG",
    code3: "PNG",
    name: "Papua New Guinea",
    currencyName: "Papua New Guinea Kina",
    currencyCode: "PGK",
  },
  {
    code2: "PY",
    code3: "PRY",
    name: "Paraguay",
    currencyName: "Paraguay Guarani",
    currencyCode: "PYG",
  },
  {
    code2: "PE",
    code3: "PER",
    name: "Peru",
    currencyName: "Peruvian Nuevo Sol",
    currencyCode: "PEN",
  },
  {
    code2: "PH",
    code3: "PHL",
    name: "Philippines",
    currencyName: "Philippine Peso",
    currencyCode: "PHP",
  },
  {
    code2: "PN",
    code3: "PCN",
    name: "Pitcairn",
    currencyName: "",
    currencyCode: "NZD",
  },
  {
    code2: "PL",
    code3: "POL",
    name: "Poland",
    currencyName: "Polish Zloty",
    currencyCode: "PLN",
  },
  {
    code2: "PT",
    code3: "PRT",
    name: "Portugal",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "PR",
    code3: "PRI",
    name: "Puerto Rico",
    currencyName: "US Dollar",
    currencyCode: "USD",
  },
  {
    code2: "QA",
    code3: "QAT",
    name: "Qatar",
    currencyName: "Qatari Rial",
    currencyCode: "QAR",
  },
  {
    code2: "RE",
    code3: "REU",
    name: "Reunion",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "RO",
    code3: "ROU",
    name: "Romania",
    currencyName: "Romanian New Leu",
    currencyCode: "RON",
  },
  {
    code2: "RU",
    code3: "RUS",
    name: "Russian Federation",
    currencyName: "",
    currencyCode: "RUB",
  },
  {
    code2: "RW",
    code3: "RWA",
    name: "Rwanda",
    currencyName: "Rwanda Franc",
    currencyCode: "RWF",
  },
  {
    code2: "BL",
    code3: "BLM",
    name: "Saint Barthélemy",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "SH",
    code3: "SHN",
    name: "Saint Helena",
    currencyName: "St. Helena Pound",
    currencyCode: "SHP",
  },
  {
    code2: "KN",
    code3: "KNA",
    name: "Saint Kitts and Nevis",
    currencyName: "",
    currencyCode: "XCD",
  },
  {
    code2: "LC",
    code3: "LCA",
    name: "Saint Lucia",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    currencyName: " Euro",
    currencyCode: "EUR",
  },
  {
    code2: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    currencyName: "East Caribbean Dollar",
    currencyCode: "XCD",
  },
  {
    code2: "WS",
    code3: "WSM",
    name: "Samoa",
    currencyName: "Samoan Tala",
    currencyCode: "WST",
  },
  {
    code2: "SM",
    code3: "SMR",
    name: "San Marino",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "ST",
    code3: "STP",
    name: "Sao Tome and Principe",
    currencyName: " Dobra",
    currencyCode: "STD",
  },
  {
    code2: "SA",
    code3: "SAU",
    name: "Saudi Arabia",
    currencyName: "Saudi Riyal",
    currencyCode: "SAR",
  },
  {
    code2: "SN",
    code3: "SEN",
    name: "Senegal",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "RS",
    code3: "SRB",
    name: "Serbia",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "SC",
    code3: "SYC",
    name: "Seychelles",
    currencyName: "Seychelles Rupee",
    currencyCode: "SCR",
  },
  {
    code2: "SL",
    code3: "SLE",
    name: "Sierra Leone",
    currencyName: "Sierra Leone Leone",
    currencyCode: "SLL",
  },
  {
    code2: "SG",
    code3: "SGP",
    name: "Singapore",
    currencyName: "Singapore Dollar",
    currencyCode: "SGD",
  },
  {
    code2: "SX",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
    currencyName: "Netherlands Antillean Guilder",
    currencyCode: "ANG",
  },
  {
    code2: "SK",
    code3: "SVK",
    name: "Slovakia",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "SI",
    code3: "SVN",
    name: "Slovenia",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "SB",
    code3: "SLB",
    name: "Solomon Islands",
    currencyName: "Solomon Islands Dollar",
    currencyCode: "SBD",
  },
  {
    code2: "SO",
    code3: "SOM",
    name: "Somalia",
    currencyName: "Somali Shilling",
    currencyCode: "SOS",
  },
  {
    code2: "ZA",
    code3: "ZAF",
    name: "South Africa",
    currencyName: "South African Rand",
    currencyCode: "ZAR",
  },
  {
    code2: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    currencyName: "Pound Sterling",
    currencyCode: "GBP",
  },
  {
    code2: "KR",
    code3: "KOR",
    name: "South Korea",
    currencyName: "Korean Won",
    currencyCode: "KRW",
  },
  {
    code2: "SS",
    code3: "SSD",
    name: "South Sudan",
    currencyName: "South Sudan Pound",
    currencyCode: "SSP",
  },
  {
    code2: "ES",
    code3: "ESP",
    name: "Spain",
    currencyName: "Euro",
    currencyCode: "EUR",
  },
  {
    code2: "LK",
    code3: "LKA",
    name: "Sri Lanka",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "SD",
    code3: "SDN",
    name: "Sudan",
    currencyName: "Sudanese Pound",
    currencyCode: "SDG",
  },
  {
    code2: "SR",
    code3: "SUR",
    name: "Suriname",
    currencyName: "Surinam Dollar",
    currencyCode: "SRD",
  },
  {
    code2: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    currencyName: "",
    currencyCode: "NOK",
  },
  {
    code2: "SZ",
    code3: "SWZ",
    name: "Swaziland",
    currencyName: "Swaziland Lilangeni",
    currencyCode: "SZL",
  },
  {
    code2: "SE",
    code3: "SWE",
    name: "Sweden",
    currencyName: "Swedish Krona",
    currencyCode: "SEK",
  },
  {
    code2: "CH",
    code3: "CHE",
    name: "Switzerland",
    currencyName: "Swiss Franc",
    currencyCode: "CHF",
  },
  {
    code2: "SY",
    code3: "SYR",
    name: "Syria",
    currencyName: "Syrian Pound",
    currencyCode: "SYP",
  },
  {
    code2: "TW",
    code3: "TWN",
    name: "Taiwan, Province of China",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "TJ",
    code3: "TJK",
    name: "Tajikistan",
    currencyName: "Tajik Somoni",
    currencyCode: "TJS",
  },
  {
    code2: "TZ",
    code3: "TZA",
    name: "Tanzania",
    currencyName: "Tanzanian Shilling",
    currencyCode: "TZS",
  },
  {
    code2: "TH",
    code3: "THA",
    name: "Thailand",
    currencyName: "Thai Baht",
    currencyCode: "THB",
  },
  {
    code2: "CD",
    code3: "COD",
    name: "The Democratic Republic of Congo",
    currencyName: "Franc Congolais",
    currencyCode: "CDF",
  },
  {
    code2: "TL",
    code3: "TLS",
    name: "Timor-Leste",
    currencyName: "",
    currencyCode: "",
  },
  {
    code2: "TG",
    code3: "TGO",
    name: "Togo",
    currencyName: "CFA Franc BCEAO",
    currencyCode: "XOF",
  },
  {
    code2: "TK",
    code3: "TKL",
    name: "Tokelau",
    currencyName: "New Zealand Dollar",
    currencyCode: "NZD",
  },
  {
    code2: "TO",
    code3: "TON",
    name: "Tonga",
    currencyName: "Tongan Pa'anga",
    currencyCode: "TOP",
  },
  {
    code2: "TT",
    code3: "TTO",
    name: "Trinidad and Tobago",
    currencyName: "Trinidad and Tobago Dollar",
    currencyCode: "TTD",
  },
  {
    code2: "TN",
    code3: "TUN",
    name: "Tunisia",
    currencyName: "Tunisian Dollar",
    currencyCode: "TND",
  },
  {
    code2: "TR",
    code3: "TUR",
    name: "Turkey",
    currencyName: "Turkish Lira",
    currencyCode: "TRY",
  },
  {
    code2: "TM",
    code3: "TKM",
    name: "Turkmenistan",
    currencyName: "Manat",
    currencyCode: "TMT",
  },
  {
    code2: "TC",
    code3: "TCA",
    name: "Turks and Caicos Islands",
    currencyName: "United States Dollar",
    currencyCode: "USD",
  },
  {
    code2: "TV",
    code3: "TUV",
    name: "Tuvalu",
    currencyName: "Australian Dollar",
    currencyCode: "AUD",
  },
  {
    code2: "UG",
    code3: "UGA",
    name: "Uganda",
    currencyName: "Uganda Shilling",
    currencyCode: "UGX",
  },
  {
    code2: "UA",
    code3: "UKR",
    name: "Ukraine",
    currencyName: "Ukraine Hryvnia",
    currencyCode: "UAH",
  },
  {
    code2: "AE",
    code3: "ARE",
    name: "United Arab Emirates",
    currencyName: "Arab Emirates Dirham",
    currencyCode: "AED",
  },
  {
    code2: "GB",
    code3: "GBR",
    name: "United Kingdom",
    currencyName: "Pound sterling",
    currencyCode: "GBP",
  },
  {
    code2: "US",
    code3: "USA",
    name: "United States",
    currencyName: "",
    currencyCode: "USD",
  },
  {
    code2: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    currencyName: "United States Dollar",
    currencyCode: "USD",
  },
  {
    code2: "UY",
    code3: "URY",
    name: "Uruguay",
    currencyName: "Uruguayan Peso",
    currencyCode: "UYU",
  },
  {
    code2: "UZ",
    code3: "UZB",
    name: "Uzbekistan",
    currencyName: "Uzbekistan Sum",
    currencyCode: "UZS",
  },
  {
    code2: "VU",
    code3: "VUT",
    name: "Vanuatu",
    currencyName: "Vanuatu Vatu",
    currencyCode: "VUV",
  },
  {
    code2: "VE",
    code3: "VEN",
    name: "Venezuela",
    currencyName: "Venezuelan Bolivar",
    currencyCode: "VEF",
  },
  {
    code2: "VN",
    code3: "VNM",
    name: "Vietnam",
    currencyName: "Vietnamese Dong",
    currencyCode: "VND",
  },
  {
    code2: "VG",
    code3: "VGB",
    name: "Virgin Islands, British",
    currencyName: "United States Dollar",
    currencyCode: "USD",
  },
  {
    code2: "VI",
    code3: "VIR",
    name: "Virgin Islands, U.S.",
    currencyName: "United States Dollar",
    currencyCode: "USD",
  },
  {
    code2: "WF",
    code3: "WLF",
    name: "Wallis and Futuna",
    currencyName: "",
    currencyCode: "XPF",
  },

  {
    code2: "EH",
    code3: "ESH",
    name: "Western Sahara",
    currencyName: "Moroccan Dirham",
    currencyCode: "MAD",
  },
  {
    code2: "YE",
    code3: "YEM",
    name: "Yemen",
    currencyName: "Yemeni Rial",
    currencyCode: "YER",
  },
  {
    code2: "ZM",
    code3: "ZMB",
    name: "Zambia",
    currencyName: "Zambian Kwacha",
    currencyCode: "ZMW",
  },
  {
    code2: "ZW",
    code3: "ZWE",
    name: "Zimbabwe",
    currencyName: "Zimbabwe Dollar",
    currencyCode: "ZWD",
  },
];
