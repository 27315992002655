/* eslint-disable multiline-ternary, spaced-comment, object-curly-spacing, quotes, comma-dangle,
   react/button-has-type, no-return-assign, import/no-unresolved, import/extensions,
   no-unused-expressions
*/
import React, {useEffect, useState} from 'react'
import {arrayOf, bool, func, object, string} from 'prop-types'
import classNames from 'classnames'
import {FormattedMessage} from '../../util/reactIntl'
import {ensureOwnListing} from '../../util/data'
import {getDefaultTimeZoneOnBrowser, timestampToDate} from '../../util/dates'
import {DATE_TYPE_DATETIME, LISTING_STATE_DRAFT, propTypes} from '../../util/types'
import {
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '..'
import {EditListingAvailabilityExceptionForm, EditListingAvailabilityPlanForm} from '../../forms'
import css from './EditListingAvailabilityPanel.module.css'
import {getSdk} from '../../util/sdkHelper'
import NamedLink from "../NamedLink/NamedLink"
import {getBrowserTimeZone} from '../../models/dateAdapter'
import {tabLog, inspectAsTable} from '../../models/misc'
import {featFlags} from '../../red/featureFlags'

const dumpAvailEx = (entries, availabilityExceptions) => {
  const avex = availabilityExceptions.map(({type, attributes: {start, end, seats}}) => ({
    type, seats, start: start?.toString().split(' GMT')[0], end: end?.toString().split(' GMT')[0]
  }))
  entries?.length && inspectAsTable(entries, '#:2,dayOfWeek,seats,startTime,endTime', {hueRot: 270})
  avex?.length && inspectAsTable(avex, '#:2,type:26,seats,start:25,end:25', {hueRot: 180})
}

let Red = featFlags.isAvailDebug && import('../../red/red').then(({Red: _}) => (Red = _).init())

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100

const defaultTimeZone = () => getBrowserTimeZone().timeZone
  //typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC'

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek)

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek)

const Weekday = props => {
  const {availabilityPlan, dayOfWeek, openEditModal} = props
  const hasEntry = findEntry(availabilityPlan, dayOfWeek)

  return ( // eslint-disable-next-line
    <div
      className={classNames(css.weekDay, {[css.blockedWeekDay]: !hasEntry})}
      onClick={() => openEditModal(true)}
      role='button'
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`}/>
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => (
            <span key={`${e.dayOfWeek}${e.startTime}`} className={css.entry}>
              {`${e.startTime} - ${e.endTime === '00:00' ? '24:00' : e.endTime}`}
            </span>))
          : null}
      </div>
    </div>
  )
}

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const {startTime, endTime: endHour, dayOfWeek} = entry
    const dayGroup = groupedEntries[dayOfWeek] || []
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    }
  }, {})

// Create initial values
const createInitialValues = availabilityPlan => {
  //orig
  const {timezone, entries} = availabilityPlan || {}
  const tz = timezone || defaultTimeZone()
  //console.log(`create initial called`, {finalTimeZone: tz, planTimeZone: timezone})

  //const {entries} = availabilityPlan || {}
  //const tz = defaultTimeZone()

  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  }
}

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || []
    const dayEntries = dayValues.map(dayValue => {
      const {startTime, endTime} = dayValue
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
          dayOfWeek,
          seats: 1,
          startTime,
          endTime: endTime === '24:00' ? '00:00' : endTime,
        }
        : null
    })

    return allEntries.concat(dayEntries.filter(e => !!e))
  }, [])

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone, // this is not important
    entries: createEntriesFromSubmitValues(values),
  },
})

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) =>
  a.attributes.start.getTime() - b.attributes.start.getTime()

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    listingTitleOverride,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    durationBased,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
    isGroupSession,
  } = props
  //console.log(`editlistingavailpanel`, {props})
  const {entries} = listing.attributes.availabilityPlan || {}
  const {duration = '-'} = listing?.attributes?.publicData || {}
  // Hooks
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false)
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false)
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null)

  const classes = classNames(rootClassName || css.root, className)
  const currentListing = ensureOwnListing(listing)
  // TODO: Improve the logic
  // const isNextButtonDisabled = !currentListing.attributes.availabilityPlan
  const isNextButtonDisabled = false;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT

  const defaultEntries = isGroupSession
    ? []
    : [
      {dayOfWeek: 'mon', startTime: '00:00', endTime: '24:00', seats: 1},
      {dayOfWeek: 'tue', startTime: '00:00', endTime: '24:00', seats: 1},
      {dayOfWeek: 'wed', startTime: '00:00', endTime: '24:00', seats: 1},
      {dayOfWeek: 'thu', startTime: '00:00', endTime: '24:00', seats: 1},
      {dayOfWeek: 'fri', startTime: '00:00', endTime: '24:00', seats: 1},
      {dayOfWeek: 'sat', startTime: '00:00', endTime: '24:00', seats: 1},
      {dayOfWeek: 'sun', startTime: '00:00', endTime: '24:00', seats: 1},
    ]

  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: defaultEntries,
  }
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan
  const initialValues = valuesFromLastSubmit || createInitialValues(availabilityPlan)
  // console.log('elap constr valuesFromLastSubmit ', valuesFromLastSubmit?.mon?.[0])
  // console.log('elap constr createInitialValues ', createInitialValues(availabilityPlan)?.mon?.[0])
  // console.log('elap constr availabilityPlan ', availabilityPlan?.entries?.[0])
  // console.log('elap constr initialValues ', initialValues?.mon?.[0])
  // console.log(`editlistingavailpanel`, {
  //   currentListing,
  //   availabilityPlan,
  //   availabilityExceptions,
  //   initialValues
  // })
  const debugAvailability = {
    isGroupSession,
    currentListing,
    availabilityPlan,
    availabilityExceptions,
    entries
  }
  const {timezone: timeZone} =  initialValues
  const timeZone3 = timeZone
  //const {timeZone, timeZone3} = getBrowserTimeZone()

  const doLog = true
  if (doLog) {
    tabLog({ml: 80, bg: '#bb0', c: '#00a'}, `\n%c📅EditListingAvailabilityPanel`, {entries, availabilityExceptions})
    dumpAvailEx(entries, availabilityExceptions)
    //console.log(`initialvalues`, {initialValues, timeZone, timeZone3})
    //console.groupEnd()
  }

  useEffect(() => {
    if (!currentListing.attributes.availabilityPlan) {
      onSubmit(createAvailabilityPlan(initialValues))
    }
  }, [])

  useEffect(() => {
    if (isGroupSession) {
      const sdk = getSdk();
      sdk.ownListings
        .update({
          id: currentListing.id,
          publicData: {
            events: availabilityExceptions.map(slot => slot.attributes.start.toISOString()),
          }
        });
    }
  }, [availabilityExceptions])

  const handleSubmit = values => {
    //console.log(`editListingAvPanel.handleSubmit`, values?.mon)
    setValuesFromLastSubmit(values)
/*
    // Final Form can wait for Promises to return.
    return onSubmit(createAvailabilityPlan(values))
      .then(() => {
        setIsEditPlanModalOpen(false)
      })
      .catch(e => {
        // Don't close modal if there was an error
      })
      */
    // Final Form can wait for Promises to return.
    const newAvailabilityPlan = createAvailabilityPlan(values)
    const onSubmitPromise = onSubmit(newAvailabilityPlan)
    //console.log(`editListingAvPanel.handleSubmit inside:`, {newAvailabilityPlan, onSubmitPromise})
    return onSubmitPromise
      .then(() => {
        //console.log(`editListingAvPanel.handleSubmit.then (onSubmit ready)`, newAvailabilityPlan?.entries?.[0])
        setIsEditPlanModalOpen(false)
      })
      .catch(e => {
        // Don't close modal if there was an error
      })
      
  }

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime)
  const canAddDate = isGroupSession ? exceptionCount < 1 : exceptionCount <= MAX_EXCEPTIONS_COUNT

  // Save exception click handler

  // TODO separate groupSession from session here
  const saveException = values => {
    const {availability, seats: rawSeats, exceptionStartTime, exceptionEndTime} = values
    const isAvailable = isGroupSession || availability === 'available'
    const seats = isAvailable ? (rawSeats || 1) : 0

    console.log(`saveException will add exception`, {seats, rawSeats, availability, exceptionStartTime, exceptionEndTime})

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      //seats: seats > 0 ? seats : 999999999,
      //seats: availability === 'available' ? 1 : 0,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        //console.log(`will close exception modal`)
        setIsEditExceptionsModalOpen(false)
      })
      .catch(e => {
        // Don't close modal if there was an error
      })
  }
  const listingType = isGroupSession ? 'groupSession' : 'session'
  const lectStr = isGroupSession ? '.groupSession' : ''

  const WeekDaySelector = () => (
    <section className={css.section} style={{background: '#fdfdff'}}>
      <header className={css.sectionHeader}>
        <h2 className={css.sectionTitle}>
          <FormattedMessage id='EditListingAvailabilityPanel.defaultScheduleTitle'/>
          {timeZone ? ` (${timeZone})` : ''}
        </h2>
        <InlineTextButton className={css.editPlanButton}
                          onClick={() => setIsEditPlanModalOpen(true)}>
          <IconEdit className={css.editPlanIcon}/>{' '}
          <FormattedMessage id='EditListingAvailabilityPanel.edit'/>
        </InlineTextButton>
      </header>
      {/* {console.log({availabilityPlan})} */}
      <div className={css.week}>
        {WEEKDAYS.map(w =>
          <Weekday 
            key={w} 
            {...{availabilityPlan}} 
            dayOfWeek={w}
            openEditModal={setIsEditPlanModalOpen}/>
        )}
      </div>
    </section>
  )

  return (
    <main className={classes} style={{background: '#fffdfd'}}>
      {/* {console.log(`elap rendering`, availabilityPlan.entries[0])} */}
      {/* <div>EditListingAvailability</div> */}
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id={`EditListingAvailabilityPanel.title${lectStr}`}
            values={{
              listingTitle: (
                <ListingLink listing={listing}>
                  <FormattedMessage id='EditListingAvailabilityPanel.listingTitle'
                                    values={{listingType}}/>
                </ListingLink>
              ),
            }}
          />
        ) : (
          <FormattedMessage id={`EditListingAvailabilityPanel.createListingTitle${lectStr}`}
                            values={{listingType}}/>
        )}
      </h1>
      {
        (!listing.attributes.publicData.flags?.isMaster && !isGroupSession) ?
          <div>
            Your dates have already been set! To edit or review, please&nbsp;
            <NamedLink className={css.link} name="GuideAvailabilityPage">click here</NamedLink>.
          </div> : (
            <>
              {isGroupSession || <WeekDaySelector/>}
              <section className={css.section} style={{background: '#fdfffd'}}>
                <header className={css.sectionHeader}>
                  <h2 className={css.sectionTitle}>
                    {fetchExceptionsInProgress ? (
                      <FormattedMessage
                        id={`EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount${lectStr}`}/>
                    ) : (
                      <FormattedMessage
                        id={`EditListingAvailabilityPanel.availabilityExceptionsTitle${lectStr}`}
                        values={{count: exceptionCount}}
                      />
                    )}
                  </h2>
                </header>
                {fetchExceptionsInProgress ? (
                  <div className={css.exceptionsLoading}>
                    <IconSpinner/>
                  </div>
                ) : exceptionCount === 0 ? (
                  <div className={css.noExceptions}>
                    <FormattedMessage id={`EditListingAvailabilityPanel.noExceptions${lectStr}`}/>
                  </div>
                ) : (
                  <div className={css.exceptions} style={{background: '#fffdff'}}>
                    {sortedAvailabilityExceptions.map(availabilityException => {
                      const {start, end, seats} = availabilityException.attributes
                      const hasSeats = seats > 0
                      const isAvailable = isGroupSession || hasSeats
                      return (
                        <div key={availabilityException.id.uuid} className={css.exception}>
                          <div className={css.exceptionHeader}>
                            <div className={css.exceptionAvailability}>
                              <div
                                className={classNames(css.exceptionAvailabilityDot, {
                                  [css.isAvailable]: isAvailable
                                })}
                              />
                              <div className={css.exceptionAvailabilityStatus}>
                                {isAvailable ? (
                                  <FormattedMessage
                                    id={
                                      isGroupSession
                                        ? `EditListingAvailabilityPanel.eventDate`
                                        : `EditListingAvailabilityPanel.exceptionAvailable`
                                    }
                                    values={{seats}}
                                  />
                                ) : (
                                  <FormattedMessage
                                    id='EditListingAvailabilityPanel.exceptionNotAvailable'/>
                                )}
                                {durationBased && 
                                  <span className={css.exceptionDuration} >&nbsp;{`(${duration} mins)`}</span>}
                              </div>
                            </div>
                            <button
                              className={css.removeExceptionButton}
                              onClick={() => onDeleteAvailabilityException({id: availabilityException.id})}
                            >
                              <IconClose className={css.removeIcon} size='normal'/>
                            </button>
                          </div>
                          {/* TimeRangemod: if start===end, only start will be displayed*/}
                          {/* {console.log({durationBased, start, end})} */}
                          <TimeRange
                            className={css.timeRange}
                            dateType={DATE_TYPE_DATETIME}
                            endDate={durationBased ? start : end}
                            startDate={start}
                            timeZone={timeZone}
                            timeZone3={timeZone3}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
                {/*console.log('SPY', {disabled, ready, exceptionCount, MAX_EXCEPTIONS_COUNT})*/}
                {canAddDate ? (
                  <InlineTextButton
                    className={css.addExceptionButton}
                    disabled={disabled}
                    onClick={() => setIsEditExceptionsModalOpen(true)}
                    ready={ready}
                  >
                    <FormattedMessage id={`EditListingAvailabilityPanel.addException${lectStr}`}/>
                  </InlineTextButton>
                ) : null}
              </section>
            </>
          )
      }

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingAvailabilityPanel.showListingFailed'/>
        </p>
      ) : null}

      {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          disabled={isNextButtonDisabled}
          onClick={onNextTab}
        >
          {submitButtonText}
        </Button>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          containerClassName={css.modalContainer}
          id='EditAvailabilityPlan'
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <EditListingAvailabilityPlanForm
            availabilityPlan={availabilityPlan}
            fetchErrors={errors}
            formId='EditListingAvailabilityPlanForm'
            initialValues={initialValues}
            inProgress={updateInProgress}
            listingTitle={listingTitleOverride || currentListing.attributes.title}
            onSubmit={handleSubmit}
            timeZone3={timeZone3}
            weekdays={WEEKDAYS}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          containerClassName={css.modalContainer}
          id='EditAvailabilityExceptions'
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <EditListingAvailabilityExceptionForm
            availabilityExceptions={sortedAvailabilityExceptions}
            fetchErrors={errors}
            formId='EditListingAvailabilityExceptionForm'
            isGroupSession={isGroupSession}
            onSubmit={saveException}
            timeZone={defaultTimeZone()}
            updateInProgress={updateInProgress}
          />
        </Modal>
      ) : null}
      {Red.on ? <Red.AvailabilityPanel {...{debugAvailability}} /> : ''}
    </main>
  )
}

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  listingTitleOverride: "",
  availabilityExceptions: [],
}

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  listingTitleOverride: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  durationBased: bool.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func, // .isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  isGroupSession: bool.isRequired,
}

export default EditListingAvailabilityPanel
