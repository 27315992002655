export const PRACTITIONER = "Practitioner";
export const CUSTOMER = "Customer";


export const ACUPUNCTURE = "acupuncture";
export const AKASHIC_RECORDS = "akashicRecords";
export const ASTROLOGY = "astrology";
export const AURA_CLEARING = "auraClearing";
export const AURA_READING = "auraReading";
export const AWAKENING = "awakening";
export const BOWEN_THERAPY = "bowenTherapy";
export const BREATHWORK = "breathwork";
export const CHANNELING = "channeling";
export const CHI_GONG = "chiGong";
export const COACHING = "coaching";
export const DANCE = "dance";
export const DEATH_ROUDULA = "deathRoudula";
export const ENERGY_CLEARING = "energyClearing";
export const ENERGY_HEALING = "energyHealing";
export const FUNCTIONAL_MEDICINE = "functionalMedicine";
export const HEALING = "healing";
export const HUMAN_DESIGN = "humanDesign";
export const HYPNOTHERAPY = "hypnotherapy";
export const INTIMACY = "intimacy";
export const KUNDALINI = "kundalini";
export const MEDITATION = "meditation";
export const MEDIUMSHIP = "mediumship";
export const NUMEROLOGY_CHART = "numerologyChart";
export const NUTRITION = "nutrition";
export const PSYCH_K = "psychK";
export const READING = "reading";
export const SHAMANISM = "shamanism";
export const SOUL_BLUEPRINT = "soulBlueprint";
export const SOUND_HEALING = "soundHealing";
export const SPIRITUAL_MENTORING = "spiritualMentoring";
export const TAI_CHI = "taiChi";
export const THETA_HEALING = "thetaHealing";
export const YOGA = "yoga";
export const OTHER = "other";


export const services = [
  ACUPUNCTURE,
  AKASHIC_RECORDS,
  ASTROLOGY,
  AURA_CLEARING,
  AURA_READING,
  AWAKENING,
  BOWEN_THERAPY,
  BREATHWORK,
  CHANNELING,
  CHI_GONG,
  COACHING,
  DANCE,
  DEATH_ROUDULA,
  ENERGY_CLEARING,
  ENERGY_HEALING,
  FUNCTIONAL_MEDICINE,
  HEALING,
  HUMAN_DESIGN,
  HYPNOTHERAPY,
  INTIMACY,
  KUNDALINI,
  MEDITATION,
  MEDIUMSHIP,
  NUMEROLOGY_CHART,
  NUTRITION,
  PSYCH_K,
  READING,
  SHAMANISM,
  SOUL_BLUEPRINT,
  SOUND_HEALING,
  SPIRITUAL_MENTORING,
  TAI_CHI,
  THETA_HEALING,
  YOGA,
  OTHER,
];

export const READINGS = "Readings";
export const CHARTS = "Charts";
export const HEALING_THEME = "Healing";
export const WELLBEING = "Wellbeing";
export const EXPERIENTIAL = "Experiential";
export const ALIGN = "Align";
export const SOS = "SOS";
export const FAMILY = "Family";
export const LIGHTEN_UP = "Lighten Up";

export const topics = [
  READINGS,
  CHARTS,
  HEALING_THEME,
  WELLBEING,
  EXPERIENTIAL,
  ALIGN,
  SOS,
  FAMILY,
  LIGHTEN_UP
]

export const topicsSpecialtyMap = {
  [ACUPUNCTURE]: [WELLBEING],
  [AKASHIC_RECORDS]: [READINGS, FAMILY],
  [ASTROLOGY]: [CHARTS, FAMILY],
  [AURA_CLEARING]: [HEALING_THEME, SOS, LIGHTEN_UP],
  [AURA_READING]: [READINGS, SOS],
  [AWAKENING]: [HEALING_THEME, SOS],
  [BOWEN_THERAPY]: [HEALING_THEME, WELLBEING, FAMILY],
  [BREATHWORK]: [HEALING_THEME, WELLBEING,],
  [CHANNELING]: [READINGS],
  [CHI_GONG]: [WELLBEING, ALIGN, FAMILY, LIGHTEN_UP],
  [COACHING]: [WELLBEING, SOS, FAMILY, LIGHTEN_UP],
  [DANCE]: [WELLBEING, ALIGN, FAMILY, LIGHTEN_UP],
  [DEATH_ROUDULA]: [HEALING_THEME, FAMILY],
  [ENERGY_CLEARING]: [HEALING_THEME, SOS, FAMILY, LIGHTEN_UP],
  [ENERGY_HEALING]: [HEALING_THEME, WELLBEING, ALIGN],
  [FUNCTIONAL_MEDICINE]: [WELLBEING, FAMILY],
  [HEALING]: [HEALING_THEME, WELLBEING, ALIGN, SOS, FAMILY, LIGHTEN_UP],
  [HUMAN_DESIGN]: [CHARTS, FAMILY],
  [HYPNOTHERAPY]: [HEALING_THEME, FAMILY],
  [INTIMACY]: [WELLBEING],
  [KUNDALINI]: [HEALING_THEME, WELLBEING, ALIGN],
  [MEDITATION]: [WELLBEING, ALIGN, LIGHTEN_UP],
  [MEDIUMSHIP]: [READINGS, FAMILY, SOS],
  [NUMEROLOGY_CHART]: [CHARTS, FAMILY],
  [NUTRITION]: [WELLBEING, FAMILY],
  [PSYCH_K]: [HEALING_THEME, SOS, FAMILY, LIGHTEN_UP],
  [READING]: [READINGS, ALIGN],
  [SHAMANISM]: [HEALING],
  [SOUL_BLUEPRINT]: [READINGS],
  [SOUND_HEALING]: [HEALING_THEME, WELLBEING, FAMILY],
  [SPIRITUAL_MENTORING]: [SOS],
  [TAI_CHI]: [WELLBEING, ALIGN, FAMILY, LIGHTEN_UP],
  [THETA_HEALING]: [HEALING_THEME, WELLBEING],
  [YOGA]: [WELLBEING, ALIGN, FAMILY, LIGHTEN_UP],
  [OTHER]: [],
}


// export COLORS = {
//   blue: "#568efc",
// }
