/* eslint-disable object-curly-spacing, spaced-comment, no-void, no-debugger, no-underscore-dangle,
   no-restricted-syntax, quotes, no-return-assign, no-unused-expressions, camelcase
*/
import {getSdk} from '../util/sdkHelper'
import {getPractitionerGroupListingsTimeSlotsArray} from './listingAdapter'
import {extractUser} from './userAdapter'
import {getImminentTimeWindow} from './dateAdapter'
import * as Corelib from '../red/esm/stdlib/corelib-esm'
import { featFlags } from '../red/featureFlags'

const {startEndThrottle} = Corelib.Tardis

const getABL = async user => {
  const groupReservedArrSafety = await getPractitionerGroupListingsTimeSlotsArray(user)

  console.log(`NU?`, {user})
  const {publicData: {aggregatedBookings = ''}} = extractUser(user)
  const {groupReservedArr = groupReservedArrSafety, aggregatedBookingsList = []} = aggregatedBookings
    ? JSON.parse(aggregatedBookings)
    : {}

  console.log(`🩹get cached aggregated group listings & all bookings:`, {aggregatedBookingsList, groupReservedArr})
  return {aggregatedBookingsList, groupReservedArr}
}

const genABL = async user => {
  const sdk = getSdk()

  const groupReservedArr = await getPractitionerGroupListingsTimeSlotsArray(user)

  const response = await sdk.listings.query({
    authorId: user.id.uuid,
    pub_isVerified: null,
    perPage: 20,
    page: 1,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData']
  })
  const listings = response.data.data.filter(listing => !listing.attributes.publicData?.flags?.isMaster)
  console.log(`🔥ABL`, {listings})

  const {today, futureDay} = getImminentTimeWindow()
  console.log(`🔥ABL`, {today, futureDay})
  
  const allBookingsPromise = Promise.all(listings.map(listing => sdk.bookings.query({
    listingId: listing.id.uuid,
    start: today,
    end: futureDay
  })))
  
  allBookingsPromise.catch(err => {
    console.warn(`🔥ABL`, err)
    debugger
    return []
  })
  
  const ret = []
  const respArr = await allBookingsPromise
  const dataArr = respArr.map(({data}, ix) => ({booking: data, listing: listings[ix]}))

  for (const {booking, listing} of dataArr) {
    console.log(`🔥`, {booking, listing})
    const {title, publicData} = listing.attributes || {}
    const {isGroupSession, serviceType = '', sessionType = '', duration = 0} = publicData
    for (const {attributes} of booking.data) {
      console.log(`🔥BOOKING`, attributes)
      const {start, end, seats, state} = attributes
      state === 'accepted' && ret.push({start, end, seats, title, duration, isGroupSession, serviceType, sessionType})
    }
  }
  console.log(`🔥`, {respArr})
  return {groupReservedArr, aggregatedBookingsList: ret}
}

const abl = {}
const bbl = {}

// FIX gen -> no need for promise
export const getPractitionersAggregatedBookings = (user, id = user.id?.uuid) => abl[id] || (abl[id] = getABL(user))

export const genPractitionersAggregatedBookings = (user, id = user.id?.uuid) => bbl[id] || (bbl[id] = genABL(user))

const updateProfile = aggregatedBookings => {
  const sdk = getSdk()

  console.log(`💉💉💉`, {aggregatedBookings})

  sdk.currentUser.updateProfile({publicData: {aggregatedBookings}}, {expand: false})
    .then(response => console.log(`updated`, {response}))
    .catch(e => console.error(e))
}

const lazyUpdateProfile = startEndThrottle(updateProfile, 900)

const regenerateCache = user => genPractitionersAggregatedBookings(user)
  .then(aggregatedBookings => {
    featFlags.isDev && console.log(`💉💉Rewriting ABL cache:`, {aggregatedBookings}, {user})
    lazyUpdateProfile(JSON.stringify(aggregatedBookings)) // FIX rename

    featFlags.isDev && console.log(`💉💉Will return aggregatedBookings`)
    return aggregatedBookings
  })

//const lazyRegenerateCache = startEndThrottle(regenerateCache, 500)

export const bookingListener = async (ret, user) => {
  featFlags.isDev && console.log(`bookingListener: accept awaited:`, await ret, {user})
  const {protectedData} = extractUser(user)
  if (protectedData.userType === 'Practitioner') { // YUCK!!!! temporary
    const aggregatedBookings = await regenerateCache(user)
    featFlags.isDev && console.log(`💉Will return aggregatedBookings`, {aggregatedBookings})
    return aggregatedBookings
  }
  return []
}
