/* eslint-disable no-void, spaced-comment, no-restricted-syntax, prefer-template,
   no-debugger, object-curly-spacing, quotes, no-return-assign, no-unused-expressions */

import React, {useEffect, useState} from 'react'
import PropTypes, {object} from 'prop-types'
import classNames from 'classnames'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {FormattedMessage} from '../../util/reactIntl'
import Gate from "../Gate/Gate"
import {ReactComponent as Arrow} from "../Arrow/Arrow.svg"
import css from './SectionPeople.module.css'
import "./carousel.css"
import {listPractitioners} from "../../util/api"
import {useIntl} from 'react-intl'
import {displayUserLanguages, getUserLanguages} from "../../util/practitioners";

export const ProfileGate = ({profile}) => {
  const intl = useIntl();
  const specialtyString = profile
    ?.specialties
    ?.slice(0, 3)
    .map(specialty => intl.formatMessage({id: `Services.${specialty}`}))
    .join(", ");

  return (
    <div className={css.practitionerProfile}>
      <img alt='' className={css.photo} src={profile.imageUrl}/>
      <div className={css.name}>
        {profile.name}
      </div>
      <div className={css.location}>
        Lives in <span className={css.city}>{profile.city}</span>
      </div>
      <div className={css.language}>
        Speaks <span className={css.languageList}>{displayUserLanguages(profile)}</span>
      </div>
      <div className={css.skills}>
        <span>{specialtyString}</span>
        {profile.specialties?.length > 3 && <span>+</span>}
      </div>
    </div>
  )
};

const SectionPeople = props => {
  const {rootClassName, className, people} = props;
  const [selected, setSelected] = React.useState(0);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    let mounted = true
    listPractitioners().then(users => mounted && setUsers(users.slice(0, 3)))
    return () => mounted = false
  }, [])

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.leftContainer}>
        <div className={css.title}>
          <FormattedMessage id="LandingPageSectionPeople.title"/>
        </div>

        <div className={css.lookingFor}>
          <FormattedMessage id="LandingPageSectionPeople.lookingFor"/>
        </div>

        <div className={css.verticalLine}/>

        <a className={css.viewAll} href="/guides">
          <FormattedMessage id="LandingPageSectionPeople.viewAll"/>
          <Arrow/>
        </a>
      </div>

      <div className={css.practitionerContainer}>
        <div className={css.gateContainer}>
          <div className={css.gateControls}>
            <span onClick={() => setSelected(selected - 1)}>
              <Arrow className={css.leftArrow}/>
            </span>

            <div className={css.controlLine}/>

            <span onClick={() => setSelected(selected + 1)}>
              <Arrow className={css.rightArrow}/>
            </span>
          </div>

          <Gate className={css.gate}>
            <Carousel
              emulateTouch
              onChange={(index) => setSelected(index)}
              selectedItem={selected}
              showArrows={false}
              showIndicators
              showStatus={false}
              showThumbs={false}
              swipeable
            >
              {
                users.map(user => <ProfileGate key={user.id} profile={user}/>)
              }
            </Carousel>
          </Gate>
        </div>
      </div>
    </div>
  );
};

SectionPeople.defaultProps = {rootClassName: null, className: null};

const {string} = PropTypes;

SectionPeople.propTypes = {
  rootClassName: string,
  className: string,
  listings: object,
};

export default SectionPeople;


