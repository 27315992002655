export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log(`\n\n\n%cAnalytics page view: ${url}`, 'background:#bbb;color:#000;font-weight:900;padding:8px 4px 2px;border-radius: 16px 16px 0 0;font:700 14px roboto condensed');
  }
}

export class GoogleAnalyticsHandler {
  constructor(ga) {
    if (typeof ga !== 'function') {
      throw new TypeError('Variable `ga` missing for Google Analytics');
    }
    this.ga = ga;
  }

  trackPageView(url) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
    this.ga('set', 'page', url);
    this.ga('send', 'pageview');
  }
}
